import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Input, Modal, Table, TooltipCurrency } from 'components'
import { Currency, Globals } from 'utils'
import { CashModalFinesProps, Fine, Invoice } from '../Cash'
import { useCashContext } from '../useCashContext'

const headerModal = ['', 'Descripción', 'Total']

const CashModalFines = ({ visible, setVisible }: CashModalFinesProps) => {
  const { loadingFines, getPropertyIdFines, fines, setFines, property, invoices, setInvoices } = useCashContext()
  const [search, setSearch] = useState('')

  const addToInvoices = () => {
    const filter = fines
      .filter((fine) => {
        return fine.isSelected && !invoices.some((i) => i.id === fine.id)
      })
      .map((fine): Invoice => {
        return {
          id: fine.id,
          fines_id: fine.id,
          name: fine.name,
          amount: fine.total,
          amount_bs: Currency.Convert(Currency.Convert(fine.total, property?.petro_value!), 1),
        }
      })

    setInvoices([...invoices, ...filter])
    onClose()
  }

  const handleFilterFine = () => {
    return fines.filter((fine) => fine.name.toLowerCase().includes(search.trim().toLowerCase()))
  }

  const handleSelected = (fine: Fine) => {
    const index = fines.findIndex((f) => f.id === fine.id)
    const list = fines.map((f, i) => {
      // if (f.id === fine.id) f.isSelected = !fine.isSelected
      f.isSelected = i <= index

      return f
    })

    setFines(list)
  }

  const onClose = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (visible && property) {
      getPropertyIdFines(property.id)
    }
  }, [visible])

  if (!property) {
    return null
  }

  return (
    <Modal className="high-modal modal-view-account-status" visible={visible} onClose={onClose} title="Agregar Multa">
      <div>
        <div className="d-flex flex-column w-50">
          <Input
            color="white"
            searchRight
            value={search}
            name="search"
            placeholder="Buscar..."
            onChange={(e: any) => {
              setSearch(e.target.value)
            }}
          />
        </div>

        {loadingFines && <p className="text-center mt-5">Cargando...</p>}

        {!loadingFines && (
          <>
            <Table title="" data={handleFilterFine()} header={headerModal}>
              {handleFilterFine().length === 0 ? (
                <tr>
                  <td className="text-center" colSpan={headerModal.length}>
                    No hay datos
                  </td>
                </tr>
              ) : (
                handleFilterFine().map((fine) => (
                  <tr key={fine.id} onClick={() => handleSelected(fine)}>
                    <td>
                      <Checkbox value={fine.id} checked={fine.isSelected} />
                    </td>
                    <td className="capitalize">{fine.name}</td>
                    <td>
                      <TooltipCurrency title={fine.total}>
                        {Globals.formatMiles(Currency.Convert(fine.total, property.petro_value), true, Currency.BOLIVAR)}
                      </TooltipCurrency>
                    </td>
                  </tr>
                ))
              )}
            </Table>
          </>
        )}

        <div className="d-flex justify-content-end mt-3">
          <Button className="btn-urbaser-green" onClick={addToInvoices}>
            Agregar a factura
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CashModalFines
