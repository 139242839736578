import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Input, Tooltip, PaperClip, TooltipCurrency } from 'components';
import { VoucherHistoryService } from 'services';
import moment from 'moment';
import { Globals, Currency, Print, Constants, RegisterMovement } from 'utils';
import { AdjuntarIcon, PrintIcon } from 'assets/icons';
import { ImagesAndDocuments } from 'components/paperclip';
import CreditNotes from './credit-notes';
import queryString from 'query-string';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

const INITIAL_STATE = {
  search: ''
}

const TABS = {
  BILLS: 1,
  CREDIT: 2
}

class VoucherHistory extends React.Component<Props> {  

  timer: any = undefined;

  state = {
    header: [
      'Fecha',
      'Tipo',
      'Nro. de Factura',
      'Descripción',
      'Banco',
      'Nro. Referencia',
      'Código',
      'Razón Social',
      'Medio de Pago',
      'Periodo',
      'Estatus',
      'Monto',
      'Comprobante de Retención'
    ],
    page: 1,
    last_page: 1,
    data: [],
    form: INITIAL_STATE,
    tab: TABS.BILLS
  }

  componentDidMount() {
  	this.props.dispatch({
  		type: 'SET_HEADER',
  		payload: {
  			title: 'Historial de Comprobantes',
  			onBack: null
  		}
  	});

    const params = queryString.parse(this.props.location.search);

    // @ts-ignore
    if (params.tab && params.tab == TABS.CREDIT) {
      this.setState({
        tab: params.tab
      });
    }
    else {
      this.load();
    }
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await VoucherHistoryService.admin.get({
      page: this.state.page,
      ...this.state.form,
      withoutLoading
    });
    this.setState({
      data: res.bills.data,
      last_page: res.bills.last_page
    });
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  upload = async (e: any,item: any) => {
    await VoucherHistoryService.admin.createFile({
      bill_id: item.id,
      file: e.target.value,
      hasFile: true
    });
    Globals.showSuccess("Se ha subido el archivo correctamente");
    this.load(true);
    RegisterMovement.save({
        value_id: item.id,
        user_id: this.props.user.id,
        module_id: Constants.MODULES.VOUCHER_HISTORY,
        description: `Adjunto de retención del contribuyente ${ item?.user?.full_name } ${ item?.user?.full_document }`
    });
  }

  print = (e: any,url: string) => {
    e.preventDefault();
    Print(url);
  }

  setTab = (tab: number) => {
    this.setState({
      tab
    },() => {
      this.props.history.push('/admin/voucher-history?tab=' + tab);
      if (tab == TABS.BILLS) {
        this.load();
      }
    });
  } 
  
  render() {
    const { tab } = this.state;

    return (
        <div id="admin-voucher-history">
          <div className="container-tabs">
            <div className={ `tab ${ tab == TABS.BILLS ? 'active' : '' }` } onClick={ () => this.setTab(TABS.BILLS) }>
              <p>Facturas/Convenios</p>
            </div>
            <div className={ `tab ${ tab == TABS.CREDIT ? 'active' : '' }` } onClick={ () => this.setTab(TABS.CREDIT) }>
              <p>Notas de Crédito</p>
            </div>         
          </div>

          {
            tab == TABS.CREDIT && (
              <CreditNotes user={ this.props.user } />
            )
          }

          {
            tab == TABS.BILLS && (
              <React.Fragment>
                <p className="top">Esta sección le permite mantener control sobre el histórico de pagos realizados por conceptos de tasa de servicio de Aseo Urbano, entre los cuales se encuentran factura, recibos, notas de crédito, convenios de pago, multas y servicios especiales, entre otros.</p>

                <div className="row row-filter">
                    <div className="col-md-6">
                        <Input
                          color="white"
                          searchRight
                          value={ this.state.form.search }
                          name="search"
                          placeholder="Búsqueda por nro, código o referencia"
                          onChange={ (e: any) => {
                            clearTimeout(this.timer);
                            this.timer = setTimeout(() => {
                              this.load(true);
                            },1000);
                            this.change(e); 
                          } } />
                    </div>
                  </div>

                {
                  this.state.form != INITIAL_STATE && (
                    <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
                  )
                }  

                <Table data={ this.state.data.length } title="" header={ this.state.header }>
                    { this.state.data.map((i: any,index: number) => {
                      return (
                        <tr key={ index }>
                          <td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
                          <td>{ i.property?.type?.name || '-' }</td>
                          <td>{ i.number_format }</td>
                          <td>{ i.bill_type?.name || '-' }</td>
                          <td>{ i.details?.bank?.name || '-' }</td>
                          <td>{ i.details?.number || '-' }</td>
                          <td>{ i.user.username}</td>
                          <td>{ i.user.business_name }</td>
                          <td>{ i.payment_method?.name || '-' }</td>
                          <td className="capitalize no-wrap">{ i.since ? (moment(i.since).format('MMM YYYY') + (i.until && i.until != i.since ? (' - ' + moment(i.until).format('MMM YYYY')) : '')) : moment(i.created_at).format('MMM YYYY') }</td>
                          <td>{ i.status_text }</td>

                          <td className="no-wrap"><TooltipCurrency title={ i.total }>{ Globals.formatMiles(Currency.ConvertAux(i.total,i.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></td>
                          <td className="icon-actions">
                            {
                              i.user?.retention == Constants.RETENTION.YES && (
                                <Tooltip title="Adjuntar">
                                  <PaperClip
                                    formats={ ImagesAndDocuments }
                                    onChange={ (e: any) => {
                                      this.upload(e,i);
                                    } } />
                                </Tooltip>
                              )
                            }
                            
                              {
                                i.file && (
                                  <Tooltip title="Imprimir">
                                    <a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i.file?.file_url) }>
                                      <img src={ PrintIcon } />
                                    </a>
                                  </Tooltip>
                                )
                              }                        
                          </td>
                        </tr>
                      )
                    }) }
                  </Table>

                  <Pagination 
                    pages={ this.state.last_page } 
                    active={ this.state.page }
                    onChange={ async (page: number) => {
                      await this.setState({
                        page: page
                      });
                      this.load();
                  } } />
              </React.Fragment>
            )
          }
        </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(VoucherHistory);