import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { store, persistor } from "store";
import { Table, Pagination, Input, Select, Button, Tooltip, DatePicker, Modal } from 'components';
import { ViewIcon, CheckIcon, CloseIcon, PrintIcon } from 'assets/icons';
import { UrbanCleaningSolvencyService } from 'services';
import ViewSolvency from './view-solvency';
import CreateSolvency from './create-solvency';
import { Globals, Constants, Print } from 'utils';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

const INITIAL_SEARCH = {
  search: ''
}

class UrbanCleanliness extends React.Component<Props> {

  timer: any = undefined;

  state = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'Fecha',
      'Contribuyente',
      'Código',
      'No. Solvencia',
      'Acciones'
    ],
    solvency: null,
    user: this.props.user.id,
    visible: false,
    visible_create: false,
    properties: [],
    form:INITIAL_SEARCH
  }

  componentDidMount() {
  	this.props.dispatch({
  		type: 'SET_HEADER',
  		payload: {
  			title: 'Solvencia Aseo Urbano',
  			onBack: null
  		}
  	});
    this.getProperties();
    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await UrbanCleaningSolvencyService.get({
      user_id: this.props.user.id,
      page: this.state.page,
      withoutLoading,
      ...this.state.form
    });
    
    this.setState({
      data: res.solvencies.data,
      last_page: res.solvencies.last_page
    });
  }

  getProperties = async () => {
    const properties: any = await UrbanCleaningSolvencyService.getProperties({
      user_id: this.props.user.id
    });
    this.setState({
      properties: properties.properties
    });
  }

  view = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      solvency: item
    });
  }

  onClose = () => {
    this.setState({
      visible: false
    });
  }

  onCloseCreate = () => {
    this.setState({
      visible_create: false
    });
    this.load();
  }

  create = (e: any) => {
    e.preventDefault();
    this.setState({
      visible_create: true
    }); 
  }

  print = (e: any, item: any) => {
    e.preventDefault();
    Print(UrbanCleaningSolvencyService.download(item.file),true);
  }

  change =  (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
    this.load(true);
  }
  
  render() {
    const { visible, visible_create } = this.state;

    return (
        <div id="urban-cleanliness">
          <Modal
              className="modal-view-solvency"
              visible={ visible }
              onClose={ this.onClose } 
              title="Solvencia de Aseo Urbano"
          >
            <ViewSolvency
              solvency={ this.state.solvency }
            />
          </Modal>

          <Modal
              className="modal-create-solvency"
              visible={ visible_create }               
              onClose={ this.onCloseCreate }
              title="Generar Solvencia de Aseo Urbano"
          >
            <CreateSolvency user={ this.props.user } properties={ this.state.properties } onClose={ this.onCloseCreate}/>
          </Modal>
          <h3 className="title title-admin">Bandeja</h3>
          <p>
            El pago oportuno del servicio de aseo urbano y domiciliario en el Municipio le otorgará una solvencia requerida para renovar
            o pagar licencias de actividades económicas, así como tramitar cualquier permiso autorización en la administración municipal, 
            con relación a inmuebles ubicados en el territorio del Municipio.
          </p>
          <p>
            Estimado usuario para que la solvencia tenga validez debe dirigirse a nuestras oficinas para la certificación.
          </p>
          <p>
            Para cualquier otro trámite que no tenga que ver con los entes municipales, la solvencia tendrá un costo que está establecido en la ordenanza.
          </p>

          <div className="row">
            <div className="col-4">
              <Button type="submit" onClick={ this.create }>
                Solicitar Solvencia
              </Button>
            </div>
            {/* <div className="col-8 mt-3">
              <Input
                color="white"
                searchRight
                value={ this.state.form.search }
                name="search"
                placeholder="Buscar (Contribuyente, Código, No Solvencia)"
                onChange={ (e: any) => {
                  clearTimeout(this.timer);
                  this.timer = setTimeout(() => {
                    this.load(true);
                  },1000);
                  this.change(e); 
                } } 
              />
            </div> */}
          </div>

          <Table data={ this.state.data.length } title="" header={ this.state.header }>
            { this.state.data.map((i: any,index: number) => {
              return (
                <tr key={ index }>
                  <td className="text-nowrap">{ moment(i.created_at).format('DD/MM/YYYY') }</td>
                  <td className="text-nowrap">{ i.user?.full_name }</td>
                  <td className="text-nowrap">{ i.property?.urbaser_code }</td>
                  <td className="text-nowrap">{ i.number_format }</td>
                  <td className="icon-actions">
                    <Tooltip title="Ver">
                      <a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
                        <img src={ ViewIcon } />
                      </a>
                    </Tooltip>
                    <Tooltip title="Imprimir">
                      <a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
                        <img src={ PrintIcon } />
                      </a>
                    </Tooltip>
                  </td>
                </tr>
              )
            }) }
          </Table>
          <Pagination 
            pages={ this.state.last_page } 
            active={ this.state.page }
            onChange={ async (page: number) => {
              await this.setState({
                page: page
              });
              this.load();
          } } />

        </div>        
    )
  }
}

//export default connect()(UrbanCleanliness);

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(UrbanCleanliness);