import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Input, Select, Button, Tooltip, DatePicker, Modal } from 'components';
import { UrbanCleaningSolvencyService } from 'services';
import { ViewIcon, CheckIcon, TrashIcon, PrintIcon } from 'assets/icons';
import { Globals, Constants, Print, RegisterMovement } from 'utils';
import ViewSolvency from './view-solvency';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

const INITIAL_SEARCH = {
  search: ''
}

class UrbanCleaningSolvencies extends React.Component<Props> {

  timer: any = undefined;

  state = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'Fecha',
      'Contribuyente',
      'Código',
      'No. Solvencia',
      'Acciones'
    ],
    form:INITIAL_SEARCH,
    visible: false,
    solvency: null
  }

  componentDidMount() {
  	this.props.dispatch({
  		type: 'SET_HEADER',
  		payload: {
  			title: 'Solvencias Aseo Urbano',
  			onBack: null
  		}
  	});
    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await UrbanCleaningSolvencyService.admin.get({
      page: this.state.page,
      withoutLoading,
      ...this.state.form
    });
    this.setState({
      data: res.solvencies.data,
      last_page: res.solvencies.last_page
    });
  }

  view = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      solvency: item
    });
  }

  delete = (e: any, item: any) => {
    e.preventDefault();
    Globals.confirm('¿Desea eliminar esta solvencia?',async () => {
      await UrbanCleaningSolvencyService.admin.delete({
        solvency_id: item.id
      });
      Globals.showSuccess("Se ha eliminado correctamente la solvencia");
      this.load();
    });
  }

  print = (e: any, item: any) => {
    e.preventDefault();
    Print(UrbanCleaningSolvencyService.admin.download(item.file),true);
    RegisterMovement.save({
        value_id: item.id,
        user_id: this.props.user.id,
        module_id: Constants.MODULES.URBAN_CLEANLINESS,
        description: `Impresión de solvencia del contribuyente ${ item?.user?.full_name } ${ item?.user?.full_document }`
    });
  }

  onClose = () => {
    this.setState({
      visible: false
    });
  }

  change = async (e: any,callback = () => {}) => {
    await this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
    this.load(true);
  }
  
  render() {
    const { visible } = this.state;

    return (
      <div id="urban-cleaning-solvencies">
        <Modal
            className="modal-view-solvency"
            visible={ visible }
            onClose={ this.onClose } 
            title="Solvencia de Aseo Urbano"
        >
          <ViewSolvency
            solvency={ this.state.solvency }
          />
        </Modal>
        <div className="row">
          <div className="col-md-8">
            <Input
              color="white"
              searchRight
              value={ this.state.form.search }
              name="search"
              placeholder="Buscar (Contribuyente, Código, No Solvencia)"
              onChange={ (e: any) => {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                  this.load(true);
                },1000);
                this.change(e); 
              } } 
            />
          </div>
        </div>
        <Table data={ this.state.data.length } title="" header={ this.state.header }>
          { this.state.data.map((i: any,index: number) => {
            return (
              <tr key={ index }>
                <td className="text-nowrap">{ moment(i.created_at).format('DD/MM/YYYY') }</td>
                <td className="text-nowrap">{ i.user?.full_name }</td>
                <td className="text-nowrap">{ i.property?.urbaser_code }</td>
                <td className="text-nowrap">{ i.number_format }</td>
                <td className="icon-actions">
                  <Tooltip title="Ver">
                    <a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
                      <img src={ ViewIcon } />
                    </a>
                  </Tooltip>
                  <Tooltip title="Imprimir">
                    <a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
                      <img src={ PrintIcon } />
                    </a>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
                      <img src={ TrashIcon } />
                    </a>
                  </Tooltip>
                </td>
              </tr>
            )
          }) }
        </Table>
        <Pagination 
          pages={ this.state.last_page } 
          active={ this.state.page }
          onChange={ async (page: number) => {
            await this.setState({
              page: page
            });
            this.load();
        } } />
      </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(UrbanCleaningSolvencies);