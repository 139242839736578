import { Api, Constants } from 'utils';

class UserService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/moderators/get',params),
		getModules: async (params?: any) => Api.createResponse('admin/moderators/modules',params),
		create: async (params?: any) => Api.createResponse('admin/moderators/create',params),
		edit: async (params?: any) => Api.createResponse('admin/moderators/edit',params),
		getUser: async (params?: any) => Api.createResponse('admin/moderators/get-user',params),
		status: async (params?: any) => Api.createResponse('admin/moderators/status',params),
		delete: async (params?: any) => Api.createResponse('admin/moderators/delete',params),
		download: (id?: string) => Constants.BASE_API + 'admin/moderators/download/' + id,
		roles: async (params?: any) => Api.createResponse('admin/roles/get'),
	}
}

export default UserService;