import React from 'react';
import { Input, Button } from 'components';
import { ProfileService } from 'services';
import moment from 'moment';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { Globals } from 'utils';
import { RouteComponentProps } from 'react-router-dom';

const INITIAL_STATE = {
	password: '',
	password_confirmation: '',
	current_password: ''
}

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Password extends React.Component<Props> {

	state = {
		form_password: INITIAL_STATE
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Cambiar contraseña',
				onBack: null
			}
		});
	}

	submitPassword = async (e: any) => {
		e.preventDefault();
		await ProfileService.password({
			user_id: this.props.user.id,
			...this.state.form_password
		});
		this.setState({
			form_password: INITIAL_STATE
		});
		Globals.showSuccess("Se ha actualizado la contraseña correctamente");
	}

	changePassword = (e: any) => {
		this.setState({
			form_password: {
				...this.state.form_password,
				[e.target.name]: e.target.value
			}
		});
	}
	
	render() {
		return (
			<div id="password">
				<p className="important">
					Importante:
				</p>
				<ul>
					<li>- Cuando elija una contraseña, evite utilizar palabras comunes o que puedan adivinarse con facilidad</li>
					<li>- Por ejemplo, evite utilizar nombres de miembros de la familia o mascotas, su fecha de nacimiento, etc.</li>
				</ul>
				<div className="row">
					<div className="col-md-12">
						<form onSubmit={ this.submitPassword }>
							<div className="row container-form col-12">
								<div className="col-md-4">
									<Input
										type="password"
										color="white"
										value={ this.state.form_password.current_password }
										name="current_password" 
										placeholder="Contraseña Actual"
										onChange={ this.changePassword } />
								</div>
								<div className="col-md-4">
									<Input
										type="password"
										color="white"
										value={ this.state.form_password.password }
										name="password" 
										placeholder="Nueva Contraseña"
										onChange={ this.changePassword } />
								</div>
								<div className="col-md-4">
									<Input
										type="password"
										color="white"
										value={ this.state.form_password.password_confirmation }
										name="password_confirmation" 
										placeholder="Repetir Contraseña"
										onChange={ this.changePassword } />
								</div>
							</div>
							<div className="col-12 text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user,
		header: state.header
	}
})(Password);