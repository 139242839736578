import { axios, Error, Globals } from 'utils'
import { AxiosError, AxiosResponse } from 'axios'

const txtTokenInvalid = 'Token is Invalid,Token is Expired,Authorization Token not found'
const methodsPost = ['post', 'put', 'patch', 'upload']

class Api {
  createResponse = (uri: string, params?: any) => {
    const opt: any = { headers: {} }
    if (!params) {
      params = {}
    }
    return new Promise((resolve, reject) => {
      if (!params.withoutLoading) {
        Globals.setLoading()
      }

      const token = localStorage.getItem('urbtoken')
      if (token) {
        opt.headers['Authorization'] = `Bearer ${token}`
      }

      let method = ''

      if (params.method) {
        method = params.method
      } else {
        method = params.hasFile ? 'upload' : 'post'
      }

      let fetchData = null

      if (methodsPost.includes(method)) {
        fetchData = axios[method](uri, params, opt)
      } else {
        delete params.method
        fetchData = axios[method](uri, {
          ...opt,
          params,
        })
      }

      fetchData
        .then((res: AxiosResponse) => {
          if (!params.withoutLoading) {
            Globals.quitLoading()
          }

          if (txtTokenInvalid.includes(res.data.status)) {
            localStorage.removeItem('persist:SARU')
            localStorage.removeItem('urbtoken')
            window.location.href = '/'

            // setTimeout(() => {
            //   Error.default(res.data)
            //   reject(res.data)
            // }, 100)
          }

          setTimeout(() => {
            resolve(res.data)
          }, 100)
        })
        .catch((err: AxiosError) => {
          if (!params.withoutLoading) {
            Globals.quitLoading()
          }
          setTimeout(() => {
            Error.default(err)
            reject(err)
          }, 100)
        })
    })
  }
}

export default new Api()
