import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, SelectSearch, Pagination, Input, Select, Button, Tooltip, DatePicker, Modal } from 'components';
import { checkPermission, Permissions, Constants, Socket, SocketEvents, Globals, Print, Currency } from 'utils';
import { ReportService, CashService } from 'services';
import { CardIcon } from 'assets/icons';
import moment from 'moment';

interface LocationProps {
  page: number | undefined;
  form: any
}

interface Props extends RouteComponentProps<{},{},LocationProps> {
  dispatch: any
}

const INITIAL_STATE = {
  search: '',
  since: '',
  until: '',
  client_id: '',
  service_type_id: ''
}

class OverdueCollection extends React.Component<Props> {  

  timer: any = undefined;

  state = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'Fecha',
      //'Tipo',
      'RIF',
      'Contribuyente',
      'Cod. Propiedad',
      'Actividad Comercial',
      'Cod. Factura',
      'Monto Deuda (UCD)',
      'Monto Deuda (Bs)',
      'Acción'
    ],
    form: INITIAL_STATE,
    clients: [],
    client: null,
    conversion_petro: 0,
    service_types: []
  }

  async componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Reporte de cobros vencido',
        onBack: null
      }
    });

    await this.setState({
      page: this.props.location.state?.page || this.state.page,
      form: this.props.location.state?.form || this.state.form
    });

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const client: any = this.state.client;
    const res: any = await ReportService.admin.overdue_collection({
      page: this.state.page,
      withoutLoading,
      ...this.state.form,
      since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
      until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
      client_id: client?.id || ''
    });

    this.setState({
      data: res.payments.data,
      last_page: res.payments.last_page,
      conversion_petro: res.conversion_petro?.amount || 0,
      clients: res.users,
      service_types: res.service_types
    });
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1,
      client: null
    },() => this.load(true));
  }
  
  render() {
    const { clients } = this.state;
    const client: any = this.state.client;

    return (
        <div id="overdue-collection-report">
            <div className="row">
              <div className="col-md-4">
                <DatePicker
                  color="white"
                  label="Desde"
                  maxDate={ moment(this.state.form.until || moment()).toDate() }
                  value={this.state.form.since}
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'since', value }
                  },() => this.load(true))}
                />
              </div>
              <div className="col-md-4">
                <DatePicker
                  color="white"
                  label="Hasta"
                  maxDate={ moment().toDate() }
                  minDate={ this.state.form.since && moment(this.state.form.since).toDate() }
                  value={this.state.form.until}
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'until', value }
                  },() => this.load(true))}
                />
              </div>
              <div className="col-md-4">
                  <SelectSearch
                    label="Contribuyente"
                    onChange={ (client: any) => {
                      this.setState({
                        page: 1,
                        form: {
                          ...this.state.form,
                          client_id: client.id
                        },
                        client: {
                          id: client.value,
                          full_name: client.label
                        }  
                      },() => this.load(true));
                    } }
                    placeholder="Seleccionar Contribuyente"
                    value={ {
                      value: client?.id || '',
                      label: client?.full_name || ''
                    } }
                    options={ clients.map((i: any) => {
                      return {
                        value: i.id,
                        label: i.full_name + ' - ' + i.full_document + ' - ' + i.username
                      }
                    }) } />
                </div>
                {/* <div className="col-md-4">
                  <Select
                    label="Tipo de Servicio"
                    name="service_type_id"
                    onChange={(e: any) => this.change(e,() => this.load(true))}
                    placeholder="Tipo"
                    value={ this.state.form.service_type_id }
                    options={ this.state.service_types.map((item: any) => {
                      return {
                        value: item.id,
                        label: item.name
                      }
                    }) } 
                  />
                </div> */}
                <div className="col-md-4">
                  <Input
                    color="white"
                    searchRight
                    value={ this.state.form.search }
                    name="search"
                    placeholder="Buscar (Código, Contribuyente o RIF)"
                    onChange={ (e: any) => {
                      clearTimeout(this.timer);
                      this.timer = setTimeout(() => {
                        this.load(true);
                      },1000);
                      this.change(e); 
                    } } />
                </div>
                <div className="col-md-4">
                  {
                    this.state.data.length > 0 && (
                      <div>
                        <a href={ Constants.BASE_API + 'admin/reports/overdue_collection/excel?' + Globals.getParams(this.state.form) }>
                          <Button className="btn-blue-create">
                            Exportar Excel Detallado
                          </Button>
                        </a>
                        <a href={ Constants.BASE_API + 'admin/reports/overdue_collection/excel?group=true&' + Globals.getParams(this.state.form) } >
                          <Button className="btn-blue-create ml-2">
                            Exportar Excel
                          </Button>
                        </a>
                      </div>
                    )
                  }
                </div>
              </div>

              {
                this.state.form != INITIAL_STATE && (
                  <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
                )
              }  

              <Table data={ this.state.data.length } title="" header={ this.state.header }>
                { this.state.data.map((report: any,index: number) => {
                  return (
                    <tr key={ index }>
                      <td className="no-wrap">{ moment(report.created_at).format('DD/MM/YYYY HH:mm') }</td>
                      {/* <td>{ report.service_type?.name || '-' }</td> */}
                      <td className="no-wrap">{ report.user?.full_document }</td>
                      <td>{ report.user?.full_name }</td>
                      <td>{ report.property?.urbaser_code }</td>
                      <td>{ report.property?.economic_activity?.name }</td>
                      <td>{ (report.bills.length > 0 && report.bills[0].number_format) || '-' }</td>
                      <td className="no-wrap">{ Globals.formatMiles(report.total,true,Currency.PETRO, 4) }</td>
                      <td className="no-wrap">{ Globals.formatMiles(Currency.ConvertAux(report.total,this.state.conversion_petro),true,Currency.BOLIVAR) }</td>
                      <td className="icon-actions">
                        <Tooltip title="Pagar">
                          <Link className="link-icon" to={ `/admin/cash-new/${ report.user?.id }` }>
                            <img src={ CardIcon } />
                          </Link>
                        </Tooltip>
                      </td>
                    </tr>
                  )
                }) }
              </Table>

              <Pagination 
                pages={ this.state.last_page } 
                active={ this.state.page }
                onChange={ async (page: number) => {
                  await this.setState({
                    page: page
                  });
                  this.load();
              } } />
        </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(OverdueCollection);