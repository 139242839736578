import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Input, Button, Select, DatePicker, Modal } from 'components';
// import { ClientService } from 'services';
// import { Globals, Constants, RegisterMovement } from 'utils';
// import { TrashIcon } from 'assets/icons';
import moment from 'moment';
import FormClientProperty from 'components/FormClientProperty';
import { ClientService } from 'services';
import { Constants, Globals, RegisterMovement } from 'utils';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

class CreateClient extends React.Component<Props> {
  load = async (self: any) => {
    const res: any = await ClientService.admin.getDocumentTypes()
    self.setState({
      codes: res.codes,
      document_types: res.document_types,
      economic_activities: res.economic_activities,
      property_types: res.property_types,
      property_uses: res.property_uses.filter((item: any) => item.name !== 'Otro'),
    })
  };

  submit = async (self: any) => {
    await ClientService.admin.validate({
      ...self.state.form,
    })

    const property: any = Globals.clone(self.state.property)
    property.date = property.date && moment(property.date, 'DD-MM-YYYY').format('YYYY-MM-DD')

    const res: any = await ClientService.admin.create({
      ...self.state.form,
      emails: JSON.stringify(self.state.emails),
      phones: JSON.stringify(self.state.phones),
      property: JSON.stringify(property),
      // property: JSON.stringify(
      //   properties.map((item: any) => {
      //     item.date = item.date && moment(item.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      //     return item
      //   })
      // ),
    })

    Globals.showSuccess('Se ha creado correctamente el usuario')
    self.props.history.push({
      pathname: '/admin/users/clients',
      state: self.props.location.state,
    })

    RegisterMovement.save({
      value_id: res.client?.id,
      user_id: self.props.user.id,
      module_id: Constants.MODULES.CLIENTS,
      description: `Registro del contribuyente ${res.client?.full_name} ${res.client?.full_document}`,
    })
  };

  render() {
    return (
      <FormClientProperty
        {...this.props}
        show="ALL"
        headTitle="Crear Contribuyente"
        showNote={true}
        onBack={() => {
          this.props.history.push({
            pathname: '/admin/users/clients',
            state: this.props.location.state
          });
        }}
        onLoad={this.load}
        onSubmit={this.submit}
        isAdmin={this.props.user.level_id != 3}
      />
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(CreateClient);