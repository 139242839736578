import React from 'react';
import { Table, Pagination, Button, InputCurrency, DatePicker, Input, Tooltip, Modal } from 'components';
import { Discounts, EconomicActivityService } from 'services';
import { Globals, RegisterMovement, Constants } from 'utils';
import moment from 'moment';
import { PropsEdit, INITIAL_STATE } from './interfaces';

class EditDiscount extends React.Component<PropsEdit> {

  timer: any = undefined;

	state = {
    data: [],
    page: 1,
    last_page: 1,
    id: this.props.value,
    form: INITIAL_STATE,
    header: [
      'Código',
      'Nombre',
      'Área Máxima'
    ],
    search: '',
	}

	componentDidMount() {
		this.setState({
			id: this.props.value
		});

    this.loadDiscount();
    this.load(true);
	}
  loadDiscount = async (withoutLoading: boolean = false) => {
    const res: any = await Discounts.admin.view(this.state.id, {
      page: this.state.page,
      withoutLoading,
      search: this.state.search,
    });

    const form = {
      amount: res.discount.amount,
      start_date: new Date(res.discount.start_date),
      expire_date: new Date(res.discount.expire_date),
      items: res.discount.economic_activities.map((item: any) => item.id),
    }

    this.setState({
      form,
    });
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await EconomicActivityService.admin.get({
      page: this.state.page,
      withoutLoading,
      ...this.state.form
    });
    this.setState({
      data: res.activities.data,
      last_page: res.activities.last_page,
      types: res.types,
      conversion_dolar: res.conversion_dolar,
      conversion_petro: res.conversion_petro,
      iva: res.iva,
      retention: res.retention,
      bankInterest: res.bankInterest
    });
  }

	change = (e: any,callback = () => {}) => {
	    this.setState({
	      form: {
	        ...this.state.form,
	        [e.target.name]: e.target.value
	      }
	    },callback);
	}

  toggleItem = (item: number): void => {
    let _items: number[] = [...this.state.form.items]

    if (_items.includes(item)) {
      _items = _items.filter((id) => id !== item)
    } else {
      _items.push(item)
    }

    this.setState({
      form: {
        ...this.state.form,
        items: [..._items],
      }
    })
  }

	submit = async (e: any) => {
		e.preventDefault();
		if (this.state.form.amount > 100) {
			Globals.showError("Lo sentimos, el Porcentaje no puede ser mayor a 100");
			return false;
		}
		if (!this.state.form.start_date || !this.state.form.expire_date) {
			Globals.showError("Lo sentimos, ambas fechas son requeridas");
			return false;
		}

    try {
      const form = { ...this.state.form };

      form.start_date = moment(form.start_date).format('YYYY-MM-DD hh:mm:ss');
      form.expire_date = moment(form.expire_date).format('YYYY-MM-DD hh:mm:ss');

      await Discounts.admin.edit(this.state.id, form);
      // RegisterMovement.save({
      //       user_id: this.props.user.id,
      //       module_id: Constants.MODULES.TARIFF,
      //       description: `Edición del Interes Bancario a ${ Globals.formatMiles(this.state.form.value,true,'') }%`
      //   });
      this.props.onClose(true);

		  Globals.showSuccess("Se ha editado el descuento con exito");
    } catch (error) {
      Globals.showError("Lo sentimos, ocurrio un error al editar el descuento");
    }
	}

	render() {
		return (
			<div id="admin-edit-discount">
				<form onSubmit={ this.submit }>
					<div className="row">
						<div className="col-md-12">
							<InputCurrency
                color="white"
                value={ this.state.form.amount }
                name="amount"
                label="Porcentaje"
                placeholder="Porcentaje"
                precision="0"
                onChange={ this.change } />
						</div>
            <div className="col-md-6">
              <DatePicker label="Desde" value={this.state.form.start_date}
                showYearDropdown={ true }
                maxDate={ moment(this.state.form.expire_date).toDate() }
                onChange={ (value: string) => this.change({
                  target: { name: 'start_date', value }
                }) }
              />
            </div>
            <div className="col-md-6">
              <DatePicker label="Hasta" value={this.state.form.expire_date}
                showYearDropdown={ true }
                minDate={ this.state.form.start_date && moment(this.state.form.start_date).toDate() }
                onChange={ (value: string) => this.change({
                  target: { name: 'expire_date', value }
                }) }
              />
            </div>
            <div className="col-md-12 mb-n4">
              <Input
                color="white"
                searchRight
                value={ this.state.search }
                name="search"
                placeholder="Buscar por código o nombre"
                onChange={ (e: any) => {
                  clearTimeout(this.timer);
                  this.timer = setTimeout(() => {
                    this.load(true);
                  }, 1000);
                  this.setState({ search: e.target.value });
                } } />
            </div>
						<div className="col-md-12">
              <Table data={ this.state.data.length } title="" header={ this.state.header }>
                { this.state.data.map((i: any,index: number) => {
                  return (
                    <tr
                      key={ index }
                      className={`${this.state.form.items.includes(i.id) ? 'bg-urbaser-5' : ''}`}
                      onClick={ () => { this.toggleItem(i.id) } }
                    >
                      <td>{ i.id }</td>
                      <td>{ i.name }</td>
                      <td>{ i.size } m2</td>
                    </tr>
                  )
                }) }
              </Table>

              <Pagination 
                pages={ this.state.last_page } 
                active={ this.state.page }
                onChange={ async (page: number) => {
                  await this.setState({
                    page: page
                  });
                  this.load();
                } } />
						</div>
						<div className="col-md-12 text-right mt-2">
              <Button className="bg-secondary mr-2" onClick={ () => this.props.onClose(false) }>
								Cancelar
							</Button>
							<Button type="submit">
								Editar descuento
							</Button>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default EditDiscount;