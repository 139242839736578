import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { useHistory } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { ArrowLeft, UserIcon } from 'assets/icons';

interface Props {
	className?: string,
	title?: string
}

const logout = (dispatch: any, history: any) => {
	Globals.confirm('¿Desea cerrar sesión?',() => {
		dispatch({
			type: 'SET_USER',
			payload: null
		});
		localStorage.removeItem('urbtoken');
		history.replace('/login');
		Globals.quitLoading();
	});
}

const Header = (props: Props) => {
	const header = useSelector((state: RootState) => state.header);
	const user = useSelector((state: RootState) => state.user);
	const [open,setOpen] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const isAdmin = user?.level_id == Constants.LEVELS.ADMIN;

	return (
		<div id="header" className={ props.className }>
			<div className="container-blue-top">
				<div className="container-text">
					<h5>
						{
							header.onBack && (
								<div className="arrow-back" onClick={ header.onBack }>
									<img src={ ArrowLeft } />
								</div>
							)
						}
						{ header.title }
					</h5>
				</div>
				<button className="focusable-container" onBlur={ () => setOpen(false) }>
					{
						user && (
							<div className="container-user" onClick={ () => setOpen(!open) }>
								<p>{ user?.name }{' '}{user?.box && `(${user?.box})`}</p>
								<img src={ UserIcon } />
							</div>
						)
					}
					{
						open && (
							<ul>
								<li onClick={ () => { setOpen(false); history.replace('/profile') } }>Mis Datos</li>
								<li onClick={ () => { setOpen(false); history.replace('/password') } }>Cambiar Contraseña</li>
								<li onClick={ () => { setOpen(false); logout(dispatch,history) } }>Cerrar Sesión</li>
							</ul>
						)
					}		
				</button>
			</div>			
		</div>
	)	
}

export default Header;