import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

class Welcome extends React.Component<Props> {  

  componentDidMount() {
  	this.props.dispatch({
  		type: 'SET_HEADER',
  		payload: {
  			title: 'Bienvenido',
  			onBack: null
  		}
  	});
  }
  
  render() {
    return (
        <div id="welcome">

        </div>        
    )
  }
}

export default connect()(Welcome);