import React from 'react';
import { Table, Pagination, Select, Button } from 'components';
import { Globals, Currency } from 'utils';
import { UrbanCleaningSolvencyService } from 'services';
import moment from 'moment';

interface Props {
	user: any,
	properties: any,
	onClose: any
}

const INITIAL_FORM = {
	property_id : ''
}

class CreateSolvency extends React.Component<Props> {

	state = {
		user: null,
		properties: [],
		form:INITIAL_FORM,
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		this.setState({
			user: this.props.user,
			properties: this.props.properties
		});
	}

	change = (e: any,callback = () => {}) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

	generate = async () => {
		if(this.state.form.property_id == ''){
			Globals.showError("Debe seleccionar la propiedad");
			return false;
		}

		const valid: any = await UrbanCleaningSolvencyService.validateDebt({
			property_id: this.state.form.property_id
		});

		if(valid.debt > 0){
			Globals.showError("La propiedad no se encuentra solvente");
			return false;	
		}

		const res: any = await UrbanCleaningSolvencyService.create({
			...this.state.form,
			user_id: this.props.user.id
		});

		if (!res.hasOwnProperty("message")) {
			Globals.showSuccess("Solvencia generada exitosamente");
		} else {
			Globals.showError("No hay deudas cargadas");
		}	
		
		this.props.onClose();
	}
	
	render() {
		const user: any = this.state.user;
		const properties: any = this.state.properties;

		return (
			<div id="create-solvency">
				{
					user && (
						<React.Fragment>
							<p>Por favor seleccione la propiedad que desea generarle solvencia.</p>
							<div className="row">
	              <div className="col-md-8">
	                <Select
	                  label="Propiedad"
	                  color="gray"
	                  name="property_id"
	                  onChange={(e: any) => this.change(e) }
	                  placeholder="Propiedad"
	                  value={ this.state.form.property_id }
	                  options={ properties } 
	                />
	              </div>
	              <div className="col-md-4">
	              	<Button type="submit" onClick={ this.generate }>
		                Generar
		              </Button>
	             	</div>
	            </div>
						</React.Fragment>
					)
				}
			</div>
		)
	}
}

export default CreateSolvency;