import React from 'react';

interface Props {
	name: string,
	right: React.ReactNode
}

const Title = (props: Props) => (
	<div className="container-title-component">
		<h2 className="title-component">
			{ props.name }
			{ props.right && <div className="title-component-right">
				{ props.right }
			</div> }
		</h2>
		<hr />
	</div>
)

export default Title;