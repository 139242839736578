import React from 'react';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Constants } from 'utils';

const Profile = () => {
	const user = useSelector((state: RootState) => state.user);

	if (user.level_id == Constants.LEVELS.CLIENT) {
		return <Redirect to="/client/profile" />
	}
	else {
		return <Redirect to="/admin/profile" />
	}
}

export default Profile;