import React from 'react';
import { CreditNoteService } from 'services';
import moment from 'moment';
import { Globals, Currency, Constants, RegisterMovement, checkPermission, Permissions } from 'utils';
import { TrashIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker, TooltipCurrency, Tooltip, Button, Modal, InputCurrency } from 'components';

interface Props {
	user: any
}

const INITIAL_STATE = {
	search: '',
	since: '',
	until: ''
}

class CreditNotes extends React.Component<Props> {  

  timer: any = undefined;

  state: any = {
  	header: [
  	  'Cod. Contribuyente',
      'Fecha',
      'Nombre',
      'Monto',
      'Acciones'
    ],
    page: 1,
    last_page: 1,
    data: [],
    conversion_petro: 0,
		level: 0,
    form: INITIAL_STATE,
		visible_amount: false,
		item: null,
		amount: 0,
  }

  componentDidMount() {
    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await CreditNoteService.admin.get({
      page: this.state.page,
      ...this.state.form,
      since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
      until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
      withoutLoading
    });
    this.setState({
      data: res.credit_notes.data,
      last_page: res.credit_notes.last_page,
      conversion_petro: res.conversion_petro || 0,
			level: res.level,
    });
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  delete = (e: any,item: any) => {
  	e.preventDefault();
  	Globals.confirm('¿Desea eliminar la nota de crédito?',async () => {
  		await CreditNoteService.admin.delete({
  			credit_note_id: item.id
  		});
  		Globals.showSuccess("Se ha eliminado la nota de crédito");
  		this.load(true);
  		RegisterMovement.save({
	        value_id: item.id,
	        user_id: this.props.user.id,
	        module_id: Constants.MODULES.BANK_ACCOUNTS,
	        description: `Eliminación de nota de crédito del contribuyente ${ item.user?.full_name }`
	    });
  	});
  }

	onEditAmount = (e: any, voucher: any) => {
		e.preventDefault();
    this.setState({
      item: voucher,
			amount: Currency.ConvertAux(voucher.amount, voucher.conversion_petro),
      visible_amount: true,
    });
  }

	onClose = () => {
    this.setState({
      item: null,
			amount: 0,
      visible_amount: false,
    });
  }

	onUpdateAmount = async () => {
		try {
			await CreditNoteService.admin.edit({
  			credit_note_id: this.state.item?.id,
				amount: this.state.amount
  		});
  		Globals.showSuccess("Se ha actualizado la nota de crédito");
  		this.load(true);
			this.onClose();
		} catch (error) {
			console.log(error);
		}
	}

  render() {
    return (
			<div id="credit-notes">
					<Modal
						className="modal-view-account-status"
						visible={this.state.visible_amount}
						onClose={ this.onClose }
						title="Editar monto"
					>
						<div>
							<div className="d-flex flex-column align-items-center">
								<div className="w-100">
									<InputCurrency
										color="white"
										value={this.state.amount}
										name="amount"
										precision="4"
										placeholder="Escribe el monto"
										onChange={(e: any) => {
											this.setState({
												amount: e.target.value
											});
										}}
									/>
								</div>
								<Button className="btn-urbaser-green w-50" onClick={this.onUpdateAmount}>
									Confirmar
								</Button>
							</div>
						</div>
					</Modal>

        	<div className="row row-filter">
	            <div className="col-md-6">
	                <Input
	                  color="white"
	                  searchRight
	                  value={ this.state.form.search }
	                  name="search"
	                  placeholder="Búsqueda por código o contribuyente"
	                  onChange={ (e: any) => {
	                    clearTimeout(this.timer);
	                    this.timer = setTimeout(() => {
	                      this.load(true);
	                    },1000);
	                    this.change(e); 
	                  } } />
	            </div>
	            <div className="col-md-3">
		            <DatePicker
		              color="white"
		              label="Desde"
		              value={ this.state.form.since }
		              onChange={ (value: string) => this.change({ 
		                target: { name: 'since', value }                
		              },() => this.load(true))}
		            />
		        </div>
		        <div className="col-md-3">
		            <DatePicker
		              color="white"
		              label="Hasta" 
		              value={ this.state.form.until }
		              onChange={ (value: string) => this.change({ 
		                target: { name: 'until', value }
		              },() => this.load(true))}
		            />
		        </div>
		        <div className="col-md-3">
		        	<a href={ Constants.BASE_API + 'admin/credit-notes/excel?' + Globals.getParams(this.state.form) }>
                      <Button className="btn-blue-create">
                        Exportar Excel
                      </Button>
                    </a>
		        </div>
	        </div>

	        {
	          this.state.form != INITIAL_STATE && (
	            <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
	          )
	        }  

	        <Table data={ this.state.data.length } title="" header={ this.state.header }>
	            { this.state.data.map((i: any,index: number) => {
	              return (
	                <tr key={ index }>
	                  <td>{ i.user?.username }</td>
	                  <td>{ moment(i.created_at).format('DD/MM/YYYY HH:mm') }</td>
	                  <td>{ i.user?.full_name }</td>
	                  <td>
											{ this.state.level === Constants.LEVELS.ADMIN
												? <Tooltip title={ i.amount }>
													<a className="link-icon" href="#" onClick={ (e: any) => this.onEditAmount(e, i) }>
														{ Globals.formatMiles(Currency.ConvertAux(i.amount,i.conversion_petro),true,Currency.BOLIVAR) }
													</a>
												</Tooltip>
												:
												<TooltipCurrency title={ i.amount }>{ Globals.formatMiles(Currency.ConvertAux(i.amount,i.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency>
											}
										</td>
	                  <td>
	                  	{
                            checkPermission(Permissions.VOUCHER_HISTORY.DELETE_CREDIT_NOTE) && (
			                  	<Tooltip title="Eliminar">
			                        <a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
			                          <img src={ TrashIcon } />
			                        </a>
			                    </Tooltip>
			                )
			            }
	                  </td>
	                </tr>
	              )
	            }) }
	          </Table>

	          <Pagination 
	            pages={ this.state.last_page } 
	            active={ this.state.page }
	            onChange={ async (page: number) => {
	              await this.setState({
	                page: page
	              });
	              this.load();
	          } } />
        </div>        
    )
  }
}

export default CreditNotes;