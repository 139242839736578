import { Api, Constants } from 'utils';

class Discounts {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/discounts', { ...params, method: 'get' }),
		view: async (id: string | number, params?: any) => Api.createResponse(`admin/discounts/${id}`, { ...params, method: 'get' }),
		create: async (params?: any) => Api.createResponse('admin/discounts', { ...params, method: 'post' }),
		edit: async (id: string | number, params?: any) => Api.createResponse(`admin/discounts/${id}`,{ ...params, method: 'put' }),
		delete: async (id: string | number, params?: any) => Api.createResponse(`admin/discounts/${id}`,{ ...params, method: 'delete' }),
	}
}

export default Discounts;