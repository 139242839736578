import React from 'react';
import { Constants } from 'utils';
import { Table } from 'components';

interface Props {
	activity: any,
	types: any
}

class ViewTariff extends React.Component<Props> {

	state = {
		header: [
			'Factor Comercial',
			'Coeficiente Adicional',
			'Factor de Ajuste'
		]
	}
	
	render() {
		const { activity, types } = this.props;

		return (
			<div id="admin-view-tariff">
				<h3>Datos de la Actividad Económica</h3>
				<p>Nombre: { activity?.name }</p>
				<p>Área Máxima: { activity?.size } m2</p>
				<p>Exento de IVA: { activity?.tax_free == Constants.TAX_FREE.YES ? 'Si' : 'No' }</p>
				{
					types.map((item: any,index: number) => {
						const prices: any = activity.prices.filter((i: any) => i.service_type_id == item.id);

						return (
							<React.Fragment key={ index }>
								<h3>{ item.name }</h3>
								<Table title="" data={ prices.length } header={ this.state.header }>
									{
										prices.map((item: any,index: number) => (
											<tr key={ index }>
												<td>{ item.commercial_factor }</td>
												<td>{ item.additional_coefficient }</td>
												<td>{ item.adjustment_factor }</td>
											</tr>
										))
									}
								</Table>
							</React.Fragment>
						)
					})
				}
			</div>
		)
	}
}

export default ViewTariff;