import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Input, Button, Select, DatePicker, Modal } from 'components';
// import { ClientService } from 'services';
// import { Globals, Constants, RegisterMovement } from 'utils';
// import { TrashIcon } from 'assets/icons';
import moment from 'moment';
import FormClientProperty from 'components/FormClientProperty';
import { ClientService, PropertyService } from 'services';
import { Constants, Globals, RegisterMovement } from 'utils';

interface Props extends RootState, RouteComponentProps<Params> {
  dispatch: any
}

interface Params {
  id: string | undefined,
  propertyId: string | undefined
}

class CreateClientProperty extends React.Component<Props> {
  load = async (self: any) => {
    const res: any = await ClientService.admin.getDocumentTypes()
    self.setState({
      codes: res.codes,
      document_types: res.document_types,
      economic_activities: res.economic_activities,
      property_types: res.property_types,
      property_uses: res.property_uses.filter((item: any) => item.name !== 'Otro'),
    })
  };

  submit = async (self: any) => {
    if (!this.props.match.params.propertyId) {
      const form: any = self.state.property;
      const values = Object.keys(form).filter((key) => {
        let response = form[key] === '' && key != 'quantity_houses';
        if (form['property_type_id'] == Constants.PROPERTY_TYPES.RESIDENTIAL && Constants.OPTIONAL_FIELDS.indexOf(key) != -1) {
          response = false;
        }
        if (Constants.OPTIONAL_FIELDS_ALL.indexOf(key) != -1) {
          response = false;
        }
        return response;
      });
      const property = {
        ...form,
        date: form.date && moment(form.date,'DD-MM-YYYY').format('YYYY-MM-DD'),
        user_id: self.props.match.params.id,
        // secondary: JSON.stringify(form.secondary.map((item: any) => {
        //   item.date = item.date && moment(item.date,'DD-MM-YYYY').format('YYYY-MM-DD');
        //   return item;
        // }))
      };
      await PropertyService.create({ property: JSON.stringify(property) });
      Globals.showSuccess("Se ha creado correctamente la propiedad");
      self.props.history.push({
        pathname: '/admin/users/clients',
        state: self.props.location.state
      });

      return;
    }

    // Si es con cliente continua lo siguiente
    await ClientService.admin.validate({
      ...self.state.form,
    })

    const property: any = Globals.clone(self.state.property)
    property.date = property.date && moment(property.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
    property.parent_id = self.props.match.params.propertyId
    property.user_id = self.props.match.params.id

    const value = {
      ...self.state.form,
      emails: JSON.stringify(self.state.emails),
      phones: JSON.stringify(self.state.phones),
      property: JSON.stringify(property),
      is_child: true,
      // property: JSON.stringify(
      //   properties.map((item: any) => {
      //     item.date = item.date && moment(item.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      //     return item
      //   })
      // ),
    };

    const res: any = await ClientService.admin.create(value)

    Globals.showSuccess('Se ha creado correctamente la propiedad')
    self.props.history.push({
      pathname: '/admin/users/clients',
      state: self.props.location.state,
    })

    RegisterMovement.save({
      value_id: res.client?.id,
      user_id: self.props.user.id,
      module_id: Constants.MODULES.CLIENTS,
      description: `Registro del contribuyente ${res.client?.full_name} ${res.client?.full_document}`,
    })
  };

  render() {
    return (
      <FormClientProperty
        {...this.props}
        show={this.props.match.params.propertyId ? "ALL" : "PROPERTY"}
        headTitle="Crear Propiedad"
        onBack={() => {
          this.props.history.push({
            pathname: '/admin/users/clients',
            state: this.props.location.state
          });
        }}
        onLoad={this.load}
        onSubmit={this.submit}
        isAdmin={this.props.user.level_id != 3}
      />
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(CreateClientProperty);