import { Api, Constants } from 'utils'

class SuggestionsService {
  static get = async (params?: any) => Api.createResponse('suggestions', params)
  static create = async (params?: any) => Api.createResponse('suggestions/create', params)
  static view = async (params?: any) => Api.createResponse('suggestions/view', params)
  static delete = async (params?: any) => Api.createResponse('suggestions/delete', params)

  static admin = {
    get: async (params?: any) => Api.createResponse('admin/suggestions', params),
    view: async (params?: any) => Api.createResponse('admin/suggestions/view', params),
    changeStatus: async (params?: any) => Api.createResponse('admin/suggestions/status', params),
  }

  static message = {
    get: async (params?: any) => Api.createResponse('suggestions/message', params),
    view: async (params?: any) => Api.createResponse('suggestions/message/view', params),
    create: async (params?: any) => Api.createResponse('suggestions/message/create', params),
  }
}

export default SuggestionsService
