import { Api, Constants } from 'utils';

class EconomicActivityService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/economic-activities',params),
		edit: async (params?: any) => Api.createResponse('admin/economic-activities/edit',params),
		editIVA: async (params?: any) => Api.createResponse('admin/economic-activities/edit-iva',params),
		editRetention: async (params?: any) => Api.createResponse('admin/economic-activities/edit-retention',params),
		editBankInterest: async (params?: any) => Api.createResponse('admin/economic-activities/edit-bankInterest',params),
		editDolar: async (params?: any) => Api.createResponse('admin/economic-activities/edit-dolar',params),
		editPetro: async (params?: any) => Api.createResponse('admin/economic-activities/edit-petro',params),
		download: (id?: string) => Constants.BASE_API + 'admin/economic-activities/download/' + id,
	}
}

export default EconomicActivityService;