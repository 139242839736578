import { Api } from 'utils';

class BankAccountService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/bank-accounts/get',params),
		create: async (params?: any) => Api.createResponse('admin/bank-accounts/create',params),
		edit: async (params?: any) => Api.createResponse('admin/bank-accounts/edit',params),
		status: async (params?: any) => Api.createResponse('admin/bank-accounts/status',params),
		delete: async (params?: any) => Api.createResponse('admin/bank-accounts/delete',params),
	}
}

export default BankAccountService;