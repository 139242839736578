import React from 'react'
import { Button, Textarea } from 'components'
import { Globals } from 'utils'
import { ClientService } from 'services'

interface Props {
  user: any
  onClose: any
}

const INITIAL_FORM = {
  disincorporate: '',
}

class CreateComment extends React.Component<Props> {
  state = {
    user: null,
    form: INITIAL_FORM,
  }

  componentDidMount() {
    this.load()
  }

  load = async () => {
    this.setState({
      user: this.props.user,
      form: {
        ...this.state.form,
        disincorporate: this.props.user.disincorporate ?? ''
      }
    })
  }

  change = (e: any, callback = () => {}) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      },
      callback
    )
  }

  create = async () => {
    if (this.state.form.disincorporate.trim() === '') {
      Globals.showError('Debe agregar un comentario')
      return false
    }

    const res: any = await ClientService.admin.disincorporate(this.props.user.id, {
      ...this.state.form
    })

    if (!res.hasOwnProperty('message')) {
      Globals.showSuccess('Comentario agregado exitosamente')
    } else {
      Globals.showError('No se logró agregar el comentario')
    }

    this.props.onClose()
  }

  render() {
    const user: any = this.state.user

    return (
      <div id="create-solvency">
        {user && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-12">
								<Textarea
									placeholder="Escribe un comentario..."
									rows={ 4 }
									color="white"
									name="disincorporate"
									onChange={ this.change }
									value={ this.state.form.disincorporate } />
              </div>
              <div className="col-md-12 text-right">
                <Button type="submit" onClick={this.create}>
                  Agregar comentario
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default CreateComment
