import React from "react";
import { Table, DatePicker, Select, Button, Tooltip } from "components";
import { Globals } from "utils";
import moment from "moment";
import { TrashIcon } from "assets/icons";
import { HistoryEconomicActivityService, PropertyService } from "services";
import Swal from "sweetalert2";
import colors from "utils/colors";

interface LocationProps {
  page: number | undefined;
  form: any;
}

interface Props {
  property: any;
  client: any;
  onClose: any;
}

const INITIAL_STATE = {
  property_id: "",
  economic_activity_id: "",
  start: "",
  end: "",
};

class ViewClientHistoryActivity extends React.Component<Props> {
  state = {
    header: ["Actividad Económica", "Desde", "Hasta", "Acciones"],
    form: { ...INITIAL_STATE },
    economicActivities: [],
    historyActivities: [],
  };

  componentDidMount() {
    this.loadActivities();
    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await HistoryEconomicActivityService.admin.getByProperty(this.props?.property?.id, {
      withoutLoading,
    });
    console.log(res);
    this.setState({
      historyActivities: res.data ?? [],
    });
  };

  loadActivities = async () => {
    const res: any = await PropertyService.getActivities();

    this.setState({
      economicActivities: res.economic_activities.map((i: any) => ({
        value: i.id,
        label: `[${i.code}] ${i.name}`,
      })),
    });
  };

  change = (e: any, callback = () => {}) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      },
      callback
    );
  };

  remove = async (e: any, item: any) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Esta acción no se podrá revertir",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      try {
        await HistoryEconomicActivityService.admin.delete(item.id);
        this.load();
      } catch (e) {
        console.log(e);
      }
    }
  };

  submit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const formData: any = { ...this.state.form };
    formData.economic_activity_id = parseInt(formData.economic_activity_id,  10);
    formData.property_id = this.props.property?.id ?? "";

    if (!formData.property_id) {
      Globals.showError("Debe seleccionar la propiedad");
      return false;
    }
    if (!formData.economic_activity_id) {
      Globals.showError("Debe seleccionar la actividad económica");
      return false;
    }
    if (!formData.start || !formData.end) {
      Globals.showError("La fecha desde y hasta son requeridas");
      return false;
    }

    try {
      const res: any = await HistoryEconomicActivityService.admin.create(formData);
      console.log({ res });
      this.setState({
        historyActivities: [...this.state.historyActivities, formData],
        form: { ...INITIAL_STATE },
      });

      Globals.showSuccess("Agregada al historial exitosamente");

      this.load();
    } catch (error) {
      Globals.showError("Ups!, Ocurrio un error");
    }
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="row">
              <div className="col-md-12">
                <Select
                  label="Actividad Económica"
                  color="gray"
                  name="economic_activity_id"
                  onChange={(e: any) => this.change(e)}
                  placeholder="Actividad Económica"
                  value={this.state.form.economic_activity_id}
                  options={this.state.economicActivities}
                />
              </div>
              <div className="col-md-6">
                <DatePicker
                  id="start_date"
                  label="Desde"
                  value={this.state.form.start}
                  showYearDropdown={true}
                  onChange={(value: string) =>
                    this.change({
                      target: { name: "start", value },
                    })
                  }
                />
              </div>
              <div className="col-md-6">
                <DatePicker
                  id="end_date"
                  label="Hasta"
                  value={this.state.form.end}
                  showYearDropdown={true}
                  onChange={(value: string) =>
                    this.change({
                      target: { name: "end", value },
                    })
                  }
                />
              </div>

              <div className="col-md-12 text-right">
                <Button type="submit" onClick={this.submit}>
                  Generar
                </Button>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-md-12">
            <Table
              title=""
              data={this.state.historyActivities.length}
              header={this.state.header}
            >
              <React.Fragment>
                {this.state.historyActivities.map(
                  (activity: any, index: number) => (
                    <tr key={index}>
                      <td>{activity.economic_activity?.name ?? ""}</td>
                      <td>{moment(activity.start).format("DD MMM YYYY")}</td>
                      <td>{moment(activity.end).format("DD MMM YYYY")}</td>
                      <td>
                        <Tooltip title="Eliminar">
                          <a
                            className="link-icon"
                            href="#"
                            onClick={(e: any) => this.remove(e, activity)}
                          >
                            <img src={TrashIcon} />
                          </a>
                        </Tooltip>
                      </td>
                    </tr>
                  )
                )}
              </React.Fragment>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewClientHistoryActivity;
