import React from 'react';
import { Table, Pagination, Button, InputCurrency, DatePicker, Tooltip, Modal } from 'components';
import CreateDiscount from './create-discount';
import ViewDiscount from './view-discount';
import EditDiscount from './edit-discount';
import { Discounts, EconomicActivityService } from 'services';
import { Globals, RegisterMovement, Constants, checkPermission, Permissions } from 'utils';
import { EditIcon, ViewIcon, TrashIcon } from 'assets/icons';
import moment from 'moment';
import { setTimeout } from 'timers';
import { INITIAL_STATE } from './interfaces';

interface Props {
	onClose: (reload: boolean) => void,
	user: any
}

class Discount extends React.Component<Props> {

	state = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'Código',
      'Descuento',
      'Desde - Hasta',
      'Acciones',
    ],
    visible_create: false,
    form: { ...INITIAL_STATE },
    visible_view: false,
    id_view: 0,
    visible_edit: false,
    id_edit: 0,
	}

	componentDidMount() {
    this.load();
	}

  load = async (withoutLoading: boolean = false) => {
    const res: any = await Discounts.admin.get({
      page: this.state.page,
      withoutLoading,
    });

    this.setState({
      data: res.data.data,
      last_page: res.data.last_page,
    });
  }

  delete = (e: any, item: any) => {
    e.preventDefault();

    Globals.confirm('¿Desea eliminar este descuento?', async () => {
      await Discounts.admin.delete(item.id);

      Globals.showSuccess("Se ha eliminado correctamente el descuento");
      /* Socket.emit(SocketEvents.USERS.DELETE,{
        id: item.id,
      }); */
      this.load();

      // RegisterMovement.save({
      //     value_id: item.id,
      //     user_id: this.props.user.id,
      //     module_id: Constants.MODULES.CLIENTS,
      //     description: `Eliminación del contribuyente ${ item?.full_name } ${ item?.full_document }`
      // });
    });
  }

  onCloseCreate = (reload: boolean = false) => {
    this.setState({
      visible_create: false
    },() => {
      if (reload) {
        this.load();
      }
    });
  }

  onCloseView = (reload: boolean = false) => {
    this.setState({
      visible_view: false,
      id_view: 0,
    },() => {
      if (reload) {
        this.load();
      }
    });
  }

  onCloseEdit = (reload: boolean = false) => {
    this.setState({
      visible_edit: false,
      id_edit: 0,
    },() => {
      if (reload) {
        this.load();
      }
    });
  }

  changeCreate = () => {
    this.setState({
      visible_edit: false,
      visible_view: false,
      visible_create: true,
    });
  }

  changeView = (e: any, id: string | number) => {
    e.preventDefault();

    this.setState({
      visible_create: false,
      visible_edit: false,
      visible_view: true,
      id_view: id,
    });
  }

  changeEdit = (e: any, id: string | number) => {
    e.preventDefault();

    this.setState({
      visible_create: false,
      visible_view: false,
      visible_edit: true,
      id_edit: id,
    });
  }

	render() {
		return (
			<div id="admin-view-discount">
        <div className="row">
          <div className="col-md-12">
          {
              checkPermission(Permissions.DISCOUNT.VIEW) &&
              this.state.visible_view &&
              <ViewDiscount
                user={ this.props.user }
                value={ this.state.id_view }
                onClose={ this.onCloseView }
              />
            }
            {
              checkPermission(Permissions.DISCOUNT.EDIT) &&
              this.state.visible_edit &&
              <EditDiscount
                user={ this.props.user }
                value={ this.state.id_edit }
                onClose={ this.onCloseEdit }
              />
            }
          </div>

          <div className="col-md-12">
            {
              checkPermission(Permissions.DISCOUNT.CREATE) &&
              !this.state.visible_create &&
              !this.state.visible_view &&
              !this.state.visible_edit &&
              (
                <div className="text-right">
                  <Button onClick={ this.changeCreate }>
                    Crear Descuento
                  </Button>
                </div>
              )
            }
            {
              checkPermission(Permissions.DISCOUNT.CREATE) &&
              this.state.visible_create &&
              <CreateDiscount
                user={ this.props.user }
                value={ this.state.form }
                onClose={ this.onCloseCreate }
              />
            }
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Table data={ this.state.data.length } title="" header={ this.state.header }>
              { this.state.data.map((i: any, index: number) => {
                return (
                  <tr key={ index }>
                    <td>{ i.id }</td>
                    <td>{ i.amount }%</td>
                    <td>{ moment(i.start_date).format('DD/MM/YYYY') } - { moment(i.expire_date).format('DD/MM/YYYY') }</td>
                    <td>
                      <Tooltip title="Ver">
                        <a className="link-icon" href="#" onClick={ (e: any) => this.changeView(e, i.id) }>
                          <img src={ ViewIcon } />
                        </a>
                      </Tooltip>
                      {
                        checkPermission(Permissions.DISCOUNT.EDIT) && (
                          <Tooltip title="Editar">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.changeEdit(e, i.id) }>
                              <img src={ EditIcon } />
                            </a>
                          </Tooltip>
                        )
                      }
                      {
                        checkPermission(Permissions.DISCOUNT.DELETE) && (
                          <Tooltip title="Eliminar">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.delete(e, i) }>
                              <img src={ TrashIcon } />
                            </a>
                          </Tooltip>
                        )
                      }
                    </td>
                  </tr>
                )
              }) }
            </Table>

            <Pagination
              pages={ this.state.last_page }
              active={ this.state.page }
              onChange={ async (page: number) => {
                await this.setState({
                  page: page
                });
                this.load();
              } } />
          </div>
        </div>
			</div>
		)
	}
}

export default Discount;