import React, { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment'

import { Fine, Invoice, OptionProperty, Payment, PaymentDetail, PropertyDetail, Service, Voucher } from './Cash'

import { CashService } from 'services'
import { Constants, Currency, Globals, Permissions, checkPermission } from 'utils'

export interface CashContextProps {
  user: any

  searchValue: OptionProperty
  setSearchValue: Dispatch<SetStateAction<OptionProperty>>
  loadingSearch: boolean
  searchPropertiesWithClient: (search?: string) => Promise<void>
  handleSelectSearch: (value: OptionProperty) => Promise<void>
  clients: OptionProperty[]

  loadingProperty: boolean
  getPropertyId: (id: string | number) => Promise<void>
  property: PropertyDetail | null

  loadingPayments: boolean
  getPropertyIdPayments: (id: string | number) => Promise<void>
  payments: Payment[]
  setPayments: Dispatch<SetStateAction<Payment[]>>

  loadingFines: boolean
  getPropertyIdFines: (id: string | number) => Promise<void>
  fines: Fine[]
  setFines: Dispatch<SetStateAction<Fine[]>>

  loadingServices: boolean
  getPropertyIdServices: () => Promise<void>
  services: Service[]
  setServices: Dispatch<SetStateAction<Service[]>>

  invoices: Invoice[]
  setInvoices: Dispatch<SetStateAction<Invoice[]>>

  paymentDetail: PaymentDetail

  creditApply: boolean
  setCreditApply: Dispatch<SetStateAction<boolean>>

  vouchers: Voucher[]
  setVouchers: Dispatch<SetStateAction<Voucher[]>>

  isCondominium: boolean

  resetAll: VoidFunction
  generatePreinvoice: VoidFunction
  finish: VoidFunction
  loadingFinish: boolean

  userIsClient: boolean

  currentPetroValue: string
  setCurrentPetroValue: Dispatch<SetStateAction<string>>

  visibleConfirmPetroValue: boolean
  setVisibleConfirmPetroValue: (value: boolean) => void
}

const CASH_PAYMENT_DETAILS_DEFAULT = {
  subtotal: 0,
  taxBase: 0,
  penaltyFee: 0,
  exempt: 0,
  iva: 0,
  retention: 0,
  total: 0,
  total_to_pay: 0,
  total_voucher: 0,
  credit: 0,
}

const SEARCH_VALUE_DEFAULT = {
  value: '',
  label: '',
}

const IVA_PERCENTAGE = 0.16
const RETENTION_PERCENTAGE = 0.75

const paymentAdapter = (payment: any) => {
  const serviceName = payment.service_type?.name ?? 'Sin servicio'
  const date = payment.created_at ? moment(payment.created_at, 'YYYY-MM').format('MMMM YYYY') : 'Sin fecha'

  return {
    ...payment,
    total: payment.total ?? 0,
    urbaser_code: payment.urbaser_code ?? '-',
    house_number: payment.house_number ?? '-',
    fines: payment.fines ?? [],
    childs: payment.childs?.map(paymentAdapter)
      .sort((a: any, b: any) => {
        const one = (new Date(a.created_at)).getTime()
        const two = (new Date(b.created_at)).getTime()

        if (one < two) return -1;
        if (one > two) return 1;
        return 0;
      }) ?? [],
    description: `${serviceName} (${date})`,
    isSelected: false,
    isCollapse: false,
  }
}

export const CashContext = createContext<CashContextProps>({} as CashContextProps)

export const CashContextProvider = ({ user, children }: { user: any; children: ReactNode | ReactNode[] }) => {
  // const router = useRouter()
  // const { id } = router.query
  const [searchValue, setSearchValue] = useState<OptionProperty>(SEARCH_VALUE_DEFAULT)
  const [clients, setClients] = useState<OptionProperty[]>([])
  const [property, setProperty] = useState<PropertyDetail | null>(null)
  const [payments, setPayments] = useState<Payment[]>([]) // Deudas
  const [fines, setFines] = useState<Fine[]>([]) // Multas
  const [services, setServices] = useState<Service[]>([]) // Servicios
  const [invoices, setInvoices] = useState<Invoice[]>([]) // Facturas
  const [vouchers, setVouchers] = useState<Voucher[]>([]) // Comprobantes
  const [currentPetroValue, setCurrentPetroValue] = useState('') // Valor del petro a enviar cuando se consulte la property
  const [visibleConfirmPetroValue, _setVisibleConfirmPetroValue] = useState(false)

  const [loadingSearch, setLoadingSearch] = useState(false)
  const [loadingProperty, setLoadingProperty] = useState(false)
  const [loadingPayments, setLoadingPayments] = useState(false)
  const [loadingFines, setLoadingFines] = useState(false)
  const [loadingServices, setLoadingServices] = useState(false)
  const [loadingFinish, setLoadingFinish] = useState(false)
  const isCondominium =
    property?.use_id === Constants.PROPERTY_USES.CONDOMINIUM && property?.type_id === Constants.PROPERTY_TYPES.COMMERCIAL
  const userIsClient = user.level_id === Constants.LEVELS.CLIENT

  /** Payments
   * subtotal     | Subtotal       => La suma de todos los Invoices
   * taxBase      | Base Imponible => La suma de todos los Invoices donde fines_id no exista
   * penaltyFee   | Multa          => La suma de todos los Invoices donde fines_id existe
   * exempt       | Exento         => Se calcula de la Base Imponible:
   *                                  Si es property.type.id (recidencial) === 2 (Constants.PROPERTY_TYPES.RESIDENTIAL) se calcula por IVA
   *                                  De lo contrario no calcula Exento => es 0
   * iva          | Iva (16%)      => Se calcula de la Base Imponible:
   *                                  Si los property.type.id (recidencial) !== 2 (Constants.PROPERTY_TYPES.RESIDENTIAL) se calcula el IVA
   *                                  De lo contratio no calcula IVA => es 0
   * retention    | Retención      => Si el IVA > 0 y property.retention === Constants.RETENTION.YES => seria IVA * 0.75
   *                                  De lo contrario no calcula Retención => es 0
   * total_voucher| Total comprob  => Es la suma total de Vouchers
   * total        | Total factura  => La suma del Subtotal + IVA
   * total_to_pay | Total a pagar  => La suma del Subtotal + IVA - Retención - Total comprobantes
   *                                  Tomar en cuenta la nota de credito property.total_credit_notes_petro
   *                                  Si se aplica la nota de credito se resta del total_to_pay
   * credit       | Crédito        => Es el reciduo del Total a pagar
   *                                  Si el Total a pagar es negativo se toma como crédito
   */
  const [paymentDetail, setPaymentDetail] = useState<PaymentDetail>({ ...CASH_PAYMENT_DETAILS_DEFAULT })
  const [creditApply, setCreditApply] = useState(false)

  const loadData = useCallback(async (id: string | number) => {
    if (!id) return

    console.log('loadData', { id })
    setLoadingSearch(true)

    try {
      // fetch
      await new Promise((resolve) => setTimeout(resolve, 1000))
      // adapter
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearch(false)
    }
  }, [])

  const searchPropertiesWithClient = async (search?: string) => {
    setLoadingSearch(true)

    const pagination = { search }
    const requestParams = userIsClient ? { client_id: user.id, ...pagination } : { ...pagination }

    try {
      const res: any = await CashService.admin.getPropertiesWithClient(requestParams)

      const list = Object.entries<string>(res.properties).map(
        ([id, value]): OptionProperty => ({
          value: id,
          label: value,
        })
      )

      setClients(list)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearch(false)
    }
  }

  const handleSelectSearch = async (client: OptionProperty) => {
    setSearchValue(client)
    setInvoices([])
    await getPropertyId(client.value)
  }

  const getPropertyId = async (id: string | number) => {
    setLoadingProperty(true)
    try {
      const res = (await CashService.admin.getPropertyId(id, currentPetroValue)) as PropertyDetail

      setProperty({ ...res, id })
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingProperty(true)
    }
  }

  const getPropertyIdPayments = async (id: string | number) => {
    setLoadingPayments(true)

    try {
      const res: any = await CashService.admin.getPropertyIdFines(id)

      const resPayments: Payment[] = Object.values(res?.data ?? {})
        .filter((payment: any) => payment.urbaser_code)
        .map(paymentAdapter)
        .sort((a, b) => {
          const one = (new Date(a.created_at)).getTime()
          const two = (new Date(b.created_at)).getTime()

          if (one < two)
            return -1;
          if (one > two)
            return 1;
          return 0;
        })

      setPayments(resPayments ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingPayments(false)
    }
  }

  const getPropertyIdFines = async (id: string | number) => {
    setLoadingFines(true)

    try {
      const res: any = await CashService.admin.getPropertyIdFines(id, { fines: 1 })

      const resFines: Fine[] = Object.values(res?.data)
        .map(
          (payment: any) =>
            payment?.fines?.map((fine: any) => ({
              ...fine,
              service_type: payment.service_type?.name + ` (${moment(payment.created_at, 'YYYY-MM').format('MMMM YYYY')})`,
            })) ?? []
        )
        .flat(2)
        .map((fine: any) => ({
          ...fine,
          name: `${fine.type?.name} - ${fine.service_type}`,
          total: fine.total ?? 0,
          isSelected: false,
        }))

      setFines(resFines ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingFines(false)
    }
  }

  const getPropertyIdServices = async () => {
    setLoadingServices(true)

    try {
      const res: any = await CashService.admin.getServiceTypes()

      const resServices: Service[] = res?.service_types?.map((s: any) => ({
        ...s,
        isSelected: false,
      }))

      setServices(resServices ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingServices(false)
    }
  }

  const calculatePaymentDetails = () => {
    // if (invoices.length === 0) {
    //   setPaymentDetail({ ...CASH_PAYMENT_DETAILS_DEFAULT })
    //   return
    // }

    const base = { subtotal: 0, taxBase: 0, penaltyFee: 0 }
    const { subtotal, taxBase, penaltyFee } = invoices.reduce((obj, current) => {
      return {
        subtotal: Currency.Convert(obj.subtotal + current.amount_bs, 1),
        taxBase: !current.fines_id ? Currency.Convert(obj.taxBase + current.amount_bs, 1) : obj.taxBase,
        penaltyFee: current.fines_id ? Currency.Convert(obj.penaltyFee + current.amount_bs, 1) : obj.penaltyFee,
      }
    }, base)

    const exempt = property?.type_id === Constants.PROPERTY_TYPES.RESIDENTIAL ? Currency.Convert(taxBase * IVA_PERCENTAGE, 1) : 0
    const iva = property?.type_id !== Constants.PROPERTY_TYPES.RESIDENTIAL ? Currency.Convert(taxBase * IVA_PERCENTAGE, 1) : 0
    const retention = iva > 0 && property?.retention === Constants.RETENTION.YES ? Currency.Convert(iva * RETENTION_PERCENTAGE, 1) : 0

    const total = Currency.Convert(subtotal + iva, 1)
    const total_voucher = vouchers.reduce((value, current) => value + parseFloat(current.amount_paid), 0)

    const _total_to_pay = Currency.Convert(total - retention - total_voucher, 1)
    let total_to_pay = creditApply ? _total_to_pay - Currency.Convert(property?.total_credit_notes_bs ?? 0, 1) : _total_to_pay

    let credit = 0

    if (total_to_pay < 0) {
      credit = total_to_pay * -1
      total_to_pay = 0
    }

    setPaymentDetail({
      subtotal,
      taxBase,
      penaltyFee,
      exempt,
      iva,
      retention,
      total,
      total_to_pay,
      total_voucher,
      credit,
    })
  }

  const resetAll = () => {
    // if (!property) return

    setClients([])
    setPayments([])
    setFines([])
    setServices([])
    setInvoices([])
    setVouchers([])
    setCreditApply(false)
    setProperty(null)
    setSearchValue(SEARCH_VALUE_DEFAULT)
    setCurrentPetroValue('')
    setVisibleConfirmPetroValue(true)
  }

  const generatePreinvoice = () => {
    if (!property) return
    console.log('generatePreinvoice')
  }

  const finish = async () => {
    if (!property) return

    if (invoices.length === 0) {
      Globals.showError('Debe agregar al menos 1 item a facturar')
      return
    }

    if (invoices.length !== 0 && paymentDetail.total_to_pay !== 0) {
      if (!property?.retention && vouchers.length === 0 && paymentDetail.credit === 0) {
        Globals.showError('Debe agregar al menos 1 comprobante de pago')
        return
      }
    }

    if (!property?.retention && paymentDetail.total_to_pay !== 0) {
      Globals.showError('Debe completar el Total a pagar')
      return
    }

    setLoadingFinish(true)

    const paymentDetailPetro = Object.entries(paymentDetail).reduce((obj, [key, value]) => {
      return {
        ...obj,
        [key]: value / property.petro_value,
      }
    }, {})
    const voucherTotal = vouchers.reduce((sum, v) => sum + parseFloat(v.amount_paid), 0)

    try {
      const data = {
        property,
        invoices,
        vouchers,
        voucherTotalBs: voucherTotal,
        voucherTotalPetro: voucherTotal / property.petro_value,
        paymentDetailBs: paymentDetail,
        paymentDetailPetro,
        creditApply,
      }

      await CashService.admin.createPayment(data)

      Globals.showSuccess('Se han guardado los datos correctamente')
      resetAll()
    } catch (error) {
      console.log(error)
      Globals.showError('Error al guardar los datos')
    } finally {
      setLoadingFinish(false)
    }
  }

  // Solo se mostrarà el modal a los usuarios con el permiso EDIT_UCD
  const setVisibleConfirmPetroValue = (value: boolean) => {
    if (checkPermission(Permissions.CASH.EDIT_UCD))
      _setVisibleConfirmPetroValue(value)
  }

  useEffect(() => {
    calculatePaymentDetails()
  }, [invoices, vouchers, creditApply])

  const values = useMemo<CashContextProps>(
    () => ({
      user,

      searchValue,
      setSearchValue,
      loadingSearch,
      searchPropertiesWithClient,
      handleSelectSearch,
      clients,

      loadingProperty,
      getPropertyId,
      property,

      loadingPayments,
      getPropertyIdPayments,
      payments,
      setPayments,

      loadingFines,
      getPropertyIdFines,
      fines,
      setFines,

      loadingServices,
      getPropertyIdServices,
      services,
      setServices,

      invoices,
      setInvoices,

      paymentDetail,
      creditApply,
      setCreditApply,

      vouchers,
      setVouchers,

      isCondominium,

      resetAll,
      generatePreinvoice,
      finish,
      loadingFinish,

      userIsClient,

      currentPetroValue,
      setCurrentPetroValue,

      visibleConfirmPetroValue,
      setVisibleConfirmPetroValue,
    }),
    [
      searchValue,
      loadingSearch,
      clients,
      loadingProperty,
      property,
      loadingPayments,
      payments,
      loadingFines,
      fines,
      loadingServices,
      services,
      invoices,
      paymentDetail,
      creditApply,
      vouchers,

      isCondominium,
      loadingFinish,

      userIsClient,
      currentPetroValue,
      visibleConfirmPetroValue,
    ]
  )

  return <CashContext.Provider value={values}>{children}</CashContext.Provider>
}

export function useCashContext() {
  const context = useContext(CashContext)

  if (!context) {
    console.error('Error deploying Cash Context!!!')
  }

  return context
}
