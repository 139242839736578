import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Button, Input, Select, Tooltip, Modal } from 'components';
import { checkPermission, Permissions, Constants, Globals, Socket, SocketEvents, Print, RegisterMovement } from 'utils';
import { UserService } from 'services';
import { EditIcon, ViewIcon, TrashIcon, CheckIcon, CloseIcon, PrintIcon } from 'assets/icons';
import ViewModerator from './view-moderator';

interface LocationProps {
	page: number | undefined;
	form: any
}

interface Props extends RouteComponentProps<{},{},LocationProps>, RootState {
	dispatch: any
}

const { ACTIVE, INACTIVE, ACTIVE_LABEL, INACTIVE_LABEL } = Constants.USERS_STATUS;

const STATUS = [
  { value: ACTIVE.toString(), label: ACTIVE_LABEL },
  { value: INACTIVE.toString(), label: INACTIVE_LABEL }
]

const INITIAL_STATE = {
	search: '',
	status: ''
}

class Moderators extends React.Component<Props> {  

  timer: any = undefined;

  state = {
  	data: [],
  	page: 1,
  	last_page: 1,
  	header: [
  		'ID',
  		'Caja',
  		'Administrador',
  		'Nombre y Apellido',
  		'C.I. / RIF',
  		'Estado',
		// 'Rol',
  		'Acciones'
  	],
  	form: INITIAL_STATE,
  	moderator: null,
  	visible: false
  }

  async componentDidMount() {
	this.props.dispatch({
		type: 'SET_HEADER',
		payload: {
			title: 'Administradores',
			onBack: null
		}
	});

	await this.setState({
		page: this.props.location.state?.page || this.state.page,
		form: this.props.location.state?.form || this.state.form
	});

	this.load();
  }

  load = async (withoutLoading: boolean = false) => {
	const res: any = await UserService.admin.get({
		page: this.state.page,
		withoutLoading,
		...this.state.form
	});
	this.setState({
		data: res.users.data,
		last_page: res.users.last_page
	});
  }

  edit = (e: any, url: string) => {
	e.preventDefault();
	this.props.history.push({
		pathname: url,
		state: this.state
	});
  }

  create = () => {
	this.props.history.push({
		pathname: '/admin/users/moderators/create',
		state: this.state
	});
  }

  change = (e: any,callback = () => {}) => {
	this.setState({
		page: 1,
		form: {
			...this.state.form,
			[e.target.name]: e.target.value
		}
	},callback);
  }

  reset = () => {
	this.setState({
		form: INITIAL_STATE,
		page: 1
	},() => this.load(true));
  }

  view = (e: any, item: any) => {
  	e.preventDefault();
  	this.setState({
  		visible: true,
  		moderator: item
  	});
  }

  delete = (e: any, item: any) => {
  	e.preventDefault();
  	Globals.confirm('¿Desea eliminar este Administrador?',async () => {
		await UserService.admin.delete({
			id: item.id
		});
		Globals.showSuccess("Se ha eliminado correctamente el Administrador");
		/* Socket.emit(SocketEvents.USERS.DELETE,{
			id: item.id,
		}); */
		this.load();

		RegisterMovement.save({
	        value_id: item?.id,
	        user_id: this.props.user.id,
	        module_id: Constants.MODULES.MODERATORS,
	        description: `Eliminación del Administrador ${ item?.full_name } ${ item?.full_document }`
	    });
	});
  }

  print = (e: any, item: any) => {
    e.preventDefault();
    Print(UserService.admin.download(item.crypt),true);
  }

  changeStatus = (e: any, i: any) => {
  	e.preventDefault();
  	Globals.confirm(`¿Desea ${ i.status == ACTIVE ? 'desactivar' : 'activar' } este Administrador?`,async () => {
		await UserService.admin.status({
			id: i.id,
			status: i.status == ACTIVE ? INACTIVE : ACTIVE
		});
		Globals.showSuccess("Se ha modificado correctamente el Administrador");
		/* Socket.emit(SocketEvents.USERS.CHANGE_STATUS,{
			id: i.id,
		}); */
		this.load();
	});
  }

  onClose = () => {
    this.setState({
      visible: false
    });
  }
  
  render() {
  	const { visible } = this.state;

    return (
        <div id="moderators">
        	<Modal
                className="high-modal modal-view-moderator"
                visible={ visible }
                onClose={ this.onClose } 
                title="Ver Administrador"
            >
              <ViewModerator
                moderator={ this.state.moderator }
              />
            </Modal>

        	<div className="row row-filter">
				<div className="col-12 text-right">
					{
						checkPermission(Permissions.CLIENTS.CREATE) && (
							<Button className="btn-urbaser-green" onClick={ this.create }>
								Registrar nuevo
							</Button>
						)
					}
				</div>
				<div className="col-md-12">
					<div className="row row-search">

					<div className="col-md-3">
							<Select
								color="white"
								name="status"
								onChange={ (e: any) => this.change(e,() => {
									this.load(true);
								}) }
								placeholder="Estatus"
								value={ this.state.form.status }
								options={ STATUS } />
						</div>
						
						<div className="col-md-5">
							<Input
								color="white"
								searchRight
								value={ this.state.form.search }
								name="search"
								placeholder="Buscar Código, Administrador, Nombre, CI"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
					
					</div>
				</div>
			</div>

				
			{
				this.state.form != INITIAL_STATE && (
					<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
				)
			}	

        	<Table data={ this.state.data.length } title="" header={ this.state.header }>
				{ this.state.data.map((i: any,index: number) => {
					return (
						<tr key={ index }>
							<td>{ i.number_format }</td>
							<td>{ i.box }</td>
							<td>{ i.username }</td>
							<td>{ i.full_name }</td>
							<td>{ i.full_document }</td>
							<td>{ i.status_text }</td>
							{/* <td>{ i.roles.length > 0? i.roles[i.roles.length - 1].name : ''}</td> */}
							<td>
								<Tooltip title="Ver">
									<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
										<img src={ ViewIcon } />
									</a>
								</Tooltip>
								{
									checkPermission(Permissions.MODERATORS.CREATE) && (
										<Tooltip title="Editar">
											<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,`/admin/users/moderators/edit/${ i.id }`) }>
												<img src={ EditIcon } />
											</a>
										</Tooltip>
									)
								}
								<Tooltip title={ i.status == ACTIVE ? 'Desactivar' : 'Activar' }>
									<a className="link-icon" href="#" onClick={ (e: any) => this.changeStatus(e,i) }>
										<img src={ i.status == ACTIVE ? CloseIcon : CheckIcon } />
									</a>
								</Tooltip>
								<Tooltip title="Imprimir">
									<a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
										<img src={ PrintIcon } />
									</a>
								</Tooltip>
								{
									checkPermission(Permissions.MODERATORS.DELETE) && (
										<Tooltip title="Eliminar">
											<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
												<img src={ TrashIcon } />
											</a>
										</Tooltip>
									)
								}
							</td>
						</tr>
					)
				}) }
			</Table>

			<Pagination 
				pages={ this.state.last_page } 
				active={ this.state.page }
				onChange={ async (page: number) => {
					await this.setState({
						page: page
					});
					this.load();
				} } />
        </div>        
    )
  }
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Moderators);