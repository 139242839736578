import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Constants } from 'utils';
import { Redirect } from 'react-router-dom';

const Home = () => {
	const user = useSelector((state: RootState) => state.user);

	if (!user) {
		return <Redirect to="/login" />
	}
	else {
		return <Redirect to="/welcome" />
	}
}

export default Home;