import { Button, InputCurrency, Modal } from "components"
import { CashModalInvoiceEditAmountProps } from "../Cash"
import { useCashContext } from "../useCashContext"
import React from "react"
import { Currency } from "utils"

const CashModalInvoiceEditAmount = ({ invoice, visible, setVisible, onSave }: CashModalInvoiceEditAmountProps) => {
  const { property } = useCashContext()

  const onClose = () => {
    setVisible(false)
  }

  return (
    <Modal className="modal-create-bank-account" visible={visible} onClose={onClose} title="Editar monto">
      <div>
        <div className="row">
          <div className="col-12">
            <InputCurrency
              color="white"
              label="Monto"
              value={invoice?.amount_bs ?? ''}
              onChange={(e: any) => {
                if (!invoice || !property) return

                invoice.amount = e.target.value / property.petro_value
                invoice.amount_bs = Currency.Convert(e.target.value, 1)
              }}
            />
          </div>
        </div>
        <div className="text-center">
          <Button type="submit" onClick={(e: any) => onSave()}>
            Guardar
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CashModalInvoiceEditAmount
