import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { BankAccountService } from 'services';
import { Table, Input, Pagination, Button, Modal, Tooltip } from 'components';
import { Permissions, checkPermission, Constants, Globals, RegisterMovement } from 'utils';
import { EditIcon, TrashIcon, CloseIcon, CheckIcon } from 'assets/icons';
import CreateBankAccount from './create-bank-account';
import EditBankAccount from './edit-bank-account';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

const INITIAL_STATE = {
  search: ''
}

const { ACTIVE, INACTIVE } = Constants.BANK_ACCOUNT_STATUS;

class BankAccounts extends React.Component<Props> {  

  timer: any = undefined;

  state = {
    form: INITIAL_STATE,
    data: [],
    page: 1,
    last_page: 1,
    visible: false,
    header: [
      'Banco',
      'Tipo',
      'Propietario',
      'Nro. Cuenta',
      'Identificación',
      'Acciones'
    ],
    bank_account: null,
    visible_edit: false,
    banks: [],
    document_types: [],
    account_types: []
  }

  componentDidMount() {
  	this.props.dispatch({
  		type: 'SET_HEADER',
  		payload: {
  			title: 'Cuentas Bancarias',
  			onBack: null
  		}
  	});

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await BankAccountService.admin.get({
      page: this.state.page,
      withoutLoading,
      ...this.state.form
    });
    this.setState({
      data: res.accounts.data,
      last_page: res.accounts.last_page,
      document_types: res.document_types,
      account_types: res.account_types,
      banks: res.banks
    });
  }

  onClose = (reload: boolean = false) => {
    this.setState({
      visible: false
    },() => {
      if (reload) {
        this.load(true);
      }
    });
  }

  onCloseEdit = (reload: boolean = false) => {
    this.setState({
      visible_edit: false
    },() => {
      if (reload) {
        this.load(true);
      }
    });
  }

  create = () => {
    this.setState({
      visible: true
    });
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

  changeStatus = (e: any, i: any) => {
    e.preventDefault();
    Globals.confirm(`¿Desea ${ i.status == ACTIVE ? 'desactivar' : 'activar' } esta cuenta?`,async () => {
      await BankAccountService.admin.status({
        bank_account_id: i.id,
        status: i.status == ACTIVE ? INACTIVE : ACTIVE
      });
      Globals.showSuccess("Se ha modificado correctamente la cuenta");
      this.load(true);
    });
  }

  delete = (e: any, item: any) => {
    e.preventDefault();
    Globals.confirm('¿Desea eliminar esta cuenta?',async () => {
      await BankAccountService.admin.delete({
        bank_account_id: item.id
      });
      Globals.showSuccess("Se ha eliminado correctamente la cuenta");
      this.load();
      const bank: any = this.state.banks.find((i: any) => i.id == item.bank_id);
      RegisterMovement.save({
          value_id: item.id,
          user_id: this.props.user.id,
          module_id: Constants.MODULES.BANK_ACCOUNTS,
          description: `Eliminación de cuenta bancaria del banco ${ bank?.name }`
      });
    });
  }

  edit = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible_edit: true,
      bank_account: item
    });
  }
  
  render() {
    const { visible, visible_edit, bank_account, document_types, banks, account_types } = this.state;

    return (
        <div id="bank-accounts">
            <Modal
                className="high-modal modal-create-bank-account"
                visible={ visible }
                onClose={ this.onClose } 
                title="Registrar Cuenta Bancaria"
            >
              <CreateBankAccount
                onClose={ this.onClose }
                document_types={ document_types }
                banks={ banks }
                account_types={ account_types }
                user={ this.props.user }
              />
            </Modal>

            <Modal
                className="high-modal modal-edit-bank-account"
                visible={ visible_edit }
                onClose={ this.onCloseEdit } 
                title="Editar Cuenta Bancaria"
            >
              <EditBankAccount
                onClose={ this.onCloseEdit }
                document_types={ document_types }
                banks={ banks }
                account_types={ account_types }
                bank_account={ bank_account }
                user={ this.props.user }
              />
            </Modal>

            <div className="row row-filter">
              <div className="col-12 text-right">
                {
                  checkPermission(Permissions.BANK_ACCOUNTS.CREATE) && (
                    <Button className="btn-urbaser-green" onClick={ this.create }>
                      Registrar nueva
                    </Button>
                  )
                }
              </div>
              <div className="col-md-8">
                <Input
                  color="white"
                  searchRight
                  value={ this.state.form.search }
                  name="search"
                  placeholder="Buscar Unidad, Parroquia"
                  onChange={ (e: any) => {
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                      this.load(true);
                    },1000);
                    this.change(e); 
                  } } />
              </div>
            </div>

            {
              this.state.form != INITIAL_STATE && (
                <p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
              )
            }  

            <Table data={ this.state.data.length } title="" header={ this.state.header }>
              { this.state.data.map((i: any,index: number) => {
                return (
                  <tr key={ index }>
                    <td>{ i.bank?.name || '-' }</td>
                    <td>{ i.account_type?.name || '-' }</td>
                    <td>{ i.name }</td>
                    <td>{ i.number }</td>
                    <td>{ i.full_document }</td>
                    <td>
                      {
                        checkPermission(Permissions.BANK_ACCOUNTS.CREATE) && (
                          <Tooltip title="Editar">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
                              <img src={ EditIcon } />
                            </a>
                          </Tooltip>
                        )
                      }
                      <Tooltip title={ i.status == ACTIVE ? 'Desactivar' : 'Activar' }>
                        <a className="link-icon" href="#" onClick={ (e: any) => this.changeStatus(e,i) }>
                          <img src={ i.status == ACTIVE ? CloseIcon : CheckIcon } />
                        </a>
                      </Tooltip>
                      {
                        checkPermission(Permissions.BANK_ACCOUNTS.DELETE) && (
                          <Tooltip title="Eliminar">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
                              <img src={ TrashIcon } />
                            </a>
                          </Tooltip>
                        )
                      }
                    </td>
                  </tr>
                )
              }) }
            </Table>

            <Pagination 
              pages={ this.state.last_page } 
              active={ this.state.page }
              onChange={ async (page: number) => {
                await this.setState({
                  page: page
                });
                this.load();
              } } />
        </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(BankAccounts);