import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Select, Input } from 'components';
import { TrashIcon } from 'assets/icons';
import { ProfileService } from 'services';
import { Globals, Constants, Numeric } from 'utils';
import FormClientProperty from 'components/FormClientProperty';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

class Profile extends React.Component<Props> {

  // state = {
  //   form: {
  //     name: '',
  //     lastname: '',
  //     username: '',
  //     email: '',
  //     phone: '',
  //     document_type_id: '',
  //     document: '',
  //     business_name: '',
  //     economic_activity: '',
  //     fiscal_address: '',
  //     retention: Constants.RETENTION.NO
  //   },
  //   codes: [],
  //   document_types: [],
  //   phones: [],
  //   emails: []
  // }

  // componentDidMount() {
  // 	this.props.dispatch({
  // 		type: 'SET_HEADER',
  // 		payload: {
  // 			title: 'Mis Datos',
  // 			onBack: null
  // 		}
  // 	});

  //   this.load();
  // }

  // load = async () => {
  //   const res: any = await ProfileService.get({
  //     user_id: this.props.user.id
  //   });
  //   this.setState({
  //     form: {
  //       name: res.user.name,
  //       lastname: res.user.lastname,
  //       username: res.user.username,
  //       email: res.user.email,
  //       phone: res.user.phone,
  //       document_type_id: res.user.document_type_id,
  //       document: res.user.document,
  //       business_name: res.user.business_name,
  //       fiscal_address: res.user.fiscal_address,
  //       retention: res.user.retention
  //     },
  //     document_types: res.document_types,
  //     codes: res.codes,
  //     emails: res.user.emails,
  //     phones: res.user.phones,
  //   });
  // }

  // addPhone = () => {
  //   let phones: any = [...this.state.phones];
  //   phones.push({
  //     phone: ''
  //   });
  //   this.setState({
  //     phones
  //   });
  // }

  // addEmail = () => {
  //   let emails: any = [...this.state.emails];
  //   emails.push({
  //     email: ''
  //   });
  //   this.setState({
  //     emails
  //   });
  // }

  // change = (e: any,callback = () => {}) => {
  //   this.setState({
  //     form: {
  //       ...this.state.form,
  //       [e.target.name]: e.target.value
  //     }
  //   },callback);
  // }

  // deletePhone = (index: number) => {
  //   let phones: any = [...this.state.phones];
  //   phones.splice(index,1);
  //   this.setState({
  //     phones
  //   });
  // }

  // deleteEmail = (index: number) => {
  //   let emails: any = [...this.state.emails];
  //   emails.splice(index,1);
  //   this.setState({
  //     emails
  //   });
  // }

  // changeEmails = (e: any,index: number) => {
  //   let emails: any = [...this.state.emails];
  //   emails[index].email = e.target.value;
  //   this.setState({
  //     emails
  //   });
  // }

  // changePhones = (e: any,index: number) => {
  //   let phones: any = [...this.state.phones];
  //   phones[index].phone = e.target.value;
  //   this.setState({
  //     phones
  //   });
  // }

  // submit = async () => {
  //   await ProfileService.updateClient({
  //     ...this.state.form,
  //     user_id: this.props.user.id,
  //     emails: JSON.stringify(this.state.emails),
  //     phones: JSON.stringify(this.state.phones)
  //   });
  //   Globals.showSuccess("Se han guardado los datos correctamente");
  // }

  load = async (self: any) => {
    const res: any = await ProfileService.get({
      user_id: self.props.user.id
    });
    self.setState({
      form: {
        name: res.user.name,
        lastname: res.user.lastname,
        username: res.user.username,
        email: res.user.email,
        phone: Numeric.getOnlyNumber(res.user.phone),
        document_type_id: res.user.document_type_id,
        document: res.user.document,
        business_name: res.user.business_name,
        fiscal_address: res.user.fiscal_address,
        retention: res.user.retention
      },
      document_types: res.document_types,
      codes: res.codes,
      emails: res.user.emails,
      phones: res.user.phones?.map((n: string) => Numeric.getOnlyNumber(n)),
    });
  }

  submit = async (self: any) => {
    await ProfileService.updateClient({
      ...self.state.form,
      user_id: self.props.user.id,
      emails: JSON.stringify(self.state.emails),
      phones: JSON.stringify(self.state.phones)
    });
    Globals.showSuccess("Se han guardado los datos correctamente");
  }

  render() {
    // const phones: any = this.state.phones;
    // const emails: any = this.state.emails;

    return (
        <div id="admin-create-user">
          <FormClientProperty
            {...this.props}
            isProfile={true}
            show="CLIENT"
            headTitle="Mis Datos"
            onBack={null}
            onLoad={this.load}
            onSubmit={this.submit}
          />
          {/* <div className="row row-form">
             <div className="col-lg-6">
                <Input
                  color="white"
                  value={ this.state.form.name }
                  name="name" 
                  label="Primer nombre e inicial del segundo"
                  onChange={ this.change } />
              </div>
              <div className="col-lg-6">
                <Input
                  color="white"
                  value={ this.state.form.lastname }
                  name="lastname" 
                  label="Primer apellido e inicial del segundo"
                  onChange={ this.change } />
              </div>
              <div className="col-lg-4">
                <p className="label">C.I. / RIF</p>
                <div className="i-flex">
                  <div className="doc-flex">
                    <Select
                      color="gray"
                      className="select-phone"
                      name="document_type_id"
                      onChange={ this.change }
                      value={ this.state.form.document_type_id }
                      placeholder="Tipo"
                      options={ this.state.document_types.map((i: any) => {
                        return {
                          value: i.id,
                          label: i.name
                        }
                      }) } />
                  </div>
                  <div className="doc-flex">
                    <Input
                      color="white"
                      value={ this.state.form.document }
                      name="document" 
                      type="number"
                      placeholder="C.I. / RIF"
                      onChange={ this.change } />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                 <Input
                  type="number"
                  color="white"
                  value={ this.state.form.phone }
                  name="phone" 
                  label="Teléfono"
                  onChange={ this.change } />
              </div>
              <div className="col-lg-4">
                <p className="label label-button">
                  Teléfonos Adicionales
                  <div className="btn-add" onClick={ this.addPhone }>
                    +
                  </div>
                </p>              
                {
                  phones.map((item: any,index: number) => (
                     <div className="container-delete">
                      <Input
                        color="white"
                        value={ phones[index].phone }
                        name="phone" 
                        type="number"
                        placeholder="Teléfono"
                        onChange={ (e: any) => this.changePhones(e,index) } />
                      <img src={ TrashIcon } onClick={ () => this.deletePhone(index) } />
                    </div>    
                  ))
                }
              </div>
              <div className="col-lg-4">
                <Input
                  color="white"
                  value={ this.state.form.email }
                  name="email" 
                  label="Correo Electrónico"
                  onChange={ this.change } />
              </div>
              <div className="col-lg-4">
                <p className="label label-button">
                  Correos Adicionales
                  <div className="btn-add" onClick={ this.addEmail }>
                    +
                  </div>
                </p>
                {
                  emails.map((item: any,index: number) => (
                    <div className="container-delete">
                      <Input
                        color="white"
                        value={ emails[index].email }
                        name="email" 
                        placeholder="Correo Electrónico"
                        onChange={ (e: any) => this.changeEmails(e,index) } />
                      <img src={ TrashIcon } onClick={ () => this.deleteEmail(index) } />
                    </div>                   
                  ))
                }
              </div>
              <div className="col-lg-4"></div>
              <div className="col-lg-6">
                <Input
                  color="white"
                  value={ this.state.form.fiscal_address }
                  name="fiscal_address" 
                  label="Dirección Fiscal"
                  onChange={ this.change } />
              </div>
              <div className="col-lg-6">
                <Input
                  color="white"
                  value={ this.state.form.business_name }
                  name="business_name" 
                  label="Razón Social"
                  onChange={ this.change } />
              </div>
              <div className="col-lg-4">
                <Input
                  color="white"
                  value={ this.state.form.username }
                  name="username" 
                  label="Usuario"
                  onChange={ this.change } 
                  readOnly
                  />
              </div>
              <div className="col-lg-4">
                  {
                    parseInt(this.state.form.document_type_id) == Constants.DOCUMENT_TYPES.J && (
                      <div className="container-radio retention">
                        <p>Agente de Retención</p>
                        <div className="item-radio" onClick={() => this.change({
                          target: {
                            name: 'retention',
                            value: Constants.RETENTION.YES
                          }
                        })}>
                          <div className={`radio ${this.state.form.retention == Constants.RETENTION.YES ? 'active' : ''}`}>
                            <div className="circle"></div>
                          </div>
                          <p>Si</p>
                        </div>
                        <div className="item-radio" onClick={() => this.change({
                          target: {
                            name: 'retention',
                            value: Constants.RETENTION.NO
                          }
                        })}>
                          <div className={`radio ${this.state.form.retention == Constants.RETENTION.NO ? 'active' : ''}`}>
                            <div className="circle"></div>
                          </div>
                          <p>No</p>
                        </div>
                      </div>
                    )
                  }
                </div>
              <div className="col-12 text-right">
                <Button type="button" onClick={ this.submit }>
                  Guardar
                </Button>
              </div>
            </div>  */}
        </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(Profile);