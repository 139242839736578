import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, SelectSearch, InputCurrency, Button, Tooltip, Select, Input, DatePicker, TooltipCurrency, PaperClip, Checkbox } from 'components';
import { CashService } from 'services';
import { CloseIcon, CheckWhite } from 'assets/icons';
import { Currency, Globals, Constants, RegisterMovement, Print, SocketEvents } from 'utils';
import moment from 'moment';
import Swal from 'sweetalert2';
import colors from './../../../utils/colors';
import RegisterAgreement from './register-agreement';
import queryString from 'query-string';
import { ImagesAndDocuments } from 'components/paperclip';
import { on } from 'jetemit';

interface Params {
	id: string | undefined
}

interface Props extends RootState, RouteComponentProps<Params> {
  dispatch: any
}

const TABS = {
  BILLS: 1,
  AGREEMENTS: 2
}

const INITIAL_STATE = {
  client_id: '',
  number: '',
  payment_method_id: '',
  bank_id: '',
  bank_account_id: '',
  date: '',
  property_id: '',
  file: '',
  accepted: Constants.BILL_STATUS.PENDING,
  reception_date: '',
  amount_paid: '',
  discount: '',
}

class Cash extends React.Component<Props> {  

  state = {
    form: INITIAL_STATE,
    clients: [],
    client: null,
    header: [
      'Descripción',
      'Total',
      // 'Acción'
    ],
    payments: {},
    conversion_petro: 0,
    iva: 0,
    payment_methods: [],
    methods: {},
    bank_accounts: [],
    banks: [],
    tab: TABS.BILLS,
    credit: 0,
    unsubscribe: () => {},
    totalDiscount : 0,
    receipts: [
      { ...INITIAL_STATE }
    ],
    receipts_amount: '',
    retention: 0,
    tax_base: 0,
    totalTemp: 0,
    institution: null,
  }

  componentDidMount() {
  	this.props.dispatch({
  		type: 'SET_HEADER',
  		payload: {
  			title: 'Caja',
  			onBack: null
  		}
  	});

    this.load();

    this.setState({
      subscribe: this.state
    });
  }

  componentWillUnmount() {
    this.state.unsubscribe();
  }

  updatePetro = async () => {
    const res: any = await CashService.admin.petro({
      withoutLoading: true
    });
    this.setState({
      conversion_petro: res.conversion_petro?.amount || 0
    });
  }

  fetchClients = async (search?: string ) => {
    const pagination = { search };
    const params = this.props.user.level_id === 3 ? { client_id: this.props.user.id, ...pagination } : { ...pagination };

    try {
      const res: any = await CashService.admin.getClients(params);

      this.setState({
        clients: res.clients,
        payment_methods: res.payment_methods,
        banks: res.banks,
        bank_accounts: res.bank_accounts
      },() => {
        if (this.props.user.level_id === 3) {
          this.setClient(this.props.user.id);
          return;
        }
  
        const params = queryString.parse(this.props.location.search);
        if (params.client_id) {
          this.setClient(Array.isArray(params.client_id) ? params.client_id[0] : params.client_id);
          return;
        }
  
        if (this.props.match.params.id) {
          this.setClient(this.props.match.params.id);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  load = async () => {
    const params = this.props.user.level_id === 3 ? { client_id: this.props.user.id } : {};

    if (this.props.match.params.id) {
      const res: any = await CashService.admin.getClients(params);

      this.setState({
        clients: res.clients,
        payment_methods: res.payment_methods,
        banks: res.banks,
        bank_accounts: res.bank_accounts
      },() => {
        if (this.props.user.level_id === 3) {
          this.setClient(this.props.user.id);
          return;
        }

        const params = queryString.parse(this.props.location.search);
        if (params.client_id) {
          this.setClient(Array.isArray(params.client_id) ? params.client_id[0] : params.client_id);
          return;
        }

        if (this.props.match.params.id) {
          this.setClient(this.props.match.params.id);
          return;
        }
      });
    }
  }

  setClient = (clientId: string | number) => {
    const client_id: number = typeof clientId === 'string' ? parseInt(clientId) : clientId;

    if (client_id) {
      const client: any = this.state.clients.find((i: any) => i.id == client_id);

      if (client) {
        this.setState({
          form: {
            ...this.state.form,
            client_id: client_id,
            property_id: ''
          },
          client: {
            id: client.id,
            full_name: client.full_name,
            properties: []
          },
          tab: TABS.BILLS,
          payments: {}
        },
        () => this.loadProperties((properties) => {
          const property_id = properties.length > 0 ? properties[0].id : null;

          if (property_id) {
            this.setState({
              form: {
                ...this.state.form,
                property_id,
              }
            },
            () => this.loadPayments((payments: any) => {
              // Object.keys(payments).map((key: string, index: number) => {

              //   Object.keys(payments[key]).map((_key: string, _index: number) => {

              //     if (index === 0 || payments[Object.keys(payments)[index - 1]][_key]['checked']) {

              //       this.checkClick({ index, key, _key });
              //     }
              //   });
              // });
            }));
          }
        }));
      } else {
        Globals.showError("Lo sentimos, el cliente no tiene caja asignada");
      }
    }
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
    
  }

  changeDebounce = (callback: any, time: number) => {
    let timeout: any;
    return (e: any) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => callback(e), time);
    };
  }

  changeDiscount = this.changeDebounce(async (e: any, callback = () => {}) => {
    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Cambiar el descuento restaurará los recibos agregados, esta seguro de continuar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      this.change(e, () => {
        this.setState({
          receipts: [
            { ...INITIAL_STATE }
          ],
          receipts_amount: '',
        }, () => {
          this.calculateAmountPaid(this.state.payments);
          callback();
        });
      })
    }
  }, 600)

  getCalculateTotalReceipts = () => {
    let amount = 0;

    this.state.receipts.forEach((receipt) => {
      amount += parseFloat(receipt.amount_paid);
    });

    if (amount < 0) amount = 0;

    return amount;
  }

  getCalculateRemainingAmount = () => {
    let amount = 0;

    this.state.receipts.forEach((receipt) => {
      amount += parseFloat(receipt.amount_paid);
    });

    amount = parseFloat(this.state.form.amount_paid) - amount;

    if (amount < 0) amount = 0;

    return amount.toFixed(2);
  }

  updateReceiptTotal = () => {
    const total = this.getCalculateTotalReceipts();
    this.setState({
      receipts_amount: total,
    })
  }

  changeReceipts = (e: any, index: number, callback = () => {}) => {
    const data = JSON.parse(JSON.stringify(this.state.receipts))

    data[index] = {
      ...data[index],
      [e.target.name]: e.target.value
    }

    this.setState({
      receipts: [...data]
    }, () => {
      callback();
      this.updateReceiptTotal();
    });
  }

  addReceipt = () => {
    const amount = this.getCalculateRemainingAmount();

    this.setState({
      receipts: [
        ...this.state.receipts,
        {
          ...INITIAL_STATE,
          amount_paid: amount,
        }
      ],
    }, () => this.updateReceiptTotal());
  }

  removeReceipt = (e: any, index: number) => {
    e.preventDefault();

    this.setState({
      receipts: [...this.state.receipts.filter((_, i) => i !== index)]
    }, () => this.updateReceiptTotal());
  }

  loadPayments = async (callback = (payments: any[]) => {}) => {
    const client: any = this.state.client;
    const res: any = await CashService.admin.getPayments({
      client_id: client?.id,
      property_id: this.state.form.property_id
    });

    // Ordenar los payments por fecha
    const paymentsSort = Object.entries(res.payments)
      .sort((a, b) => {
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
      })
      .reduce((acc: any, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});


    this.setState({
      payments: paymentsSort,
      conversion_petro: res.conversion_petro?.amount || 0,
      iva: res.iva?.amount || 0,
      methods: {},
      credit: res.credit,
      retention: res.retention,
    }, () => callback(paymentsSort));
  }

  loadProperties = async (callback = (properties: any[]) => {}) => {
    const client: any = this.state.client;
    const res: any = await CashService.admin.getProperties({
      client_id: client?.id,
    });
    this.setState({
      client: {
        ...client,
        properties: res.properties
      },
      institution: parseInt(res.institution)
    }, () => callback(res.properties));
  }

  delete = (e: any,item: any,index: number) => {
    e.preventDefault();
    Globals.confirm('¿Desea eliminar el aviso de pago?',async () => {
      const res: any = await CashService.admin.deletePayment({
        payment_id: item.id
      });
      this.loadPayments();
      RegisterMovement.save({
          value_id: item.id,
          user_id: this.props.user.id,
          module_id: Constants.MODULES.PAYMENTS,
          description: `Eliminación del aviso de pago de ${ res.payment?.service_type?.name } del contribuyente ${ res.payment?.user?.full_name } ${ res.payment?.user?.full_document }`
      });
    });    
  }

  print = (item: any) => {
    Print(CashService.admin.print(item.crypt,this.props.user.crypt),true);
  }

  calculateDiscount = (total: number) => {
    let discount: any = this.state.form.discount;
    let res: any = (total - (total * discount) / 100);
    
    return res;
  }

  calculateIvaSobreDiscount = (base: number, iva: number) => {
    let discount: any = this.calculateDiscount(base);
    let res: any = (discount * iva) / 100;
     
    return res;
  }

  calculateIvaRentention = (base: number, iva: number) => {
    const retention = Constants.BASE_INSTITUTION === Constants.INSTITUTION.GUAYOS ? 0.75 : 0.75;

    const result = ((base * iva) / 100) * retention;

    return result;
  }

  calculateTotalWithRentention = (base: number, iva: number, total: number, fines?: number) => {
    if(this.state.retention == 0) {
      return total;
    }

    const retention = Constants.BASE_INSTITUTION === Constants.INSTITUTION.GUAYOS ? 0.25 : 0.25;
    let iva_: any = (base * iva) / 100;
    let diff: any = (iva_ * retention);
    let res: any = base + diff;

    return res + (fines ?? 0);
  }

  calculateTotal = (base: number, iva: number) => {
    let discount: any = this.calculateDiscount(base);
    let retention: any = this.calculateIvaRentention(base, iva);
    
    let res: any = discount + ((discount * iva) / 100);
    
    if(this.state.retention == 1){
      res = res - retention;
    }
    
    return parseFloat(res.toFixed(2));
  }

  finish = (total: number) => {
    let months: any = [];
    const payments: any = this.state.payments;

    Object.keys(payments).map((key: string,index: number) => {
      Object.keys(payments[key]).map((_key: string,index: number) => {
        if (payments[key][_key].checked) {
          const [year, month] = key.split('-');
          months.push(`${month}-${year}`);
        }
      });
    });
    
    const form = { ...this.state.form  };
    if (total > 0 && this.state.retention === Constants.RETENTION.NO) {
      let message: string | boolean = false;
      const { TRANSFER, CASH, DEBIT, CREDIT, DEPOSIT } = Constants.PAYMENT_METHODS;

      if (!this.state.receipts.length) {
        message = "Debe ingresar al menos un (1) recibo";
      }

      form.payment_method_id = this.state.receipts[0].payment_method_id;
      form.number = this.state.receipts[0].number;
      form.date = this.state.receipts[0].date;
      form.bank_id = this.state.receipts[0].bank_id;
      form.bank_account_id = this.state.receipts[0].bank_account_id;
      form.reception_date = this.state.receipts[0].reception_date;
      form.accepted = this.state.receipts[0].accepted;

      if (!form.payment_method_id) {
        message = "Debe ingresar el método de pago";
      }

      if (!form.amount_paid) {
        message = "Debe ingresar el monto pagado";
      }
      
      // console.log(parseFloat(this.state.receipts_amount), Currency.Convert(this.calculateTotalWithRentention(this.state.tax_base, this.state.iva, total),this.state.conversion_petro));
      if (parseFloat(this.state.receipts_amount) < Currency.Convert(this.calculateTotalWithRentention(this.state.tax_base, this.state.iva, total),this.state.conversion_petro)) {
        message = "El monto pagado no puede ser menor al total";
      }

      if (parseInt(form.payment_method_id) == TRANSFER) {
        if (!form.number || !form.date || !form.bank_id || !form.bank_account_id) {
          message = "Debe ingresar todos los datos de pago";
        }
        if (!form.reception_date && form.accepted == Constants.BILL_STATUS.ACCEPTED) {
          message = "Debe ingresar la fecha de recepción del pago";
        }
      } 
      else if ([TRANSFER,CREDIT,DEBIT].indexOf(parseInt(form.payment_method_id)) != -1) {
        if (!form.number || !form.bank_id) {
          message = "Debe ingresar todos los datos de pago";
        }
      }
      else if ([DEPOSIT].indexOf(parseInt(form.payment_method_id)) != -1) {
        if (!form.number || !form.date) {
          message = "Debe ingresar todos los datos de pago";
        }
      }

      if (message) {
        Globals.showError(message);
        return false;
      }
    }

    const client: any = this.state.client;
    Globals.confirm('Corfirmar ejecución de pago?',async () => {
      const res: any = await CashService.admin.finish({
        ...form,
        auth_id: this.props.user.id,
        client_id: client?.id,
        date: form.date && moment(form.date).format('YYYY-MM-DD'),
        reception_date: form.reception_date && moment(form.reception_date).format('YYYY-MM-DD'),
        months,
        amount_paid: this.state.form.amount_paid,
        hasFile: true,
        receipts: JSON.stringify(this.state.receipts),
        receipts_amount: this.state.receipts_amount,
      });
      Globals.showSuccess("Se ha generado el recibo correspondiente");
      this.loadPayments();
      this.setState({
        form: INITIAL_STATE
      });

      if (res.bill?.status == Constants.BILL_STATUS.ACCEPTED) {
        this.print(res.bill);
      }

      RegisterMovement.save({
        value_id: res.bill?.id,
        user_id: this.props.user.id,
        module_id: Constants.MODULES.CASH,
        description: `Totalización de avisos de cobro del contribuyente ${ res.bill?.user?.full_name } ${ res.bill?.user?.full_document }`
      });
    });
  }

  changeMethod = (e: any,key: string,_key: string, value: string) => {
    let methods: any = {...this.state.methods};
    if (!methods[key]) {
      methods[key] = {};
    }
    if (!methods[key][_key]) {
      methods[key][_key] = {};
    }
    methods[key][_key][value] = e.target.value;
    this.setState({
      methods
    });
  }

  setTab = (tab: number) => {
    this.setState({
      tab
    });
  }

  calculateAmountPaid = (payments: any) => {
    let subtotal = 0;
    let fines = 0;
    let iva = 0;
    let tax_free = 0;
    let tax_base = 0;
    
    Object.keys(payments).map((key: string,index: number) => {
      Object.keys(payments[key]).map((_key: string,index: number) => {
        if (payments[key][_key].checked) {
          subtotal += payments[key][_key]
            .map((i: any) => i.total)
            .reduce((a: number, b: number) => a + b,0);

          fines += payments[key][_key]
            .map((i: any) => i.fines
              .map((i: any) => i.total)
              .reduce((a: number, b: number) => a + b,0))
            .reduce((a: number, b: number) => a + b,0);

          iva += payments[key][_key]
            .map((i: any) => {
              const total = i.total;
              if (i.property?.economic_activity?.tax_free == Constants.TAX_FREE.NO) {
                tax_base += total;
                return (total * this.state.iva) / 100;
              } else {
                tax_free += total;
                return 0;
              }
            })
            .reduce((a: number, b: number) => a + b, 0);
        }
      });
    });

    let total = subtotal + iva + fines - this.state.credit;
    if (total < 0) {
      total = 0;
    }

    const onlyOne = this.state.receipts.length === 1;
    const total_calculate = (this.state.retention == 1) ? this.calculateTotalWithRentention(tax_base, this.state.iva, total, fines) : total;
    const amountPaid = Currency.Convert( total_calculate , this.state.conversion_petro);

    this.setState({
      form: {
        ...this.state.form,
        amount_paid: amountPaid,
      },
      receipts_amount: amountPaid,
      receipts: onlyOne
        ? [{
          ...this.state.receipts[0],
          amount_paid: amountPaid,
        }]
        : this.state.receipts,
      tax_base: tax_base,
    });
  }

  checkClick = (data: { index: number, key: string, _key: string }) => {
    const { index, key, _key } = data;

    let payments: any = {...this.state.payments};
    if (!payments[key][_key]['checked'])
      payments[key][_key]['checked'] = true;
    else {
      payments[key][_key]['checked'] = false;
      Object.keys(payments).forEach((item: any,_index: number) => {
        if (_index > index) {
           payments[Object.keys(payments)[_index]][_key]['checked'] = false;
         }
      });
    }
    this.setState({
      payments
    },() => this.calculateAmountPaid(payments));
  }

  render() {
    const { clients, tab } = this.state;
    const client: any = this.state.client;
    const payments: any = this.state.payments;
    const methods: any = this.state.methods;
    const { TRANSFER, CASH, DEBIT, CREDIT, DEPOSIT } = Constants.PAYMENT_METHODS;
    const property: any = client?.properties.find((i: any) => i.id == this.state.form.property_id);

    let subtotal = 0;
    let fines = 0;
    let iva = 0;
    let cant = 0;
    let tax_free = 0;
    let tax_base = 0;
    let fines_list: any = [];

    Object.keys(payments).map((key: string,index: number) => {
      Object.keys(payments[key]).map((_key: string,index: number) => {
        if (payments[key][_key].checked) {
          subtotal += payments[key][_key]
            .map((i: any) => i.total)
            .reduce((a: number, b: number) => a + b, 0);

          fines += payments[key][_key]
            .map((i: any) => i.fines
              .map((i: any) => {
                const index = fines_list.findIndex((_i: any) => i.type?.id == _i.id);
                if (index == -1) {
                  fines_list.push({
                    id: i.type?.id,
                    name: i.type?.name,
                    total: i.total,
                  });
                }
                else {
                  fines_list[index].total += i.total;
                }
                return i.total;
              })
              .reduce((a: number, b: number) => a + b, 0))
            .reduce((a: number, b: number) => a + b, 0);

          iva += payments[key][_key]
            .map((i: any) => {
              const total = i.total;

              if (i.property?.economic_activity?.tax_free == Constants.TAX_FREE.NO) {
                tax_base += total;
                return (total * this.state.iva) / 100;
              } else {
                tax_free += total;
                return 0;
              }
            })
            .reduce((a: number, b: number) => a + b,0);
          cant++;
        }
      });
    });

    let total = subtotal + iva + fines - this.state.credit;

    if (total < 0) {
      total = 0;
    }

    const credit = (parseFloat(parseFloat(this.state.receipts_amount).toFixed(2)) - total);

    return (
        <div id="admin-cash">
          { this.props.user.level_id !== 3 && (
            <div className="container-white">
                <SelectSearch
                  label="Cliente"
                  onEnter={(value: string) => {
                    this.fetchClients(value)
                  }}
                  onChange={ (client: any) => {
                    console.log({ client });
                    this.setState({
                      form: {
                        ...this.state.form,
                        client_id: client.id,
                        property_id: ''
                      },
                      client: {
                        id: client.value,
                        full_name: client.label,
                        properties: []
                      },
                      tab: TABS.BILLS,
                      payments: {}
                    },() => this.loadProperties());
                  } }
                  placeholder="Seleccionar Cliente"
                  value={ {
                    value: client?.id || '',
                    label: client?.full_name || ''
                  } }
                  options={ clients.map((i: any) => {
                    return {
                      properties: i.properties,
                      value: i.id,
                      label: i.full_name + ' - ' + i.full_document + ' - ' + i.username,
                      client: {
                        id: i.id
                      }
                    }
                  }) } />
            </div>
          )}
          { client && client.properties.length === 0 && (
            <p className="text-center">Las propiedades del cliente pertenece a un condominio</p>
          )}
          {
            client && client.properties.length !== 0 && (
              <React.Fragment>
                <div style={ { marginTop: '20px' } }>
                 <Select
                    color="gray"
                    name="property_id"
                    onChange={ (e: any) => this.change(e,() => this.loadPayments()) }
                    label="Propiedad"
                    value={ this.state.form.property_id }
                    options={ client?.properties.map((i: any) => {
                      if (!i.property_id) {
                        return {
                          value: i.id,
                          label: i.urbaser_code + ' - ' + i.type?.name,
                          options: this.state.institution === Constants.INSTITUTION.GUAYOS ? i.secondary_properties ?? [] : []
                        }
                      }
                    }) } />
                </div>

                {
                  this.state.form.property_id && (
                    <div className="container-tabs">
                      <div className={ `tab ${ tab == TABS.BILLS ? 'active' : '' }` } onClick={ () => this.setTab(TABS.BILLS) }>
                        <p>Avisos de Cobro</p>
                      </div>
                      <div className={ `tab ${ tab == TABS.AGREEMENTS ? 'active' : '' }` } onClick={ () => this.setTab(TABS.AGREEMENTS) }>
                        <p>Convenios</p>
                      </div>
                      {/* {
                        Object.keys(payments).length == 0 && (
                          <div className={ `tab ${ tab == TABS.AGREEMENTS ? 'active' : '' }` } onClick={ () => this.setTab(TABS.AGREEMENTS) }>
                            <p>Convenios</p>
                          </div>
                        )
                      }                   */}
                    </div>
                  )
                }             

                {
                  tab == TABS.AGREEMENTS && (
                    <RegisterAgreement
                      conversion_petro={ this.state.conversion_petro }
                      property={ property } 
                      user={ client } 
                      moderator={ this.props.user } />
                  )
                }

                {
                  tab == TABS.BILLS && (
                    <React.Fragment>
                     {
                       property && (
                         <React.Fragment>
                           {
                             Object.keys(payments).map((key: string,index: number) => {
                               return (
                                 <React.Fragment>
                                   {
                                     Object.keys(payments[key]).map((_key: string,_index: number) => {
                                       return (
                                         <React.Fragment>
                                            <div className="row">
                                              <div className="col-md-10">
                                                <Table key={ _index } title="" data={ payments[key][_key].length } header={ this.state.header }>
                                                  {
                                                     payments[key][_key].map((item: any,index: number) => (
                                                         <tr key={ index }>
                                                           <td className="capitalize">{ item.service_type?.name + ` (${ moment(key,'YYYY-MM').format('MMMM YYYY') })` }</td>
                                                           <td>
                                                             <TooltipCurrency title={ (item.total) }>
                                                               { Globals.formatMiles(Currency.Convert((item.total),this.state.conversion_petro),true,Currency.BOLIVAR) }
                                                             </TooltipCurrency>
                                                           </td>
                                                           {/* <td>
                                                             {
                                                               this.props.user.level_id == Constants.LEVELS.ADMIN && (
                                                                <Tooltip title="Eliminar">
                                                                  <a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,item,index) }>
                                                                    <img src={ CloseIcon } />
                                                                  </a>
                                                                </Tooltip>
                                                               )
                                                             }                                               
                                                           </td> */}
                                                         </tr>
                                                      ))
                                                  }
                                                </Table>
                                              </div>
                                              <div className="col-md-2">
                                                  {
                                                    (index == 0 || payments[Object.keys(payments)[index - 1]][_key]['checked']) && (
                                                      <div
                                                        onClick={ () => this.checkClick({ index, key, _key }) }
                                                        className={ `item-check ${ payments[key][_key]['checked'] && 'active' }` }
                                                      >
                                                        <img src={ CheckWhite } />
                                                      </div>
                                                    )
                                                  }                                                  
                                              </div>
                                            </div>
                                         </React.Fragment>
                                     )})
                                   }
                                 </React.Fragment>
                               )
                            })
                          }

                          {
                            Object.keys(payments).length > 0 && (
                              <React.Fragment>
                                <div className="row">
                                  <div className="col-md-8">
                                    {(total) > 0 && this.state.retention === Constants.RETENTION.NO && 
                                    <Table title="" data={ this.state.receipts.length } header={ ['Recibos'] }>
                                      {
                                        this.state.receipts.map((receipt, index) => (
                                          <tr>
                                            <td style={{ borderBottom: '1px solid #6c757d' }}>
                                              <div key={index} className="row py-3 px-2">
                                                <div className="col-md-4">
                                                  <Select
                                                    name="payment_method_id"
                                                    onChange={ (e) => this.changeReceipts(e, index) }
                                                    label="Método de Pago"
                                                    value={ receipt.payment_method_id }
                                                    options={ this.state.payment_methods.map((i: any) => {
                                                      return {
                                                        value: i.id,
                                                        label: i.name
                                                      }
                                                    }) } />
                                                </div>
                                                <div className="col-md-4">
                                                  <InputCurrency
                                                    value={ receipt.amount_paid }
                                                    name="amount_paid"
                                                    label="Monto del recibo"
                                                    onChange={ (e: any) => this.changeReceipts(e, index) } />
                                                </div>
                                                {/* { TRANSFER == parseInt(receipt.payment_method_id) && ( */}
                                                  <div className="col-md-4">
                                                    <Input
                                                      type="date"
                                                      value={ receipt.date }
                                                      name="date"
                                                      label={parseInt(receipt.payment_method_id) === DEBIT ? "Fecha de Pago" : "Fecha de Transferencia"}
                                                      maxDate={ moment().toDate() }
                                                      onChange={ (e: any) => this.changeReceipts(e, index) } />
                                                    {/* <DatePicker
                                                      label="Fecha de Transferencia"
                                                      showYearDropdown={ true }
                                                      maxDate={ moment().toDate() }
                                                      onChange={ (value: string) => this.changeReceipts({
                                                        target: {
                                                          value,
                                                          name: 'date'
                                                        }
                                                      }, index) }
                                                      value={ receipt.date }
                                                    /> */}
                                                  </div>
                                                {/* )} */}
                                                {
                                                  [DEPOSIT].indexOf(parseInt(receipt.payment_method_id)) != -1 && (
                                                    <React.Fragment>
                                                      <div className="col-md-4">
                                                        <Select
                                                          name="bank_id"
                                                          onChange={ (e) => this.changeReceipts(e, index) }
                                                          label="Banco"
                                                          value={ receipt.bank_id }
                                                          options={ this.state.banks.map((i: any) => {
                                                            return {
                                                              value: i.id,
                                                              label: i.name
                                                            }
                                                          }) } />
                                                      </div>
                                                      <div className="col-md-4">
                                                        <Input
                                                          type="number"
                                                          value={ receipt.number }
                                                          name="number" 
                                                          label="Nro. de Referencia"
                                                          helpText={ receipt.number?.toString().length !== 8 ? "Escriba los últimos 8 dígitos" : "" }
                                                          onChange={ (e: any) => this.changeReceipts(e, index) } />
                                                      </div>
                                                    </React.Fragment>
                                                  )
                                                }
                                                {
                                                  [TRANSFER,CREDIT,DEBIT].indexOf(parseInt(receipt.payment_method_id)) != -1 && (
                                                    <React.Fragment>
                                                      <div className="col-md-4">
                                                        <Select
                                                          name="bank_id"
                                                          onChange={ (e) => this.changeReceipts(e, index) }
                                                          label="Banco"
                                                          value={ receipt.bank_id }
                                                          options={ this.state.banks.map((i: any) => {
                                                            return {
                                                              value: i.id,
                                                              label: i.name
                                                            }
                                                          }) } />
                                                      </div>
                                                      <div className="col-md-4">
                                                        <Input
                                                          type="number"
                                                          value={ receipt.number }
                                                          name="number" 
                                                          label="Nro. de Referencia"
                                                          helpText={ receipt.number?.toString().length !== 8 ? "Escriba los últimos 8 dígitos" : "" }
                                                          onChange={ (e: any) => this.changeReceipts(e, index) } />
                                                      </div>
                                                    </React.Fragment>
                                                  )
                                                }
                                                {
                                                  TRANSFER == parseInt(receipt.payment_method_id) && (
                                                    <React.Fragment>
                                                      <div className="col-md-4">
                                                        <Select
                                                          name="bank_account_id"
                                                          onChange={ (e) => this.changeReceipts(e, index) }
                                                          label="Cuenta de Banco"
                                                          value={ receipt.bank_account_id }
                                                          options={ this.state.bank_accounts.map((i: any) => {
                                                            return {
                                                              value: i.id,
                                                              label: i.name
                                                            }
                                                          }) } />
                                                      </div>
                                                      <div className="col-md-4">
                                                        <PaperClip
                                                          name="file"
                                                          formats={ ImagesAndDocuments }
                                                          value={ receipt.file }
                                                          className={ receipt.file && 'active' }
                                                          successText="Comprobante Cargado"
                                                          pendingText="Subir Comprobante"
                                                          onChange={ (e: any) => this.changeReceipts({
                                                            target: {
                                                              value: e.target.dataURL,
                                                              name: e.target.name,
                                                            }
                                                          }, index) } />
                                                      </div>
                                                      {/*
                                                        this.state.form.accepted == Constants.BILL_STATUS.ACCEPTED && (
                                                          <div className="col-md-4">
                                                            <DatePicker
                                                              color="gray"
                                                              label="Fecha de Recepción del Pago"
                                                              showYearDropdown={ true }
                                                              maxDate={ moment().toDate() }
                                                              onChange={ (value: string) => this.change({
                                                                target: {
                                                                  value,
                                                                  name: 'reception_date'
                                                                }
                                                              }) }
                                                              value={ this.state.form.reception_date }
                                                            />
                                                          </div>
                                                        )
                                                      */}
                                                      {/* <div className="col-md-4">
                                                        <Checkbox
                                                          name="accepted"
                                                          inline={ true }
                                                          label="Pago Conciliado"
                                                          checked={ this.state.form.accepted == Constants.BILL_STATUS.ACCEPTED }
                                                          onChange={ () => {
                                                            this.setState({
                                                              form: {
                                                                ...this.state.form,
                                                                accepted: this.state.form.accepted == Constants.BILL_STATUS.ACCEPTED ? Constants.BILL_STATUS.PENDING : Constants.BILL_STATUS.ACCEPTED
                                                              }              
                                                            });
                                                          } } />
                                                      </div>*/}
                                                    </React.Fragment>
                                                  )
                                                }
                                                <div className="col-md-4">
                                                  <div className="d-flex align-items-center">
                                                    { this.state.receipts.length > 1 &&
                                                      <Tooltip title="Eliminar">
                                                        <a
                                                          href="#"
                                                          className={`link-icon ml-3 ${(this.state.receipts.length - 1) === index ? 'mt-3' : 'mt-4'}`}
                                                          onClick={(e: any) => this.removeReceipt(e, index)}
                                                        >
                                                          <img src={ CloseIcon } />
                                                        </a>
                                                      </Tooltip>
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      }
                                      <tr>
                                        <td>
                                          <div style={{ textAlign: 'center' }}>
                                            <Button type="button" className="my-2" onClick={ this.addReceipt }>
                                              Agregar recibo
                                            </Button>
                                          </div>
                                        </td>
                                      </tr>
                                    </Table>
                                    }
                                  </div>
                                  <div className="col-md-4">
                                    {
                                      cant > 0 && (
                                        <React.Fragment>
                                          <div className="row" style={ { marginTop: '20px' } }>
                                              <div className="col-md-8 offset-4">
                                              {
                                                (total) > 0 && (
                                                  <InputCurrency
                                                    color="gray"
                                                    value={ this.state.receipts_amount }
                                                    name="amount_paid" 
                                                    label="Monto Pagado"
                                                    disabled />
                                                )
                                              }
                                              {
                                                (total) > 0 && (parseFloat(this.state.receipts_amount) > Currency.Convert(parseFloat(this.calculateTotalWithRentention(tax_base, this.state.iva, total, fines).toFixed(2)), this.state.conversion_petro)) && (
                                                  <p className="warning-credit-note">El monto restante será tomado como abono de meses o se creará una nota de crédito</p>
                                                )
                                              }
                                            </div>
                                            {/* <div className="col-md-8 offset-4">
                                              { 
                                              this.props.user.level_id !== 3 && (
                                                <InputCurrency
                                                  color="gray"
                                                  value={ this.state.form.discount }
                                                  name="discount"
                                                  label="Descuento (%)"
                                                  maxLength={ 6 }
                                                  onChange={ (e: any) => this.changeDiscount(e) } />
                                                )
                                              }
                                            </div> */}
                                          </div>

                                          <div className="text-right">
                                              <div className="container-white">
                                                <p>Subtotal: <TooltipCurrency title={ (subtotal) }>{ Globals.formatMiles(Currency.Convert(subtotal,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>
                                                {
                                                  fines_list.map((item: any,index: number) => (
                                                    <p key={ index }>{ item.name }: <TooltipCurrency title={ item.total }>{ Globals.formatMiles(Currency.Convert(item.total,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>
                                                  ))
                                                }
                                                {
                                                  this.state.credit > 0 && (
                                                    <p>Nota de Crédito: <TooltipCurrency title={ (this.state.credit) }>{ Globals.formatMiles(Currency.Convert(this.state.credit,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>
                                                  )
                                                }    
                                                <p>Exento: <TooltipCurrency title={ (tax_free) }>{ Globals.formatMiles(Currency.Convert(tax_free,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>     
                                                <p>Base Imponible: <TooltipCurrency title={ (tax_base) }>{ Globals.formatMiles(Currency.Convert(tax_base,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>     
                                                
                                                {
                                                  parseFloat(this.state.form.discount) > 0 && (
                                                    <>
                                                    
                                                      <p>Descuento ({this.state.form.discount }%): &nbsp;
                                                        <TooltipCurrency title={ (this.calculateDiscount(tax_base) / this.state.conversion_petro) }>
                                                          { Globals.formatMiles(Currency.Convert(this.calculateDiscount(tax_base),this.state.conversion_petro),true,Currency.BOLIVAR) }
                                                        </TooltipCurrency>
                                                      </p>
                                                      
                                                      <p>IVA ({ this.state.iva }%): <TooltipCurrency title={ (this.calculateIvaSobreDiscount(tax_base, this.state.iva)) }>{ Globals.formatMiles(Currency.Convert(this.calculateIvaSobreDiscount(tax_base, this.state.iva),this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>                     
                                                      
                                                      {
                                                        (this.state.retention == 1) && 
                                                        <p>Retención: <TooltipCurrency title={ (this.calculateIvaRentention(tax_base, this.state.iva)) }>{ Globals.formatMiles(Currency.Convert(this.calculateIvaRentention(tax_base, this.state.iva),this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>                     
                                                      }

                                                      <div className="footer-container-white"> &nbsp;
                                                        <p>Total: 
                                                          
                                                          <TooltipCurrency title={ (this.calculateTotal(tax_base, this.state.iva)) }>{ Globals.formatMiles(Currency.Convert(this.calculateTotal(tax_base, this.state.iva),this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>                            
                                                      </div>
                                                      {/* {
                                                        (this.calculateTotal(tax_base, this.state.iva)) > 0 && (parseFloat(credit.toFixed(2)) > 0) &&
                                                        <div className="footer-container-white mt-n4"> &nbsp;
                                                          <p>Crédito: 
                                                            <TooltipCurrency title={ (credit / this.state.conversion_petro) }>{ Globals.formatMiles(credit, true, Currency.BOLIVAR) }</TooltipCurrency>
                                                          </p>
                                                        </div>
                                                      } */}

                                                    </>

                                                  )     
                                                }

                                                {
                                                  this.state.form.discount == '' && (
                                                    <>
                                                      <p>IVA ({ this.state.iva }%): <TooltipCurrency title={ (iva) }>{ Globals.formatMiles(Currency.Convert(iva,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>                     
                                                      {
                                                        (this.state.retention == 1) && 
                                                        <p>Retención: <TooltipCurrency title={ (this.calculateIvaRentention(tax_base, this.state.iva)) }>{ Globals.formatMiles(Currency.Convert(this.calculateIvaRentention(tax_base, this.state.iva),this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>                     
                                                      }
                                                      <div className="footer-container-white"> &nbsp;
                                                        <p>Total: 
                                                          
                                                          <TooltipCurrency title={ this.calculateTotalWithRentention(tax_base, this.state.iva, total, fines) }>{ Globals.formatMiles(Currency.Convert(this.calculateTotalWithRentention(tax_base, this.state.iva, total, fines),this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>                            
                                                      </div>
                                                      
                                                      {/* {
                                                        (total) > 0 && (parseFloat(credit.toFixed(2)) > 0) &&
                                                        <div className="footer-container-white mt-n4"> &nbsp;
                                                          <p>Crédito: 
                                                            <TooltipCurrency title={ (credit / this.state.conversion_petro) }>{ Globals.formatMiles(credit, true, Currency.BOLIVAR) }</TooltipCurrency>
                                                          </p>
                                                        </div>
                                                      } */}
                                                    </> 
                                                  )
                                                }
                                              </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="text-right">
                                              { 
                                                  
                                                  parseFloat(this.state.form.discount) > 0 && (
                                                    <>
                                                    <Button
                                                      type="button"
                                                      className="btn-total"
                                                      disabled={parseFloat(parseFloat(this.state.receipts_amount).toFixed(2)) < (this.calculateTotal(tax_base, this.state.iva))}
                                                      onClick={ () => this.finish(this.calculateTotal(tax_base, this.state.iva)) }>
                                                      Enviar Pago con Descuento
                                                    </Button>
                                                    </>
                                                  )
                                              }

                                              {
                                                  this.state.form.discount == '' && (
                                                    <>
                                                      <Button
                                                        type="button"
                                                        className="btn-total"
                                                        disabled={parseFloat(parseFloat(this.state.receipts_amount).toFixed(2)) < parseFloat(total.toFixed(2))}
                                                        onClick={ () => this.finish(total) }>
                                                        { this.state.retention === Constants.RETENTION.YES ? 'Generar prefactura' : 'Enviar Pago' }
                                                      </Button>
                                                    </>
                                                  )
                                              }
                                              </div>
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      )
                                    }
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          }

                          {
                            Object.keys(payments).length == 0 && (
                              <p className="text-center">No hay facturas por pagar</p>
                            )
                          }
                         </React.Fragment>
                       )
                     }
                    </React.Fragment>
                  )
                }
              </React.Fragment>
            )
          }
        </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(Cash);