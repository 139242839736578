import { Api, Constants } from 'utils';

class UrbanCleaningSolvenciesService {

	static get = async (params?: any) => Api.createResponse('urban-cleaning-solvencies/get',params);
	static create = async (params?: any) => Api.createResponse('urban-cleaning-solvencies/create',params);
	static getProperties = async (params?: any) => Api.createResponse('urban-cleaning-solvencies/get-properties',params);
	static validateDebt = async (params?: any) => Api.createResponse('urban-cleaning-solvencies/validate-debt',params);
	static download = (file?: string) => Constants.BASE_API + 'urban-cleaning-solvencies/download/' + file;

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/urban-cleaning-solvencies/get',params),
		delete: async (params?: any) => Api.createResponse('admin/urban-cleaning-solvencies/delete',params),
		download: (file?: string) => Constants.BASE_API + 'admin/urban-cleaning-solvencies/download/' + file
	}
}

export default UrbanCleaningSolvenciesService;