import { Api, Constants } from 'utils';

class CreditNoteService {

	static get = async (params?: any) => Api.createResponse('credit-notes',params);

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/credit-notes',params),
		edit: async (params?: any) => Api.createResponse('admin/credit-notes/edit',params),
		delete: async (params?: any) => Api.createResponse('admin/credit-notes/delete',params),
	}
}

export default CreditNoteService;