import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Input, Select, Button, Tooltip, DatePicker, Modal } from 'components';
import { checkPermission, Permissions, Constants, Socket, SocketEvents, Globals, Print, Currency } from 'utils';
import { ReportService } from 'services';
import { CardIcon } from 'assets/icons';

interface LocationProps {
  page: number | undefined;
  form: any
}

interface Props extends RouteComponentProps<{},{},LocationProps> {
  dispatch: any
}

const INITIAL_STATE = {
  search: '',
  init: '',
  end: '',
  type: '',
  status: ''
}

const { ACTIVE, INACTIVE, ACTIVE_LABEL, INACTIVE_LABEL, VERIFIED, VERIFIED_LABEL } = Constants.USERS_STATUS;

const STATUS = [
  { value: ACTIVE.toString(), label: ACTIVE_LABEL },
  { value: INACTIVE.toString(), label: INACTIVE_LABEL },
  { value: VERIFIED.toString(), label: VERIFIED_LABEL },
]

class Report extends React.Component<Props> {  

  timer: any = undefined;

  state = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'Fecha Emisión',
      'Cod. Contribuyente',
      'CI / Rif',
      'Nombre / Razón Social',
      'Cod. Propiedad',
      'Actividad Económica',
      'Monto Adecuado',
      'Cuotas Mensuales'
    ],
    form: INITIAL_STATE,
    visible: false,
    client: null,
    conversion_dolar: 0,
    form_search: '',
  }

  async componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Reporte de Convenios',
        onBack: null
      }
    });

    await this.setState({
      page: this.props.location.state?.page || this.state.page,
      form: this.props.location.state?.form || this.state.form
    });

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await ReportService.admin.bank_reconciliation({
      page: this.state.page,
      withoutLoading,
      ...this.state.form
    });
    this.setState({
      data: res.bills.data,
      last_page: res.bills.last_page,
      conversion_dolar: res.conversion_dolar?.amount || 0,
    });
  }


  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

  view = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      client: item
    });
  }


  onClose = () => {
    this.setState({
      visible: false
    });
  }
  
  render() {
    const { visible } = this.state;

    return (
        <div id="report-agreements">
            <div className="row">

            <div className="col-md-2">
                <Select
                  label="Tipo"
                  name="type"
                  onChange={ (e: any) => this.change(e,() => {
                    this.load(true);
                  }) }
                  placeholder="Tipo"
                  value={ this.state.form.type }
                  options={ STATUS } />
              </div>

              <div className="col-md-2">
                <DatePicker
                  color="white"
                  label="Desde"
                  value={this.state.form.init}
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'init', value }
                    
                  },() => this.load(true))}
                />
              </div>

              <div className="col-md-2">
                <DatePicker
                  color="white"
                  label="Hasta" 
                  value={this.state.form.end}
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'end', value }
                  },() => this.load(true))}
                />
              </div>

       
              <div className="col-md-2 vcenter">
                     <Button className="btn-blue-create">
                          Filtrar
                        </Button>
              </div>

            </div>
            <div className="row area-movil">
              <div className="col-md-2">
                {
                  this.state.data.length > 0 && (
                    <div>
                      <a href="#">
                        <Button className="btn-blue-create">
                          Exportar Excel
                        </Button>
                      </a>
                    </div>
                  )
                }
              </div>
              <div className="col-md-2">
                {
                  this.state.data.length > 0 && (
                    <div>
                      <a href="#">
                        <Button className="btn-blue-create">
                          Exportar PDF
                        </Button>
                      </a>
                    </div>
                  )
                }
              </div>
              <div className="col-md-8">
                <Input
                  color="white"
                  searchRight
                  value={ this.state.form.search }
                  name="search"
                  placeholder="Buscar (Código, ID, Nombre, Concepto)"
                  onChange={ (e: any) => {
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                      this.load(true);
                    },1000);
                    this.change(e); 
                  } } />
              </div>
            </div>


              {
                this.state.form != INITIAL_STATE && (
                  <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
                )
              }  

              <Table data={ this.state.data.length } title="" header={ this.state.header }>
                { this.state.data.map((report: any,index: number) => {
                  return (
                    <tr key={ index }>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )
                }) }
              <tr className="box-mti">
                  <td colSpan={15}>
                    <h6>Montos totales del Reporte de Convenios</h6>
                  </td>
                </tr>
              </Table>

              <div className="row">
                <Pagination 
                  pages={ this.state.last_page } 
                  active={ this.state.page }
                  onChange={ async (page: number) => {
                    await this.setState({
                      page: page
                    });
                    this.load();
                } } />
              </div>
        </div>        
    )
  }
}

export default connect()(Report);