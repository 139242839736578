import React from 'react'
import moment from 'moment'
import { useCashContext } from './useCashContext'

const CashDetailProperty = () => {
  const { property } = useCashContext()

  return (
    <div className="p-3 mb-1 rounded-lg" style={{ backgroundColor: '#c7ccd1' }}>
      <div className="row">
        <p className="col-12 mb-3 text-right">Fecha: {moment().format('DD/MM/YYYY')}</p>
        <p className="col-6 mb-2">Código: {property?.urbaser_code ?? ''}</p>
        <p className="col-6 mb-2">RIF: {property?.document ?? ''}</p>
        <p className="col-12 mb-2">Nombre: {property?.name ?? ''}</p>
        <p className="col-12 mb-2">Actividad: {property?.economic_activity ?? ''}</p>
        <p className="col-12 mb-2">Agente de retención: {property?.retention ? 'Si' : 'No'}</p>
        <p className="col-12 mb-2">Tipo de propiedad: {property?.type ?? ''}</p>
        <p className="col-12 mb-2 mb-0">Uso Propiedad: {property?.use ?? ''}</p>
      </div>
    </div>
  )
}

export default CashDetailProperty
