import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Input, Select, Button, Tooltip, DatePicker, Modal, TooltipCurrency } from 'components';
import { checkPermission, Permissions, Constants, Socket, SocketEvents, Globals, Print, Currency } from 'utils';
import { AccountStatusService } from 'services';
import { EditIcon, ViewIcon, TrashIcon, CheckIcon, CloseIcon, PrintIcon, PaperclipIcon } from 'assets/icons';
import ViewProperty from './view-property';
import moment from 'moment';

interface LocationProps {
  page: number | undefined;
  form: any
}
interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

const INITIAL_SEARCH = {
  since: '',
  until: '',
  status: '',
  type: '',
  search: '',
  user_id: null
}

const { RECONCILED, TO_RECONCILE, NON_PAYMENT, RECONCILED_LABEL, TO_RECONCILE_LABEL, NON_PAYMENT_LABEL } = Constants.ACCOUNT_STATUS;

const STATUS = [
  { value: RECONCILED.toString(), label: RECONCILED_LABEL },
  { value: TO_RECONCILE.toString(), label: TO_RECONCILE_LABEL },
  { value: NON_PAYMENT.toString(), label: NON_PAYMENT_LABEL }
]

const { CONDOMINIUM } = Constants.PROPERTY_USES

class AccountStatus extends React.Component<Props> {

  timer: any = undefined;
  
  state = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      // 'Código Contribuyente',
      'Código',
      'Tipo',
      'Fecha',
      'RIF',
      'Nombre / Razón Social',
      'Monto Adecuado',
      'Estatus',
      'Acciones'
    ],
    references:{
      payment_methods : [],
      types : [],
      conversion_dolar: {
        amount: 0
      },
      conversion_petro: {
        amount: 0
      }
    },
    form:INITIAL_SEARCH,
    visible: false,
    property: null,
  }

  async componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Record de Cuenta',
        onBack: null
      }
    });
    await this.setState({
      form:{
        ...this.state.form,
        user_id: this.props.user.id
      }
    });
    this.getReferences();
    this.load();
  }

  getReferences = async () => {
    const references: any = await AccountStatusService.getReferences();
    this.setState({
      references: references
    });
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await AccountStatusService.get({
      page: this.state.page,
      withoutLoading,
      ...this.state.form
    });
    this.setState({
      data: res.properties.data,
      last_page: res.properties.last_page
    });
  }

  change = async (e: any,callback = () => {}) => {
    await this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  view = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      property: item
    });
  }

  onClose = () => {
    this.setState({
      visible: false
    });
  }

  print = (e: any, item: any) => {
    e.preventDefault();

    if (item.property_use_id !== CONDOMINIUM) {
      Print(AccountStatusService.download(item.crypt), true);
    } else {
      window.open(AccountStatusService.download(item.crypt))
    }
  }

  reset = () => {
    this.setState({
      form:{
        ...INITIAL_SEARCH,
        user_id: this.props.user.id
      },
      page: 1
    },() => this.load(true));
  }
  
  render() {
    const { visible } = this.state;

    return (
      <div id="account-status">
        <Modal
            className="high-modal modal-view-account-status"
            visible={ visible }
            onClose={ this.onClose } 
            title="Pagos Pendientes"
        >
          <ViewProperty
            property={ this.state.property }
          />
        </Modal>
        {/* <div className="row">
          <div className="col-md-4">
            <div className="row">
               <div className="col-md-6">
                <DatePicker label="Desde" value={this.state.form.since}
                  showYearDropdown={ true }
                  maxDate={ moment(this.state.form.until || moment()).toDate() }
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'since', value }
                  },() => this.load(true))}
                />
              </div>
              <div className="col-md-6">
                <DatePicker label="Hasta" value={this.state.form.until}
                  showYearDropdown={ true }
                  maxDate={ moment().toDate() }
                  minDate={ this.state.form.since && moment(this.state.form.since).toDate() }
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'until', value }
                  },() => this.load(true))}
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <Select
                  label="Tipo"
                  color="white"
                  name="type"
                  onChange={(e: any) => this.change(e,() => this.load(true))}
                  placeholder="Tipo"
                  value={ this.state.form.type }
                  options={ this.state.references.types } 
                />
              </div>
              <div className="col-md-6">
                <Select
                  label="Estatus"
                  color="white"
                  name="status"
                  onChange={(e: any) => this.change(e,() => this.load(true))}
                  placeholder="Estatus"
                  value={ this.state.form.status }
                  options={ STATUS } />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-6">
                <a href={ Constants.BASE_API + 'account-status/excel?' + Globals.getParams(this.state.form) }>
                  <Button className="btn-blue-create btn-filter">
                    Exportar Excel
                  </Button>
                </a>
              </div>
              <div className="col-md-6">
                <a href={ Constants.BASE_API + 'account-status/pdf?' + Globals.getParams(this.state.form) }>
                  <Button className="btn-blue-create btn-filter">
                    Exportar PDF
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <Input
              color="white"
              searchRight
              value={ this.state.form.search }
              name="search"
              placeholder="Buscar (Código, ID, Nombre, Monto)"
              onChange={ (e: any) => {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                  this.load(true);
                },1000);
                this.change(e); 
              } } 
            />
          </div>
        </div>

        {
          this.state.form != INITIAL_SEARCH && (
            <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
          )
        }   */}

        <Table data={ this.state.data.length } title="" header={ this.state.header }>
          { this.state.data.map((i: any,index: number) => {
            return (
              <tr key={ index }>
                {/* <td>{ i.user?.number_format }</td> */}
                <td>{ i.urbaser_code }</td>
                <td className="text-nowrap">{ i.type?.name ?? '' }</td>
                <td className="text-nowrap">{ i.last_payment ? moment(i.last_payment).format('DD/MM/YYYY') : '-' }</td>
                <td className="text-nowrap">{ i.user?.full_document }</td>
                <td className="text-nowrap">{ i.user?.full_name }</td>
                <td className="text-nowrap text-right">
                  <TooltipCurrency title={ i.total_record }>{ Globals.formatMiles(Currency.Convert(i.total_record, this.state.references.conversion_petro.amount),true,Currency.BOLIVAR) }</TooltipCurrency>
                </td>
                <td className="text-nowrap">{ i.status }</td>
                <td className="icon-actions">
                    <Tooltip title="Ver">
                      <a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
                        <img src={ ViewIcon } />
                      </a>
                    </Tooltip>
                    <Tooltip title="Imprimir">
                      <a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
                        <img src={ PrintIcon } />
                      </a>
                    </Tooltip>
                </td>
              </tr>
            )
          }) }
        </Table>


        <Pagination 
          pages={ this.state.last_page } 
          active={ this.state.page }
          onChange={ async (page: number) => {
            await this.setState({
              page: page
            });
            this.load();
        } } />

      </div>        
    )
  }
}

export default connect((state: RootState) => {
  return{
    user: state.user
  }
})(AccountStatus);