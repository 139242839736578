import Calendar from './calendar.svg'
import Search from './search.svg'
import UserIcon from './user.svg'
import ArrowLeft from './arrow-left.svg'
import PaperclipIcon from './paperclip.png'
import CloseIcon from './close.png'
import CheckIcon from './check.png'
import TrashIcon from './trash.png'
import ViewIcon from './view.png'
import EditIcon from './edit.png'
import PrintIcon from './print.png'
import HouseIcon from './house.png'
import AdjuntarIcon from './adjuntar.png'
import CardIcon from './card.png'
import AdjuntoIcon from './adjunto.png'
import CheckWhite from './check-white.png'
import ViewFileIcon from './view-file.png'
import MoneyIcon from './money.png'
import RefreshIcon from './refresh.png'
import SendIcon from './send.png'
import ParentFolder from './parent_folder.svg'
import CommentIcon from './comment.png'
import ExpandMore from './expand_more.svg'
import NotifyIcon from './notify.webp'
//Menu

import MenuHome from './menu/ico-home.png'
import MenuTarifas from './menu/ico-tarifa.png'
import MenuRecord from './menu/ico-estado.png'
import MenuCaja from './menu/ico-caja.png'
import MenuComprobante from './menu/ico-comprobante.png'
import MenuSolvencia from './menu/ico-solvencia.png'
import MenuConvenios from './menu/ico-convenios.png'
import MenuReportes from './menu/ico-reportes.png'
import MenuCuentas from './menu/ico-cuentas-bancarias.png'
import MenuRutas from './menu/ico-rutas.png'
import MenuDenuncias from './menu/ico-denuncias.png'
import MenuSalir from './menu/ico-salir.png'
import MenuUser from './menu/ico-user.png'
import MenuPagos from './menu/ico-pagos.png'

const Menu = {
  MenuHome,
  MenuTarifas,
  MenuRecord,
  MenuCaja,
  MenuComprobante,
  MenuSolvencia,
  MenuConvenios,
  MenuReportes,
  MenuCuentas,
  MenuRutas,
  MenuDenuncias,
  MenuSalir,
  MenuUser,
  MenuPagos,
}

export {
  Calendar,
  Search,
  Menu,
  ArrowLeft,
  UserIcon,
  PaperclipIcon,
  EditIcon,
  CheckIcon,
  PrintIcon,
  ViewIcon,
  CloseIcon,
  TrashIcon,
  HouseIcon,
  AdjuntarIcon,
  CardIcon,
  AdjuntoIcon,
  CheckWhite,
  ViewFileIcon,
  MoneyIcon,
  RefreshIcon,
  SendIcon,
  ParentFolder,
  CommentIcon,
  ExpandMore,
  NotifyIcon,
}
