import React from 'react';
import { AgreementService } from 'services';
import { SelectSearch, DatePicker, Select, Button, Input } from 'components';
import { Globals, Currency, RegisterMovement, Constants } from 'utils';
import moment from 'moment';

interface Props {
	onClose: () => void,
	user: any
}

const TYPES = {
	MENSUAL: 1,
	QUINCENAL: 2
}

const INITIAL_STATE = {
	client_id: '',
	expiration_date: '',
	quotas: '',
	type: TYPES.MENSUAL,
	property_id: '',
	quota: ''
}

class CreateAgreement extends React.Component<Props> {

	state = {
		clients: [],
		form: INITIAL_STATE,
		client: null,
		property: null,
		properties: [],
		conversion_petro: 0,
		searchValue: '',
	}

	load = async (value: string) => {
		const res: any = await AgreementService.admin.getClients({ search: value });
		this.setState({
			clients: res.clients,
			conversion_petro: res.conversion_petro?.amount || 0,
		});
	}

	loadProperties = async () => {
		const client: any = this.state.client;
		const res: any = await AgreementService.admin.getProperties({
			client_id: client?.id
		});
		this.setState({
			properties: res.properties
		});
	}

	change = (e: any,callback = () => {}) => {
	    this.setState({
	      form: {
	        ...this.state.form,
	        [e.target.name]: e.target.value
	      }
	    },callback);
	}

	finish = async () => {
		if (parseInt(this.state.form.quotas) <= 0) {
			Globals.showError("El campo cantidad de cuotas debe ser mayor a 0");
			return false;
		}

		const res: any = await AgreementService.admin.finish({
			...this.state.form
		});
		Globals.showSuccess("Se ha creado correctamente el convenio");
		this.props.onClose();

		RegisterMovement.save({
	        value_id: res.agreement?.id,
	        user_id: this.props.user.id,
	        module_id: Constants.MODULES.PAYMENT_AGREEMENTS,
	        description: `Emisión de convenio de pago ${ res.agreement?.number } del contribuyente ${ res.agreement?.user?.full_name } ${ res.agreement?.user?.full_document }`
	    });
	}

	calculateDate = (property: any) => {
		// @ts-ignore
      	const quota = property.debt_total / this.state.form.quotas;
      	const date = this.state.form.type == TYPES.QUINCENAL ? 
      				moment().add((parseInt(this.state.form.quotas) + 1) * 15,'days').add(1,'day').toDate()
      				: moment().add(parseInt(this.state.form.quotas) + 1,'months').add(1,'day').toDate();
      	this.change({
      		target: {
      			name: 'quota',
      			value: quota
      		}
      	},() => {
      		this.change({
          		target: {
          			name: 'expiration_date',
          			value: date
          		}
          	});
      	});
	}
	
	render() {
		const client: any = this.state.client;
		const property: any = this.state.property;

		return (
			<div id="admin-modal-create-agreements">
				<h3>Contribuyente</h3>
				<SelectSearch
					label="Código Contribuyente - Razón Social"
					onEnter={(value: string) => {
						this.setState({
							searchValue: value
						}, () => this.load(value))
					}}
					onChange={ (client: any) => {
						this.setState({
							form: {
								...this.state.form,
								client_id: client.value,
								property_id: ''
							},
							property: null,
							client: {
								id: client.value,
								full_name: client.label
							},
							searchValue: client
						},() => this.loadProperties());
					} }
					placeholder="Contribuyente"
					value={this.state.searchValue}
					options={ this.state.clients.map((i: any) => {
						return {
							value: i.id,
							label: i.full_name + ' - ' + i.full_document + ' - ' + i.username
						}
					}) }
				/>
				<hr />
	            {
	            	client && (
	            		<React.Fragment>
	            			<h3>Cod. Propiedad</h3>
				            <div className="row">
				            	<div className="col-md-6">
				            		<SelectSearch
						                label="Código Propiedad - Actividad Comercial"
						                onChange={ (property: any) => {
						                  this.setState({
						                    form: {
						                      ...this.state.form,
						                      property_id: property.value,
						                    },
						                    property: {
						                      id: property.value,
						                      urbaser_code: property.label,
						                      debt_total: property.debt_total
						                    }
						                  });
						                } }
						                placeholder="Propiedad"
						                value={ {
						                  value: property?.id || '',
						                  label: property?.urbaser_code || ''
						                } }
						                options={ this.state.properties.map((i: any) => {
						                  return {
						                  	debt_total: i.debt_total,
						                    value: i.id,
						                    label: i.urbaser_code + ' - ' + i.economic_activity?.name
						                  }
						                }) } />
				            	</div>
				            	<div className="col-md-6">
				            		{
				            			property && (
											<Input
							                  color="gray"
							                  value={ Globals.formatMiles(Currency.Convert(property.debt_total,this.state.conversion_petro),true,Currency.BOLIVAR) + ` (${ Globals.formatMiles(property.debt_total,true,Currency.PETRO) })` }
							                  name="debt_total"
							                  disabled={ true }
							                  label="Monto Total de la Deuda" />
				            			)				            		
				            		}
				            	</div>
				            </div>
				            <hr />
				            {
				            	property && (
				            		<React.Fragment>
				            			<h3>Datos del Convenio</h3>
										<div className="row">
											<div className="col-md-6">
												<div className="col-12 row-top-radio">
							                        <div className="container-radio bottom">
							                       	  <p>Seleccione el tipo de convenio</p>
							                          <div className="item-radio" onClick={ () => this.change({
							                          	target: {
							                          		name: 'type',
							                          		value: TYPES.MENSUAL
							                          	}
							                          },() => this.calculateDate(property)) }>
							                            <div className={ `radio ${ this.state.form.type == TYPES.MENSUAL ? 'active' : '' }` }>
							                              <div className="circle"></div>
							                            </div>
							                            <p>Mensual</p>
							                          </div> 
							                         <div className="item-radio" onClick={ () => this.change({
							                          	target: {
							                          		name: 'type',
							                          		value: TYPES.QUINCENAL
							                          	}
							                          },() => this.calculateDate(property)) }>
							                            <div className={ `radio ${ this.state.form.type == TYPES.QUINCENAL ? 'active' : '' }` }>
							                              <div className="circle"></div>
							                            </div>
							                            <p>Quincenal</p>
							                          </div> 
							                        </div>
							                    </div>
											</div>
											<div className="col-md-6">
												<Input
								                  color="gray"
								                  type="number"
								                  value={ this.state.form.quotas }
								                  name="quotas"
								                  label="Cantidad de Cuotas"
								                  onChange={ (e: any) => this.change(e,() => {
								                  	this.calculateDate(property);
								                  }) } />
											</div>
							            	<div className="col-md-6">
							            		<Input
								                  color="gray"
								                  value={ 
								                    // @ts-ignore
								                  	Globals.formatMiles(Currency.Convert(this.state.form.quota,this.state.conversion_petro),true,Currency.BOLIVAR) + ` (${ Globals.formatMiles(this.state.form.quota,true,Currency.PETRO) })`
								                  }
								                  name="quota"
								                  disabled={ true }
								                  label="Monto por Cuota" />
							            	</div>
							            	<div className="col-md-6">
							            		<DatePicker
							            			minDate={ this.state.form.type == TYPES.QUINCENAL ? moment().add((parseInt(this.state.form.quotas) + 1) * 15,'days').add(1,'day').toDate() : moment().add(parseInt(this.state.form.quotas) + 1,'months').add(1,'day').toDate() }
							            			label="Fecha de Expiración"
							            			value={ this.state.form.expiration_date }
									                onChange={ (value: string) => {
									                	this.change({ 
										                    target: { name: 'expiration_date', value }
										                });
									                } }
								                />
							            	</div>
							            </div>
							            <div className="row">
							            	<div className="col-md-6 text-right">
							            		<Button className="btn-urbaser-red " onClick={ () => this.props.onClose() }>
								                    Cancelar
								                </Button>
							            	</div>
							            	<div className="col-md-6">
							            		<Button onClick={ this.finish }>
								                    Generar
								                </Button>
							            	</div>
							            </div>
				            		</React.Fragment>
				            	)
				            }
	            		</React.Fragment>
	            	)
	            }
			</div>
		)
	}	
}

export default CreateAgreement;