import React, { useEffect, useState } from 'react'
import { Button, InputCurrency, Modal } from 'components'
import { CashModalConfirmPetroValueProps } from '../Cash'
import { useCashContext } from '../useCashContext'

const CashModalConfirmPetroValue = ({ visible, setVisible }: CashModalConfirmPetroValueProps) => {
  const { currentPetroValue, setCurrentPetroValue } = useCashContext()

  const onClose = () => {
    setVisible(false)
  }

  return (
    <Modal className="modal-view-account-status" visible={visible} onClose={onClose} title="Confirmar el valor del UCD">
      <div>
        <div className="d-flex flex-column align-items-center">
          <div className="w-100">
            <InputCurrency
              color="white"
              value={currentPetroValue}
              name="currentPetroValue"
              precision="4"
              placeholder="Escribe el valor del UCD"
              onChange={(e: any) => {
                setCurrentPetroValue(e.target.value)
              }}
            />
          </div>
          <Button className="btn-urbaser-green w-50" onClick={onClose}>
            Confirmar
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CashModalConfirmPetroValue
