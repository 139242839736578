import React from 'react';
import { Tooltip } from 'components';
import { Globals, Currency } from 'utils';

const TooltipPetro = (props: any) => {
	const currency = props.currency || Currency.PETRO;
	const decimals = currency === Currency.PETRO ? 4 : props.decimals || 2;

	return (
		<Tooltip title={ Globals.formatMiles(props.title,true,currency, props.decimals || decimals) }>
			<span>{ props.children }</span>
		</Tooltip>
	)
}

export default TooltipPetro;