export interface DiscountForm {
  amount: number,
  start_date: string,
  expire_date: string,
  items: number[],
}

interface DiscountsProps {
  onClose: (reload: boolean) => void,
  user: any,
}

export interface PropsCreate extends DiscountsProps {
	value: DiscountForm,
}

export interface PropsView extends DiscountsProps {
	value: string | number,
}

export interface PropsEdit extends PropsView {}

export const INITIAL_STATE: DiscountForm = {
  amount: 5,
  start_date: '',
  expire_date: '',
  items: [],
}
