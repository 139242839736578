export default {
  BASE_STORAGE: process.env.REACT_APP_BASE_STORAGE,
  BASE_API: process.env.REACT_APP_BASE_API,
  BASE_PUBLIC: process.env.REACT_APP_BASE_PUBLIC,
  BASE_INSTITUTION: process.env.REACT_APP_BASE_INSTITUTION ?? 1,
  LEVELS: {
    ADMIN: 1,
    MODERATOR: 2,
    CLIENT: 3,
    ADMIN_LABEL: 'Administrador',
    MODERATOR_LABEL: 'Moderador',
    CLIENT_LABEL: 'Cliente',
  },
  STATUS_SALES_BOOK: {
    ACTIVE: 1,
    INACTIVE: 2,
    ACTIVE_LABEL: 'Activa',
    INACTIVE_LABEL: 'Anulada',
  },
  USERS_STATUS: {
    ACTIVE: 1,
    INACTIVE: 0,
    VERIFIED: 3,
    ACTIVE_LABEL: 'Activos',
    INACTIVE_LABEL: 'Inactivos',
    VERIFIED_LABEL: 'Por verificar',
  },
  BANK_ACCOUNT_STATUS: {
    ACTIVE: 1,
    INACTIVE: 0,
    ACTIVE_LABEL: 'Activas',
    INACTIVE_LABEL: 'Inactivas',
  },
  USERS_VERIFIED: {
    VERIFIED: 1,
    NOT_VERIFIED: 0,
  },
  ACTIONS: {
    MAIN: 1,
    SECONDARY: 0,
  },
  PROPERTIES: {
    CONDOMINIUM: {
      YES: 1,
      NO: 0,
    },
    OWNER: {
      YES: 1,
      NO: 0,
    },
  },
  TAX_FREE: {
    YES: 1,
    NO: 0,
  },
  SUGGESTIONS: {
    VIEWED: 1,
    NOT_VIEWED: 0,
    PENDING: 0,
    PROCESS: 1,
    ATTENDED: 2,
    RESOLVED: 3,
  },
  RETENTION: {
    YES: 1,
    NO: 0,
  },
  DOCUMENT_TYPES: {
    V: 1,
    E: 2,
    J: 3,
    G: 4,
  },
  ACCOUNT_STATUS: {
    RECONCILED: 1,
    TO_RECONCILE: 2,
    NON_PAYMENT: 3,
    RECONCILED_LABEL: 'Conciliado',
    TO_RECONCILE_LABEL: 'Por Conciliar',
    NON_PAYMENT_LABEL: 'Impago',
  },
  PAYMENT_METHODS: {
    CASH: 1,
    TRANSFER: 2,
    CREDIT: 3,
    DEBIT: 4,
    DEPOSIT: 5,
  },
  PROPERTY_TYPES: {
    COMMERCIAL: 1,
    RESIDENTIAL: 2,
    INDUSTRIAL: 3,
    GOVERNMENT: 4,
  },
  PROPERTY_USES: {
    INDIVIDUAL: 1,
    CONDOMINIUM: 2,
    SHOPPING_CENTER: 3,
    OTHERS: 4,
  },
  OPTIONAL_FIELDS: ['house_number', 'catastral_id', 'license', 'floor', 'date', 'size'],
  OPTIONAL_FIELDS_ALL: ['nic', 'catastral_id', 'license', 'vba_number', 'zone', 'street', 'house_number', 'floor', 'reference', 'date'],
  SERVICE_TYPES: {
    CLEAN: 1,
    RDS: 2,
  },
  URBAN_CLEANLINESS_SOLVENCY: {
    URL: 'urban_cleanliness_solvency/',
  },
  BILL_STATUS: {
    PENDING: 0,
    ACCEPTED: 1,
    REJECTED: 2,
    CANCELLED: 3,
    TO_PAY: 4,
    PENDING_LABEL: 'Por Verificar',
    ACCEPTED_LABEL: 'Aceptado',
    REJECTED_LABEL: 'Reversado',
    CANCELLED_LABEL: 'Anulado',
  },
  MODULES: {
    CLIENTS: 1,
    MODERATORS: 2,
    TARIFF: 3,
    VOUCHER_RECORD: 4,
    CASH: 5,
    VOUCHER_HISTORY: 6,
    PAYMENTS: 7,
    URBAN_CLEANLINESS: 8,
    PAYMENT_AGREEMENTS: 9,
    REPORTS: 10,
    BANK_ACCOUNTS: 11,
    ROUTES_SCHEDULES: 12,
    COMPLAINTS_SUGGESTIONS: 13,
    ACCOUNT_STATUS: 14,
  },
  AGREEMENT_STATUS: {
    ACTIVE: 1,
    INACTIVE: 0,
  },
  ROUTES_STATUS: {
    ACTIVE: 1,
    INACTIVE: 0,
  },
  TYPE_FORM: {
    COMERCIAL: 'COMERCIAL',
    ESTATE: 'ESTATE',
  },
  INSTITUTION: {
    GUAYOS: 1,
    NAGUANAGUA: 2,
  },
}
