import React from 'react';
import { Globals, Currency, Constants } from 'utils';
import { TooltipCurrency } from 'components';

interface Props {
	onClose: () => void,
	agreement: any,
	conversion_petro: any
}

class ViewAgreement extends React.Component<Props> {
	
	render() {
		const { agreement } = this.props;

		return (
			<div id="admin-modal-view-agreements">
				<table className="table table-bordered">
					<tr>
						<th>Nro de Cuotas</th>
						<td>{ agreement?.quantity_quotas }</td>
					</tr>
					<tr>
						<th>Nro Control de Recibo Emitido</th>
						<td>{ agreement?.number_format }</td>
					</tr>
					<tr>
						<th>Monto Pagado</th>
						<td><TooltipCurrency title={ agreement?.total - agreement?.to_pay }>{ Globals.formatMiles(Currency.Convert(agreement?.total - agreement?.to_pay,agreement.status == Constants.AGREEMENT_STATUS.ACTIVE ? this.props.conversion_petro : agreement?.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></td>
					</tr>
					<tr>
						<th>Total Bs</th>
						<td><TooltipCurrency title={ agreement?.total }>{ Globals.formatMiles(Currency.Convert(agreement?.total,agreement.status == Constants.AGREEMENT_STATUS.ACTIVE ? this.props.conversion_petro : agreement?.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></td>
					</tr>
				</table>
			</div>
		)
	}	
}

export default ViewAgreement;