import { store } from 'store'
import Constants from './constants'

export default {
  CLIENTS: {
    VIEW: 'view-clients',
    CREATE: 'create-clients',
    DELETE: 'delete-clients',
  },
  MODERATORS: {
    VIEW: 'view-users',
    CREATE: 'create-users',
    DELETE: 'delete-users',
  },
  TARIFF: {
    VIEW: 'view-tariff',
    EDIT: 'edit-tariff',
    EDIT_IVA: 'edit-iva',
    EDIT_RETENTION: 'edit-retention',
    EDIT_DOLAR: 'edit-dolar',
    EDIT_PETRO: 'edit-petro',
    EDIT_INTEREST: 'edit-interest',
  },
  DISCOUNT: {
    VIEW: 'view-discount',
    CREATE: 'create-discount',
    EDIT: 'edit-discount',
    DELETE: 'delete-discount',
  },
  VOUCHER_RECORD: {
    VIEW: 'view-voucher-record',
  },
  CASH: {
    VIEW: 'view-cash',
    EDIT_UCD: 'edit-ucd',
  },
  VOUCHER_HISTORY: {
    VIEW: 'view-voucher-history',
    DELETE_CREDIT_NOTE: 'delete-credit-note',
  },
  PAYMENTS: {
    VIEW: 'view-payments',
    EDIT: 'edit-payments-data',
    EDIT_STATUS: 'edit-payment-status',
  },
  URBAN_CLEANLINESS: {
    VIEW: 'view-urban-cleanliness',
  },
  URBAN_CLEANING_SOLVENCIES: {
    VIEW: 'view-urban-cleaning-solvencies',
  },
  PAYMENT_AGREEMENTS: {
    VIEW: 'view-payment-agreements',
  },
  REPORTS: {
    VIEW: 'view-reports',
  },
  BANK_ACCOUNTS: {
    VIEW: 'view-bank-accounts',
    CREATE: 'create-bank-accounts',
    DELETE: 'delete-bank-accounts',
  },
  ROUTES_SCHEDULES: {
    VIEW: 'view-routes-schedules',
    UPLOAD: 'upload-routes',
  },
  COMPLAINTS_SUGGESTIONS: {
    VIEW: 'view-complaints-suggestions',
  },
  ACCOUNT_STATUS: {
    VIEW: 'view-account-status',
    VIEW_RECORD: 'view-account-record',
    VIEW_SEND_EMAIL: 'mail-account-record',
    VIEW_CALCULATE: 'calculate-account-record',
  },
  AUDITS: {
    VIEW: 'view-audits',
  },
}

export const checkPermission = (permission: string) => {
  const user = store.getState().user
  return user.level_id != Constants.LEVELS.MODERATOR || user.permissions.map((i: any) => i.code).indexOf(permission) != -1
}
