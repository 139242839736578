import React from 'react';
import { Button, Input, Select } from 'components';
import { BankAccountService } from 'services';
import { Globals, RegisterMovement, Constants } from 'utils';

interface Props {
	document_types: any,
	banks: any,
	account_types: any,
	bank_account: any,
	onClose: (reload: boolean) => void,
	user: any
}

class EditBankAccount extends React.Component<Props> {

	state = {
		form: {
			name: '',
			number: '',
			account_type_id: '',
			bank_id: '',
			document_type_id: '',
			document: '',
			phone: ''
		}
	}

	componentDidMount() {
		const { bank_account } = this.props;

		this.setState({
			form: {
				name: bank_account.name,
				number: bank_account.number,
				account_type_id: bank_account.account_type_id,
				bank_id: bank_account.bank_id,
				document_type_id: bank_account.document_type_id,
				document: bank_account.document,
				phone: bank_account.phone
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();

		const { bank_account } = this.props;

		await BankAccountService.admin.edit({
			bank_account_id: bank_account.id,
			...this.state.form
		});
		Globals.showSuccess("Se han guardado los cambios correctamente");
		this.props.onClose(true);

		const bank: any = this.props.banks.find((i: any) => i.id == this.state.form.bank_id);
		RegisterMovement.save({
	        value_id: bank_account?.id,
	        user_id: this.props.user.id,
	        module_id: Constants.MODULES.BANK_ACCOUNTS,
	        description: `Edición de cuenta bancaria del banco ${ bank?.name }`
	    });
	}

	change = (e: any,callback = () => {}) => {
	    this.setState({
	      form: {
	        ...this.state.form,
	        [e.target.name]: e.target.value
	      }
	    },callback);
	  }
	
	render() {
		return (
			<div id="edit-bank-account">
				<form onSubmit={ this.submit }>
					<div className="row">
						<div className="col-md-6">
							<Input
			                  color="gray"
			                  value={ this.state.form.name }
			                  name="name"
			                  placeholder="Propietario"
			                  onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Select
		                        color="gray"
		                        name="bank_id"
		                        onChange={ this.change }
		                        placeholder="Banco"
		                        value={ this.state.form.bank_id }
		                        options={ this.props.banks.map((i: any) => {
		                        	return {
		                        		value: i.id,
		                        		label: i.name
		                        	}
		                        }) } />
						</div>
						<div className="col-md-6">
		                    <div className="i-flex">
		                      <div className="doc-flex">
		                        <Select
		                          color="gray"
		                          className="select-phone"
		                          name="document_type_id"
		                          onChange={ this.change }
		                          value={ this.state.form.document_type_id }
		                          placeholder="Tipo"
		                          options={ this.props.document_types.map((i: any) => {
		                            return {
		                              value: i.id,
		                              label: i.name
		                            }
		                          }) } />
		                      </div>
		                      <div className="doc-flex">
		                        <Input
		                          color="white"
		                          value={ this.state.form.document }
		                          name="document" 
		                          type="number"
		                          placeholder="Identificación"
		                          onChange={ this.change } />
		                      </div>
		                    </div>
						</div>
						<div className="col-md-6">
							<Input
							  type="number"
			                  color="gray"
			                  value={ this.state.form.number }
			                  name="number"
			                  placeholder="Nro. de Cuenta"
			                  onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Input
			                  color="gray"
			                  type="number"
			                  value={ this.state.form.phone }
			                  name="phone"
			                  placeholder="Teléfono"
			                  onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Select
		                        color="gray"
		                        name="account_type_id"
		                        onChange={ this.change }
		                        placeholder="Tipo de Cuenta"
		                        value={ this.state.form.account_type_id }
		                        options={ this.props.account_types.map((i: any) => {
		                        	return {
		                        		value: i.id,
		                        		label: i.name
		                        	}
		                        }) } />
						</div>
					</div>

					<div className="text-center">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>				
			</div>
		)
	}
}

export default EditBankAccount;