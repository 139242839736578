import React from 'react';

interface Props {
	label?: string,
	name: string,
	color?: string,
	options: Item[],
	placeholder?: string,
	onChange: (e: any) => void,
	value: any,
	className?: any
	required?: boolean
	disabled?: boolean
	readonly?: boolean
}

interface Item {
	value: string,
	label: string,
	options?: []
}

const Select = (props: Props) => ( 
	<div className="form-group">
		{ props.label && <label htmlFor={ props.name }>{ props.label } {props.required && <span className="text-danger">(*)</span>}</label> }
		<select { ...props } className={ `form-control ${ props.color ? props.color : '' } ${ props.className ? props.className : '' }` } required={ props.required }>
			<option value="" disabled={ !props.placeholder }>{ props.placeholder || 'Seleccione' }</option>
			{ props.options.map((i: Item,index: number) => {
				if (i) {
					if (i.options?.length) {
						return (
							<optgroup key={ index } label={ i.label }>
								{i.options?.map((p_secondary: any, p_index: number) => 
									<option 
										key={ p_index } 
										value={ p_secondary.id }>
										{ p_secondary.urbaser_code + ' - ' + p_secondary.type?.name }
									</option>
								)}
							</optgroup>
						)
					} else {
						return (
							<option 
								key={ index } 
								value={ i.value }>
								{ i.label }
							</option>
						)	
					}	
				}	
			}) }
		</select>
	</div>
)

export default Select;