import { Api } from 'utils'

class PropertyService {
  static get = async (params?: any) => Api.createResponse('properties', params)
  static create = async (params?: any) => Api.createResponse('properties/create', params)
  static edit = async (params?: any) => Api.createResponse('properties/edit', params)
  static delete = async (params?: any) => Api.createResponse('properties/delete', params)
  static getActivities = async (params?: any) => Api.createResponse('properties/activities', params)
  static view = async (params?: any) => Api.createResponse('properties/view', params)
  static checkSend = async (property: string | number, params?: any) => Api.createResponse(`properties/${property}/can-be-sent`, params)
  static refresh = async (property: string | number, params?: any): Promise<any> =>
    Api.createResponse(`properties/${property}/refresh`, params)
  static manualSend = async (property: string | number, params?: any): Promise<any> =>
    Api.createResponse(`properties/${property}/manual-sent`, params)
  static notifySend = async (property: string | number, params?: any): Promise<any> =>
    Api.createResponse(`properties/${property}/notification-sent`, params)
  static sendMails = async (params?: any): Promise<any> => Api.createResponse(`properties/send-mails`, params)
  static addParent = async (id: any, params?: any) => Api.createResponse(`properties/update/${id}/parent`, params)
}

export default PropertyService
