import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Input, Select, Button, Tooltip, Modal, DatePicker, TooltipCurrency } from 'components';
import { checkPermission, Permissions, Constants, Socket, SocketEvents, Globals, Print, Currency } from 'utils';
import { AgreementService } from 'services';
import { EditIcon, ViewIcon, TrashIcon, CheckIcon, CloseIcon, PrintIcon, AdjuntoIcon } from 'assets/icons';
import moment from 'moment';
import ViewAgreement from './view-agreement';

interface LocationProps {
  page: number | undefined;
  form: any
}

interface Props extends RouteComponentProps<{},{},LocationProps>, RootState {
  dispatch: any
}

const INITIAL_STATE = {
  search: '',
  until: '',
  since: ''
}

class PaymentAgreements extends React.Component<Props> {  

  timer: any = undefined;

  state = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'Cod. Contribuyente',
      'Nro. de Correlativo',
      'Fecha',
      'Nro. Acta',
      'Nro. Cuotas',
      'Tipo',
      'Datos Inmueble',
      'Monto',
      'Monto Cuotas',
      'Nro Cuotas Conciliadas',
      'Fecha Vencimiento',
      'Monto por pagar',
      'Acciones'
    ],
    form: INITIAL_STATE,
    visible: false,
    agreement: null,
    conversion_petro: 0,
    visible_create: false
  }

  async componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Convenios de Pago',
        onBack: null
      }
    });

    await this.setState({
      page: this.props.location.state?.page || this.state.page,
      form: this.props.location.state?.form || this.state.form
    });

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await AgreementService.get({
      page: this.state.page,
      withoutLoading,
      user_id: this.props.user.id,
      ...this.state.form,
      since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
      until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
    });
    this.setState({
      data: res.agreements.data,
      last_page: res.agreements.last_page,
      conversion_petro: res.conversion_petro?.amount || 0
    });
  }

  create = () => {
    this.setState({
      visible_create: true
    });
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

  view = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      agreement: item
    });
  }

  download = (e: any, item: any) => {
    e.preventDefault();
  }

  print = async (e: any, item: any) => {
    e.preventDefault();
    Print(Constants.BASE_STORAGE + item.file);
  }

  onClose = () => {
    this.setState({
      visible: false
    });
  }

  onCloseCreate = () => {
    this.setState({
      visible_create: false
    },() => this.load(true));
  }
  
  render() {
    const { visible, visible_create } = this.state;

    return (
        <div id="admin-clients">
            <Modal
                className="modal-view-agreement"
                visible={ visible }
                onClose={ this.onClose } 
                title="Ver Convenio"
            >
              <ViewAgreement 
                conversion_petro={ this.state.conversion_petro }
                agreement={ this.state.agreement }
                onClose={ this.onClose } />
            </Modal>

            <p style={ { marginBottom: '20px' } }>En caso de deuda acumulada, la Autoridad Municipal competente o la empresa recaudadora debidamente autorizada, podrá celebrar con el usuario convenios de pago.  Este documento considera términos y condiciones bajo las cuales el deudor pueda honrar su compromiso ante el Municipio.</p>

            {/* <div className="row filter-center">
              <div className="col-md-4">
                <DatePicker label="Desde" value={this.state.form.since}
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'since', value }
                  },() => this.load(true))}
                />
              </div>
              <div className="col-md-4">
                <DatePicker label="Hasta" value={this.state.form.until}
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'until', value }
                  },() => this.load(true))}
                />
              </div>
            <div className="col-md-4">
                  <Input
                    color="white"
                    searchRight
                    value={ this.state.form.search }
                    name="search"
                    placeholder="Buscar por código o correlativo"
                    onChange={ (e: any) => {
                      clearTimeout(this.timer);
                      this.timer = setTimeout(() => {
                        this.load(true);
                      },1000);
                      this.change(e); 
                    } } 
                  />
                </div>
            </div>

              {
                this.state.data.length > 0 && (
                  <div className="row" style={ { maxWidth: '400px' } }>
                    <div className="col-md-6">
                      <a href={ Constants.BASE_API + 'agreements/excel?' + Globals.getParams({
                        ...this.state.form,
                        user_id: this.props.user.id
                      }) }>
                        <Button className="btn-blue-create">
                          Exportar Excel
                        </Button>
                      </a>
                    </div>
                    <div className="col-md-6">
                      <a href={ Constants.BASE_API + 'agreements/pdf?' + Globals.getParams({
                        ...this.state.form,
                        user_id: this.props.user.id
                      }) }>
                        <Button className="btn-blue-create">
                          Exportar PDF
                        </Button>
                      </a>
                    </div>
                  </div>
                )
              }


              {
                this.state.form != INITIAL_STATE && (
                  <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
                )
              }   */}

              <Table data={ this.state.data.length } title="" header={ this.state.header }>
                { this.state.data.map((i: any,index: number) => {
                  return (
                    <tr key={ index }>
                        <td>{ i.property?.urbaser_code || '-' }</td>
                        <td>{ i.number }</td>
                        <td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
                        <td>{ i.number_format }</td>
                        <td>{ i.quantity_quotas }</td>
                        <td>{ i.property?.type?.name || '-' }</td>
                        <td>{ i.property?.economic_activity?.name || '-' }</td>
                        <td className="no-wrap"><TooltipCurrency title={ i.total }>{ Globals.formatMiles(Currency.Convert(i.total, i.status == Constants.AGREEMENT_STATUS.ACTIVE ? this.state.conversion_petro : i.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></td>
                        <td className="no-wrap"><TooltipCurrency title={ i.quotas }>{ Globals.formatMiles(Currency.Convert(i.quotas, i.status == Constants.AGREEMENT_STATUS.ACTIVE ? this.state.conversion_petro : i.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></td>
                        <td>{ i.bills_count }</td>
                        <td>{ moment(i.expiration_date).format('DD/MM/YYYY') }</td>
                        <td className="no-wrap"><TooltipCurrency title={ i.to_pay }>{ Globals.formatMiles(Currency.Convert(i.to_pay, i.status == Constants.AGREEMENT_STATUS.ACTIVE ? this.state.conversion_petro : i.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></td>
                        <td className="icon-actions">
                            <Tooltip title="Ver">
                              <a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
                                <img src={ ViewIcon } />
                              </a>
                            </Tooltip>
                            {/*
                              <Tooltip title="Descargar">
                                <a className="link-icon" target="_blank" onClick={ (e: any) => this.print(e,i) }>
                                  <img src={ AdjuntoIcon } />
                                </a>
                              </Tooltip>
                            */}
                            {
                              i.file && (
                                <Tooltip title="Imprimir">
                                  <a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
                                    <img src={ PrintIcon } />
                                  </a>
                                </Tooltip>
                              )
                            }
                        </td>
                    </tr>
                  )
                }) }
              </Table>

              <Pagination 
                pages={ this.state.last_page } 
                active={ this.state.page }
                onChange={ async (page: number) => {
                  await this.setState({
                    page: page
                  });
                  this.load();
              } } />
        </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(PaymentAgreements);