import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Input, InputCurrency, Modal, PaperClip, Select } from 'components'
import moment from 'moment'
import { ImagesAndDocuments } from 'components/paperclip'
import { Constants, Globals } from 'utils'
import { useCashContext } from '../useCashContext'
import { CashModalVoucherProps, Voucher } from '../Cash'
import { CashService } from 'services'

const INITIAL_FORM_PAYMENT: Voucher = {
  number: '',
  amount_paid: '',
  payment_method_id: 0,
  bank_id: 0,
  bank_account_id: 0,
  date: '',
  file: '',
  accepted: Constants.BILL_STATUS.PENDING,
  reception_date: '',
}

const adapterOptions = (i: any) => ({
  value: i.id,
  label: i.name,
})

const CashModalVoucher = ({ visible, setVisible }: CashModalVoucherProps) => {
  const { vouchers, setVouchers, property, paymentDetail, userIsClient } = useCashContext()

  const [formPayment, setFormPayment] = useState<Voucher>({ ...INITIAL_FORM_PAYMENT })
  const { TRANSFER, CREDIT, DEBIT, DEPOSIT } = Constants.PAYMENT_METHODS

  const [methods, setMethods] = useState([])
  const [banks, setBanks] = useState([])
  const [bank_accounts, setBank_accounts] = useState([])

  const loadPaymentMethods = async () => {
    try {
      const res: any = await CashService.admin.getPaymentMethods()

      const _methods = res.methods.map(adapterOptions)
      const _banks = res.banks.map(adapterOptions)
      const _bank_accounts = res.bank_accounts.map(adapterOptions)

      if (userIsClient) {
        setMethods(_methods.filter((m: any) => m.label !== 'TDD'))
      } else {
        setMethods(_methods)
      }
      setBanks(_banks)
      setBank_accounts(_bank_accounts)
    } catch (error) {
      console.log(error)
    }
  }

  const changePayment = (e: any) => {
    setFormPayment({
      ...formPayment,
      [e.target.name]: e.target.value,
    })
  }

  const changePaymentNumber = (e: any) => {
    changePayment({
      target: {
        name: e.target.name,
        value: parseInt(e.target.value),
      },
    })
  }

  const onClose = () => {
    setVisible(false)
  }

  const finish = async () => {
    // const item: any = this.state.item
    let message: string | boolean = false

    if (!formPayment.file && formPayment.payment_method_id !== DEBIT && formPayment.payment_method_id !== CREDIT) {
      message = "Debe subir el comprobante de pago";
    }

    if (!formPayment.payment_method_id) {
      message = 'Debe ingresar el método de pago'
    }

    if (formPayment.number?.toString().length !== 8) {
      message = 'Nro. de referencia debe escribir los últimos 8 dígitos'
    }

    if (formPayment.payment_method_id === TRANSFER) {
      if (!formPayment.number || !formPayment.date || !formPayment.bank_id || !formPayment.bank_account_id || !formPayment.file) {
        message = 'Debe ingresar todos los datos de pago'
      }
      if (!formPayment.reception_date && formPayment.accepted === Constants.BILL_STATUS.ACCEPTED) {
        message = 'Debe ingresar la fecha de recepción del pago'
      }
    } else if ([TRANSFER, CREDIT, DEBIT].indexOf(formPayment.payment_method_id) !== -1) {
      if (!formPayment.number || !formPayment.bank_id) {
        message = 'Debe ingresar todos los datos de pago'
      }
    } else if ([DEPOSIT].indexOf(formPayment.payment_method_id) !== -1) {
      if (!formPayment.number || !formPayment.date) {
        message = 'Debe ingresar todos los datos de pago'
      }
    }

    if (message) {
      Globals.showError(message)
      return false
    }

    const validate: any = await CashService.admin.validateVoucher({ bank_id: formPayment.bank_id, number: formPayment.number })
    const validateByList = !!vouchers.filter((v) => formPayment.number === v.number && formPayment.bank_id === v.bank_id).length

    if (validate || validateByList) {
      Globals.showError('El número de referencia ha sido usado')
      return false
    }

    setVouchers([...vouchers, formPayment])

    // Globals.showSuccess('Se han guardado los datos correctamente')
    onClose()
    setFormPayment({ ...INITIAL_FORM_PAYMENT })
  }

  useEffect(() => {
    loadPaymentMethods()
  }, [])

  useEffect(() => {
    if (visible) {
      setFormPayment({
        ...INITIAL_FORM_PAYMENT,
        amount_paid: paymentDetail.total_to_pay?.toString() ?? '',
      })
    }
  }, [visible])

  if (!property) {
    return null
  }

  return (
    <Modal className="high-modal modal-payment-retention" visible={visible} onClose={onClose} title="Subir datos de pago">
      <>
        <div className="row" style={{ marginTop: '20px' }}>
          <div className="col-md-4">
            <Select
              color="gray"
              name="payment_method_id"
              onChange={changePaymentNumber}
              label="Método de Pago"
              placeholder="Seleccionar"
              value={formPayment.payment_method_id}
              options={methods}
            />
          </div>
          {[TRANSFER, CREDIT, DEBIT].indexOf(formPayment.payment_method_id) !== -1 && (
            <>
              <div className="col-md-4">
                <Select
                  color="gray"
                  name="bank_id"
                  onChange={changePaymentNumber}
                  label="Banco"
                  placeholder="Seleccionar"
                  value={formPayment.bank_id}
                  options={banks}
                />
              </div>
              <div className="col-md-4">
                <Input
                  type="number"
                  color="gray"
                  value={formPayment.number}
                  name="number"
                  label="Nro. de Referencia"
                  helpText={formPayment.number?.toString().length !== 8 ? 'Escriba los últimos 8 dígitos' : ''}
                  onChange={changePayment}
                />
              </div>
              <div className="col-md-4">
                <InputCurrency value={formPayment.amount_paid} name="amount_paid" label="Monto del recibo" onChange={changePayment} />
              </div>
            </>
          )}
          {[DEBIT].indexOf(formPayment.payment_method_id) !== -1 && (
            <div className="col-md-4">
              <DatePicker
                color="gray"
                label="Fecha de Pago"
                showYearDropdown={true}
                maxDate={moment().toDate()}
                onChange={(value: string) =>
                  changePayment({
                    target: {
                      name: 'date',
                      value,
                    },
                  })
                }
                value={formPayment.date}
              />
            </div>
          )}
          {[DEPOSIT].indexOf(formPayment.payment_method_id) !== -1 && (
            <>
              <div className="col-md-4">
                <Select
                  color="gray"
                  name="bank_id"
                  onChange={changePaymentNumber}
                  label="Banco"
                  placeholder="Seleccionar"
                  value={formPayment.bank_id}
                  options={banks}
                />
              </div>
              <div className="col-md-4">
                <Input
                  type="number"
                  color="gray"
                  value={formPayment.number}
                  name="number"
                  label="Nro. de Referencia"
                  helpText={formPayment.number?.toString().length !== 8 ? 'Escriba los últimos 8 dígitos' : ''}
                  onChange={changePayment}
                />
              </div>
              <div className="col-md-4">
                <InputCurrency value={formPayment.amount_paid} name="amount_paid" label="Monto del recibo" onChange={changePayment} />
              </div>
              <div className="col-md-4">
                <DatePicker
                  color="gray"
                  label="Fecha de Pago"
                  showYearDropdown={true}
                  maxDate={moment().toDate()}
                  onChange={(value: string) =>
                    changePayment({
                      target: {
                        name: 'date',
                        value,
                      },
                    })
                  }
                  value={formPayment.date}
                />
              </div>
            
              <div className="col-md-4">
                <PaperClip
                  name="file"
                  formats={ImagesAndDocuments}
                  value={formPayment.file}
                  className={formPayment.file && 'active'}
                  successText="Comprobante Cargado"
                  pendingText="Subir Comprobante"
                  onChange={(e: any) => {
                    changePayment({
                      target: {
                        name: 'file',
                        value: e.target.dataURL,
                      },
                    })
                  }}
                />
              </div>
              
            </>
          )}
          {TRANSFER === formPayment.payment_method_id && (
            <>
              <div className="col-md-4">
                <Select
                  color="gray"
                  name="bank_account_id"
                  onChange={changePaymentNumber}
                  label="Cuenta de Banco"
                  placeholder="Seleccionar"
                  value={formPayment.bank_account_id}
                  options={bank_accounts}
                />
              </div>
              <div className="col-md-4">
                <DatePicker
                  color="gray"
                  label="Fecha de Transferencia"
                  showYearDropdown={true}
                  maxDate={moment().toDate()}
                  onChange={(value: string) =>
                    changePayment({
                      target: {
                        name: 'date',
                        value,
                      },
                    })
                  }
                  value={formPayment.date}
                />
              </div>
              {/* <div className="col-md-4">
                <InputCurrency value={formPayment.amount_paid} name="amount_paid" label="Monto del recibo" onChange={changePayment} />
              </div> */}
              <div className="col-md-4">
                <PaperClip
                  name="file"
                  formats={ImagesAndDocuments}
                  value={formPayment.file}
                  className={formPayment.file && 'active'}
                  successText="Comprobante Cargado"
                  pendingText="Subir Comprobante"
                  onChange={(e: any) => {
                    changePayment({
                      target: {
                        name: 'file',
                        value: e.target.dataURL,
                      },
                    })
                  }}
                />
              </div>
              {formPayment.accepted == Constants.BILL_STATUS.ACCEPTED && (
                <div className="col-md-4">
                  <DatePicker
                    color="gray"
                    label="Fecha de Recepción del Pago"
                    showYearDropdown={true}
                    maxDate={moment().toDate()}
                    onChange={(value: string) =>
                      changePayment({
                        target: {
                          value,
                          name: 'reception_date',
                        },
                      })
                    }
                    value={formPayment.reception_date}
                  />
                </div>
              )}
              {/* <div className="col-md-4">
                <Checkbox
                  name="accepted"
                  inline={ true }
                  label="Pago Conciliado"
                  checked={ formPayment.accepted == Constants.BILL_STATUS.ACCEPTED }
                  onChange={ () => {
                    this.setState({
                      formPayment: {
                        ...formPayment,
                        accepted: formPayment.accepted == Constants.BILL_STATUS.ACCEPTED ? Constants.BILL_STATUS.PENDING : Constants.BILL_STATUS.ACCEPTED
                      }
                    });
                  } } />
              </div> */}
            </>
          )}
        </div>
        <div className="text-right">
          <Button type="button" className="btn-total" onClick={finish}>
            Guardar
          </Button>
        </div>
      </>
    </Modal>
  )
}

export default CashModalVoucher
