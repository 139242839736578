import React from 'react'
import { RootState } from 'reducers'
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
// import { connect } from 'react-redux';
import { Input, Button, Select, DatePicker, Modal } from 'components'
import { ClientService } from 'services'
import { Globals, Constants, RegisterMovement } from 'utils'
import { TrashIcon } from 'assets/icons'

interface Props extends RootState, RouteComponentProps {
  dispatch: any
  show: 'CLIENT' | 'PROPERTY' | 'ALL'
  headTitle: string
  onBack?: any | null
  onLoad: (self: any) => Promise<void>
  onSubmit: (self: any, values: any) => Promise<void | false | undefined>
  isRegister?: boolean
  isProfile?: boolean
  showNote?: boolean
  hideClient?: boolean
  passwordNotRequired?: boolean
  fieldsDisabled?: string[]
  isAdmin?: boolean
}

const TABS = {
  DATA: 1,
  PROPERTIES: 2,
}

const allRequired = 'property_use_id,property_type_id,economic_activity_id,nic,catastral_id,license,zone,street,house_number,floor,size,reference,quantity_houses'

const CONDOMINIUM = 'condominium'
const RESIDENTIAL = 'residential'
const COMMERCIAL = 'commercial'
const INDUSTRIAL = 'industrial'
const GOVERNMENTAL = 'governmental'

const idByValid: { [key: string]: string } = {
  [Constants.PROPERTY_TYPES.COMMERCIAL]: COMMERCIAL,
  [Constants.PROPERTY_TYPES.RESIDENTIAL]: RESIDENTIAL,
  [Constants.PROPERTY_TYPES.INDUSTRIAL]: INDUSTRIAL,
  [Constants.PROPERTY_TYPES.GOVERNMENT]: GOVERNMENTAL,
}

const notRequired = (txt: string) => {
  if (!txt) return allRequired;
  return allRequired.split(',').filter(x => !txt.includes(x)).join(',');
}

const listOfRequired: { [key: string]: string } = {
  [CONDOMINIUM]: notRequired('catastral_id,nic,reference'),
  [RESIDENTIAL]: notRequired('catastral_id,nic,reference'),
  [COMMERCIAL]: notRequired('catastral_id,floor,nic,license,house_number,reference'),
  [INDUSTRIAL]: notRequired('catastral_id,floor,license,house_number,reference'),
  [GOVERNMENTAL]: notRequired('catastral_id,floor,license,house_number,reference'),
}

const LengthByCI = {
  [Constants.DOCUMENT_TYPES.V]: 8,
  [Constants.DOCUMENT_TYPES.E]: 20,
  [Constants.DOCUMENT_TYPES.J]: 9,
  [Constants.DOCUMENT_TYPES.G]: 9,
}

export default class FormClientProperty extends React.Component<Props> {
  random = +Date.now()
  state = {
    form: {
      name: '',
      lastname: '',
      username: '',
      email: '',
      phone: '',
      document_type_id: '',
      document: '',
      password: '',
      password_confirmation: '',
      business_name: '',
      economic_activity: '',
      fiscal_address: '',
      retention: Constants.RETENTION.NO,
      note: '',
    },
    codes: [],
    document_types: [],
    emails: [],
    phones: [],
    active: false,
    property_uses: [],
    property_types: [],
    economic_activities: [],
    tab: TABS.DATA,
    // properties: [
    //   {
    //     owner: Constants.PROPERTIES.OWNER.YES,
    //     economic_activity_id: '',
    //     property_use_id: '',
    //     property_type_id: '',
    //     catastral_id: '',
    //     nic: '',
    //     license: '',
    //     urbaser_code: '',
    //     date: '',
    //     vba_number: '',
    //     street: '',
    //     floor: '',
    //     zone: '',
    //     house_number: '',
    //     size: '',
    //     reference: '',
    //     secondary: [],
    //     quantity_houses: '',
    //     type_form: Constants.TYPE_FORM.ESTATE,
    //   },
    // ],
    visible: false,
    property: {
      owner: Constants.PROPERTIES.OWNER.YES,
      economic_activity_id: '',
      property_use_id: '',
      property_type_id: '',
      catastral_id: '',
      nic: '',
      license: '',
      urbaser_code: '',
      date: '',
      vba_number: '',
      street: '',
      floor: '',
      zone: '',
      house_number: '',
      size: '',
      reference: '',
      secondary: [],
      quantity_houses: '',
      type_form: Constants.TYPE_FORM.ESTATE,
    },
    validation: allRequired,
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: this.props.headTitle,
        onBack: this.props.onBack,
      },
    })

    this.init()
  }

  init = async () => {
    await this.load()
    const valid = this.canValidation(this.state.property?.property_type_id, 'property_type_id');

    this.setState({ validation: valid })
  }

  load = async () => {
    await this.props.onLoad(this)
  }

  change = (e: any, callback = () => {}) => {
    const form = {
      ...this.state.form,
      [e.target.name]: e.target.value,
    };

    if (e.target.name === 'name') {
      form.business_name = e.target.value;
    }

    this.setState(
      { form },
      callback
    )
  }

  changeEmails = (e: any, index: number) => {
    let emails: any = [...this.state.emails]
    emails[index].email = e.target.value
    this.setState({
      emails,
    })
  }

  changePhones = (e: any, index: number) => {
    let phones: any = [...this.state.phones]
    phones[index].phone = e.target.value
    this.setState({
      phones,
    })
  }

  submit = async (e: any) => {
    e.preventDefault();
    const form = this.state.form;
    form.business_name = form.name;
    await this.props.onSubmit(this, form);
  }

  addPhone = () => {
    let phones: any = [...this.state.phones]
    phones.push({
      phone: '',
    })
    this.setState({
      phones,
    })
  }

  addEmail = () => {
    let emails: any = [...this.state.emails]
    emails.push({
      email: '',
    })
    this.setState({
      emails,
    })
  }

  deletePhone = (index: number) => {
    let phones: any = [...this.state.phones]
    phones.splice(index, 1)
    this.setState({
      phones,
    })
  }

  deleteEmail = (index: number) => {
    let emails: any = [...this.state.emails]
    emails.splice(index, 1)
    this.setState({
      emails,
    })
  }

  canValidation = (value: any, target: string) => {
    let valid = this.state.validation;

    if (target === 'property_use_id' && !this.state.property.property_type_id) {
      valid = value == 2 ? listOfRequired[CONDOMINIUM] : allRequired;
    }

    if (target === 'property_type_id' && idByValid[value]) {
      valid = listOfRequired[idByValid[value]];
    }

    return valid;
  }

  setValueProperty = (value: any, target: string, callback: any = () => {}) => {
    let valid = this.canValidation(value, target);

    this.setState(
      {
        property: {
          ...this.state.property,
          [target]: value,
        },
        validation: valid,
      },
      callback
    )
  }

  isRequired = (target: string): boolean => {
    const valid = this.state.validation;
    return valid.includes(target);
  }

  render() {
    const emails: any = this.state.emails
    const phones: any = this.state.phones
    const property: any = this.state.property

    return (
      <div id="admin-create-user">
        <form onSubmit={this.submit}>
          <div className="row-form row-form-property">
            <div className="col-12">
              {this.props.show === 'CLIENT' && <h3>Ingrese los datos de cliente</h3>}
              {this.props.show === 'PROPERTY' && <h3>Ingrese los datos del inmueble</h3>}
              {this.props.show === 'ALL' && <h3>Ingrese los datos de cliente y del inmueble</h3>}
            </div>

            {!this.props.hideClient && (
              <div className="row row-form">
                <div className="col-lg-6">
                  <Input
                    color="white"
                    value={this.state.form.name}
                    name="name"
                    label="Nombre o Razón social"
                    onChange={this.change}
                    required={true}
                    disabled={this.props.fieldsDisabled?.includes('name')}
                  />
                </div>
                {/* <div className="col-lg-6">
                  <Input
                    color="white"
                    value={this.state.form.lastname}
                    name="lastname"
                    label="Primer apellido e inicial del segundo"
                    onChange={this.change}
                    required={true}
                  />
                </div> */}
                <div className="col-lg-6">
                  <p className="label">C.I. / RIF <span className="text-danger">(*)</span></p>
                  <div className="i-flex">
                    <div className="doc-flex">
                      <Select
                        color="gray"
                        className="select-phone"
                        name="document_type_id"
                        onChange={(e: any) => {
                          let retention = this.state.form.retention
                          if (parseInt(this.state.form.document_type_id) != Constants.DOCUMENT_TYPES.J) {
                            retention = Constants.RETENTION.NO
                          }
                          this.setState({
                            form: {
                              ...this.state.form,
                              retention,
                              document_type_id: e.target.value,
                            },
                          })
                        }}
                        value={this.state.form.document_type_id}
                        placeholder="Tipo"
                        options={this.state.document_types.map((i: any) => {
                          return {
                            value: i.id,
                            label: i.name,
                          }
                        })}
                        required={true}
                        disabled={this.props.fieldsDisabled?.includes('document_type_id')}
                      />
                    </div>
                    <div className="doc-flex">
                      <Input
                        color="white"
                        value={this.state.form.document}
                        name="document"
                        type="number"
                        placeholder="C.I. / RIF"
                        required={true}
                        onChange={(e: any) => {
                          const length = LengthByCI[parseInt(this.state.form.document_type_id)] ?? 0
                          if (e.target.value.length <= length) {
                            this.change(e)
                          } else if (e.target.value.length <= this.state.form.document.length) {
                            this.change(e)
                          }
                        }}
                        disabled={this.props.fieldsDisabled?.includes('document')}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <Input
                    color="white"
                    value={this.state.form.fiscal_address}
                    name="fiscal_address"
                    label="Dirección Fiscal"
                    onChange={this.change}
                    required={true}
                    disabled={this.props.fieldsDisabled?.includes('fiscal_address')}
                  />
                </div>
                {/* <div className="col-lg-4">
                  <Input
                    color="white"
                    value={this.state.form.business_name}
                    name="business_name"
                    label="Razón Social"
                    onChange={this.change}
                    required={true}
                  />
                </div> */}
                { this.props.isProfile && (
                  <div className="col-lg-4">
                    <Input
                      color="white"
                      value={ this.state.form.username }
                      name="username"
                      label="Usuario"
                      onChange={ this.change }
                      readOnly
                      disabled={this.props.fieldsDisabled?.includes('username')}
                    />
                  </div>
                )}
                <div className="col-lg-4">
                  {parseInt(this.state.form.document_type_id) == Constants.DOCUMENT_TYPES.J && (
                    <div className="container-radio retention">
                      <p>Agente de Retención</p>
                      <div
                        className="item-radio"
                        onClick={() =>
                          this.change({
                            target: {
                              name: 'retention',
                              value: Constants.RETENTION.YES,
                            },
                          })
                        }
                      >
                        <div className={`radio ${this.state.form.retention == Constants.RETENTION.YES ? 'active' : ''}`}>
                          <div className="circle"></div>
                        </div>
                        <p>Si</p>
                      </div>
                      <div
                        className="item-radio"
                        onClick={() =>
                          this.change({
                            target: {
                              name: 'retention',
                              value: Constants.RETENTION.NO,
                            },
                          })
                        }
                      >
                        <div className={`radio ${this.state.form.retention == Constants.RETENTION.NO ? 'active' : ''}`}>
                          <div className="circle"></div>
                        </div>
                        <p>No</p>
                      </div>
                    </div>
                  )}
                </div>
                { !this.props.isProfile && (
                  <div className="col-lg-4"></div>
                )}
                <div className="col-lg-3">
                  <Input type="number" color="white" value={this.state.form.phone} name="phone" label="Teléfono" onChange={this.change} required={true} />
                </div>
                <div className="col-lg-3">
                  <p className="label label-button">
                    Teléfonos Adicionales
                    <div className="btn-add" onClick={this.addPhone}>
                      +
                    </div>
                  </p>
                  {phones.map((item: any, index: number) => (
                    <div className="container-delete">
                      <Input
                        color="white"
                        value={phones[index].phone}
                        name="phone"
                        type="number"
                        placeholder="Teléfono"
                        onChange={(e: any) => this.changePhones(e, index)}
                        required={true}
                        disabled={this.props.fieldsDisabled?.includes('phone')}
                      />
                      <img src={TrashIcon} onClick={() => this.deletePhone(index)} />
                    </div>
                  ))}
                </div>
                <div className="col-lg-3">
                  <Input color="white" value={this.state.form.email} name="email" label="Correo Electrónico" onChange={this.change} required={true} />
                </div>
                <div className="col-lg-3">
                  <p className="label label-button">
                    Correos Adicionales
                    <div className="btn-add" onClick={this.addEmail}>
                      +
                    </div>
                  </p>
                  {emails.map((item: any, index: number) => (
                    <div className="container-delete">
                      <Input
                        color="white"
                        value={emails[index].email}
                        name="email"
                        placeholder="Correo Electrónico"
                        onChange={(e: any) => this.changeEmails(e, index)}
                        required={true}
                        disabled={this.props.fieldsDisabled?.includes('email')}
                      />
                      <img src={TrashIcon} onClick={() => this.deleteEmail(index)} />
                    </div>
                  ))}
                </div>
                {/* <div className="col-lg-4">
                  
                      <p className="label">Solicitar correo de validación</p>
                      <Button type="button" className={ 'btn-validation ' + (this.state.active ? 'active' : '') } onClick={ () => {
                        this.setState({
                          active: !this.state.active
                        });
                      } }>
                        Solicitar
                      </Button>
                   
                </div> */}
                {this.props.showNote && (
                  <div className="col-lg-12">
                    <Input
                      color="white"
                      value={ this.state.form.note }
                      name="note"
                      label="Nota"
                      onChange={ this.change }
                      disabled={this.props.fieldsDisabled?.includes('note')}
                    />
                  </div>
                )}
                {this.props.isRegister && (
                  <React.Fragment>
                    <div className="col-12 px-0">
                      <div className="row">
                        <div className="col-lg-4">
                          <Input
                            color="white"
                            type="password"
                            value={ this.state.form.password }
                            name="password"
                            label="Contraseña"
                            onChange={ this.change }
                            required={!this.props.passwordNotRequired}
                            disabled={this.props.fieldsDisabled?.includes('password')}
                          />
                        </div>
                        <div className="col-lg-4">
                          <Input
                            color="white"
                            type="password"
                            value={ this.state.form.password_confirmation }
                            name="password_confirmation"
                            label="Confirmar Contraseña"
                            onChange={ this.change }
                            required={!this.props.passwordNotRequired}
                            disabled={this.props.fieldsDisabled?.includes('password_confirmation')}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {this.props.show === 'CLIENT' && (
                  <div className="col-12 text-right">
                    <Button type="submit">Guardar</Button>
                  </div>
                )}
              </div>
            )}

            {this.props.show === 'ALL' && (
              <div className="col-12">
                <hr />
              </div>
            )}

            {(this.props.show === 'PROPERTY' || this.props.show === 'ALL') && (
              <div className="row row-form">
                {this.props.show === 'ALL' && (
                  <div className="col-12">
                    <h4 className="mt-0">Datos del Inmueble</h4>
                  </div>
                )}

                <div className="col-12 row-top-radio">
                  <div className="container-radio bottom">
                    <div className="item-radio" onClick={() => this.setValueProperty(Constants.PROPERTIES.OWNER.YES, 'owner')}>
                      <div className={`radio ${property.owner == Constants.PROPERTIES.OWNER.YES ? 'active' : ''}`}>
                        <div className="circle"></div>
                      </div>
                      <p>Propietario</p>
                    </div>
                    <div className="item-radio" onClick={() => this.setValueProperty(Constants.PROPERTIES.OWNER.NO, 'owner')}>
                      <div className={`radio ${property.owner == Constants.PROPERTIES.OWNER.NO ? 'active' : ''}`}>
                        <div className="circle"></div>
                      </div>
                      <p>Inquilino</p>
                    </div>
                    <p>Seleccione si es propietario o inquilino</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <Select
                    color="gray"
                    name="property_use_id"
                    onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                    value={property.property_use_id}
                    label="Uso del Inmueble"
                    options={this.state.property_uses.map((i: any) => {
                      return {
                        value: i.id,
                        label: i.name,
                      }
                    })}
                    required={this.isRequired('property_use_id')}
                    disabled={this.props.fieldsDisabled?.includes('property_use_id')}
                  />
                </div>
                <div className="col-md-4">
                  <Select
                    color="gray"
                    name="property_type_id"
                    onChange={(e: any) =>
                      this.setValueProperty(e.target.value, e.target.name, () => {
                        this.setValueProperty('', 'economic_activity_id')
                      })
                    }
                    value={property.property_type_id}
                    label="Tipo de Inmueble"
                    options={this.state.property_types.map((i: any) => {
                      return {
                        value: i.id,
                        label: i.name,
                      }
                    })}
                    required={this.isRequired('property_type_id')}
                    disabled={this.props.fieldsDisabled?.includes('property_type_id')}
                  />
                </div>
                <div className="col-md-3">
                  {property.property_type_id && (
                    <Select
                      color="gray"
                      name="economic_activity_id"
                      onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                      value={property.economic_activity_id}
                      label="Actividad Económica Principal"
                      options={Globals.filterEconomicActivities(this.state.economic_activities, property).map((i: any) => {
                        return {
                          value: i.id,
                          label: '[' + i.code + '] ' +  (this.props.isAdmin === true ? i.name : i.short),
                        }
                      })}
                      required={this.isRequired('economic_activity_id')}
                      disabled={this.props.fieldsDisabled?.includes('economic_activity_id')}
                    />
                  )}
                </div>
                <div className="col-md-1">
                  {/* {
                    property.property_type_id && (
                      <div className="btn-add btn-add-secondary" onClick={() => this.setState({
                        visible: true,
                        property: property
                      })}>
                        +
                      </div>
                    )
                  } */}
                </div>
                { (property.property_use_id == 2 || property.property_type_id == 2) && (
                  <div className="col-md-3">
                    <Input
                      color="white"
                      value={property.nic}
                      name="nic"
                      label="NIC Corpoelec"
                      onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                      required={this.isRequired('nic')}
                      disabled={this.props.fieldsDisabled?.includes('nic')}
                    />
                  </div>
                )}
                <div className="col-md-3">
                  <Input
                    color="white"
                    value={property.catastral_id}
                    name="catastral_id"
                    label="Cédula Catastral"
                    onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                    required={this.isRequired('catastral_id')}
                    disabled={this.props.fieldsDisabled?.includes('catastral_id')}
                  />
                </div>
                { (property.property_type_id != 2) && (
                  <div className="col-md-3">
                    <Input
                      color="white"
                      value={property.license}
                      name="license"
                      label="Lic. Actividad Económica"
                      onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                      required={this.isRequired('license')}
                      disabled={this.props.fieldsDisabled?.includes('license')}
                    />
                  </div>
                )}
                {/* <div className="col-md-3">
                    <Input
                      color="white"
                      value={ item.urbaser_code }
                      name="urbaser_code" 
                      label="Código Cta"
                      onChange={ (e: any) => this.setValueProperty(e.target.value, e.target.name) } />
                  </div> */}
                {/* <div className="col-12">
                    <h4>Visto Bueno ambiental</h4>
                  </div> */}
                {/* <div className="col-md-4">
                    <Input
                      color="white"
                      value={ item.vba_number }
                      name="vba_number" 
                      label="Código VBA"
                      onChange={ (e: any) => this.setValueProperty(e.target.value, e.target.name) } />
                  </div>
                  <div className="col-md-4">
                    <DatePicker
                      color="gray"
                      label="Fecha de Emisión"
                      showYearDropdown={ true }
                      maxDate={ moment().toDate() }
                      onChange={ (value: string) => this.setValueProperty(value, 'date') }
                      value={ item.date }
                    />
                  </div> */}
                <div className="col-md-4">
                  <Input
                    color="white"
                    value={property.zone}
                    name="zone"
                    label="Zona, Sector, Urb"
                    onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                    required={this.isRequired('zone')}
                    disabled={this.props.fieldsDisabled?.includes('zone')}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    color="white"
                    value={property.street}
                    name="street"
                    label="Av / Calle"
                    onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                    required={this.isRequired('street')}
                    disabled={this.props.fieldsDisabled?.includes('street')}
                  />
                </div>
                { (property.property_use_id != 2 || property.property_type_id != 2) && (
                <div className="col-md-4">
                  <Input
                    color="white"
                    value={property.house_number}
                    name="house_number"
                    label={ (property.property_use_id != 2 && property.property_type_id != 2 ? "N° de local" : "Nc Local, Casa, Apto") }
                    onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                    required={this.isRequired('house_number')}
                    disabled={this.props.fieldsDisabled?.includes('house_number')}
                  />
                </div>
                )}
                { (property.property_type_id != 2) && (
                  <React.Fragment>
                    <div className="col-md-4">
                      <Input
                        color="white"
                        value={property.floor}
                        name="floor"
                        label="Piso"
                        onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                        required={this.isRequired('floor')}
                        disabled={this.props.fieldsDisabled?.includes('floor')}
                      />
                    </div>
                    {/* <div className="col-md-4">
                      <Input
                        type="decimal"
                        color="white"
                        value={property.size}
                        name="size"
                        label="Metraje (m2)"
                        onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                        required={this.isRequired('size')}
                      />
                    </div> */}
                  </React.Fragment>
                )}
                <div className="col-md-4">
                  <Input
                    color="white"
                    value={property.reference}
                    name="reference"
                    label="Punto de Referencia"
                    onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                    required={this.isRequired('reference')}
                    disabled={this.props.fieldsDisabled?.includes('reference')}
                  />
                </div>
                {property.property_type_id == Constants.PROPERTY_TYPES.RESIDENTIAL &&
                  property.property_use_id == Constants.PROPERTY_USES.CONDOMINIUM && (
                    <div className="col-md-4">
                      <Input
                        type="number"
                        color="white"
                        value={property.quantity_houses}
                        name="quantity_houses"
                        label="N° de Casas o Apartamentos"
                        onChange={(e: any) => this.setValueProperty(e.target.value, e.target.name)}
                        required={this.isRequired('quantity_houses')}
                        disabled={this.props.fieldsDisabled?.includes('quantity_houses')}
                      />
                    </div>
                  )}
                {/* <div className="col-12">
                    <div className="container-radio">
                      <p>¿Pertenece a condominio?</p>
                      <div className="item-radio" onClick={ () => this.setValueProperty(Constants.PROPERTIES.CONDOMINIUM.YES,'condominium') }>
                        <div className={ `radio ${ item.condominium == Constants.PROPERTIES.CONDOMINIUM.YES ? 'active' : '' }` }>
                          <div className="circle"></div>
                        </div>
                        <p>Si</p>
                      </div> 
                      <div className="item-radio" onClick={ () => this.setValueProperty(Constants.PROPERTIES.CONDOMINIUM.NO,'condominium') }>
                        <div className={ `radio ${ item.condominium == Constants.PROPERTIES.CONDOMINIUM.NO ? 'active' : '' }` }>
                          <div className="circle"></div>
                        </div>
                        <p>No</p>
                      </div> 
                    </div>
                  </div> */}
                {this.props.show === 'PROPERTY' && (
                  <div className="col-12 text-right">
                    <Button type="submit">Guardar</Button>
                  </div>
                )}
              </div>
            )}
          </div>

          {this.props.show === 'ALL' && (
            <div className="row mt-4">
              <div className="col-12 text-right">
                <Button type="submit" className="px-5 hola">
                  <div style={{color: '#2B2C2E'}}>Guardar</div>
                </Button>
              </div>
            </div>
          )}
        </form>
      </div>
    )
  }
}
