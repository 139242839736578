import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Input, Modal, Table } from 'components'
import { CashModalServicesProps, Invoice, Service } from '../Cash'
import { useCashContext } from '../useCashContext'
import { CashService } from 'services'

const headerModal = ['', 'Nombre']

const CashModalServices = ({ visible, setVisible }: CashModalServicesProps) => {
  const { loadingServices, getPropertyIdServices, services, setServices, property, invoices, setInvoices } = useCashContext()
  const [serviceName, setServiceName] = useState('')
  const [loading, setLoading] = useState(false)

  const addToInvoices = () => {
    const filter = services
      .filter((service) => {
        return service.isSelected && !invoices.some((i) => i.id === service.id)
      })
      .map((service): Invoice => {
        return {
          id: service.id,
          service_id: service.id,
          name: service.name,
          amount: 0,
          amount_bs: 0,
        }
      })

    setInvoices([...invoices, ...filter])
    onClose()
  }

  const handleSelected = (service: Service) => {
    const list = services.map((s) => {
      if (s.id === service.id) s.isSelected = !service.isSelected

      return s
    })

    setServices(list)
  }

  const onCreate = async () => {
    setLoading(true)
    try {
      const res: any = await CashService.admin.createServiceTypes({ name: serviceName })
      const service = res?.service_type

      setServices([...services, { ...service, isSelected: false }])
      setServiceName('')
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (visible && property) {
      getPropertyIdServices()
    }
  }, [visible])

  if (!property) {
    return null
  }

  return (
    <Modal className="high-modal modal-view-account-status" visible={visible} onClose={onClose} title="Agregar Multa">
      <div>
        <div className="d-flex w-50">
          <div className="w-100 mb-n3 mr-3">
            <Input
              color="white"
              value={serviceName}
              name="serviceName"
              placeholder="Escribe el nombre del servicio"
              onChange={(e: any) => {
                setServiceName(e.target.value)
              }}
            />
          </div>
          <Button className="btn-urbaser-green" disabled={loading} onClick={onCreate}>
            {loading ? 'Creando...' : 'Crear'}
          </Button>
        </div>

        {loadingServices && <p className="text-center mt-5">Cargando...</p>}

        {!loadingServices && (
          <>
            <Table title="" data={services} header={headerModal}>
              {services.length === 0 ? (
                <tr>
                  <td className="text-center" colSpan={headerModal.length}>
                    No hay datos
                  </td>
                </tr>
              ) : (
                services.map((service) => (
                  <tr key={service.id} onClick={() => handleSelected(service)}>
                    <td>
                      <Checkbox value={service.id} checked={service.isSelected} />
                    </td>
                    <td className="capitalize">{service.name}</td>
                  </tr>
                ))
              )}
            </Table>
          </>
        )}

        <div className="d-flex justify-content-end mt-3">
          <Button className="btn-urbaser-green" onClick={addToInvoices}>
            Agregar a factura
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CashModalServices
