const INITIAL_STATE = {
	MESSAGES: 0
}

const badge = (state = INITIAL_STATE, action: any) => {
	switch(action.type) {
		case 'SET_BADGE':
            return action.payload;
            break;
        default: 
        	return state;
        	break;
	}
}

export default badge;