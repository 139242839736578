import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { SuggestionsService } from 'services';
import { Input, Textarea, Button, Pagination } from 'components';
import { Globals, Socket, SocketEvents, Constants } from 'utils';
import moment from 'moment';
import { TrashIcon } from 'assets/icons';
import { on } from 'jetemit';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

class ComplaintsSuggestions extends React.Component<Props> {  

  state = {
    page: 1,
    last_page: 1, 
    data: [],
    form_create: {
      zone: '',
      text: ''
    },
    unsubscribe: () => {},
    unsubscribeStatus: () => {}
  }

  async componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Dudas y Sugerencias',
        onBack: null
      }
    });

    await this.setState({
      unsubscribe: on(SocketEvents.MESSAGES.SEND,() => {
        this.load(true);
      }),
      unsubscribeStatus: on(SocketEvents.MESSAGES.STATUS,(data: any) => {
        if (this.state.data.map((i: any) => i.id).indexOf(data.suggestion_id) != -1) {
          this.load(true);
        }
      })
    });

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await SuggestionsService.get({
      page: this.state.page,
      withoutLoading,
      user_id: this.props.user.id
    });
    this.setState({
      data: res.suggestions.data,
      last_page: res.suggestions.last_page
    });
  }

  componentWillUnmount() {
    this.state.unsubscribe();
    this.state.unsubscribeStatus();
  }

  changeCreate = (e: any,callback = () => {}) => {
    this.setState({
      form_create: {
        ...this.state.form_create,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  submit = async (e: any) => {
    e.preventDefault();
    await SuggestionsService.create({
      user_id: this.props.user.id,
      ...this.state.form_create
    });
    Globals.showSuccess("Se ha creado correctamente la denuncia/sugerencia");
    this.setState({
      form_create: {
        zone: '',
        text: ''
      }
    },() => this.load(true));
    /* Socket.emit(SocketEvents.MESSAGES.CREATE); */
  }

  view = (item: any) => {
    this.props.history.push({
      pathname: '/complaints-suggestions/view/' + item.id,
      state: this.state
    });
  }

  delete = (item: any) => {
    Globals.confirm('¿Desea eliminar la conversación?',async () => {
      await SuggestionsService.delete({
        suggestion_id: item.id
      });
      /* Socket.emit(SocketEvents.MESSAGES.DELETE,{
        suggestion_id: item.id
      }); */
      this.load(true);
    });
  }
  
  render() {
    const { data } = this.state;

    return (
        <div id="complaints-suggestions">
          <form onSubmit={ this.submit }>
            <h3>Nueva Denuncia o Sugerencia</h3>
            <div className="container-form">
              <div className="row">
                <div className="col-md-12">
                  <Input
                    value={ this.state.form_create.zone }
                    name="zone" 
                    color="gray"
                    label="Zona, Urb, Sector"
                    onChange={ this.changeCreate } />
                </div>
                <div className="col-md-12">
                  <Textarea
                    label="Detalles del Requerimiento"
                    rows={ 4 }
                    color="gray"
                    name="text"
                    onChange={ this.changeCreate }
                    value={ this.state.form_create.text } />
                </div>
              </div>
              <div className="text-center">
                <Button type="submit">
                  Enviar
                </Button>
              </div>
            </div>            
          </form>

          <h3 className="title">Bandeja</h3>

          {
            data.map((item: any, index: number) => (
              <div className="item-suggestion" key={ index }>
                <div onClick={ () => this.view(item) } className={ `title-item ${ (item.viewed == Constants.SUGGESTIONS.NOT_VIEWED || item.messages_count > 0) ? 'active' : '' }` }>
                  <h3>{ item.zone }</h3>
                  <p>{ moment(item.created_at).format('DD MMM') }</p>
                </div>
                <div className="text">
                  <p onClick={ () => this.view(item) }>{ item.text }</p>
                  <p>Estatus: { item.status_text }</p>
                  <img src={ TrashIcon } onClick={ (e: any) => {
                    e.stopPropagation();
                    this.delete(item);
                  } } />
                </div>                
              </div>
            ))
          }

          <Pagination 
                pages={ this.state.last_page } 
                active={ this.state.page }
                onChange={ async (page: number) => {
                  await this.setState({
                    page: page
                  });
                  this.load();
              } } />
       </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(ComplaintsSuggestions);