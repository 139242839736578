const INITIAL_STATE = {
	onBack: null,
	title: ''
}

const header = (state = INITIAL_STATE, action: any) => {
	switch(action.type) {
		case 'SET_HEADER':
            return action.payload;
            break;
        default: 
        	return state;
        	break;
	}
}

export default header;