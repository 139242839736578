import React from 'react';
import { RootState } from 'reducers';
import { Table, SelectSearch, Button, Tooltip, Select, Input, DatePicker, TooltipCurrency, PaperClip } from 'components';
import { CashService } from 'services';
import { CloseIcon } from 'assets/icons';
import { Currency, Globals, Constants } from 'utils';
import moment from 'moment';
import { ImagesAndDocuments } from 'components/paperclip';

interface Props {
  user: any,
  property: any,
  load: any,
  conversion_petro: number
}

class RegisterAgreement extends React.Component<Props> {  

  state = {
    header: [      
      'Código',
      'Nro de Cuota',
      'Pendiente por pagar',
      'Monto de Cuotas'
    ],
    payment_methods: [],
    bank_accounts: [],
    banks: [],
    agreements: []
  }

  componentDidMount() {
    this.load();
  }

  change = (key: string,value: any,index: number) => {
    let agreements: any = [...this.state.agreements];
    agreements[index][key] = value;
    this.setState({
      agreements
    });
  }

  load = async () => {
    const res: any = await CashService.getAgreements({
      user_id: this.props.user.id,
      property_id: this.props.property.id
    });
    this.setState({
      payment_methods: res.payment_methods,
      banks: res.banks,
      bank_accounts: res.bank_accounts,
      agreements: res.agreements.map((i: any) => {
        i.number = '';
        return i;
      })
    });
  }

  finish = (index: number) => {
    let message: string | boolean = false;
    const item: any = this.state.agreements[index];
    const { TRANSFER, CASH, DEBIT, CREDIT, DEPOSIT } = Constants.PAYMENT_METHODS;

    if (!item.payment_method_id) {
      message = "Debe ingresar el método de pago";
    }

    if (item.payment_method_id == TRANSFER) {
      if (!item.number || !item.date || !item.bank_id || !item.bank_account_id || !item.file) {
        message = "Debe ingresar todos los datos de pago";
      }
    } 
    else if ([TRANSFER,CREDIT,DEBIT].indexOf(parseInt(item.payment_method_id)) != -1) {
      if (!item.number || !item.bank_id) {
        message = "Debe ingresar todos los datos de pago";
      }
    }
    else if ([DEPOSIT].indexOf(parseInt(item.payment_method_id)) != -1) {
      if (!item.number || !item.date) {
        message = "Debe ingresar todos los datos de pago";
      }
    }

    if (message) {
      Globals.showError(message);
      return false;
    }

    Globals.confirm('¿Desea totalizar la factura?',async () => {
      await CashService.finishAgreements({
        ...item,
        agreement_id: item.id,
        date: item.date && moment(item.date).format('YYYY-MM-DD'),
        user_id: this.props.user.id,
        hasFile: true
      });
      Globals.showSuccess("Se ha generado el recibo correspondiente");
      this.load();
      this.props.load();
    });
  }
  
  render() {
    const agreements: any = this.state.agreements;
    const { TRANSFER, CASH, DEBIT, CREDIT, DEPOSIT } = Constants.PAYMENT_METHODS;

    return (
        <div id="admin-cash">
             {
                 agreements.map((item: any,index: number) => {
                   const fines = item.fines.map((i: any) => i.total).reduce((a: number, b: number) => a + b,0);

                   return (
                     <React.Fragment>
                        <Table title="" data={ true } header={ this.state.header }>
                           <tr>
                             <td>{ item.number_format }</td>
                             <td>{ item.bills_count + 1 }</td>
                             <td><TooltipCurrency title={ item.to_pay }>{ Globals.formatMiles(Currency.Convert(item.to_pay,this.props.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></td>
                             <td><TooltipCurrency title={ item.quotas }>{ Globals.formatMiles(Currency.Convert(item.quotas,this.props.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></td>
                           </tr>
                        </Table>

                        <div className="row">
                          <div className="col-md-4">
                            <Select
                              color="gray"
                              name="payment_method_id"
                              onChange={ (e: any) => this.change('payment_method_id',e.target.value,index) }
                              label="Método de Pago"
                              value={ item['payment_method_id'] || '' }
                              options={ this.state.payment_methods.map((i: any) => {
                                return {
                                  value: i.id,
                                  label: i.name
                                }
                              }) } />
                          </div>
                          {
                            [TRANSFER,CREDIT,DEBIT].indexOf(parseInt(item['payment_method_id'])) != -1 && (
                              <React.Fragment>
                                <div className="col-md-4">
                                  <Select
                                    color="gray"
                                    name="bank_id"
                                    onChange={ (e: any) => this.change('bank_id',e.target.value,index) }
                                    label="Banco"
                                    value={ item['bank_id'] || '' }
                                    options={ this.state.banks.map((i: any) => {
                                      return {
                                        value: i.id,
                                        label: i.name
                                      }
                                    }) } />
                                </div>
                                <div className="col-md-4">
                                  <Input
                                    type="number"
                                    color="gray"
                                    value={ item['number'] || '' }
                                    name="number" 
                                    label="Nro. de Referencia"
                                    helpText={ item['number']?.toString().length !== 8 ? "Escriba los últimos 8 dígitos" : "" }
                                    onChange={ (e: any) => this.change('number',e.target.value,index) } />
                                </div>
                              </React.Fragment>
                            )
                          }
                          {
                            [DEPOSIT].indexOf(parseInt(item['payment_method_id'])) != -1 && (
                              <React.Fragment>
                                <div className="col-md-4">
                                  <Input
                                    type="number"
                                    color="gray"
                                    value={ item['number'] || '' }
                                    name="number"
                                    label="Nro. de Referencia"
                                    helpText={ item['number']?.toString().length !== 8 ? "Escriba los últimos 8 dígitos" : "" }
                                    onChange={ (e: any) => this.change('number',e.target.value,index) } />
                                </div>
                                <div className="col-md-4">
                                  <DatePicker
                                    color="gray"
                                    label="Fecha de Pago"
                                    showYearDropdown={ true }
                                    maxDate={ moment().toDate() }
                                    onChange={ (value: string) => this.change('date',value,index) }
                                    value={ item['date'] || '' }
                                  />
                                </div>
                              </React.Fragment>
                            )
                          }
                          {
                            TRANSFER == item['payment_method_id'] && (
                              <React.Fragment>
                                <div className="col-md-4">
                                  <Select
                                    color="gray"
                                    name="bank_account_id"
                                    onChange={ (e: any) => this.change('bank_account_id',e.target.value,index) }
                                    label="Cuenta de Banco"
                                    value={ item['bank_account_id'] || '' }
                                    options={ this.state.bank_accounts.map((i: any) => {
                                      return {
                                        value: i.id,
                                        label: i.name
                                      }
                                    }) } />
                                </div>
                                <div className="col-md-4">
                                  <DatePicker
                                    color="gray"
                                    label="Fecha de Transferencia"
                                    showYearDropdown={ true }
                                    maxDate={ moment().toDate() }
                                    onChange={ (value: string) => this.change('date',value,index) }
                                    value={ item['date'] || '' }
                                  />
                                </div>
                                <div className="col-md-4">
                                  <PaperClip
                                    name="file"
                                    formats={ ImagesAndDocuments }
                                    value={ item['file'] }
                                    className={ item['file'] && 'active' }
                                    successText="Comprobante Cargado"
                                    pendingText="Subir Comprobante"
                                    onChange={ (e: any) => this.change('file',e.target.value,index) } />
                                </div>
                              </React.Fragment>
                            )
                          }
                        </div>

                        <div className="text-right">
                            <div className="container-white">
                              <div className="footer-container-white">
                                {
                                  item.fines.map((i: any,index: number) => (
                                    <p key={ index }>{ i.type?.name ?? '' }: <TooltipCurrency title={ i.total }>{ Globals.formatMiles(Currency.Convert(i.total,this.props.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>
                                  ))
                                }
                                <p>Total: <TooltipCurrency title={ item.quotas + fines }>{ Globals.formatMiles(Currency.Convert(item.quotas + fines,this.props.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>
                              </div>
                            </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="text-right">
                                <Button type="button" className="btn-total" onClick={ () => this.finish(index) }>
                                  Totalizar
                                </Button>
                             </div>
                          </div>
                        </div>
                     </React.Fragment>
                   )
                })
              }

              {
                agreements.length == 0 && (
                  <p className="text-center">No hay cuotas de convenio por pagar</p>
                )
              }
           </div>
        )
    }
}

export default RegisterAgreement;