import React from 'react';
import { RootState } from 'reducers';
import { Table, SelectSearch, InputCurrency, Button, Tooltip, Select, Input, DatePicker, TooltipCurrency, PaperClip } from 'components';
import { CashService } from 'services';
import { CloseIcon, CheckWhite } from 'assets/icons';
import { Currency, Globals, Constants, SocketEvents } from 'utils';
import moment from 'moment';
import RegisterAgreement from './register-agreement';
import { ImagesAndDocuments } from 'components/paperclip';
import { on } from 'jetemit';

interface Props {
  user: any,
  load: any
}

const TABS = {
  BILLS: 1,
  AGREEMENTS: 2
}

const INITIAL_STATE = {
  client_id: '',
  number: '',
  payment_method_id: '',
  bank_id: '',
  bank_account_id: '',
  date: '',
  property_id: '',
  file: '',
  amount_paid: ''
}

class RegisterPayment extends React.Component<Props> {  

  state = {
    form: INITIAL_STATE,
    client: null,
    header: [
      'Descripción',
      'Total'
    ],
    payments: [],
    conversion_petro: 0,
    iva: 0,
    payment_methods: [],
    methods: {},
    bank_accounts: [],
    banks: [],
    tab: TABS.BILLS,
    credit: 0,
    unsubscribe: () => {}
  }

  componentDidMount() {
    this.load();

    this.setState({
      subscribe: on(SocketEvents.PETRO.CHANGE,() => {
        this.updatePetro();
      })
    });
  }

  componentWillUnmount() {
    this.state.unsubscribe();
  }

  updatePetro = async () => {
    const res: any = await CashService.petro({
      withoutLoading: true
    });
    this.setState({
      conversion_petro: res.conversion_petro?.amount || 0
    });
  }

  load = async () => {
    const res: any = await CashService.getPaymentsPay({
      client_id: this.props.user.id,
      property_id: this.state.form.property_id
    });
    this.setState({
      form: {
        ...this.state.form,
        client_id: res.client.id
      },
      client: {
        id: res.client.id,
        full_name: res.client.full_name,
        properties: res.client.properties
      },
      payment_methods: res.payment_methods,
      banks: res.banks,
      bank_accounts: res.bank_accounts,
      payments: res.payments,
      conversion_petro: res.conversion_petro?.amount || 0,
      iva: res.iva?.amount || 0,
      methods: {},
      credit: res.credit
    });
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  finish = (total: number) => {
    let months: any = [];
    const payments: any = this.state.payments;

    Object.keys(payments).map((key: string,index: number) => {
      Object.keys(payments[key]).map((_key: string,index: number) => {
        if (payments[key][_key].checked) {
          months.push(key);
        }        
      });
    });

    if (total > 0) {
      let message: string | boolean = false;
      const { TRANSFER, CASH, DEBIT, CREDIT, DEPOSIT } = Constants.PAYMENT_METHODS;

      if (!this.state.form.payment_method_id) {
        message = "Debe ingresar el método de pago";
      }

      if (!this.state.form.amount_paid) {
        message = "Debe ingresar el monto pagado";
      }

      if (parseFloat(this.state.form.amount_paid) < Currency.Convert(total,this.state.conversion_petro)) {
        message = "El monto pagado no puede ser menor al total";
      }

      if (parseInt(this.state.form.payment_method_id) == TRANSFER) {
        if (!this.state.form.number || !this.state.form.date || !this.state.form.bank_id || !this.state.form.bank_account_id || !this.state.form.file) {
          message = "Debe ingresar todos los datos de pago";
        }
      } 
      else if ([TRANSFER,CREDIT,DEBIT].indexOf(parseInt(this.state.form.payment_method_id)) != -1) {
        if (!this.state.form.number || !this.state.form.bank_id) {
          message = "Debe ingresar todos los datos de pago";
        }
      }
      else if ([DEPOSIT].indexOf(parseInt(this.state.form.payment_method_id)) != -1) {
        if (!this.state.form.number || !this.state.form.date) {
          message = "Debe ingresar todos los datos de pago";
        }
      }

      if (message) {
        Globals.showError(message);
        return false;
      }
    }

    const client: any = this.state.client;
    Globals.confirm('¿Desea totalizar la factura?',async () => {
      await CashService.finish({
        ...this.state.form,
        client_id: client?.id,
        date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
        months,
        amount_paid: this.state.form.amount_paid,
        hasFile: true
      });
      Globals.showSuccess("Se ha generado el recibo correspondiente");
      this.load();
      this.setState({
        form: INITIAL_STATE
      });
      this.props.load();
    });
  }

  changeMethod = (e: any,key: string,_key: string, value: string) => {
    let methods: any = {...this.state.methods};
    if (!methods[key]) {
      methods[key] = {};
    }
    if (!methods[key][_key]) {
      methods[key][_key] = {};
    }
    methods[key][_key][value] = e.target.value;
    this.setState({
      methods
    });
  }

  setTab = (tab: number) => {
    this.setState({
      tab
    });
  }

  calculateAmountPaid = (payments: any) => {
    let subtotal = 0;
    let fines = 0;
    let iva = 0;
    let tax_free = 0;
    let tax_base = 0;

    Object.keys(payments).map((key: string,index: number) => {
      Object.keys(payments[key]).map((_key: string,index: number) => {
        if (payments[key][_key].checked) {
          subtotal += payments[key][_key].map((i: any) => i.total).reduce((a: number, b: number) => a + b,0);
          fines += payments[key][_key].map((i: any) => i.fines.map((i: any) => {
            return i.total;
          }).reduce((a: number, b: number) => a + b,0)).reduce((a: number, b: number) => a + b,0);
          iva += payments[key][_key].map((i: any) => {
           if (i.property?.economic_activity?.tax_free == Constants.TAX_FREE.NO) {
             tax_base += i.total;
             return (i.total * this.state.iva) / 100;
           } 
           else {
             tax_free += i.total;
             return 0;
           }                     
          }).reduce((a: number, b: number) => a + b,0);
        }        
      });
    });

    let total = subtotal + iva + fines - this.state.credit;
    if (total < 0) {
      total = 0;
    }

    this.setState({
      form: {
        ...this.state.form,
        amount_paid: Currency.Convert(total,this.state.conversion_petro)
      }
    });
  }
  
  render() {
    const client: any = this.state.client;
    const { tab } = this.state;
    const payments: any = this.state.payments;
    const methods: any = this.state.methods;
    const { TRANSFER, CASH, DEBIT, CREDIT, DEPOSIT } = Constants.PAYMENT_METHODS;
    const property: any = client?.properties.find((i: any) => i.id == this.state.form.property_id);

    let subtotal = 0;
    let fines = 0;
    let iva = 0;
    let cant = 0;
    let tax_free = 0;
    let tax_base = 0;
    let fines_list: any = [];

    Object.keys(payments).map((key: string,index: number) => {
      Object.keys(payments[key]).map((_key: string,index: number) => {
        if (payments[key][_key].checked) {
          subtotal += payments[key][_key].map((i: any) => i.total).reduce((a: number, b: number) => a + b,0);
          fines += payments[key][_key].map((i: any) => i.fines.map((i: any) => {
            const index = fines_list.findIndex((_i: any) => i.type?.id == _i.id);
            if (index == -1) {
              fines_list.push({
                id: i.type?.id,
                name: i.type?.name,
                total: i.total
              });
            }
            else {
              fines_list[index].total += i.total;
            }
            return i.total;
          }).reduce((a: number, b: number) => a + b,0)).reduce((a: number, b: number) => a + b,0);
          iva += payments[key][_key].map((i: any) => {
           if (i.property?.economic_activity?.tax_free == Constants.TAX_FREE.NO) {
             tax_base += i.total;
             return (i.total * this.state.iva) / 100;
           } 
           else {
             tax_free += i.total;
             return 0;
           }                    
          }).reduce((a: number, b: number) => a + b,0);
          cant++;
        }        
      });
    });

    let total = subtotal + iva + fines - this.state.credit;
    if (total < 0) {
      total = 0;
    }

    return (
        <div id="admin-cash">
          <div style={ { marginTop: '20px' } }>
           {
             client && (
               <Select
                  color="gray"
                  name="property_id"
                  onChange={ (e: any) => this.change(e,() => this.load()) }
                  label="Propiedad"
                  value={ this.state.form.property_id }
                  options={ client?.properties.map((i: any) => {
                    return {
                      value: i.id,
                      label: i.urbaser_code + ' - ' + i.type?.name
                    }
                  }) } /> 
             )
           }
         </div>

          {
            this.state.form.property_id && (
              <div className="container-tabs">
                <div className={ `tab ${ tab == TABS.BILLS ? 'active' : '' }` } onClick={ () => this.setTab(TABS.BILLS) }>
                  <p>Facturas</p>
                </div>
                {
                  Object.keys(payments).length == 0 && (
                    <div className={ `tab ${ tab == TABS.AGREEMENTS ? 'active' : '' }` } onClick={ () => this.setTab(TABS.AGREEMENTS) }>
                      <p>Convenios</p>
                    </div>
                  )
                } 
              </div>
            )
          }

          {
            tab == TABS.BILLS && client && (
              <React.Fragment>
                 {
                     property && (
                       <React.Fragment>
                         {
                           Object.keys(payments).map((key: string,index: number) => {
                             return (
                               <React.Fragment>
                                 {
                                   Object.keys(payments[key]).map((_key: string,_index: number) => {
                                     return (
                                       <React.Fragment>
                                          <div className="row">
                                            <div className="col-md-10">
                                              <Table key={ _index } title="" data={ payments[key][_key].length } header={ this.state.header }>
                                                {
                                                   payments[key][_key].map((item: any,index: number) => (
                                                       <tr key={ index }>
                                                         <td className="capitalize">{ item.service_type?.name + ` (${ moment(key,'MM-YYYY').format('MMMM YYYY') })` }</td>
                                                         <td><TooltipCurrency title={ item.total }>{ Globals.formatMiles(Currency.Convert(item.total,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></td>
                                                       </tr>
                                                    ))
                                                }
                                              </Table>
                                            </div>
                                            <div className="col-md-2">
                                                {
                                                  (index == 0 || payments[Object.keys(payments)[index - 1]][_key]['checked']) && (
                                                    <div onClick={ () => {
                                                      let payments: any = {...this.state.payments};
                                                      if (!payments[key][_key]['checked'])
                                                        payments[key][_key]['checked'] = true;
                                                      else {
                                                        payments[key][_key]['checked'] = false;
                                                        Object.keys(payments).forEach((item: any,_index: number) => {
                                                          if (_index > index) {
                                                             payments[Object.keys(payments)[_index]][_key]['checked'] = false;
                                                           }                                                           
                                                        });
                                                      }
                                                      this.setState({
                                                        payments
                                                      },() => this.calculateAmountPaid(payments));
                                                    } } className={ `item-check ${ payments[key][_key]['checked'] && 'active' }` }>
                                                      <img src={ CheckWhite } />
                                                    </div>
                                                  )
                                                }                                                  
                                            </div>
                                          </div>
                                       </React.Fragment>
                                   )})
                                 }
                               </React.Fragment>
                             )
                          })
                        }

                        {
                          Object.keys(payments).length > 0 && (
                            <React.Fragment>
                              {
                                total > 0 && (
                                  <div className="row" style={ { marginTop: '20px' } }>
                                    <div className="col-md-4">
                                      <Select
                                        color="gray"
                                        name="payment_method_id"
                                        onChange={ this.change }
                                        label="Método de Pago"
                                        value={ this.state.form.payment_method_id }
                                        options={ this.state.payment_methods.map((i: any) => {
                                          return {
                                            value: i.id,
                                            label: i.name
                                          }
                                        }) } />
                                    </div>
                                    <div className="col-md-4">
                                        {
                                          (total) > 0 && (
                                            <InputCurrency
                                              color="gray"
                                              value={ this.state.form.amount_paid }
                                              name="amount_paid" 
                                              label="Monto Pagado"
                                              onChange={ this.change } />
                                          )
                                        }
                                        {
                                            (total) > 0 && (parseFloat(this.state.form.amount_paid) > Currency.Convert(total,this.state.conversion_petro)) && (
                                              <p className="warning-credit-note">Se creara una nota de crédito con el monto restante</p>
                                            )
                                          } 
                                    </div>
                                    {
                                      [TRANSFER,CREDIT,DEBIT].indexOf(parseInt(this.state.form.payment_method_id)) != -1 && (
                                        <React.Fragment>
                                          <div className="col-md-4">
                                            <Select
                                              color="gray"
                                              name="bank_id"
                                              onChange={ this.change }
                                              label="Banco"
                                              value={ this.state.form.bank_id }
                                              options={ this.state.banks.map((i: any) => {
                                                return {
                                                  value: i.id,
                                                  label: i.name
                                                }
                                              }) } />
                                          </div>
                                          <div className="col-md-4">
                                            <Input
                                              type="number"
                                              color="gray"
                                              value={ this.state.form.number }
                                              name="number" 
                                              label="Nro. de Referencia"
                                              helpText={ this.state.form.number?.toString().length !== 8 ? "Escriba los últimos 8 dígitos" : "" }
                                              onChange={ this.change } />
                                          </div>
                                        </React.Fragment>
                                      )
                                    }
                                    {
                                      [DEPOSIT].indexOf(parseInt(this.state.form.payment_method_id)) != -1 && (
                                        <React.Fragment>
                                          <div className="col-md-4">
                                            <Input
                                              type="number"
                                              color="gray"
                                              value={ this.state.form.number }
                                              name="number"
                                              label="Nro. de Referencia"
                                              helpText={ this.state.form.number?.toString().length !== 8 ? "Escriba los últimos 8 dígitos" : "" }
                                              onChange={ this.change } />
                                          </div>
                                          <div className="col-md-4">
                                            <DatePicker
                                              color="gray"
                                              label="Fecha de Pago"
                                              showYearDropdown={ true }
                                              maxDate={ moment().toDate() }
                                              onChange={ (value: string) => this.change({
                                                target: {
                                                  value,
                                                  name: 'date'
                                                }
                                              }) }
                                              value={ this.state.form.date }
                                            />
                                          </div>
                                        </React.Fragment>
                                      )
                                    }
                                    {
                                      TRANSFER == parseInt(this.state.form.payment_method_id) && (
                                        <React.Fragment>
                                          <div className="col-md-4">
                                            <Select
                                              color="gray"
                                              name="bank_account_id"
                                              onChange={ this.change }
                                              label="Cuenta de Banco"
                                              value={ this.state.form.bank_account_id }
                                              options={ this.state.bank_accounts.map((i: any) => {
                                                return {
                                                  value: i.id,
                                                  label: i.name
                                                }
                                              }) } />
                                          </div>
                                          <div className="col-md-4">
                                            <DatePicker
                                              color="gray"
                                              label="Fecha de Transferencia"
                                              showYearDropdown={ true }
                                              maxDate={ moment().toDate() }
                                              onChange={ (value: string) => this.change({
                                                target: {
                                                  value,
                                                  name: 'date'
                                                }
                                              }) }
                                              value={ this.state.form.date }
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <PaperClip
                                              name="file"
                                              formats={ ImagesAndDocuments }
                                              value={ this.state.form.file }
                                              className={ this.state.form.file && 'active' }
                                              successText="Comprobante Cargado"
                                              pendingText="Subir Comprobante"
                                              onChange={ this.change } />
                                          </div>
                                        </React.Fragment>
                                      )
                                    }
                                  </div>
                                )
                              }
                              
                              {
                                cant > 0 && (
                                  <React.Fragment>
                                    <div className="text-right">
                                        <div className="container-white">
                                           <p>Subtotal: <TooltipCurrency title={ subtotal }>{ Globals.formatMiles(Currency.Convert(subtotal,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>
                                            {
                                              fines_list.map((item: any,index: number) => (
                                                <p key={ index }>{ item.name }: <TooltipCurrency title={ item.total }>{ Globals.formatMiles(Currency.Convert(item.total,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>
                                              ))
                                            }
                                            {
                                              this.state.credit > 0 && (
                                                <p>Nota de Crédito: <TooltipCurrency title={ this.state.credit }>{ Globals.formatMiles(Currency.Convert(this.state.credit,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>
                                              )
                                            }   
                                            <p>Exento: <TooltipCurrency title={ tax_free }>{ Globals.formatMiles(Currency.Convert(tax_free,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>     
                                            <p>Base Imponible: <TooltipCurrency title={ tax_base }>{ Globals.formatMiles(Currency.Convert(tax_base,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>     
                                            <p>IVA ({ this.state.iva }%): <TooltipCurrency title={ iva }>{ Globals.formatMiles(Currency.Convert(iva,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>                     
                                            <div className="footer-container-white">
                                              <p>Total: <TooltipCurrency title={ total }>{ Globals.formatMiles(Currency.Convert(total,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></p>                            
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="text-right">
                                            <Button type="button" className="btn-total" onClick={ () => this.finish(total) }>
                                              Totalizar
                                            </Button>
                                         </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )
                              }
                            </React.Fragment>
                          )
                        }

                        {
                          Object.keys(payments).length == 0 && (
                            <p className="text-center">No hay facturas por pagar</p>
                          )
                        }
                       </React.Fragment>
                     )
                   }
              </React.Fragment>
            )
          }

          {
            tab == TABS.AGREEMENTS && (
              <RegisterAgreement 
                conversion_petro={ this.state.conversion_petro }
                load={ this.props.load } 
                property={ property } 
                user={ this.props.user } />
            )
          }
        </div>        
    )
  }
}

export default RegisterPayment;