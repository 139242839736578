import React from 'react'
import Select from 'react-select';
import { Search } from 'assets/icons';

interface Options {
	value: string | number,
	label: string
}

interface Props {
	options: Options[],
	label?: string,
	color?: string,
	placeholder?: string,
	onChange: (e: any) => void,
	value: any,
	searchRight?: boolean,
	onInputDebounce?: (e: any) => void,
	onEnter?: (e: any) => void,
}

const customStyles = {
  option: (provided: any, state: any) => ({
  	...provided,
    fontSize: 12
  })  
}

function debounce<T>(func: (value: T) => void, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (value: T) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(value), delay);
  };
}

const SelectSearch = (props: Props) => {
	const onInputChange = debounce((value) => {
		props.onInputDebounce && props.onInputDebounce(value);
	}, 1000);

	return (
  <div className={ `form-group container-select-search ${ props.searchRight && 'search-right' }` }>
		{ props.label && <label>{ props.label }</label> }
		<Select 
			{ ...props }
			noOptionsMessage={ () => "No hay resultados" }
			styles={ customStyles }
			className={ `form-control ${ props.color ? props.color : '' }` }
			placeholder={ props.placeholder || '' }
			options={ props.options }
			onInputChange={onInputChange}
			onKeyDown={ (e: any) => {
				if (e.key === 'Enter') {
					props.onEnter && props.onEnter(e.target.value);
				}
			} } />
		{
			props.searchRight && (
				<img className="image-search-input" src={ Search } />
			)
		}
  </div>
)}

export default SelectSearch;