import React from 'react';
import { AuthService } from 'services';
import { Globals } from 'utils';
import { Input, Button, Select } from 'components';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { BgLogin, Logo, LogoLogin, NewLogo, NewLogoBlack } from 'assets/img';

interface Props extends RouteComponentProps {
	onClose: () => void,
	dispatch: any
}

class Recover extends React.Component<Props> {

	state = {
		document: '',
		document_type_id: '',
		document_types: [],
		email: '',
		account: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Recuperación de Contraseña',
				onBack: () => {
					this.props.history.goBack();
				}
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await AuthService.phoneCodes();
		this.setState({
			document_types: res.document_types,
			account: window.location.hash.includes('account=true')
		});
	}

	send = async (e: any) => {
		e.preventDefault();
		const form: any = {
			email: this.state.email,
			// document_type_id: this.state.document_type_id
		}
		if (this.state.account) {
			form.account = true
		}

		await AuthService.recover(form);
		Globals.showSuccess("Se le ha enviado un correo electrónico");
		this.props.history.goBack();
	}
			
	render() {
		return (
			<div id="recover">					
				<div className="row container-form">
					<form className="col-md-6" onSubmit={ this.send }>
						<div className="container-logo">
							<img src={ NewLogoBlack } />

						</div>
						<div className="container-input">
							<p>Ingrese su email para iniciar la recuperación de su{' '}{ this.state.account ? 'cuenta' : 'contraseña' }</p>
							<div className="form-group col-md-12">
								<Input
										type="text" 
										name="email"
										placeholder="Ingrese su email"
										onChange={ (e: any) => {
											this.setState({
												email: e.target.value
											});
										} } />
							</div>
							
{/* 										
							<div className="i-flex">
								 <div className="doc-flex">
								  <Select
									color="gray"
									name="document_type_id"
									onChange={ (e: any) => {
										this.setState({
											document_type_id: e.target.value
										});
									} }
									value={ this.state.document_type_id }
									placeholder="Tipo"
									options={ this.state.document_types.map((i: any) => {
										return {
											value: i.id,
											label: i.name
										}
									}) } />
								</div>
								<div className="doc-email">
									
								</div>
							</div>							 */}
							<div className="text-center">
								<Button type="submit" disabled={ this.state.email == '' }>
									Enviar
								</Button>
							</div>
							<div className="recover">
								<Link to="/login">
									Regresar
								</Link>
							</div>
						</div>						
					</form>
					<div className="col-md-6 container-bg" style={ {
						backgroundImage: `url(${ BgLogin })`
					} }></div>
				</div>
			</div>
		)
	}
}

export default connect()(Recover);