import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, SelectSearch, Pagination, Input, Select, Button, Tooltip, DatePicker, Modal, TooltipCurrency } from 'components';
import { checkPermission, Permissions, Constants, Socket, SocketEvents, Globals, Print, Currency } from 'utils';
import { ReportService } from 'services';
import { CashService } from 'services';
import { EditIcon, ViewIcon, TrashIcon, CheckIcon, CloseIcon, PrintIcon } from 'assets/icons';
import moment from 'moment';

interface LocationProps {
  page: number | undefined;
  form: any
}

interface Props extends RouteComponentProps<{},{},LocationProps>, RootState {
  dispatch: any
}

const INITIAL_STATE = {
  search: '',
  since: '',
  until: '',
  client_id: '',
  type: '',
  status: '',
  report: 'sales_book'
}

const { PENDING, ACCEPTED, REJECTED, CANCELLED, TO_PAY } = Constants.BILL_STATUS;

const STATUS: any = [
  { value: PENDING, label: 'Por conciliar' },
  { value: ACCEPTED, label: 'Conciliado' },
  { value: REJECTED, label: 'Reversado' },
  { value: CANCELLED, label: 'Anulado' },
  { value: TO_PAY, label: 'Por pagar' },
]

class SalesBook extends React.Component<Props> {  

  timer: any = undefined;

  state = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'Fecha',
      'N° RIF',
      'Proveedor',
      'Fact',
      'Control',
      'Tipo Doc',
      'Total Ventas',
      'Ventas Internas No Grabadas',
      'Aseo',
      'Disposición',
      'Base Imponible Final',
      '% Alícuota',
      'IVA',
      'Retención',
    ],
    form: INITIAL_STATE,
    visible: false,
    client: null,
    conversion_petro: 0,
    form_search: '',
    total: 0,
    total_petro: 0,
    base_imponible: [],
  }

  async componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Libro de Ventas',
        onBack: null
      }
    });

    await this.setState({
      page: this.props.location.state?.page || this.state.page,
      form: this.props.location.state?.form || this.state.form
    });

    // this.load();
  }



  load = async (withoutLoading: boolean = false) => {
    const client: any = this.state.client;
    const res: any = await ReportService.admin.post({
      page: this.state.page,
      withoutLoading,
      user_id: this.props.user.id,
      ...this.state.form,
      since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
      until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
    });

    this.setState({
      data: res.bills.data,
      last_page: res.bills.last_page,
      conversion_petro: res.conversion_petro || 0,
      total_petro: res.bills_petro,
      total: Globals.formatMiles(res.bills_total,true,Currency.BOLIVAR) ,
      base_imponible: res.base_imponible || 0
    });


  }

  edit = (e: any, url: string) => {
    e.preventDefault();
    this.props.history.push({
      pathname: url,
      state: this.state
    });
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

  view = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      client: item
    });
  }



  onClose = () => {
    this.setState({
      visible: false
    });
  }


  
  render() {
    const client: any = this.state.client;
    const { visible } = this.state;

    return (
        <div id="reports">
            <div className="row">

              <div className="col-4">
                <DatePicker
                  color="white"
                  label="Desde"
                  value={this.state.form.since }
                  maxDate={ moment().toDate() }
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'since', value }
                    
                  },() => this.load(true))}
                />
              </div>

              <div className="col-4">
                <DatePicker
                  color="white"
                  label="Hasta" 
                  value={this.state.form.until}
                  maxDate={ moment().toDate() }
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'until', value }
                  },() => this.load(true))}
                />
              </div>

              <div className="col-4">
                <Select
                  label="Estatus Factura"
                  name="status"
                  onChange={ (e: any) => this.change(e,() => {
                    this.load(true);
                  }) }
                  placeholder="Estatus Factura"
                  value={ this.state.form.status }
                  options={ STATUS } />
              </div>

              <div className="col-2">
              </div>

            </div>
            <div className="row">
              <div className="col-3">
                <Button className="btn-blue-create btn-filter" onClick={() => this.load(true)}>
                  Cargar
                </Button>

                {
                  this.state.data.length > 0 && (
                    <div>
                      <a href={ Constants.BASE_API + 'admin/reports/sales-book/excel?' + Globals.getParams({
                        ...this.state.form,
                        user_id: this.props.user.id
                      }) }>
                        <Button className="btn-blue-create">
                          Exportar Excel
                        </Button>
                      </a>
                    </div>
                  )
                }
              </div>
              <div className="col-3">
                {/*
                  this.state.data.length > 0 && (
                    <div>
                      <a href={ Constants.BASE_API + 'admin/reports/sales-book/pdf?' + GlobalsgetParams({
                        ...this.state.form,
                        user_id: this.props.user.id
                      }) }>
                        <Button className="btn-blue-create">
                          Exportar PDF
                        </Button>
                      </a>
                    </div>
                  )
                */}
              </div>
              <div className="col-6">
                <Input
                  color="white"
                  searchRight
                  value={ this.state.form.search }
                  name="search"
                  placeholder="Buscar (Control, N° RIF)"
                  onChange={ (e: any) => {
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                      this.load(true);
                    },1000);
                    this.change(e); 
                  } } />
              </div>
            </div>


              {
                this.state.form != INITIAL_STATE && (
                  <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
                )
              }  

              <Table data={ this.state.data.length } title="" header={ this.state.header }>
                { this.state.data.map((report: any,index: number) => {
                  const sum_clean = report.payments.filter((i: any) => i.service_type_id == Constants.SERVICE_TYPES.CLEAN).map((i: any) => Currency.ConvertAux(i.total,i.conversion_petro)).reduce((a: number, b:number) => a + b, 0);
                  const sum_rds = report.payments.filter((i: any) => i.service_type_id == Constants.SERVICE_TYPES.RDS).map((i: any) => Currency.ConvertAux(i.total,i.conversion_petro)).reduce((a: number, b:number) => a + b, 0);
                  const sum_clean_petro = report.payments.filter((i: any) => i.service_type_id == Constants.SERVICE_TYPES.CLEAN).map((i: any) => i.total).reduce((a: number, b:number) => a + b, 0);
                  const sum_rds_petro = report.payments.filter((i: any) => i.service_type_id == Constants.SERVICE_TYPES.RDS).map((i: any) => i.total).reduce((a: number, b:number) => a + b, 0);

                  return (
                    <tr key={ index }>
                      <td className="no-wrap">{ moment(report.created_at).format('DD/MM/YYYY') }</td>
                      <td className="no-wrap">{ report.user?.full_document }</td>
                      <td>{ report.user?.name }</td>
                      <td>{ report.status_text }</td>
                      <td>{ report.number_format }</td>
                      <td>{ report.number_format }</td>
                      <td className="text-right no-wrap">
                        <TooltipCurrency title={ report.total }>{ Globals.formatMiles(Currency.ConvertAux(report.total,report.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency>
                      </td>
                      <td className="text-right no-wrap">
                        <TooltipCurrency title={ report.tax_free }>{ Globals.formatMiles(Currency.ConvertAux(report.tax_free,report.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency>
                      </td>
                      <td className="text-right no-wrap">
                        <TooltipCurrency title={ sum_clean_petro }>{ Globals.formatMiles(sum_clean,true,Currency.BOLIVAR) }</TooltipCurrency>
                      </td>
                      <td className="text-right no-wrap">
                         <TooltipCurrency title={ sum_rds_petro }>{ Globals.formatMiles(sum_rds,true,Currency.BOLIVAR) }</TooltipCurrency>
                      </td>
                      <td className="text-right no-wrap">
                        <TooltipCurrency title={ report.tax_base }>{ Globals.formatMiles(Currency.ConvertAux(report.tax_base,report.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency>
                      </td>
                      <td className="text-right no-wrap">
                        { Globals.formatMiles(report.iva_percentage,true,'%') }
                      </td>
                      <td className="text-right no-wrap">
                        <TooltipCurrency title={ report.iva }>{ Globals.formatMiles(Currency.ConvertAux(report.iva,report.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency>
                      </td>
                      <td className="text-right no-wrap">
                        <TooltipCurrency title={ report.retention }>{ Globals.formatMiles(Currency.ConvertAux(report.retention,report.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency>
                      </td>
                    </tr>
                  )
                }) }
                <tr className="box-mti">
                  <td colSpan={15}>
                    <h6>Montos Totales del Libro de Ventas: <TooltipCurrency title={ this.state.total_petro }>{ this.state.total }</TooltipCurrency></h6>
                  </td>
                </tr>
              </Table>


              <div className="row">
                <Pagination 
                  pages={ this.state.last_page } 
                  active={ this.state.page }
                  onChange={ async (page: number) => {
                    await this.setState({
                      page: page
                    });
                    this.load();
                } } />
              </div>
        </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(SalesBook);