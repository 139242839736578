import { Api, Constants } from 'utils';

class ReportService {
	
	static admin = {
		post: async (params?: any) => Api.createResponse('admin/reports/get',params),

		bank_reconciliation: async (params?: any) => Api.createResponse('admin/reports/get-bank-reconciliation',params),
		filter: async (params?: any) => Api.createResponse('admin/reports/filter',params),

		voided_invoices: async (params?: any) => Api.createResponse('admin/reports/voided-invoices',params),

		overdue_collection: async (params?: any) => Api.createResponse('admin/reports/overdue_collection',params),

		audit_report: async (params?: any) => Api.createResponse('admin/reports/audit-report',params),
	}
}

export default ReportService;