import { Api } from 'utils';

class ProfileService {
	
	static update = async (params?: any) => Api.createResponse('profile/update',params);
	static updateClient = async (params?: any) => Api.createResponse('profile/update/client',params);
	static password = async (params?: any) => Api.createResponse('profile/password',params);
	static get = async (params?: any) => Api.createResponse('profile/get',params);
}

export default ProfileService;