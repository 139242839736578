import React, { useState } from 'react';
import { Search, ViewIcon } from 'assets/icons';

interface Props {
	label?: string,
	name: string,
	type?: string 
	color?: string,
	placeholder?: string,
	onChange: any,
	value?: string | number
	helpText?: string,
	helpTextClass?: string,
	searchRight?: boolean,
	searchIcon?: string,
}

const onChange = (event: any,_onChange: (event: any) => void,type: string = 'text') => {
	if (type == 'number') {
		const regex = /^[0-9\b]+$/;
		if (regex.test(event.target.value) || event.target.value == '') {
			_onChange(event);
		}
	}
	else if (type == 'decimal') {
		const regex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
		if (regex.test(event.target.value) || event.target.value == '') {
			_onChange(event);
		}
	}
	else {
		_onChange(event);
	}
}

const Input	= React.forwardRef((props: any, ref) => {
	const [showPassword, setShowPassword] = useState(false)

	return (
		<div className="form-group form-group-input" style={props.containerStyle}>
			{ props.label && <label htmlFor={ props.name }>{ props.label } {props.required && <span className="text-danger">(*)</span>}</label> }
			<input
				ref={ ref }
				{ ...props }
				onChange={ (e) => onChange(e,props.onChange,props.type) }
				type={ props.type == 'number' ? 'text' : props.type === 'password' ? showPassword ? 'text' : props.type : props.type }
				className={ `form-control ${ props.searchRight ? 'search-right' : '' } ${ props.color ? 'input-'+props.color : 'input-white' }` }
				name={ props.name }
				required={ props.required }
				autocomplete={'off'}
			/>
			{
				props.searchRight && !props.searchIcon ? (
					<img className="image-search-input" src={ Search } />
				) : props.searchRight && props.searchIcon && (
					<img className="image-search-input" src={ props.searchIcon } />
					)
				}
			{ props.type === 'password' &&
				<img className="image-search-input" style={{ cursor: 'pointer', top: props.label ? '36px' : '11px' }} src={ ViewIcon } onClick={() => setShowPassword(!showPassword)} />
			}
			{ props.helpText && (
				<span className={`help-block small font-weight-normal text-muted ${ props.helpTextClass ?? '' }`}>
					{props.helpText}
				</span>
			)}
		</div>
	)
})

export default Input;