import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Constants } from '../utils';
import Menu from 'screens/menu';
import { RootState } from 'reducers';

const _Route = (props: any) => {
	const history = useHistory();
	const permissions = props.user?.permissions.map((i: any) => i.code);

	if (!props.user && (props.auth || props.admin || props.client)) {
		return <Redirect to="/login" />
	}
	else if (props.user && props.admin && props.user.level_id == Constants.LEVELS.CLIENT) {
		return <Redirect to="/" />
	}
	else if (props.user && props.client && (props.user.level_id != Constants.LEVELS.CLIENT && (props.user.level_id != Constants.LEVELS.MODERATOR && !props.user?.user_id))) {
		return <Redirect to="/" />
	}
	else if (props.user && props.admin && (props.user.level_id == Constants.LEVELS.MODERATOR && props.user?.user_id) && props.permission && permissions.indexOf(props.permission) == -1) {
		return <Redirect to="/welcome" />
	}
	else if (props.user && props.noAuth) {
		return <Redirect to="/" />
	}
	if (props.user && (props.auth || props.admin || props.client)) {
		return (
			<Menu history={ history }>
				<Route { ...props } />
			</Menu>
		)
	}
	else {
		return <Route { ...props } />
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(_Route);