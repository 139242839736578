const Convert = (price: number, conversion: number) => {
	return parseFloat((price * conversion).toFixed(2));
	//return price;
}
const ConvertAux = (price: number, conversion: number) => {
	return parseFloat((price * conversion).toFixed(2));
}

export default {
	Convert,
	ConvertAux,
	BOLIVAR: 'Bs.',
	DOLAR: '$',
	PETRO: 'UCD'
}