import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { SuggestionsService } from 'services';
import moment from 'moment';
import { TrashIcon } from 'assets/icons';
import { Constants, SocketEvents, Socket, Globals } from 'utils';
import { Pagination } from 'components';
import { on } from 'jetemit';
import { time } from 'console';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

class ComplaintsSuggestions extends React.Component<Props> {  

  state = {
    page: 1,
    last_page: 1, 
    data: [],
    unsubscribe: () => {},
    unsubscribeDelete: () => {},
    unsubscribeCreate: () => {},
    active: null,
    unsubscribeStatus: () => {}
  }

  async componentDidMount() {
  	this.props.dispatch({
  		type: 'SET_HEADER',
  		payload: {
  			title: 'Dudas y Sugerencias',
  			onBack: null
  		}
  	});
    
    await this.setState({
      unsubscribe: on(SocketEvents.MESSAGES.SEND,() => {
        this.load(true);
      }),
      unsubscribeDelete: on(SocketEvents.MESSAGES.DELETE,() => {
        this.load(true);
      }),
      unsubscribeCreate: on(SocketEvents.MESSAGES.CREATE,() => {
        this.load(true);
      }),
      unsubscribeStatus: on(SocketEvents.MESSAGES.STATUS,(data: any) => {
        if (this.state.data.map((i: any) => i.id).indexOf(data.suggestion_id) != -1) {
          this.load(true);
        }
      })
    });

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await SuggestionsService.admin.get({
      page: this.state.page,
      withoutLoading
    });
    this.setState({
      data: res.suggestions.data,
      last_page: res.suggestions.last_page
    });
  }

  componentWillUnmount() {
    this.state.unsubscribe();
    this.state.unsubscribeDelete();
    this.state.unsubscribeCreate();
    this.state.unsubscribeStatus();
  }

  view = (item: any) => {
    this.props.history.push({
      pathname: '/admin/complaints-suggestions/view/' + item.id,
      state: this.state
    });
  }

  changeStatus = async (status: number,item: any) => {
    await this.setState({
      active: null
    });
    Globals.confirm('¿Desea cambiar el estatus de la solicitud?',async () => {
      await SuggestionsService.admin.changeStatus({
        suggestion_id: item.id,
        status
      });
      Globals.showSuccess("Se ha cambiado el estatus correctamente");
      this.load(true);
      /* Socket.emit(SocketEvents.MESSAGES.STATUS,{
        suggestion_id: item.id,
        status
      }); */
    })
  }

  setActive = (value: any) => {
    this.setState({
      active: value
    });
  }
  
  render() {
    const { data, active } = this.state;

    return (
        <div id="complaints-suggestions">
          <h3 className="title title-admin">Bandeja</h3>

          {
            data.map((item: any, index: number) => (
              <div className="item-suggestion" key={ index }>
                <div onClick={ () => this.view(item) } className={ `title-item ${ (item.viewed == Constants.SUGGESTIONS.NOT_VIEWED || item.messages_count > 0) ? 'active' : '' }` }>
                  <h3> Código: { item.user.username } - { item.zone } </h3>
                  <p>{ moment(item.created_at).format('DD MMM') }</p>
                </div>
                <div className="text">
                  <p onClick={ () => this.view(item) }>{ item.text }</p>
                  <p>Estatus: { item.status_text }</p>
                  {
                    item.status < Constants.SUGGESTIONS.RESOLVED && (
                      <button className="btn-popover" onBlur={ (e: any) => this.setActive(null) } onClick={ () => this.setActive(item.id) }>
                        <i className="fa fa-bars"></i>
                        {
                          active == item.id && (
                              <div className="popover-status">
                                {
                                  item.status < Constants.SUGGESTIONS.PROCESS && (
                                    <li onClick={ () => this.changeStatus(Constants.SUGGESTIONS.PROCESS,item) }>En proceso</li>
                                  )
                                }
                                {
                                  item.status < Constants.SUGGESTIONS.ATTENDED && (
                                    <li onClick={ () => this.changeStatus(Constants.SUGGESTIONS.ATTENDED,item) }>Atendido</li>
                                  )
                                }
                                {
                                  item.status < Constants.SUGGESTIONS.RESOLVED && (
                                    <li onClick={ () => this.changeStatus(Constants.SUGGESTIONS.RESOLVED,item) }>Resuelto</li>
                                  )
                                }
                              </div>
                          )
                        }
                      </button>
                    )
                  }
                </div>                
              </div>
            ))
          }

          <Pagination 
                pages={ this.state.last_page } 
                active={ this.state.page }
                onChange={ async (page: number) => {
                  await this.setState({
                    page: page
                  });
                  this.load();
              } } />
        </div>        
    )
  }
}

export default connect()(ComplaintsSuggestions);