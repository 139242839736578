import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Input, Button, Select } from 'components';
import { UserService } from 'services';
import { Globals, Constants, Socket, SocketEvents, RegisterMovement, Numeric } from 'utils'
import { RootState } from 'reducers'
import axios from 'axios'

interface Props extends RouteComponentProps<Params>, RootState {
  dispatch: any
}

interface Params {
  id: string | undefined
}

class CreateUser extends React.Component<Props> {

  state: any = {
    form: {
      name: '',
      lastname: '',
      username: '',
      email: '',
      phone: '',
      document_type_id: '',
      document: '',
      password: '',
      role: '',
      box: '',
    },
    modules: [],
    user: null,
    codes: [],
    document_types: [],
    roles: []
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Editar usuario',
        onBack: () => {
          this.props.history.push({
            pathname: '/admin/users/moderators',
            state: this.props.location.state
          });
        }
      }
    });

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const _roles: any = await UserService.admin.roles();
    const rols: any = _roles.data;
    const res: any = await UserService.admin.getUser({
      id: this.props.match.params.id
    });
    const permissions = res.user.permissions.map((i: any) => i.id);

    res.modules = res.modules.map((item: any) => {
      if (item.name === 'Usuarios') item.name = 'Administradores'
      return item;
    });

    this.setState({
      form: {
        name: res.user.name,
        email: res.user.email,
        // username: res.user.username,
        lastname: res.user.lastname,
        document: res.user.document,
        phone: Numeric.getOnlyNumber(res.user.phone),
        document_type_id: res.user.document_type_id,
        box: res.user.box,
      },
      roles: rols,
      codes: res.codes,
      document_types: res.document_types,
      user: res.user,
      modules: res.modules.map((i: any) => {
        i.actions.map((i: any) => {
          if (permissions.indexOf(i.id) != -1) {
            i.active = true;
          }
          return i;
        });
        const count = i.actions.filter((i: any) => i.active).length;
        if (count == i.actions.length) {
          i.active = true;
        }
        return i;
      })
    });
  }

  submit = async (e: any) => {
    e.preventDefault();
    const res: any = await UserService.admin.edit({
      ...this.state.form,
      id: this.props.match.params.id,
      modules: JSON.stringify(this.state.modules)
    });
    Globals.showSuccess("Se han guardado los datos correctamente");
    /* Socket.emit(SocketEvents.USERS.UPDATE,{
      id: this.props.match.params.id
    }); */
    this.props.history.push({
      pathname: '/admin/users/moderators',
      state: this.props.location.state
    });

    RegisterMovement.save({
        value_id: res.user?.id,
        user_id: this.props.user.id,
        module_id: Constants.MODULES.MODERATORS,
        description: `Edición del usuario ${ res.user?.full_name } ${ res.user?.full_document }`
    });
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  checkPermissions = (id: number, module_id: number) => {
    this.setState({
      modules: this.state.modules.map((_i: any) => {
        if (_i.id == module_id) {
          _i.actions.map((i: any) => {
            if (i.id == id) {
              i.active = i.active ? false : true;
              if (i.active && i.main == Constants.ACTIONS.SECONDARY) {
                _i.actions.map((i: any) => {
                  if (i.main == Constants.ACTIONS.MAIN) {
                    i.active = true;
                  }
                  return i;
                });
              }
            }
            return i;
          });

          const count = _i.actions.filter((i: any) => i.active).length;
          if (count == _i.actions.length) {
            _i.active = true;
          }
          else {
            _i.active = false;
          }
        }
        return _i;
      })
    });
  }

  checkModule = (id: number) => {
    this.setState({
      modules: this.state.modules.map((_i: any) => {
        if (_i.id == id) {
          const count = _i.actions.filter((i: any) => i.active).length;
          if (count == _i.actions.length) {
            _i.active = false;
          }
          else {
            _i.active = true;
          }
          _i.actions.map((i: any) => {
            i.active = _i.active;
            return i;
          });
        }
        return _i;
      })
    });
  }

  selectAll = () => {
    const all_active = this.state.modules.filter((i: any) => i.active).length == this.state.modules.length;
    this.setState({
      modules: this.state.modules.map((_i: any) => {
        if (!all_active) {
          _i.actions.map((i: any) => {
            i.active = true;
            return i;
          });

          _i.active = true;
        }
        else {
          _i.actions.map((i: any) => {
            i.active = false;
            return i;
          });

          _i.active = false;
        }
        
        return _i;
      })
    });
  }
  
  render() {
    const { modules, user } = this.state;

    return (
      <div id="admin-view-permissions">
        <div className="container-view-permissions">  
          <h3>Datos Generales</h3>
          <form className="row row-form" onSubmit={ this.submit }>
            <div className="col-lg-6">
              <Input
                color="white"
                value={ this.state.form.name }
                name="name" 
                label="Nombre"
                onChange={ this.change } />
            </div>
            <div className="col-lg-6">
              <Input
                color="white"
                value={ this.state.form.lastname }
                name="lastname" 
                label="Apellido"
                onChange={ this.change } />
            </div>
            {/* <div className="col-lg-6">
              <Input
                color="white"
                value={ this.state.form.username }
                name="username" 
                label="Cuenta"
                onChange={ this.change } />
            </div> */}
            <div className="col-lg-6">
              <p className="label">C.I. / RIF</p>
              <div className="i-flex">
                <div className="doc-flex">
                  <Select
                    className="select-phone"
                    color="gray"
                    name="document_type_id"
                    onChange={ this.change }
                    value={ this.state.form.document_type_id }
                    placeholder="Tipo"
                    options={ this.state.document_types.map((i: any) => {
                      return {
                        value: i.id,
                        label: i.name
                      }
                    }) } />
                </div>
                <div className="doc-flex">
                  <Input
                    color="white"
                    value={ this.state.form.document }
                    name="document" 
                    type="number"
                    placeholder="C.I. / RIF"
                    onChange={ this.change } />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Input
                color="white"
                value={ this.state.form.email }
                name="email" 
                label="Correo Electrónico"
                onChange={ this.change } />
            </div>
            <div className="col-lg-6">
               <Input
                type="number"
                color="white"
                value={ this.state.form.phone }
                name="phone" 
                label="Teléfono"
                onChange={ this.change } />
            </div>
            <div className="col-lg-6">
              <Input
                color="white"
                type="password"
                value={ this.state.form.password }
                name="password" 
                label="Cambiar Contraseña (Opcional)"
                onChange={ this.change } />
            </div>
            <div className="col-lg-6">
              <Input
                color="white"
                value={ this.state.form.box }
                name="box"
                label="Caja"
                onChange={ this.change } />
            </div>
            {/* <div className="col-lg-6">
            <Select
                          className="select-phone"
                          color="gray"
                          name="document_type_id"
                          label="ROL"
                          onChange={ (e: any) => {
                            this.setState({
                              form: {
                                ...this.state.form,
                                role: e.target.value
                              }
                            })
                          } }
                          value={ this.state.form.role }
                          placeholder="Seleccionar"
                          options={ this.state.roles.map((i: any) => {
                            return {
                              value: i.name,
                              label: i.name
                            }
                          }) } />
                      </div> */}
            <div className="col-12 center">
              <Button className="btn-urbaser-green" type="button" onClick={ this.submit }>
                Actualizar datos
              </Button>
            </div>      
          </form>  

          <div className="row row-permissions">
                <div className="col-12">
                  <h3 className="title-select-all">Seleccione los módulos a los que puede acceder</h3>
                </div>
                <div className="col-12 text-right container-buttons">          
              <Button className="btn-urbaser-green" onClick={ this.selectAll }>
                Seleccionar Todos
              </Button>
                </div>
            {
              modules.map((i: any,index: number) => (
                <ul className="col-md-9 offset-md-3 list-checks">
                  <li key={ index }>
                    <h2>{ i.name } - <span> (Seleccionar todos) </span></h2>

                    <div className={ `container-check ${ i.active && 'active' }` } onClick={ () => this.checkModule(i.id) }>
                      <i className="fa fa-check"></i>
                    </div>
                  </li>
                  {
                    i.actions.map((_i: any,index: number) => (
                      <li className="li-action" key={ index }>
                        <p>{ _i.name }</p>

                        <div className={ `container-check ${ _i.active && 'active' }` } onClick={ () => this.checkPermissions(_i.id,i.id) }>
                          <i className="fa fa-check"></i>
                        </div>
                      </li>
                    ))                      
                  }
                </ul>
              ))
            }
          </div>        
        </div>
      </div>
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(CreateUser);