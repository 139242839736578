import io from 'socket.io-client';

const socketURL = process.env.REACT_APP_SOCKET || '';

let Socket = io(socketURL, {
      reconnection: false,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 1,
      forceNew: false 
});

export default Socket;