import { Api, Constants } from 'utils'

class CashService {
  static getPayments = async (params?: any) => Api.createResponse('cash/payments', params)
  static getPaymentsPay = async (params?: any) => Api.createResponse('cash/payments/pay', params)
  static getAgreements = async (params?: any) => Api.createResponse('cash/agreements', params)
  static finishAgreements = async (params?: any) => Api.createResponse('cash/agreements/finish', params)
  static finish = async (params?: any) => Api.createResponse('cash/payments/finish', params)
  static complete = async (params?: any) => Api.createResponse('cash/payments/complete', { method: 'post', ...params })
  static petro = async (params?: any) => Api.createResponse('cash/payments/petro', params)

  static admin = {
    getClients: async (params?: any) => Api.createResponse('admin/cash/clients', params),
    getProperties: async (params?: any) => Api.createResponse('admin/cash/properties', params),
    getPayments: async (params?: any) => Api.createResponse('admin/cash/payments', params),
    getListPayments: async (params?: any) => Api.createResponse('admin/cash/payments/list', params),
    getAgreements: async (params?: any) => Api.createResponse('admin/cash/agreements', params),
    deletePayment: async (params?: any) => Api.createResponse('admin/cash/payments/delete', params),
    finish: async (params?: any) => Api.createResponse('admin/cash/payments/finish', params),
    finishAgreements: async (params?: any) => Api.createResponse('admin/cash/agreements/finish', params),
    changeStatus: async (params?: any) => Api.createResponse('admin/cash/payments/status', params),
    cancel: async (params?: any) => Api.createResponse('admin/cash/payments/cancel', params),
    complete: async (params?: any) => Api.createResponse('admin/cash/payments/complete', params),
    petro: async (params?: any) => Api.createResponse('admin/cash/payments/petro', params),
    print: (id?: string, user_id?: string) => Constants.BASE_API + 'admin/cash/print/' + id + '/' + user_id,
    addControlNumber: async (id: string | number, params?: any) => Api.createResponse(`admin/bills/${id}/control-number`, params),
    updateCreatedAt: async (id: string | number, params?: any) => Api.createResponse(`admin/bills/${id}/date-bills`, params),

    // Nuevos
    createPayment: async (params?: any) => Api.createResponse(`admin/bill/box/create/cash/payment`, params),
    getPaymentMethods: async (params?: any) => Api.createResponse(`admin/bill/box/payment-methods`, { method: 'get', ...params }),
    getPropertiesWithClient: async (params?: any) => Api.createResponse(`admin/bill/box/properties`, { method: 'get', ...params }),
    getPropertyId: async (id: string | number, petro_value: string, params?: any) =>
      Api.createResponse(`admin/bill/box/property/${id}${petro_value ? `/${petro_value}/with-petro` : ''}`, { method: 'get', ...params }),
    // deudad y multas
    getPropertyIdFines: async (id: string | number, params?: any) =>
      Api.createResponse(`admin/bill/box/property/${id}/payments-fines`, { method: 'get', withoutLoading: true, ...params }),
    // servicios
    getServiceTypes: async (params?: any) =>
      Api.createResponse(`admin/bill/box/service-types`, { method: 'get', withoutLoading: true, ...params }),
    createServiceTypes: async (params?: any) => Api.createResponse(`admin/bill/box/service-types`, { withoutLoading: true, ...params }),
    validateVoucher: async (params?: any) =>
      Api.createResponse(`admin/bill/box/bill-detail`, { method: 'get', withoutLoading: true, ...params }),
    deletePaymentBill: async (params?: any) =>
      Api.createResponse(`admin/cash/payments/bill/delete/${params.id}`, { method: 'delete', withoutLoading: true, ...params }),
    changeBillDelivery: async (id: string | number, params?: any) => Api.createResponse(`admin/cash/bill/${id}/delivery`, { method: 'post', ...params }),
    changePaymentStatus: async (id: string | number, status: string | number, params?: any) =>
      Api.createResponse(`admin/bill/box/status/${id}/${status}`, { method: 'post', ...params }),
  }
}

export default CashService
