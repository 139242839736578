import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Input, Select, Button, Tooltip, DatePicker, Modal, TooltipCurrency } from 'components';
import { checkPermission, Permissions, Constants, Socket, SocketEvents, Globals, Print, Currency } from 'utils';
import { ReportService } from 'services';
import { CardIcon } from 'assets/icons';
import moment from 'moment';

interface LocationProps {
  page: number | undefined;
  form: any
}

interface Props extends RouteComponentProps<{},{},LocationProps> {
  dispatch: any
}

const INITIAL_STATE = {
  search: '',
  since: moment(moment().format('YYYY-MM-01')).toDate(),
  until: moment().toDate(),
  type: '',
  status: ''
}

const { ACTIVE, INACTIVE, ACTIVE_LABEL, INACTIVE_LABEL, VERIFIED, VERIFIED_LABEL } = Constants.USERS_STATUS;

const STATUS = [
  { value: ACTIVE.toString(), label: ACTIVE_LABEL },
  { value: INACTIVE.toString(), label: INACTIVE_LABEL },
  { value: VERIFIED.toString(), label: VERIFIED_LABEL },
]

class Report extends React.Component<Props> {  

  timer: any = undefined;

  state = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'Fecha Factura',
      'Banco',
      'Referencia',
      'Descripción',
      'Monto Bs',
      'Fecha Transferencia',
      'Nro. Factura',
      'Cod. Cliente',
      'Des cliente',
      'Monto Tarifa',
      'DF',
      '% IVA',
      'Monto IVA',
      'Retención IVA',
      'Nota de Crédito',
      'Monto Cobrado',
      'Conversión',
      'Fecha Cobro Factura',
      'Forma de Pago',
    ],
    form: INITIAL_STATE,
    visible: false,
    client: null,
    conversion_petro: 0,
    form_search: '',
    totals:{
      urban_clean: 0,
      urban_clean_petro: 0,
      df: 0,
      df_petro: 0,
      subtotal: 0,
      subtotal_petro: 0,
      total: 0,
      total_petro: 0,
      iva: 0,
      iva_petro: 0,
      retention: 0,
      retention_petro: 0,
      iva_without_retention: 0,
      iva_without_retention_petro: 0,
      total_without_retention: 0,
      total_without_retention_petro: 0,
      credit_note: 0,
      credit_note_petro: 0,
      bank: 0,
      bank_petro: 0,
      payment_agreement: 0,
      payment_agreement_petro: 0
    }
  }

  async componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Conciliación Bancaria',
        onBack: null
      }
    });

    await this.setState({
      page: this.props.location.state?.page || this.state.page,
      form: this.props.location.state?.form || this.state.form,
      header: this.state.header.filter(h => {
        if (h !== 'DF') return true
        if (Constants.BASE_INSTITUTION === Constants.INSTITUTION.GUAYOS) return true
        return false
      })
    });

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await ReportService.admin.bank_reconciliation({
      page: this.state.page,
      withoutLoading,
      ...this.state.form
    });
    this.setState({
      data: res.bills.data,
      last_page: res.bills.last_page,
      conversion_petro: res.conversion_petro?.amount || 0,
      totals: res.totals
    });
  }


  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

  view = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      client: item
    });
  }


  onClose = () => {
    this.setState({
      visible: false
    });
  }
  
  render() {
    const { visible } = this.state;

    return (
        <div id="bank-reconciliation-report">
            <div className="row">

              <div className="col-md-2">
                <DatePicker
                  color="white"
                  label="Desde"
                  value={this.state.form.since}
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'since', value }
                  },() => this.load(true))}
                />
              </div>

              <div className="col-md-2">
                <DatePicker
                  color="white"
                  label="Hasta" 
                  value={this.state.form.until}
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'until', value }
                  },() => this.load(true))}
                />
              </div>


              <div className="col-md-2 mt-md-4">
                {
                  this.state.data.length > 0 && (
                    <div>
                      <a href={ Constants.BASE_API + 'admin/reports/bank-reconciliation/excel?' + Globals.getParams(this.state.form) }>
                        <Button className="btn-blue-create">
                          Exportar Excel
                        </Button>
                      </a>
                    </div>
                  )
                }
              </div>
              {/* <div className="col-md-2 mt-md-4">
                {
                  this.state.data.length > 0 && (
                    <div>
                      <a href={ Constants.BASE_API + 'admin/reports/bank-reconciliation/pdf?' + Globals.getParams(this.state.form) }>
                        <Button className="btn-blue-create">
                          Exportar PDF
                        </Button>
                      </a>
                    </div>
                  )
                }
              </div> */}
            </div>


              {
                this.state.form != INITIAL_STATE && (
                  <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
                )
              }

              {
                this.state.data.length > 0 && (
                  <div className="row">
                    <div className="col-md-8 offset-md-2">
                      <table className="table table-component">
                        <thead className="thead-normal">
                          <tr>
                            <th className="vcenter">Resumen</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="row">
                                <div className="col-md-6">
                                  <table className="table-summary">
                                    <tbody>
                                      <tr>
                                        <td className="bold width-title">Aseo</td>
                                        <td className="text-right">
                                          <TooltipCurrency title={ this.state.totals.urban_clean_petro } >
                                            { Globals.formatMiles(this.state.totals.urban_clean,true,Currency.BOLIVAR) }
                                          </TooltipCurrency>
                                        </td>
                                      </tr>
                                      { Constants.BASE_INSTITUTION === Constants.INSTITUTION.GUAYOS && (
                                        <tr>
                                          <td className="bold">DF</td>
                                          <td className="text-right">
                                            <TooltipCurrency title={ this.state.totals.df_petro } >
                                              { Globals.formatMiles(this.state.totals.df,true,Currency.BOLIVAR) }
                                            </TooltipCurrency>
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td className="bold">Convenios de Pago</td>
                                        <td className="text-right">
                                          <TooltipCurrency title={ this.state.totals.payment_agreement_petro } >
                                            { Globals.formatMiles(this.state.totals.payment_agreement,true,Currency.BOLIVAR) }
                                          </TooltipCurrency>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="bold">Total sin IVA</td>
                                        <td className="text-right">
                                          <TooltipCurrency title={ this.state.totals.subtotal_petro } >
                                            { Globals.formatMiles(this.state.totals.subtotal,true,Currency.BOLIVAR) }
                                          </TooltipCurrency>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="bold">Total sin retención</td>
                                        <td className="text-right">
                                          <TooltipCurrency title={ this.state.totals.total_without_retention_petro } >
                                            { Globals.formatMiles(this.state.totals.total_without_retention,true,Currency.BOLIVAR) }
                                          </TooltipCurrency>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="bold">TOTAL</td>
                                        <td className="text-right bold">
                                          <TooltipCurrency title={ this.state.totals.total_petro } >
                                            { Globals.formatMiles(this.state.totals.total,true,Currency.BOLIVAR) }
                                          </TooltipCurrency>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="col-md-6">
                                  <table className="table-summary">
                                    <tbody>
                                      <tr>
                                        <td className="bold">IVA FACTURADO</td>
                                        <td className="text-right">
                                          <TooltipCurrency title={ this.state.totals.iva_petro } >
                                            { Globals.formatMiles(this.state.totals.iva,true,Currency.BOLIVAR) }
                                          </TooltipCurrency>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="bold">IVA RETENIDO</td>
                                        <td className="text-right">
                                          <TooltipCurrency title={ this.state.totals.retention_petro } >
                                            { Globals.formatMiles(this.state.totals.retention,true,Currency.BOLIVAR) }
                                          </TooltipCurrency>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="bold">TOTAL IVA SIN RETENCIÓN</td>
                                        <td className="text-right bold">
                                          <TooltipCurrency title={ this.state.totals.iva_without_retention_petro } >
                                            { Globals.formatMiles(this.state.totals.iva_without_retention ,true,Currency.BOLIVAR) }
                                          </TooltipCurrency>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="col-md-6 pt-3">
                                  <table className="table-summary">
                                    <tbody>
                                      <tr>
                                        <td className="bold width-title">Bancos</td>
                                        <td className="text-right">
                                          <TooltipCurrency title={ this.state.totals.bank_petro } >
                                            { Globals.formatMiles(this.state.totals.bank,true,Currency.BOLIVAR) }
                                          </TooltipCurrency>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="bold">Notas de Crédito</td>
                                        <td className="text-right">
                                          <TooltipCurrency title={ this.state.totals.credit_note_petro } >
                                            { Globals.formatMiles(this.state.totals.credit_note,true,Currency.BOLIVAR) }
                                          </TooltipCurrency>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              }

              <Table data={ this.state.data.length } title="" header={ this.state.header }>
                { this.state.data.map((report: any,index: number) => {
                  return (
                    <tr key={ index }>
                      <td>{ moment(report.created_at).format('DD/MM/YYYY') }</td>
                      <td>{ report.details?.bank?.name }</td>
                      <td>{ report.details?.number }</td>
                      <td className="nowrap">{ report.description }</td>
                      <td className="nowrap text-right">
                        <TooltipCurrency title={ report.total } >
                          { Globals.formatMiles(report.total_bs,true,Currency.BOLIVAR) }
                        </TooltipCurrency>
                      </td>                       
                      <td>{ report.details?.date && report.details?.date != '0000-00-00' && moment(report.details.date).format('DD/MM/YYYY') || '-' }</td>
                      <td>{ report.number_format }</td>
                      <td>{ report.property?.urbaser_code }</td>
                      <td className="nowrap">{ report.user?.full_name }</td>
                      <td className="nowrap">
                        <TooltipCurrency title={ report.urban_clean_amount } >
                          { Globals.formatMiles(Currency.ConvertAux(report.urban_clean_amount, report.conversion_petro),true,Currency.BOLIVAR) }
                        </TooltipCurrency>
                      </td>
                      <td className="nowrap">
                        <TooltipCurrency title={ report.df_amount } >
                          { Globals.formatMiles(Currency.ConvertAux(report.df_amount, report.conversion_petro),true,Currency.BOLIVAR) }
                        </TooltipCurrency>
                      </td>
                      <td>{ report.iva_percentage }%</td>
                      <td className="nowrap">
                        <TooltipCurrency title={ report.iva } >
                          { Globals.formatMiles(Currency.ConvertAux(report.iva, report.conversion_petro),true,Currency.BOLIVAR) }
                        </TooltipCurrency>
                      </td>
                      <td className="nowrap">
                        <TooltipCurrency title={ report.retention } >
                          { Globals.formatMiles(Currency.ConvertAux(report.retention, report.conversion_petro),true,Currency.BOLIVAR) }
                        </TooltipCurrency>
                      </td>
                      <td className="nowrap">
                        <TooltipCurrency title={ report.credit_note?.amount_used } >
                          { Globals.formatMiles(Currency.ConvertAux(report.credit_note?.amount_used, report.conversion_petro),true,Currency.BOLIVAR) }
                        </TooltipCurrency>
                      </td>
                      <td className="nowrap text-right">
                        <TooltipCurrency title={ report.amount_paid_total } >
                          { Globals.formatMiles(Currency.ConvertAux(report.amount_paid_total, report.conversion_petro),true,Currency.BOLIVAR) }

                        </TooltipCurrency>
                      </td>
                      <td className="nowrap text-right">{ Globals.formatMiles(report.conversion_petro,true,Currency.BOLIVAR) }</td>
                      <td>{ report.reception_date && moment(report.reception_date).format('DD/MM/YYYY') || '-' }</td>
                      <td>{ report.payment_method?.name}</td>
                    </tr>
                  )
                }) }
              </Table>

            
              <div className="row">
                <Pagination 
                  pages={ this.state.last_page } 
                  active={ this.state.page }
                  onChange={ async (page: number) => {
                    await this.setState({
                      page: page
                    });
                    this.load();
                } } />
              </div>
        </div>        
    )
  }
}

export default connect()(Report);