import React from "react";
import { Modal } from "react-bootstrap";
import { Globals } from "utils";

const _Modal = React.forwardRef((props: any, ref) => (
  <Modal
    backdrop="static"
    ref={ref as React.RefObject<any>}
    id={props.id}
    className={props.className}
    style={props.style}
    onShow={Globals.hideTooltip}
    show={props.visible}
    onHide={props.onClose}
    backdropClassName={props.backdropClassName}
  >
    <Modal.Header closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.children}</Modal.Body>
  </Modal>
));

export default _Modal;
