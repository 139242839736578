import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Input, Modal, Table, TooltipCurrency } from 'components'
import { Currency, Globals } from 'utils'
import { CashModalPaymentsProps, Invoice, Payment } from '../Cash'
import { useCashContext } from '../useCashContext'
import { ExpandMore } from 'assets/icons'

const headerModal = ['', 'Código', 'Local', 'Descripción', 'Total', '']

const CashModalPayments = ({ visible, setVisible }: CashModalPaymentsProps) => {
  const { loadingPayments, getPropertyIdPayments, payments, setPayments, property, invoices, setInvoices, isCondominium } = useCashContext()
  const [search, setSearch] = useState('')

  const addToInvoices = () => {
    const filter = payments
      .filter((payment) => {
        return payment.isSelected && !invoices.some((i) => i.id === payment.id)
      })
      .map((payment): Invoice[] => {
        const invoiceVal = {
          id: payment.id,
          payment_id: payment.id,
          name: payment.description,
          amount: payment.total,
          amount_bs: Currency.Convert(Currency.Convert(payment.total, property?.petro_value!), 1),
        }

        if (isCondominium) {
          return [invoiceVal]
        }

        return [
          invoiceVal,
          ...payment.fines.map((fine): Invoice => {
            return {
              id: fine.id,
              fines_id: fine.id,
              name: `${fine.type?.name} - ${payment.description}`,
              amount: fine.amount,
              amount_bs: Currency.Convert(Currency.Convert(fine.amount, property?.petro_value!), 1),
            }
          }),
        ]
      })
      .flat(2)

    setInvoices([...invoices, ...filter])
    onClose()
  }

  const handleFilterPayment = () => {
    if (search.trim().length === 0) return payments

    const searchVal = search.trim().toLowerCase()

    return payments.filter(
      (payment) =>
        payment.urbaser_code.toLowerCase().includes(searchVal) ||
        payment.house_number.toLowerCase().includes(searchVal) ||
        payment.description.toLowerCase().includes(searchVal)
    )
  }

  const handleSelected = (payment: Payment) => {
    const index = payments.findIndex((p) => p.id === payment.id)
    const list = payments.map((p, i) => {
      // if (p.id === payment.id) p.isSelected = !payment.isSelected
      p.isSelected = i <= index

      return p
    })

    setPayments(list)
  }

  const handleCollapse = (payment: Payment) => {
    const list = payments.map((p) => {
      if (p.id === payment.id) p.isCollapse = !payment.isCollapse

      return p
    })

    setPayments(list)
  }

  const onClose = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (visible && property) {
      getPropertyIdPayments(property.id)
    }
  }, [visible])

  if (!property) {
    return null
  }

  return (
    <Modal className="high-modal modal-view-account-status" visible={visible} onClose={onClose} title="Agregar Deuda">
      <div>
        <div className="d-flex flex-column w-50">
          <Input
            color="white"
            searchRight
            value={search}
            name="search"
            placeholder="Buscar..."
            onChange={(e: any) => {
              setSearch(e.target.value)
            }}
          />
        </div>

        {loadingPayments && <p className="text-center mt-5">Cargando...</p>}

        {!loadingPayments && (
          <>
            <Table title="" data={handleFilterPayment()} header={headerModal}>
              {handleFilterPayment().length === 0 ? (
                <tr>
                  <td className="text-center" colSpan={headerModal.length}>
                    No hay datos
                  </td>
                </tr>
              ) : (
                handleFilterPayment().map((payment) => (
                  <>
                    <tr key={payment.id}>
                      <td onClick={() => handleSelected(payment)}>
                        <Checkbox value={payment.id} checked={payment.isSelected} />
                      </td>
                      <td onClick={() => handleSelected(payment)}>{payment.urbaser_code}</td>
                      <td onClick={() => handleSelected(payment)}>{payment.house_number}</td>
                      <td className="capitalize" onClick={() => handleSelected(payment)}>
                        {payment.description}
                      </td>
                      <td onClick={() => handleSelected(payment)}>
                        <TooltipCurrency title={payment.total}>
                          {Globals.formatMiles(Currency.Convert(payment.total, property.petro_value), true, Currency.BOLIVAR)}
                        </TooltipCurrency>
                      </td>
                      <td style={{ padding: '5px' }}>
                        {payment.childs.length > 0 && (
                          <img
                            src={ExpandMore}
                            onClick={() => handleCollapse(payment)}
                            style={{ width: '30px', transform: payment.isCollapse ? 'rotate(180deg)' : '' }}
                          />
                        )}
                      </td>
                    </tr>
                    {payment.isCollapse &&
                      payment.childs.map((child) => (
                        <tr key={child.id}>
                          <td style={{ background: '#e3e3e3' }}></td>
                          <td style={{ background: '#e3e3e3' }}>{child.urbaser_code}</td>
                          <td style={{ background: '#e3e3e3' }}>{child.house_number}</td>
                          <td style={{ background: '#e3e3e3' }} className="capitalize">
                            {child.description}
                          </td>
                          <td style={{ background: '#e3e3e3' }}>
                            <TooltipCurrency title={child.total}>
                              {Globals.formatMiles(Currency.Convert(child.total, property.petro_value), true, Currency.BOLIVAR)}
                            </TooltipCurrency>
                          </td>
                          <td style={{ background: '#e3e3e3' }}></td>
                        </tr>
                      ))}
                  </>
                ))
              )}
            </Table>
          </>
        )}

        <div className="d-flex justify-content-end mt-3">
          <Button className="btn-urbaser-green" onClick={addToInvoices}>
            Agregar a factura
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CashModalPayments
