export default {
	USERS: {
		CHANGE_STATUS: 'users/change-status',
		DELETE: 'users/delete',
		UPDATE: 'users/update'
	},
	MESSAGES: {
		SEND: 'messages/send',
		DELETE: 'messages/delete',
		VIEWED: 'messages/viewed',
		CREATE: 'messages/create',
		STATUS: 'messages/status'
	},
	BADGE: {
		UPDATE: 'badge/update'
	},
	MOVEMENTS: {
		SAVE: 'movements/save'
	},
	PETRO: {
		CHANGE: 'petro/change'
	}
}