import React from 'react'
import { Input, Button, Select } from 'components'
import { Globals, Constants, SocketEvents, Socket } from 'utils'
import { connect } from 'react-redux'
import { AuthService } from 'services'
import { RootState } from 'reducers'
import { RouteComponentProps } from 'react-router'
import { Logo, LogoLogin, BgLogin, NewLogo, NewLogoBlack } from 'assets/img'
import { Link } from 'react-router-dom'

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

class Login extends React.Component<Props> {
  state = {
    form: {
      // TODO: *ELIMINAR* id 1 por V- para hacer login de momento
      // document_type_id: '1',
      username: '',
      password: '',
    },
    document_types: [],
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.history.replace('/')
    }

    this.props.dispatch({
      type: 'SET_BADGE',
      payload: {
        MESSAGES: 0,
      },
    })

    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Iniciar Sesión',
        onBack: null,
      },
    })

    this.load()
  }

  load = async () => {
    // const res: any = await AuthService.phoneCodes();
    this.setState({
      document_types: [
        {
          id: 1,
          name: 'V-',
          created_at: '2021-11-02 19:52:25',
          updated_at: '2021-11-02 19:52:25',
          deleted_at: null,
        },
        {
          id: 2,
          name: 'E-',
          created_at: '2021-11-02 19:52:25',
          updated_at: '2021-11-02 19:52:25',
          deleted_at: null,
        },
        {
          id: 3,
          name: 'J-',
          created_at: '2021-11-02 19:52:25',
          updated_at: '2021-11-02 19:52:25',
          deleted_at: null,
        },
        {
          id: 4,
          name: 'G-',
          created_at: '2021-11-02 19:52:25',
          updated_at: '2021-11-02 19:52:25',
          deleted_at: null,
        },
      ],
    })
  }

  submit = async (e: any) => {
    e.preventDefault()
    const res: any = await AuthService.login(this.state.form)
    localStorage.setItem('urbtoken', res.token)
    await this.props.dispatch({
      type: 'SET_USER',
      payload: res.user,
    })
    if (res.user.level_id != Constants.LEVELS.CLIENT) {
      this.props.history.replace('/admin')
    } else {
      this.props.history.replace('/welcome')
    }
  }

  disabled = () => {
    return this.state.form.username == '' || this.state.form.password == ''
  }

  change = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    })
  }

  register = () => {
    this.props.history.push('/register')
  }

  render() {
    return (
      <div id="login">
        <div className="row container-form">
          <form className="col-md-7" onSubmit={this.submit}>
            <div className="container-logo">
              <img src={NewLogoBlack} />
              <div className="text-center mt-4">Administración</div>
            </div>
            <div className="container-input">
              {/* <div className="i-flex col-md-12">
								<div className="doc-flex">
								  <Select
									color="gray"
									name="document_type_id"
									onChange={ this.change }
									value={ this.state.form.document_type_id }
									placeholder="Tipo"
									options={ this.state.document_types.map((i: any) => {
										return {
											value: i.id,
											label: i.name
										}
									}) } />
								</div>
								<div className="doc-flex">
									<Input 
										name="document" 
										placeholder="Cedula, Rif o Pasaporte"
										onChange={ this.change } />
								</div>
							</div> */}
              <div className="form-group col-md-12">
                <Input name="username" placeholder="Cuenta" onChange={this.change} />
              </div>
              <div className="form-group col-md-12">
                <Input type="password" name="password" placeholder="Contraseña" onChange={this.change} />
              </div>
              <div className="row text-center">
                <div className="col-12">
                  <Button type="submit" disabled={this.disabled()}>
                    Entrar
                  </Button>
                </div>
              </div>
              <div className="recover d-flex justify-content-center flex-column">
                <Link to="/recover" style={{ marginBottom: '10px' }}>
                  ¿Ha olvidado su contraseña?
                </Link>
                {/* <Link to="/recover?account=true">¿Ha olvidado su cuenta SARU?</Link> */}
              </div>
              <div className="register">
                ¿No tienes una cuenta?
                <Link to="/register">Regístrarse</Link>
              </div>
            </div>
          </form>
          <div
            className="col-md-5 container-bg"
            style={{
              backgroundImage: `url(${BgLogin})`,
            }}
          ></div>
        </div>
      </div>
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user,
  }
})(Login)
