import React, { useState } from 'react'
import { Button, Table, Tooltip, TooltipCurrency } from 'components'
import { Currency, Globals } from 'utils'
import { useCashContext } from './useCashContext'
import { Invoice } from './Cash'
import CashModalInvoiceEditAmount from './modals/CashModalInvoiceEditAmount'
import { TrashIcon } from 'assets/icons'

const headerInvoices = ['Nombre', 'Tipo', 'Monto', 'Acciones']

const CashInvoices = () => {
  const { invoices, setInvoices, property, userIsClient } = useCashContext()
  const [invoiceEdit, setInvoiceEdit] = useState<Invoice | null>(null)
  const [visibleModal, setVisibleModal] = useState(false)

  const handleAmountEdit = (invoice: Invoice) => {
    if (!invoice.service_id) return

    setInvoiceEdit(invoice)
    setVisibleModal(true)
  }

  const changeInvoiceAmount = () => {
    if (!invoiceEdit) return

    const list = invoices.map((invoice) => {
      if (invoice.id === invoiceEdit.id) {
        invoice.amount = invoiceEdit.amount
      }
      return invoice
    })
    setInvoices(list)

    setInvoiceEdit(null)
    setVisibleModal(false)
  }

  const removeInvoice = (e: any, invoice: Invoice) => {
    e.preventDefault()

    const list = invoices.filter((item) => item.id !== invoice.id)
    setInvoices(list)
  }

  return (
    <>
      <CashModalInvoiceEditAmount invoice={invoiceEdit} onSave={changeInvoiceAmount} visible={visibleModal} setVisible={setVisibleModal} />

      <h4 className="title-component pb-0">Detalle de la factura</h4>

      <Table data={invoices.length || 1} title="" header={headerInvoices}>
        {invoices.length === 0 ? (
          <tr>
            <td className="text-center" colSpan={headerInvoices.length}>
              No hay datos
            </td>
          </tr>
        ) : (
          property &&
          invoices.map((invoice: any, index: number) => (
            <tr key={index}>
              <td>{invoice.name}</td>
              <td>
                {invoice.payment_id && 'Deuda'}
                {invoice.fines_id && 'Multa'}
                {invoice.service_id && 'Servicio'}
              </td>
              <td className={invoice.service_id ? 'text-primary' : ''} onClick={() => handleAmountEdit(invoice)}>
                <TooltipCurrency title={invoice.amount}>
                  {Globals.formatMiles(Currency.Convert(invoice.amount, property.petro_value), true, Currency.BOLIVAR)}
                </TooltipCurrency>
              </td>
              <td>
              {!userIsClient && (
                <Tooltip title="Eliminar">
                  <a className="link-icon" href="#" onClick={ (e: any) => removeInvoice(e, invoice) }>
                    <img src={ TrashIcon } />
                  </a>
                </Tooltip>
              )}
              </td>
            </tr>
          ))
        )}
      </Table>
      {/* <hr />
      <pre>{JSON.stringify(invoices, null, 2)}</pre> */}
    </>
  )
}

export default CashInvoices
