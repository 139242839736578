import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteService } from 'services';
import { ShieldImage, LogoRoutes, LogoNaguanagua, NewLogo, NewLogoBlack } from 'assets/img';
import { PaperClip } from 'components';
import { Globals, Constants } from 'utils';
import moment from 'moment';
 
interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

class RoutesSchedules extends React.Component<Props> {  

  state = {
    routes: []
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Rutas y Horarios',
        onBack: null
      }
    });

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await RouteService.admin.get({
      withoutLoading
    });
    this.setState({
      routes: res.routes
    }); 
  }

  render() {
    const { ACTIVE, INACTIVE } = Constants.ROUTES_STATUS;

    return (
        <div id="routes-schedules">
         <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img className="shield" src={ LogoNaguanagua } />
            </div>
            <div className="col-md-4 text-center">
              <h3>RUTAS DE RECOLECCIÓN DE DESECHOS SÓLIDOS DE NAGUANAGUA</h3>
            </div>
            <div className="col-md-4 text-center">
              <img className="logo" src={ NewLogoBlack } />
            </div>
          </div>

          <div className="container-table">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td rowSpan={ 2 }>Tipo de Unidad</td>
                  <td rowSpan={ 2 }>Ruta</td>
                  <td colSpan={ 7 }>Días en que se realiza la ruta</td>
                  <td rowSpan={ 2 }>Turno</td>
                  <td rowSpan={ 2 }>Descripción de la Ruta</td>
                  <td rowSpan={ 2 }>Hora</td>
                </tr>
                <tr>
                  <td>Lunes</td>
                  <td>Martes</td>
                  <td>Miércoles</td>
                  <td>Jueves</td>
                  <td>Viernes</td>
                  <td>Sábado</td>
                  <td>Domingo</td>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.routes.map((item: any,index: number) => {
                    return (
                      <tr>
                        <td>{ item.route_type }</td>
                        <td>{ item.route_number }</td>
                        <td align="center">{ item.monday == ACTIVE ? '✓' : '' }</td>
                        <td align="center">{ item.tuesday == ACTIVE ? '✓' : '' }</td>
                        <td align="center">{ item.wednesday == ACTIVE ? '✓' : '' }</td>
                        <td align="center">{ item.thursday == ACTIVE ? '✓' : '' }</td>
                        <td align="center">{ item.friday == ACTIVE ? '✓' : '' }</td>
                        <td align="center">{ item.saturday == ACTIVE ? '✓' : '' }</td>
                        <td align="center">{ item.sunday == ACTIVE ? '✓' : '' }</td>
                        <td>{ item.turn }</td>
                        <td>{ item.description }</td>
                        <td className="no-wrap">{ (item.hour && moment(item.hour,'HH:mm:ss').format('hh:mm A')) || '' }</td>
                      </tr>
                    )
                  })
                }     
              </tbody>       
            </table>
          </div>
        </div>        
    )
  }
}

export default connect()(RoutesSchedules);