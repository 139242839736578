import { Api, Constants } from 'utils'

class AccountStatusService {
  static get = async (params?: any) => Api.createResponse('account-status/get', params)
  static getReferences = async () => Api.createResponse('account-status/get-references')
  static download = (id?: string) => Constants.BASE_API + 'admin/account-status/get-account-status/' + id

  static admin = {
    get: async (params?: any) => Api.createResponse(`admin/account-status/get${params.id ? `/${params.id}` : ''}`, params),
    getReferences: async () => Api.createResponse('admin/account-status/get-references'),
    setPendingMonths: async (id: number | string, value: number | string, params?: any) =>
      Api.createResponse(`admin/account-status/set-pending-months/${id}/${value}`, params),
    getPayments: async (params?: any) => Api.createResponse('admin/account-status/get-payments', params),
    download: (id?: string) => Constants.BASE_API + 'admin/account-status/get-account-status/' + id,
    setFieldValue: async (id: number | string, value: number | string, params?: any) =>
      Api.createResponse(`admin/account-status/set-conversion-petro/${id}/${value}`, params),
    removePayment: async (id: number | string, params?: any) => Api.createResponse(`admin/account-status/remove-payment/${id}`, params),
    removeFine: async (id: number | string, params?: any) => Api.createResponse(`admin/account-status/remove-fine/${id}/1`, params),
    removeFineClean: async (id: number | string, params?: any) => Api.createResponse(`admin/account-status/remove-fine/${id}/2`, params),
    removeDebt: async (id: number | string, params?: any) => Api.createResponse(`admin/account-status/remove-all/${id}`, params),
    removeAllFines: async (id: number | string, params?: any) => Api.createResponse(`admin/account-status/remove-all-fines/${id}`, params),
    updateFinesPayment: async (id: number | string, value: number | string, params?: any) =>
      Api.createResponse(`admin/account-status/set-fine/${id}/${value}/1`, params),
    updateFinesCleanPayment: async (id: number | string, value: number | string, params?: any) =>
      Api.createResponse(`admin/account-status/set-fine/${id}/${value}/2`, params),
    updateStatusPayment: async (id: number | string, value: number | string, params?: any) =>
      Api.createResponse(`admin/account-status/set-status/${id}/${value}`, params),
    updateCheck: async (id: number | string, value: number, params?: any) =>
      Api.createResponse(`admin/account-status/set-check/${id}/${value}`, params),
    deletePendingPayments: async (id: number | string, params?: any) => Api.createResponse(`admin/account-status/${id}/payments/status`, params),
  }
}

export default AccountStatusService
