import React from "react";
import { AccountStatusService } from "services";
import { Table, Pagination, TooltipCurrency, Modal, Tooltip, Button, Checkbox, Select } from "components";
import { Globals, Currency, Constants, Permissions, checkPermission } from "utils";
import EditFieldValue from "./edit-field-value";
import moment from "moment";
import { CheckWhite, TrashIcon } from "assets/icons";
import Swal from "sweetalert2";
import colors from "utils/colors";
import { connect } from "react-redux";
import EditFinesPaymentValue from "./edit-fines-payment-value";
import EditFinesCleanPaymentValue from "./edit-fines-clean-payment-value";

interface Props {
  property: any;
}

const { VIEW_CALCULATE } = Permissions.ACCOUNT_STATUS;

class ViewProperty extends React.Component<Props> {
  state: any = {
    property: null,
    payments: [],
    header: [
      "Fecha",
      "Tipo de Servicio",
      "Valor UCD",
      "Subtotal",
      "IVA",
      "Total",
      "Multa",
      "Intereses",
      "Acciones",
    ],
    page: 1,
    last_page: 1,
    conversion_dolar: {
      amount: 0,
    },
    conversion_petro: {
      amount: 0,
    },
    total_fines: 0,
    total_debt: 0,
    total_debt_fines: 0,
    visiblePendingMonths: false,
    editedProperty: null,

    visibleFinesPayment: false,
    visibleFinesCleanPayment: false,
    editedPayment: null,
    typePayments: [
      {
        value: 0,
        label: 'Procesados',
      },
      {
        value: 1,
        label: 'Pendientes',
      }
    ],
    typePaymentSelected: 1,
  };

  componentDidMount() {
    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await AccountStatusService.admin.getPayments({
      page: this.state.page,
      withoutLoading,
      id: this.props.property?.id,
      status: this.state.typePaymentSelected,
    });
    this.setState({
      property: this.props.property,
      payments: res.payments.data,
      last_page: res.payments.last_page,
      conversion_dolar: res.conversion_dolar,
      conversion_petro: res.conversion_petro,
      total_debt: res.total_debt,
      total_fines: res.total_fines,
      total_fines_clean: res.total_fines_clean,
      total_debt_fines: res.total_debt_fines + res.total_iva,
      total_iva: res.total_iva,
    });
  };

  pendingMonths = (e: any, item: any) => {
    e.preventDefault();

    this.setState({
      visiblePendingMonths: true,
      editedProperty: item,
    });
  };

  onClosePendingMonths = () => {
    this.setState(
      {
        visiblePendingMonths: false,
      },
      async () => {
        this.load(true);
      }
    );
  };

  updateCheck = async (e: any, payment: any) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "Confirmar esta acción",
      // text: "Confirmar esta acción",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      try {
        const value = !payment.check ? 1 : 0
        await AccountStatusService.admin.updateCheck(payment.id, value);

        this.load();
      } catch (e) {
        console.log(e);
      }
    }
  }

  removePayment = async (e: any, payment: any) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Esta acción no se podrá revertir",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      try {
        await AccountStatusService.admin.removePayment(payment.id, { method: 'delete' });

        const data = [...this.state.payments].filter((i: any) => i.id !== payment.id);

        this.setState({ payments: data });
      } catch (e) {
        console.log(e);
      }
    }
  }

  removeFinesPayment = async (e: any, payment: any) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Esta acción no se podrá revertir",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      try {
        await AccountStatusService.admin.removeFine(payment.id, { method: 'delete' });
        this.load();
      } catch (e) {
        console.log(e);
      }
    }
  }

  removeFinesCleanPayment = async (e: any, payment: any) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Esta acción no se podrá revertir",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      try {
        await AccountStatusService.admin.removeFineClean(payment.id, { method: 'delete' });
        this.load();
      } catch (e) {
        console.log(e);
      }
    }
  }

  eliminateDebt = async (e: any) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Esta acción no se podrá revertir",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      try {
        await AccountStatusService.admin.removeDebt(this.state.property.id, { method: 'delete' });
        this.load();
      } catch (e) {
        console.log(e);
      }
    }
  }

  removeAllFines = async (e: any) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Esta acción no se podrá revertir",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      try {
        await AccountStatusService.admin.removeAllFines(this.state.property.id, { method: 'delete' });
        this.load();
      } catch (e) {
        console.log(e);
      }
    }
  }

  openValuePetro = (e: any, payment: any) => {
    e.preventDefault()
    if (checkPermission(VIEW_CALCULATE))
      this.pendingMonths(e, payment)
  }

  openFinesPayment = (e: any, payment: any) => {
    e.preventDefault()
    if (checkPermission(VIEW_CALCULATE)) {
      const value_fines = Currency.Convert(payment.fines_payment, this.state.conversion_petro.amount)

      this.setState({
        visibleFinesPayment: true,
        editedPayment: { value_fines, ...payment },
      });
    }
  }

  openFinesCleanPayment = (e: any, payment: any) => {
    e.preventDefault()
    if (checkPermission(VIEW_CALCULATE)) {
      const value_fines = Currency.Convert(payment.fines_clean, this.state.conversion_petro.amount)

      this.setState({
        visibleFinesCleanPayment: true,
        editedPayment: { value_fines, ...payment },
      });
    }
  }

  onCloseFinesPayment = () => {
    this.setState({
      visibleFinesPayment: false,
      visibleFinesCleanPayment: false,
      editedPayment: null,
    }, async () => {
      this.load(true);
    });
  };

  toggleStatusPayment = async (e: any, payment: any) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "¿Está seguro?",
      // text: "Esta acción no se podrá revertir",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      try {
        const status = payment.status === 1 ? 0 : 1;

        await AccountStatusService.admin.updateStatusPayment(payment.id, status);
        this.load(true);
        Globals.showSuccess("Se ha marcado como pagado con éxito");
      } catch (e) {
        console.log(e);
      }
    }
  }

  deletePendingPayments = async (e: any) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Esta acción no se podrá revertir",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      try {
        await AccountStatusService.admin.deletePendingPayments(this.state.property.id);
        this.load();
      } catch (e) {
        console.log(e);
      }
    }
  }

  render() {
    const property: any = this.state.property;
    const payments: any = this.state.payments;
    const RESIDENTIAL_TYPE: any = Constants.PROPERTY_TYPES.RESIDENTIAL;
    const { visiblePendingMonths, visibleFinesPayment, visibleFinesCleanPayment } = this.state;

    return (
      <>
        <Modal
          className="modal-editor"
          visible={visiblePendingMonths}
          onClose={this.onClosePendingMonths}
          title="Valor UCD"
        >
          <EditFieldValue
            user={{}}
            registry={this.state.editedProperty}
            onClose={this.onClosePendingMonths}
          />
        </Modal>

        <Modal
          className="modal-editor"
          visible={visibleFinesPayment}
          onClose={this.onCloseFinesPayment}
          title="Multa"
        >
          <EditFinesPaymentValue
            user={{}}
            registry={this.state.editedPayment}
            onClose={this.onCloseFinesPayment}
          />
        </Modal>

        <Modal
          className="modal-editor"
          visible={visibleFinesCleanPayment}
          onClose={this.onCloseFinesPayment}
          title="Interés"
        >
          <EditFinesCleanPaymentValue
            user={{}}
            registry={this.state.editedPayment}
            onClose={this.onCloseFinesPayment}
          />
        </Modal>

        <div id="admin-view-client">
          {property && (
            <React.Fragment>
              <h3>Datos de la propiedad</h3>
              <div className="container-data">
                <div className="item-data">
                  <p>Código: {property.urbaser_code}</p>
                  <p>Tipo: {property.type?.name ?? ''}</p>
                  <p>Uso: {property.use?.name ?? ''}</p>
                  <p>Tamaño: {property.size} m2</p>
                  <p>Actividad comercial: {property.economic_activity.name}</p>
                  <p>Dirección fiscal: {property.user?.fiscal_address ?? ''}</p>
                  <p>Licencia de actividad económica: {property.license}</p>
                </div>
                <div className="item-data">
                  <p>Usuario: {property.user?.username}</p>
                  <p>Propietario: {property.user.full_name}</p>
                  <p>Email: {property.user.email}</p>
                  <p>Teléfono: {property.user.full_phone}</p>
                  <p>
                    Subtotal:{" "}
                    <strong>
                      <TooltipCurrency title={this.state.total_debt}>
                        {Globals.formatMiles(
                          Currency.Convert(
                            this.state.total_debt,
                            this.state.conversion_petro.amount
                          ),
                          true,
                          Currency.BOLIVAR
                        )}
                      </TooltipCurrency>
                    </strong>
                  </p>
                  <p>
                    IVA:{" "}
                    <strong>
                      <TooltipCurrency title={this.state.total_iva}>
                        {Globals.formatMiles(
                          Currency.Convert(
                            this.state.total_iva,
                            this.state.conversion_petro.amount
                          ),
                          true,
                          Currency.BOLIVAR
                        )}
                      </TooltipCurrency>
                    </strong>
                  </p>
                  <p>
                    Multas:{" "}
                    <strong>
                      <TooltipCurrency title={this.state.total_fines}>
                        {Globals.formatMiles(
                          Currency.Convert(
                            this.state.total_fines,
                            this.state.conversion_petro.amount
                          ),
                          true,
                          Currency.BOLIVAR
                        )}
                      </TooltipCurrency>
                    </strong>
                  </p>
                  <p>
                    Intereses:{" "}
                    <strong>
                      <TooltipCurrency title={this.state.total_fines_clean}>
                        {Globals.formatMiles(
                          Currency.Convert(
                            this.state.total_fines_clean,
                            this.state.conversion_petro.amount
                          ),
                          true,
                          Currency.BOLIVAR
                        )}
                      </TooltipCurrency>
                    </strong>
                  </p>
                  <p>
                    Total Deuda:{" "}
                    <strong>
                      <TooltipCurrency title={this.state.total_debt_fines}>
                        {Globals.formatMiles(
                          Currency.Convert(
                            this.state.total_debt_fines,
                            this.state.conversion_petro.amount
                          ),
                          true,
                          Currency.BOLIVAR
                        )}
                      </TooltipCurrency>
                    </strong>
                  </p>
                </div>
              </div>
            </React.Fragment>
          )}

          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-baseline">
              <h3 className="mr-2">Pagos</h3>
              <Select
                color="gray"
                className="select-phone"
                name="document_type_id"
                onChange={(e: any) => {
                  this.setState({
                    typePaymentSelected: parseInt(e.target.value)
                  }, () => {
                    this.load()
                  })
                }}
                value={this.state.typePaymentSelected}
                // placeholder="Tipo de pagos"
                options={this.state.typePayments}
                required={true}
              />
            </div>

            { checkPermission(VIEW_CALCULATE) &&
              <div>
                <Button className="btn-green-create btn-filter" onClick={this.deletePendingPayments}>
                  Marcar como pagado los Pagos Pendientes
                </Button>
                <Button className="btn-green-create btn-filter ml-2" onClick={this.removeAllFines}>
                  Eliminar Multa de Pagos Pendientes
                </Button>
                <Button className="btn-green-create btn-filter ml-2" onClick={this.eliminateDebt}>
                  Eliminar toda la deuda
                </Button>
              </div>
            }
          </div>

          {payments.length > 0 && (
            <React.Fragment>
              <Table title="" data={payments} header={this.state.header}>
                {payments.map((payment: any, index: number) => {
                  return (
                    <tr>
                      {/* <td style={ payment.check ? { background: '#fbffe7' } : {}} onClick={(e: any) => this.updateCheck(e, payment)}>
                        <Checkbox value={payment.id} checked={payment.check} />
                      </td> */}
                      <td style={ payment.check ? { background: '#fbffe7' } : {}}>{moment(payment.created_at).format("DD/MM/YYYY")}</td>
                      <td style={ payment.check ? { background: '#fbffe7' } : {}}>{payment.service_type.name}</td>
                      <td style={ payment.check ? { background: '#fbffe7' } : {}} className="text-nowrap">
                        <a href="#" onClick={(e: any) => this.openValuePetro(e, payment)}>
                          {Globals.formatMiles(
                            (payment.useConversionLocal
                            ? payment.conversion_petro
                            : this.state.conversion_petro.amount), true, Currency.BOLIVAR)}
                        </a>
                      </td>
                      <td style={ payment.check ? { background: '#fbffe7' } : {}} className="text-right">
                        <TooltipCurrency title={payment.total}>
                          {Globals.formatMiles(
                            Currency.Convert(
                              payment.total,
                              (payment.useConversionLocal
                                ? payment.conversion_petro
                                : this.state.conversion_petro.amount)
                            ),
                            true,
                            Currency.BOLIVAR
                          )}
                        </TooltipCurrency>
                      </td>
                      <td style={ payment.check ? { background: '#fbffe7' } : {}} className="text-right">
                        <TooltipCurrency
                          title={
                            property.type.id != RESIDENTIAL_TYPE
                              ? payment.iva
                              : "-"
                          }
                        >
                          {property.type.id != RESIDENTIAL_TYPE
                            ? Globals.formatMiles(
                                Currency.Convert(
                                  payment.iva,
                                  (payment.useConversionLocal
                                    ? payment.conversion_petro
                                    : this.state.conversion_petro.amount)
                                ),
                                true,
                                Currency.BOLIVAR
                              )
                            : "-"}
                        </TooltipCurrency>
                      </td>
                      <td style={ payment.check ? { background: '#fbffe7' } : {}} className="text-right">
                        <TooltipCurrency
                          title={
                            property.type.id != RESIDENTIAL_TYPE
                              ? payment.total_with_iva
                              : payment.total
                          }
                        >
                          {Globals.formatMiles(
                            Currency.Convert(
                              property.type.id != RESIDENTIAL_TYPE
                                ? payment.total_with_iva
                                : payment.total,
                                (payment.useConversionLocal
                                  ? payment.conversion_petro
                                  : this.state.conversion_petro.amount)
                            ),
                            true,
                            Currency.BOLIVAR
                          )}
                        </TooltipCurrency>
                      </td>
                      <td style={ payment.check ? { background: '#fbffe7' } : {}} className="text-right">
                        <TooltipCurrency title={payment.fines_payment}>
                          <a href="#" onClick={(e: any) => this.openFinesPayment(e, payment)}>
                            {Globals.formatMiles(
                              Currency.Convert(payment.fines_payment, this.state.conversion_petro.amount
                              ),
                              true,
                              Currency.BOLIVAR
                            )}
                          </a>
                        </TooltipCurrency>
                        { checkPermission(VIEW_CALCULATE) &&
                          <Tooltip title="Eliminar Multa">
                            <a className="link-icon ml-2" href="#" onClick={ (e: any) => this.removeFinesPayment(e, payment) }>
                              <img src={ TrashIcon } style={ { filter: 'contrast(0.2)' } } />
                            </a>
                          </Tooltip>
                        }
                      </td>
                      <td style={ payment.check ? { background: '#fbffe7' } : {}} className="text-right">
                        <TooltipCurrency title={payment.fines_clean}>
                          <a href="#" onClick={(e: any) => this.openFinesCleanPayment(e, payment)}>
                            {Globals.formatMiles(
                              Currency.Convert(payment.fines_clean, this.state.conversion_petro.amount
                              ),
                              true,
                              Currency.BOLIVAR
                            )}
                          </a>
                        </TooltipCurrency>
                        { checkPermission(VIEW_CALCULATE) && this.state.typePaymentSelected === 1 &&
                          <Tooltip title="Eliminar Interes de Mora">
                            <a className="link-icon ml-2" href="#" onClick={ (e: any) => this.removeFinesCleanPayment(e, payment) }>
                              <img src={ TrashIcon } style={ { filter: 'contrast(0.2)' } } />
                            </a>
                          </Tooltip>
                        }
                      </td>
                      <td style={ payment.check ? { background: '#fbffe7' } : {}} className="icon-actions">
                        { checkPermission(VIEW_CALCULATE) && (
                          <>
                            <Tooltip title="Marcar como pagado">
                              <a className="link-icon" style={{ margin: '5px', marginRight: '10px' }} href="#" onClick={(e: any) => this.toggleStatusPayment(e, payment)}>
                                <span className={`table-item-check ${!payment.status ? 'active' : ''}`}>
                                  <img src={CheckWhite} />
                                </span>
                              </a>
                            </Tooltip>
                            {/* {this.state.typePaymentSelected === 1 && (
                              <Tooltip title="Eliminar">
                                <a className="link-icon" href="#" onClick={ (e: any) => this.removePayment(e, payment) }>
                                  <img src={ TrashIcon } style={ { filter: 'contrast(0.2)' } } />
                                </a>
                              </Tooltip>
                            )} */}
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                pages={this.state.last_page}
                active={this.state.page}
                onChange={async (page: number) => {
                  await this.setState({
                    page: page,
                  });
                  this.load();
                }}
              />
            </React.Fragment>
          )}
        </div>
      </>
    );
  }
}

// export default ViewProperty;

export default connect((state: any) => {
  return {
    user: state.user,
  }
})(ViewProperty);