import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const _Tooltip = React.forwardRef((props: any, ref) =>  (
	<OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={ {
          marginBottom: 15
        } } id="tooltip-top">
          { props.title }
        </Tooltip>
      }
    >
		{ props.children }
	</OverlayTrigger>
))

export default _Tooltip;