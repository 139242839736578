import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Select, Input, Modal, DatePicker } from 'components';
import { Constants, Globals } from 'utils';
import { PropertyService } from 'services';
import moment from 'moment';
import { TrashIcon } from 'assets/icons';
import FormClientProperty from 'components/FormClientProperty';

interface Params {
	id: string | undefined
}

interface Props extends RootState, RouteComponentProps<Params> {
  dispatch: any
}

class CreateProperty extends React.Component<Props> {
  load = async (self: any) => {
    const res: any = await PropertyService.view({
      property_id: self.props.match.params.id,
    })
    res.property.date = moment(res.property.date)
    if (res.property.date.isValid()) {
      res.property.date = res.property.date.toDate()
    } else {
      res.property.date = ''
    }
    res.property.secondary = res.property.secondary_properties?.map((item: any) => {
      item.date = (item.date && moment(item.date).toDate()) || ''
      item.date = moment(item.date)
      if (item.date.isValid()) {
        item.date = item.date.toDate()
      } else {
        item.date = ''
      }
      return {
        id: item.id,
        owner: item.owner || Constants.PROPERTIES.OWNER.YES,
        economic_activity_id: item.economic_activity_id || '',
        property_use_id: item.property_use_id || '',
        property_type_id: item.property_type_id || '',
        catastral_id: item.catastral_id || '',
        nic: item.nic || '',
        license: item.license || '',
        urbaser_code: item.urbaser_code || '',
        date: item.date || '',
        vba_number: item.vba_number || '',
        floor: item.floor || '',
        house_number: item.house_number || '',
        size: item.size || '',
      }
    })
    self.setState({
      economic_activities: res.economic_activities,
      property_types: res.property_types,
      property_uses: res.property_uses.filter((item: any) => item.name !== 'Otro'),
      property: {
        property_id: res.property.id,
        owner: res.property.owner || Constants.PROPERTIES.OWNER.YES,
        economic_activity_id: res.property.economic_activity_id || '',
        property_use_id: res.property.property_use_id || '',
        property_type_id: res.property.property_type_id || '',
        catastral_id: res.property.catastral_id || '',
        nic: res.property.nic || '',
        license: res.property.license || '',
        urbaser_code: res.property.urbaser_code || '',
        date: res.property.date || '',
        vba_number: res.property.vba_number || '',
        street: res.property.street || '',
        floor: res.property.floor || '',
        zone: res.property.zone || '',
        house_number: res.property.house_number || '',
        size: res.property.size || '',
        reference: res.property.reference || '',
        secondary: res.property.secondary || [],
        quantity_houses: res.property.quantity_houses || '',
      },
    })
  }

  submit = async (self: any) => {
    const form: any = self.state.property
    const values = Object.keys(form).filter((key) => {
      let response = form[key] === '' && key != 'quantity_houses'
      if (form['property_type_id'] == Constants.PROPERTY_TYPES.RESIDENTIAL && Constants.OPTIONAL_FIELDS.indexOf(key) != -1) {
        response = false
      }
      if (Constants.OPTIONAL_FIELDS_ALL.indexOf(key) != -1) {
        response = false
      }
      return response
    })
    if (values.length > 0) {
      Globals.showError('Debe ingresar todos los datos de las propiedades')
      return false
    }

    // if (
    //   form.secondary.filter((i: any) => {
    //     const values = Object.keys(i).filter((key) => {
    //       let response = i[key] === ''
    //       if (i['property_type_id'] == Constants.PROPERTY_TYPES.RESIDENTIAL && Constants.OPTIONAL_FIELDS.indexOf(key) != -1) {
    //         response = false
    //       }
    //       if (Constants.OPTIONAL_FIELDS_ALL.indexOf(key) != -1) {
    //         response = false
    //       }
    //       return response
    //     })

    //     return values.length > 0
    //   }).length > 0
    // ) {
    //   Globals.showError('Tiene una actividad economica secundaria con datos por llenar')
    //   return false
    // }

    if (parseInt(form.size) <= 0) {
      Globals.showError('El campo metraje de la propiedad debe ser mayor a 0')
      return false
    }

    // if (
    //   form.secondary.filter((i: any) => {
    //     return i.size <= 0
    //   }).length > 0
    // ) {
    //   Globals.showError('El campo metraje de las actividades economicas secundarias debe ser mayor a 0')
    //   return false
    // }

    const property = {
      ...form,
      date: form.date && moment(form.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      user_id: self.props.user.id,
      // secondary: JSON.stringify(
      //   form.secondary.map((item: any) => {
      //     item.date = item.date && moment(item.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      //     return item
      //   })
      // ),
    }

    await PropertyService.edit({ property: JSON.stringify(property) })
    Globals.showSuccess('Se ha creado correctamente la propiedad')
    self.props.history.push({
      pathname: '/properties',
      state: self.props.location.state,
    })
  }

  render() {
    return (
      <FormClientProperty
        {...this.props}
        show="PROPERTY"
        hideClient={true}
        headTitle="Editar Propiedad"
        onBack={() => {
          this.props.history.push({
            pathname: '/properties',
            state: this.props.location.state,
          })
        }}
        onLoad={this.load}
        onSubmit={this.submit}
        fieldsDisabled={[
          'property_use_id',
          'property_type_id',
          'economic_activity_id'
        ]}
      />
    )
  }
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(CreateProperty);