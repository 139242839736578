import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Textarea, Button } from 'components';
import { SuggestionsService } from 'services';
import moment from 'moment';
import { Socket, SocketEvents, Globals, Constants } from 'utils';
import { on, emit } from 'jetemit';

interface Params {
	id: string | undefined
}

interface Props extends RootState, RouteComponentProps<Params> {
  dispatch: any
}

class ViewSuggestion extends React.Component<Props> {

	messages: any;

	state = {
		form: {
			text: ''
		},
		messages: [],
		unsubscribe: () => {},
		unsubscribeStatus: () => {},
		suggestion: null
	}

	async componentDidMount() {
	    this.props.dispatch({
	      type: 'SET_HEADER',
	      payload: {
	        title: 'Ver Dudas y Sugerencias',
	        onBack: () => {
	        	this.props.history.push({
		            pathname: '/complaints-suggestions',
		            state: this.props.location.state
		        });
	        }
	      }
	    });

	    this.props.dispatch({
	    	type: 'SET_CHAT',
	    	payload: this.props.match.params.id
	    });

	    await this.setState({
	    	unsubscribe: on(SocketEvents.MESSAGES.SEND,(data: any) => {
				let messages: any = [...this.state.messages];
				messages.push(data);
				this.setState({
					messages
				},this.scrollToBottom);
				if (data.user.level_id != Constants.LEVELS.CLIENT) {
					/* Socket.emit(SocketEvents.MESSAGES.VIEWED,{
						message_id: data.id
					}); */
				}
			}),
			unsubscribeStatus: on(SocketEvents.MESSAGES.STATUS,(data: any) => {
				const suggestion: any = this.state.suggestion;
				if (data.suggestion_id == suggestion?.id) {
					this.load();
				}
			}),
	    });

	    this.load();
	}

	load = async () => {
		const res: any = await SuggestionsService.view({
			suggestion_id: this.props.match.params.id
		});
		this.setState({
			messages: res.suggestion?.messages || [],
			suggestion: res.suggestion
		},this.scrollToBottom);
		emit(SocketEvents.BADGE.UPDATE);
	}

	componentWillUnmount() {
		this.state.unsubscribe();
		this.state.unsubscribeStatus();

		this.props.dispatch({
	    	type: 'SET_CHAT',
	    	payload: null
	    });
	}

	change = (e: any,callback = () => {}) => {
	    this.setState({
	      form: {
	        ...this.state.form,
	        [e.target.name]: e.target.value
	      }
	    },callback);
	}

	send = async (e: any) => {
		e.preventDefault();
		if (this.state.form.text == '') {
			Globals.showError("Debe ingresar un mensaje");
			return false;
		}

		await SuggestionsService.message.create({
			user_id: this.props.user.id,
			suggestion_id: this.props.match.params.id,
			...this.state.form
		});
		// Socket.emit(SocketEvents.MESSAGES.SEND,{
		// 	user_id: this.props.user.id,
		// 	suggestion_id: this.props.match.params.id,
		// 	...this.state.form
		// });
		this.setState({
			form: {
				text: ''
			}
		});
		this.load();
	}

	scrollToBottom = () => {
		this.messages.scrollTop = this.messages.scrollHeight;
	}
	
	render() {
		const { messages } = this.state;
		const suggestion: any = this.state.suggestion;

		return (
			<div id="view-suggestion">
				<div className="item-suggestion">
	                <div className="title-item">
	                  <h3>{ suggestion?.zone }</h3>
	                  <p>{ moment(suggestion?.created_at).format('DD MMM') }</p>
	                </div>
	                <div className="text">
	                  <p>{ suggestion?.text }</p>
	                  <p>Estatus: { suggestion?.status_text }</p>
	                </div>                
	            </div>

				<div className="container-messages" ref={ ref => this.messages = ref }>
					{
						messages.map((item: any,index: number) => (
							<div key={index} className="item-message">
								<div className="title">
									<h3>{ item.user?.name }</h3>
									<p>{ moment(item.created_at).format('DD MMM') }</p>
								</div>
								<div className="text">
									<p>{ item.text }</p>
								</div>
							</div>
						))
					}
				</div>

				{
					suggestion?.status < Constants.SUGGESTIONS.RESOLVED && (
						<form onSubmit={ this.send }>
						  <div className="row">
							<div className="col-md-12">
			                  <Textarea
			                    placeholder="Escribe un mensaje..."
			                    rows={ 4 }
			                    color="white"
			                    name="text"
			                    onChange={ this.change }
			                    value={ this.state.form.text } />
			                </div>
			              </div>
			              <div className="text-center">
			                <Button type="submit">
			                  Enviar
			                </Button>
			              </div>
						</form>	
					)
				}	
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(ViewSuggestion);