import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, InputCurrency, Input, Select, Button, Tooltip, DatePicker, Checkbox, Modal, PaperClip, Textarea, TooltipCurrency } from 'components';
import { checkPermission, Permissions, Constants, Socket, SocketEvents, Globals, Print, Currency, RegisterMovement } from 'utils';
import { CashService } from 'services';
import { EditIcon, ViewIcon, TrashIcon, CheckIcon, CloseIcon, PrintIcon, MoneyIcon, PaperclipIcon, ViewFileIcon, AdjuntoIcon, CheckWhite } from 'assets/icons';
import moment from 'moment';
import { ImagesAndDocuments } from 'components/paperclip';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

interface LocationProps {
  page: number | undefined;
  form: any
}
interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

const INITIAL_STATE = {
  init: '',
  end: '',
  status: '',
  type: '',
  payment_method: '',
  search: ''
}

const INITIAL_STATE_PAYMENT: any = {
  number: '',
  amound_paid: '',
  payment_method_id: '',
  bank_id: '',
  bank_account_id: '',
  date: '',
  file: '',
  accepted: Constants.BILL_STATUS.PENDING,
  reception_date: ''
}

const { PENDING, ACCEPTED, REJECTED, CANCELLED, TO_PAY } = Constants.BILL_STATUS;
const { VIEW, EDIT, EDIT_STATUS } = Permissions.PAYMENTS;

const STATUS: any = [
  { value: PENDING, label: 'Por conciliar' },
  { value: ACCEPTED, label: 'Conciliado' },
  { value: REJECTED, label: 'Reversado' },
  { value: CANCELLED, label: 'Anulado' },
  { value: TO_PAY, label: 'Por pagar' },
]

class Payments extends React.Component<Props> {  

  timer: any = undefined;
  
  state: any = {
    data: [],
    page: 1,
    last_page: 1,
    form_payment: INITIAL_STATE_PAYMENT,
    header: [
      'Usuario',
      'Código',
      'Contribuyente especial',
      'Fecha',
      'Factura/Convenio',
      'Series',
      'Nro. de control',
      'Tipo Doc',
      'Periodo',
      'ID - Referencia',
      'Banco',
      'Medio de Pago',
      'Monto',
      'Estatus',
      'Acciones',
      'Factura entregada',
    ],
    header_vouchers: [
      'Método de pago',
      'Monto',
      'Banco',
      'Referencia',
      'Fecha de Transferencia',
      'Comprobante',
    ],
    references:{
      payment_methods : [],
      types : [],
      conversion_dolar: {
        amount: 0
      },
      conversion_petro: {
        amount: 0
      }
    },
    form:INITIAL_STATE,
    visible: false,
    client: null,
    types: [],
    methods: [],
    item: null,
    comments: '',
    visible_view: false,
    date: '',
    visible_accepted: false,
    amount_paid: '',
    visible_retention: false,
    banks: [],
    bank_accounts: [],
    retention: false,
    visible_vouchers: false,
    visible_control_number: false,
    control_number: '',
    item_control: null,
    visible_created_at: false,
    created_at: '',
    visible_payment_status: false,
    payment_status: '',
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Pagos',
        onBack: null
      }
    });
    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await CashService.admin.getListPayments({
      page: this.state.page,
      without: ['urban_clean_amount', 'df_amount', 'codes_urbaser', 'debt', 'property.debt'],
      withoutLoading,
      ...this.state.form,
      since: this.state.form.init && moment(this.state.form.init).format('YYYY-MM-DD'),
      until: this.state.form.end && moment(this.state.form.end).format('YYYY-MM-DD')
    });
    this.setState({
      data: res.bills.data,
      last_page: res.bills.last_page,
      methods: res.methods,
      types: res.types,
      banks: res.banks,
      bank_accounts: res.bank_accounts
    });
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  onClose = () => {
    this.setState({
      visible: false
    });
  }

  onCloseAccepted = () => {
    this.setState({
      visible_accepted: false
    });
  }

  onCloseView = () => {
    this.setState({
      visible_view: false
    });
  }

  onCloseViewVouchers = () => {
    this.setState({
      visible_vouchers: false
    });
  }

  onCloseRetention = () => {
    this.setState({
      visible_retention: false
    });
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

  changeStatus = (e: any,item: any, status: number, retention: boolean) => {
    e.preventDefault();
    const { ACCEPTED } = Constants.BILL_STATUS;
    if (status == ACCEPTED) {
      this.setState({
        visible_accepted: true,
        item,
        amount_paid: Currency.ConvertAux(item.amount_paid || item.total,item.conversion_petro),
        retention: retention 
      });
    }
    else {
      this.setState({
        visible: true,
        item,
        retention: retention
      });
    }
  }

  cancel = (e: any,item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      item
    });
  }

  changePayment = (e: any,callback = () => {}) => {
    this.setState({
      form_payment: {
        ...this.state.form_payment,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  reject = async (e: any) => {
    e.preventDefault();
    const item: any = this.state.item;
    if (item.status == Constants.BILL_STATUS.ACCEPTED) {
      await CashService.admin.cancel({
        bill_id: item?.id,
        comments: this.state.comments
      });
      this.setState({
        comments: '',
        visible: false
      },() => {
        this.load(true);
        Globals.showSuccess("Se ha anulado el pago");
        RegisterMovement.save({
            value_id: item.id,
            user_id: this.props.user.id,
            module_id: Constants.MODULES.PAYMENTS,
            description: `Anulación del pago ${ item?.number_format }`
        });
      });
    }
    else {
      await CashService.admin.changeStatus({
        status: Constants.BILL_STATUS.REJECTED,
        bill_id: item?.id,
        comments: this.state.comments
      });
      this.setState({
        comments: '',
        visible: false
      },() => {
        this.load(true);
        Globals.showSuccess("Se ha modificado el estatus del pago");
        RegisterMovement.save({
            value_id: item.id,
            user_id: this.props.user.id,
            module_id: Constants.MODULES.PAYMENTS,
            description: `Rechazo del pago ${ item?.number_format }`
        });
      });
    }    
  }

  print = (item: any) => {
    Print(CashService.admin.print(item.crypt,this.props.user.crypt),true);
  }

  calculateTotalWithRentention = (base: number, iva: number, total: number) => {
    
    if(!this.state.retention){
      return total;
    }

    let iva_: any = (base * iva) / 100;
    let diff: any = (iva_ * 0.25);
    let res: any = base + diff;
     
    return res;
  }

  submit = async (e: any) => {
    e.preventDefault();
    const item: any = this.state.item;
    if (!this.state.date) {
      Globals.showError("Debe ingresar la fecha de recepción de pago");
      return false;
    }
    if (!this.state.amount_paid) {
      Globals.showError("Debe ingresar el monto pagado");
      return false;
    }
    // if (!this.state.control_number) {
    //   Globals.showError("Debe ingresar el número de control");
    //   return false;
    // }

    const credit = item.credit_note?.amount_used || 0;
    
    // if (parseFloat(this.state.amount_paid).toFixed(6) < Currency.ConvertAux(this.calculateTotalWithRentention(item.tax_base, item.iva, item.total) - credit, item.conversion_petro).toFixed(6)) {
    //   Globals.showError("El monto pagado no puede ser menor al total");
    //   return false;
    // }
    const { ACCEPTED } = Constants.BILL_STATUS;
    const res: any = await CashService.admin.changeStatus({
      status: ACCEPTED,
      bill_id: item.id,
      amount_paid: this.state.amount_paid,
      // control_number: this.state.control_number,
      reception_date: this.state.date && moment(this.state.date).format('YYYY-MM-DD')
    });
    await this.setState({
      date: '',
      amount_paid: '',
      // control_number: '',
      visible_accepted: false
    });
    this.load(true);
    Globals.showSuccess("Se ha modificado el estatus del pago")

    if (res.bill?.status == Constants.BILL_STATUS.ACCEPTED) {
      this.print(res.bill);
    }

    RegisterMovement.save({
        value_id: item.id,
        user_id: this.props.user.id,
        module_id: Constants.MODULES.PAYMENTS,
        description: `Conciliación del pago ${ item?.number_format }`
    });
  }

  openPaymentRetention = (e: any, item: any) => {
    e.preventDefault();
    // const amountPaid = item.amount_paid * item.conversion_petro ?? 0
    this.setState({
      visible_retention: true,
      item,
      form_payment: {
        ...this.state.form_payment,
        amount_paid: parseFloat(item.total_bs).toFixed(2),
      },
    })
  }

  finish = async () => {
    const item: any = this.state.item;
    let message: string | boolean = false;
    const { TRANSFER, CASH, DEBIT, CREDIT, DEPOSIT } = Constants.PAYMENT_METHODS;

    if (!this.state.form_payment.payment_method_id) {
      message = "Debe ingresar el método de pago";
    }

    if (this.state.form_payment.number?.toString().length !== 8) {
      message = 'Nro. de referencia debe escribir los últimos 8 dígitos'
    }

    if (parseInt(this.state.form_payment.payment_method_id) == TRANSFER) {
      if (!this.state.form_payment.number || !this.state.form_payment.date || !this.state.form_payment.bank_id || !this.state.form_payment.bank_account_id) {
        message = "Debe ingresar todos los datos de pago";
      }
      if (!this.state.form_payment.reception_date && this.state.form_payment.accepted == Constants.BILL_STATUS.ACCEPTED) {
        message = "Debe ingresar la fecha de recepción del pago";
      }
    } 
    else if ([TRANSFER,CREDIT,DEBIT].indexOf(parseInt(this.state.form_payment.payment_method_id)) != -1) {
      if (!this.state.form_payment.number || !this.state.form_payment.bank_id) {
        message = "Debe ingresar todos los datos de pago";
      }
    }
    else if ([DEPOSIT].indexOf(parseInt(this.state.form_payment.payment_method_id)) != -1) {
      if (!this.state.form_payment.number || !this.state.form_payment.date) {
        message = "Debe ingresar todos los datos de pago";
      }
    }

    if (message) {
      Globals.showError(message);
      return false;
    }

    const res: any = await CashService.admin.complete({
      ...this.state.form_payment,
      receipts: JSON.stringify([{ bill_id: item?.id, ...this.state.form_payment }]),
      bill_id: item?.id,
      date: this.state.form_payment.date && moment(this.state.form_payment.date).format('YYYY-MM-DD'),
      reception_date: this.state.form_payment.reception_date && moment(this.state.form_payment.reception_date).format('YYYY-MM-DD'),
      hasFile: true
    });
    Globals.showSuccess("Se han guardado los datos correctamente");
    await this.setState({
      visible_retention: false,
      form_payment: INITIAL_STATE_PAYMENT
    },() => this.load(true));
    RegisterMovement.save({
      value_id: res.bill?.id,
      user_id: this.props.user.id,
      module_id: Constants.MODULES.PAYMENTS,
      description: `Gaurdando datos de pago de la factura #${ res.bill.number_format } del contribuyente ${ res.bill?.user?.full_name } ${ res.bill?.user?.full_document }`
    });

    this.load(true);
  }

  submitControlNumber = async () => {
    try {
      const item: any = this.state.item_control;
      await CashService.admin.addControlNumber(item.id, {
        control_number: this.state.control_number,
        bill_id: item.id,
      });

      Globals.showSuccess("Número de control agregado");

      this.onCloseControlNumber();
      this.load(true);
    } catch (error) {
      const e: any = error;
      if (e.response.status === 422) {
        Globals.showError("El código de control ya existe");
      } else {
        Globals.showError("Error al agregar el número de control");
      }
    }
  }

  viewControlNumber = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible_control_number: true,
      control_number: item.control_number,
      item_control: item,
    });
  }

  onCloseControlNumber = () => {
    this.setState({
      visible_control_number: false,
      control_number: '',
      item_control: null,
    });
  }

  // Change status
  submitPaymentStatus = async () => {
    try {
      const item: any = this.state.item;
      await CashService.admin.changePaymentStatus(item.id, this.state.payment_status, {
        bill_id: item.id,
      });

      Globals.showSuccess("Se cambio el estado correctamente");

      this.onClosePaymentStatus();
      this.load(true);
    } catch (error) {
      const e: any = error;
      Globals.showError("Error al cambiar el estado");
    }
  }

  viewPaymentStatus = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible_payment_status: true,
      payment_status: item.status,
      item: item,
    });
  }

  onClosePaymentStatus = () => {
    this.setState({
      visible_payment_status: false,
      payment_status: '',
      item: null,
    });
  }

  // Modal para editar la fecha
  submitCreatedAt = async () => {
    try {
      
      //formato de fecha a Y-m-d
      const date = moment(this.state.created_at).format('YYYY-MM-DD');
      const item: any = this.state.item_control;
      await CashService.admin.updateCreatedAt(item.id, {
        created_at: date,
        bill_id: item.id,
      });

      Globals.showSuccess("Número de control agregado");

      this.onCloseCreatedAt();
      this.load(true);
    } catch (error) {
      const e: any = error;
      if (e.response.status === 422) {
        Globals.showError("El código de control ya existe");
      } else {
        Globals.showError("Error al agregar el número de control");
      }
    }
  }

  viewCreatedAt = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible_created_at: true,
      created_at: new Date(item.created_at),
      item_control: item,
    });
  }

  onCloseCreatedAt = () => {
    this.setState({
      visible_created_at: false,
      created_at: '',
      item_control: null,
    });
  }

  deletePaymentBill = async (e: any, item: any) => {
    e.preventDefault();
    e.stopPropagation();

    Globals.confirm('¿Desea eliminar este comprobante?', async () => {
      try {
        await CashService.admin.deletePaymentBill({ id: item.id });

        const newitem: any = this.state.item;

        if (newitem) {
          const detailsNew = newitem.details_new.filter((i: any) => i.id !== item.id);

          this.setState({
            item: {
              ...newitem,
              details_new: detailsNew,
            }
          });
        }
  
        Globals.showSuccess("Comprobante eliminado correctamente");
  
        this.load(true);
      } catch (error) {
        const err: any = error;
        if (err.response.status === 422) {
          Globals.showError("El comprobante no pudo ser eliminado");
        } else {
          Globals.showError("Error al eliminar el comprobante");
        }
      }
    });
  }

  toggleDelivery = async (e: any, item: any) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      console.log({ item });
      await CashService.admin.changeBillDelivery(item.id, { delivery: !item.delivery });

      this.load(true);
    } catch (error) {
      Globals.showError("Ups! Ocurrio un error");
    }
  }

  render() {
    const { visible, visible_view, visible_accepted, visible_retention, visible_vouchers, visible_control_number, visible_created_at, visible_payment_status } = this.state;
    const item: any = this.state.item;
    const { TRANSFER, CASH, DEBIT, CREDIT, DEPOSIT } = Constants.PAYMENT_METHODS;
    const { PENDING, TO_PAY } = Constants.BILL_STATUS;

    const credit = item?.credit_note?.amount_used || 0;

    return (
      <div id="payments">
        <Modal
            className="modal-change-status-payment"
            visible={ visible }
            onClose={ this.onClose } 
            title={ item?.status == Constants.BILL_STATUS.ACCEPTED ? 'Anular pago' : 'Rechazar pago' }
        >
          <form onSubmit={ this.reject }>
            <div className="row">
              <div className="col-12">
                <Textarea
                    placeholder={ item?.status == Constants.BILL_STATUS.ACCEPTED ? 'Motivo de anulación (Opcional)' : 'Motivo de rechazo (Opcional)' }
                    rows={ 4 }
                    color="white"
                    name="text"
                    onChange={ (e: any) => {
                      this.setState({
                        comments: e.target.value
                      });
                    } }
                    value={ this.state.comments } />
              </div>  
            </div>
            <div className="text-center">
              <Button type="submit">
                Enviar
              </Button>
            </div>
          </form>
        </Modal>

        <Modal
            className="high-modal modal-payment-retention"
            visible={ visible_retention }
            onClose={ this.onCloseRetention } 
            title="Subir datos de pago"
        >
          <React.Fragment>
            <div className="row" style={ { marginTop: '20px' } }>
              {this.state.item && this.state.item.credit_on > 0 && (
                <div className="col-md-12">
                  <div className="alert alert-warning">
                    <span>El usuario cuenta con nota de crédito de { Globals.formatMiles(this.state.item.credit_on,true,Currency.BOLIVAR) }</span>
                  </div>
                </div>
              )}
              <div className="col-md-4">
                <Select
                  color="gray"
                  name="payment_method_id"
                  onChange={ this.changePayment }
                  label="Método de Pago"
                  value={ this.state.form_payment.payment_method_id }
                  options={ this.state.methods.map((i: any) => {
                    return {
                      value: i.id,
                      label: i.name
                    }
                  }) } />
              </div>
              {
                [TRANSFER,CREDIT,DEBIT].indexOf(parseInt(this.state.form_payment.payment_method_id)) != -1 && (
                  <React.Fragment>
                    <div className="col-md-4">
                      <Select
                        color="gray"
                        name="bank_id"
                        onChange={ this.changePayment }
                        label="Banco"
                        value={ this.state.form_payment.bank_id }
                        options={ this.state.banks.map((i: any) => {
                          return {
                            value: i.id,
                            label: i.name
                          }
                        }) } />
                    </div>
                    <div className="col-md-4">
                      <Input
                        type="number"
                        color="gray"
                        value={ this.state.form_payment.number }
                        name="number" 
                        label="Nro. de Referencia"
                        helpText={ this.state.form_payment.number?.toString().length !== 8 ? "Escriba los últimos 8 dígitos" : "" }
                        onChange={ this.changePayment } />
                    </div>
                    <div className="col-md-4">
                      <InputCurrency
                        value={ this.state.form_payment.amount_paid }
                        name="amount_paid"
                        label="Monto del recibo"
                        onChange={ this.changePayment } />
                    </div>
                  </React.Fragment>
                )
              }
              {
                [DEPOSIT].indexOf(parseInt(this.state.form_payment.payment_method_id)) != -1 && (
                  <React.Fragment>
                    <div className="col-md-4">
                      <Select
                        color="gray"
                        name="bank_id"
                        onChange={ this.changePayment }
                        label="Banco"
                        value={ this.state.form_payment.bank_id }
                        options={ this.state.banks.map((i: any) => {
                          return {
                            value: i.id,
                            label: i.name
                          }
                        }) } />
                    </div>
                    <div className="col-md-4">
                      <Input
                        type="number"
                        color="gray"
                        value={ this.state.form_payment.number }
                        name="number"
                        label="Nro. de Referencia"
                        helpText={ this.state.form_payment.number?.toString().length !== 8 ? "Escriba los últimos 8 dígitos" : "" }
                        onChange={ this.changePayment } />
                    </div>
                    <div className="col-md-4">
                      <InputCurrency
                        value={ this.state.form_payment.amount_paid }
                        name="amount_paid"
                        label="Monto del recibo"
                        onChange={ this.changePayment } />
                    </div>
                    <div className="col-md-4">
                      <DatePicker
                        color="gray"
                        label="Fecha de Pago"
                        showYearDropdown={ true }
                        maxDate={ moment().toDate() }
                        onChange={ (value: string) => this.changePayment({
                          target: {
                            value,
                            name: 'date'
                          }
                        }) }
                        value={ this.state.form_payment.date }
                      />
                    </div>
                  </React.Fragment>
                )
              }
              {
                TRANSFER == parseInt(this.state.form_payment.payment_method_id) && (
                  <React.Fragment>
                    <div className="col-md-4">
                      <Select
                        color="gray"
                        name="bank_account_id"
                        onChange={ this.changePayment }
                        label="Cuenta de Banco"
                        value={ this.state.form_payment.bank_account_id }
                        options={ this.state.bank_accounts.map((i: any) => {
                          return {
                            value: i.id,
                            label: i.name
                          }
                        }) } />
                    </div>
                    <div className="col-md-4">
                      <DatePicker
                        color="gray"
                        label="Fecha de Transferencia"
                        showYearDropdown={ true }
                        maxDate={ moment().toDate() }
                        onChange={ (value: string) => this.changePayment({
                          target: {
                            value,
                            name: 'date'
                          }
                        }) }
                        value={ this.state.form_payment.date }
                      />
                    </div>
                    {/* <div className="col-md-4">
                      <InputCurrency
                        value={ this.state.form_payment.amount_paid }
                        name="amount_paid"
                        label="Monto del recibo"
                        onChange={ this.changePayment } />
                    </div> */}
                    <div className="col-md-4">
                      <PaperClip
                        name="file"
                        formats={ ImagesAndDocuments }
                        value={ this.state.form_payment.file }
                        className={ this.state.form_payment.file && 'active' }
                        successText="Comprobante Cargado"
                        pendingText="Subir Comprobante"
                        onChange={ (e: any) => {
                          this.changePayment({
                            target: {
                              name: 'file',
                              value: e.target.dataURL,
                            }
                          })
                        } } />
                    </div>
                    {
                      this.state.form_payment.accepted == Constants.BILL_STATUS.ACCEPTED && (
                        <div className="col-md-4">
                          <DatePicker
                            color="gray"
                            label="Fecha de Recepción del Pago"
                            showYearDropdown={ true }
                            maxDate={ moment().toDate() }
                            onChange={ (value: string) => this.changePayment({
                              target: {
                                value,
                                name: 'reception_date'
                              }
                            }) }
                            value={ this.state.form_payment.reception_date }
                          />
                        </div>
                      )
                    }
                    {/* <div className="col-md-4">
                      <Checkbox
                        name="accepted"
                        inline={ true }
                        label="Pago Conciliado"
                        checked={ this.state.form_payment.accepted == Constants.BILL_STATUS.ACCEPTED }
                        onChange={ () => {
                          this.setState({
                            form_payment: {
                              ...this.state.form_payment,
                              accepted: this.state.form_payment.accepted == Constants.BILL_STATUS.ACCEPTED ? Constants.BILL_STATUS.PENDING : Constants.BILL_STATUS.ACCEPTED
                            }              
                          });
                        } } />
                    </div> */}
                  </React.Fragment>
                )
              }
            </div>
            <div className="text-right">
              <Button type="button" className="btn-total" onClick={ () => this.finish() }>
                Guardar
              </Button>
            </div>
          </React.Fragment>
        </Modal>

        <Modal
            className="modal-change-status-payment"
            visible={ visible_accepted }
            onClose={ this.onCloseAccepted } 
            title="Conciliar pago"
        >
          <form onSubmit={ this.submit }>
            <div className="row">
              <div className="col-12">
                <DatePicker
                    color="gray"
                    label="Fecha de Recepción del Pago"
                    showYearDropdown={ true }
                    maxDate={ moment().toDate() }
                    onChange={ (value: string) => {
                      this.setState({
                        date: value
                      });
                    } }
                    value={ this.state.date }
                  />
              </div>
              {/* <div className="col-12">
                <Input
                    color="gray"
                    label="Número de control"
                    showYearDropdown={ true }
                    onChange={ (e: any) => {
                      this.setState({
                        control_number: e.target.value
                      });
                    } }
                    value={ this.state.control_number }
                  />
              </div> */}
              <div className="col-md-12">
                <InputCurrency
                  color="gray"
                  value={ this.state.amount_paid }
                  name="amount_paid" 
                  label="Monto Pagado"
                  onChange={ (e: any) => {
                    this.setState({
                      amount_paid: e.target.value
                    });
                  } } />
                  {/* {
                    item && (parseFloat(this.state.amount_paid) > Currency.ConvertAux(item.total - credit,item.conversion_petro)) && (
                      <p className="warning-credit-note">Si el monto pagado es mayor al de la factura, será utilizado para abonar a meses posteriores y la diferencia quedará en una nota de crédito</p>
                    )
                  } */}
              </div>
            </div>
            <div className="text-center">
              <Button type="submit">
                Enviar
              </Button>
            </div>
          </form>
        </Modal>

        <Modal
            className="modal-create-bank-account"
            visible={ visible_view }
            onClose={ this.onCloseView } 
            title="Pago Rechazado"
        >
          <div className="row">
            <div className="col-12">
              <Textarea
                  placeholder="Motivo de rechazo (Opcional)"
                  rows={ 4 }
                  color="white"
                  name="text"
                  disabled={ true }
                  value={ item?.reject_comments } />
            </div> 
          </div>
        </Modal>

        <Modal
          className="modal-view-vouchers"
          visible={ visible_vouchers }
          onClose={ this.onCloseViewVouchers }
          title="Comprobantes de pago"
        >
          <div className="row">
            <div className="col-12">
              <Table data={ item?.details_new.length } title="" header={ this.state.header_vouchers } rightAlign={ [8] }>
                { item?.details_new.map((i: any, index: number) => {
                  return (
                    <tr key={ index }>
                      <td>{ i.payment_method.name }</td>
                      <td>
                        <TooltipCurrency title={ i.amount_paid }>{ Globals.formatMiles(Currency.Convert(i.amount_paid, item.conversion_petro), true, Currency.BOLIVAR) }</TooltipCurrency>
                      </td>
                      <td>{ i.bank.name }</td>
                      <td>{ i.number }</td>
                      <td>{ i.date }</td>
                      <td>
                        { i.base64img &&
                          <Tooltip title="Ver Comprobante">
                            <a href={ i.base64img } download={`Comprobante de pago ${i.number}`} className="link-icon">
                              <img src={ ViewFileIcon } alt="Ver Comprobante" />
                            </a>
                          </Tooltip>
                        }
                        { (item.status === PENDING || item.status === TO_PAY) &&
                          <Tooltip title="Eliminar Comprobante">
                            <a href="#" className="link-icon" onClick={ (e: any) => this.deletePaymentBill(e, i) }>
                              <img src={ TrashIcon } alt="Eliminar Comprobante" />
                            </a>
                          </Tooltip>
                        }
                      </td>
                    </tr>
                  )
                })}
              </Table>
            </div>
          </div>
        </Modal>

        <Modal
          className="modal-create-bank-account"
          visible={ visible_control_number }
          onClose={ this.onCloseControlNumber } 
          title="Agregar número de control"
        >
          <div>
            <div className="row">
              <div className="col-12">
                <Input
                  name="control_number"
                  color="gray"
                  label="Número de control"
                  onChange={ (e: any) => {
                    this.setState({
                      control_number: e.target.value
                    });
                  } }
                  value={ this.state.control_number }
                />
              </div>
            </div>
            <div className="text-center">
              <Button type="submit" onClick={(e: any) => this.submitControlNumber()}>
                Enviar
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          className="modal-create-bank-account"
          visible={ visible_payment_status }
          onClose={ this.onClosePaymentStatus }
          title="Cambiar estado del pago"
        >
          <div>
            <div className="row">
              <div className="col-12">
                <div className="alert alert-warning mb-2">
                  <span>Esta acción no ejecuta ningún proceso interno, sólo actualiza el estado del pago.</span>
                </div>
              </div>
              <div className="col-12">
                <Select
                  label="Tipo"
                  color="white"
                  name="payment_status"
                  onChange={ (e: any) => {
                    this.setState({
                      payment_status: e.target.value
                    });
                  }}
                  value={ this.state.payment_status }
                  options={STATUS.filter((i: any) => i.value !== TO_PAY)}
                />
              </div>
            </div>
            <div className="text-center">
              <Button type="submit" onClick={(e: any) => this.submitPaymentStatus()}>
                Enviar
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          className="modal-create-bank-account"
          visible={ visible_created_at }
          onClose={ this.onCloseCreatedAt }
          title="Cambiar fecha"
        >
          <div>
            <div className="row">
              <div className="col-12">
                <DatePicker
                  color="white"
                  label="Fecha"
                  value={this.state.created_at}
                  onChange={ (value: string) => {
                    this.setState({
                      created_at: value
                    });
                  }}
                />
              </div>
            </div>
            <div className="text-center">
              <Button type="submit" onClick={(e: any) => this.submitCreatedAt()}>
                Enviar
              </Button>
            </div>
          </div>
        </Modal>

        <div className="row">
          <div className="col-md-2">
            <DatePicker
              color="white"
              label="Desde"
              value={this.state.form.init}
              onChange={ (value: string) => this.change({ 
                target: { name: 'init', value }                
              },() => this.load(true))}
            />
          </div>
          <div className="col-md-2">
            <DatePicker
              color="white"
              label="Hasta" 
              value={this.state.form.end}
              onChange={ (value: string) => this.change({ 
                target: { name: 'end', value }
              },() => this.load(true))}
            />
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <Select
                  label="Tipo"
                  color="white"
                  name="type"
                  onChange={(e: any) => this.change(e,() => {
                    this.load(true);
                  })}
                  placeholder="Tipo"
                  value={ this.state.form.type }
                  options={ this.state.types.map((item: any) => {
                    return {
                      value: item.id,
                      label: item.name
                    }
                  }) } 
                />
              </div>
              <div className="col-md-4">
                <Select
                  label="Medio de Pago"
                  color="white"
                  name="payment_method"
                  onChange={ (e: any) => {
                    this.change(e,() => this.load(true));
                  } }
                  placeholder="Método de pago"
                  value={ this.state.form.payment_method }
                  options={ this.state.methods.map((item: any) => {
                    return {
                      value: item.id,
                      label: item.name
                    }
                  }) } />
              </div>
              <div className="col-md-4">
                <Select
                  label="Estatus"
                  color="white"
                  name="status"
                  onChange={ (e: any) => {
                    this.change(e,() => this.load(true));
                  } }
                  placeholder="Estatus"
                  value={ this.state.form.status }
                  options={ STATUS } />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-6">
                <a href={ Constants.BASE_API + 'admin/cash/get-excel?' + Globals.getParams({
                  ...this.state.form
                }) }>
                  <Button className="btn-blue-create btn-filter">
                    Exportar Excel
                  </Button>
                </a>
              </div>
              {/* <div className="col-md-6">
                <a href={ Constants.BASE_API + 'admin/cash/get-pdf?' + Globals.getParams({
                  ...this.state.form
                }) }>
                  <Button className="btn-blue-create btn-filter">
                    Exportar PDF
                  </Button>
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-md-2">
          </div>
          <div className="col-md-6">
            <Input
              color="white"
              searchRight
              value={ this.state.form.search }
              name="search"
              placeholder="Buscar (Código, ID, Nombre, Monto)"
              onChange={ (e: any) => {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                  this.load(true);
                },1000);
                this.change(e); 
              } } 
            />
          </div>
        </div>

         {
            this.state.form != INITIAL_STATE && (
              <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
            )
          }  

        <Table data={ this.state.data.length } title="" header={ this.state.header } rightAlign={ [8] }>
          { this.state.data.map((i: any,index: number) => {
            return (
              <tr key={ index }>
                <td>{ i.user?.username}</td>
                <td>{ i.property?.urbaser_code}</td>
                <td>{ i.user?.retention ? 'SI' : 'NO' }</td>
                <td className="no-wrap">
                  
                  { checkPermission(EDIT) ?  

                    <a className="link-icon d-block h-100 w-100" href="#" onClick={(e: any) => this.viewCreatedAt(e, i) }>
                      { moment(i.created_at).format('DD/MM/YYYY') }
                    </a>
                    :
                    moment(i.created_at).format('DD/MM/YYYY')
                  }
                </td>
                <td>{ i.number_format }</td>
                <td>{ i.serial_code }</td>
                <td>
                  {/* { i.control_number ? i.control_number : */}
                  { checkPermission(EDIT) ?  
                    <Tooltip title="Agregar número de control">
                      <a className="link-icon d-block h-100 w-100" href="#" onClick={(e: any) => this.viewControlNumber(e, i) }>
                        {i.control_number ?? ' - '}
                      </a>
                    </Tooltip>
                    : i.control_number ?? ' - '
                   }
                   {/* } */}
                </td>
                <td className="no-wrap">{ i.bill_type?.name || '-' }</td>
                <td className="capitalize no-wrap">
                  {
                    i.since ? (
                      moment(i.since).format('MMM YYYY') + (
                        i.until && i.until != i.since ? (' - ' + moment(i.until).format('MMM YYYY')) : ''
                      )
                    ) : moment(i.created_at).format('MMM YYYY') 
                  }
                </td>
                <td>{ i.details?.number || '-' }</td>
                <td>{ i.details?.bank?.name || '-' }</td>
                <td>{ i.bill_detail_payments || '-' }</td>
                <td className="no-wrap text-right">
                  <TooltipCurrency title={ i.total }>{ Globals.formatMiles(Currency.Convert(i.total,i.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency>
                </td>
                <td className="no-wrap">
                  { checkPermission(EDIT_STATUS) ?
                    <Tooltip title="Cambiar estado">
                      <a className="link-icon d-block h-100 w-100" href="#" onClick={(e: any) => this.viewPaymentStatus(e, i) }>
                        {i.status_text ?? ' - '}
                      </a>
                    </Tooltip>
                    : i.status_text ?? ' - '
                  }
                </td>
                <td className="icon-actions">
                  {
                    (i.user?.retention == Constants.RETENTION.YES && i.status === Constants.BILL_STATUS.PENDING) ? (
                      <React.Fragment>
                        { checkPermission(EDIT) &&
                          <Tooltip title="Subir datos de pago">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.openPaymentRetention(e,i) }>
                              <img src={ MoneyIcon } />
                            </a>
                          </Tooltip>
                        }
                        <Tooltip title="Ver Factura">
                          <a target="_blank" className="link-icon" href={ Constants.BASE_API+ 'admin/cash/print/' + i.crypt + '/' + this.props.user.crypt}>
                            <img src={ ViewIcon } />
                          </a>
                        </Tooltip>
                        { checkPermission(EDIT) &&
                          <Tooltip title="Conciliada">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.changeStatus(e,i,Constants.BILL_STATUS.ACCEPTED, i.user?.retention) }>
                              <img src={ CheckIcon } />
                            </a>
                          </Tooltip>
                        }
                        { checkPermission(EDIT) &&
                          <Tooltip title="Cancelar">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.changeStatus(e,i,Constants.BILL_STATUS.CANCELLED, i.user?.retention) }>
                              <img src={ CloseIcon } />
                            </a>
                          </Tooltip>
                        }
                        {
                          i.details_new && i.details_new.length > 0 && (
                            <Tooltip title="Comprobantes">
                              <a className="link-icon" href="#" onClick={ (e: any) => {
                                e.preventDefault();
                                this.setState({
                                  visible_vouchers: true,
                                  item: i
                                });
                              } }>
                                <img src={ ViewFileIcon } />
                              </a>
                            </Tooltip>
                          )
                        }
                        {
                          i.details?.file && (
                            <Tooltip title="Ver Comprobante">
                              <a target="_blank" className="link-icon" href={ Constants.BASE_STORAGE + i.details?.file }>
                                <img src={ ViewFileIcon } />
                              </a>
                            </Tooltip>
                          )
                        }
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {
                          i.details_new && i.details_new.length > 0 && (
                            <Tooltip title="Comprobantes">
                              <a className="link-icon" href="#" onClick={ (e: any) => {
                                e.preventDefault();
                                this.setState({
                                  visible_vouchers: true,
                                  item: i
                                });
                              } }>
                                <img src={ ViewFileIcon } />
                              </a>
                            </Tooltip>
                          )
                        }
                        {
                          i.details?.file && (
                            <Tooltip title="Ver Comprobante">
                              <a target="_blank" className="link-icon" href={ Constants.BASE_STORAGE + i.details?.file }>
                                <img src={ ViewFileIcon } />
                              </a>
                            </Tooltip>
                          )
                        }
                        {
                          i.reject_comments && (
                            <Tooltip title="Ver Comentarios">
                              <a className="link-icon" href="#" onClick={ (e: any) => {
                                e.preventDefault();
                                this.setState({
                                  visible_view: true,
                                  item: i
                                });
                              } }>
                                <img src={ ViewIcon } />
                              </a>
                            </Tooltip>
                          )
                        }
                        {
                           i.status == Constants.BILL_STATUS.PENDING && (
                             <React.Fragment>
                              { checkPermission(EDIT) &&
                                <Tooltip title="Subir datos de pago">
                                  <a className="link-icon" href="#" onClick={ (e: any) => this.openPaymentRetention(e,i) }>
                                    <img src={ MoneyIcon } />
                                  </a>
                                </Tooltip>
                              }
                              { checkPermission(EDIT) &&
                                <Tooltip title="Conciliada">
                                  <a className="link-icon" href="#" onClick={ (e: any) => this.changeStatus(e,i,Constants.BILL_STATUS.ACCEPTED, i.user?.retention) }>
                                    <img src={ CheckIcon } />
                                  </a>
                                </Tooltip>
                              }
                              { checkPermission(EDIT) &&
                                <Tooltip title="Rechazada">
                                  <a className="link-icon" href="#" onClick={ (e: any) => this.changeStatus(e,i,Constants.BILL_STATUS.REJECTED, i.user?.retention) }>
                                    <img src={ CloseIcon } />
                                  </a>
                                </Tooltip>
                              }
                            </React.Fragment>
                           )
                        }
                        {
                          i.status == Constants.BILL_STATUS.ACCEPTED && (
                            <React.Fragment>
                              <Tooltip title="Ver Factura">
                                <a target="_blank" className="link-icon" href={ Constants.BASE_API+ 'admin/cash/print/' + i.crypt + '/' + this.props.user.crypt}>
                                  <img src={ ViewIcon } />
                                </a>
                              </Tooltip>
                              { checkPermission(EDIT) && 
                                <Tooltip title="Anular">
                                  <a className="link-icon" href="#" onClick={ (e: any) => this.cancel(e,i) }>
                                    <img src={ CloseIcon } />
                                  </a>
                                </Tooltip>
                              }
                            </React.Fragment>
                          )
                        }
                      </React.Fragment>
                    )
                  }
                </td>
                <td className="no-wrap">
                  { i.status == Constants.BILL_STATUS.ACCEPTED && (
                    <Tooltip title={ i.delivery ? "Desactivar delivery" : "Activar delivery" }>
                      <a className="link-icon" href="#" onClick={ (e: any) => this.toggleDelivery(e, i) }>
                        <Checkbox value={i.id} checked={i.delivery} inline={true} />
                      </a>
                    </Tooltip>
                  )}
                </td>
              </tr>
            )
          }) }
        </Table>

        <Pagination 
          pages={ this.state.last_page } 
          active={ this.state.page }
          onChange={ async (page: number) => {
            await this.setState({
              page: page
            });
            this.load();
        } } />
      </div>
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(Payments);