import React from 'react';
import { AccountStatusService } from 'services';
import { Table, Pagination } from 'components';
import { Globals, Currency } from 'utils';
import moment from 'moment';

interface Props {
	solvency: any
}

class ViewSolvency extends React.Component<Props> {

	state = {
		solvency: null,
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		this.setState({
			solvency: this.props.solvency
		});
	}
	
	render() {
		const solvency: any = this.state.solvency;

		return (
			<div id="admin-view-solvency">
				{
					solvency && (
						<React.Fragment>
							<h3>Certificado Nro {solvency.number_format }</h3>
                <div className="container-data">
									<div className="item-data-one">
										<p><strong>Código Contribuyente:</strong> { solvency.user.id }</p>
										<p><strong>Razón Social / Nombre:</strong> { solvency.user.full_name }</p>
										<p><strong>RIF/CI:</strong> { solvency.user.full_document }</p>
										<p><strong>Código:</strong> { solvency.property.urbaser_code }</p>
										<p><strong>Datos del Inmueble:</strong> 
											<br/><strong>Actividad Económica:</strong> {solvency.property?.economic_activity?.name}
											<br/><strong>Dirección:</strong> {solvency.property?.zone} {solvency.property?.street} <b>Piso: </b> {solvency.property?.floor} <b>APTO/CASA/LOCAL: </b>{solvency.property?.house_number}
										</p>
										<p>Fecha de emisión: { moment(solvency.created_at).format('DD/MM/YYYY') }</p>
									</div>
								</div>
						</React.Fragment>
					)
				}
			</div>
		)
	}
}

export default ViewSolvency;