import React from "react"
import MaskedInput from 'react-text-mask';
import createRutMask from 'text-mask-rut';

const rutMask = createRutMask();

const RutInput = React.forwardRef((props: any, ref) => (
  	<div className="form-group form-group-input" style={props.containerStyle}>
		{ props.label && <label htmlFor={ props.name }>{ props.label }</label> }
		<MaskedInput 
			mask={ rutMask }
			ref={ ref }
			{ ...props }
			onChange={ props.onChange }
			className={ `form-control ${ props.searchRight ? 'search-right' : '' } ${ props.color ? 'input-'+props.color : 'input-white' }` }
			name={ props.name } />
	</div>
))

export default RutInput;