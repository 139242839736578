import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Input, Select, Button, Tooltip, DatePicker, Modal, Textarea } from 'components';
import { checkPermission, Permissions, Constants, Socket, SocketEvents, Globals, Print, Currency } from 'utils';
import { ReportService } from 'services';
import { ViewIcon } from 'assets/icons';
import moment from 'moment';

interface LocationProps {
  page: number | undefined;
  form: any
}

interface Props extends RouteComponentProps<{},{},LocationProps> {
  dispatch: any
}

const { REJECTED, CANCELLED, REJECTED_LABEL, CANCELLED_LABEL } = Constants.BILL_STATUS;

const INITIAL_STATE = {
  search: '',
  since: '',
  until: '',
  type: '',
  status: ''
}


const STATUS = [
  { value: REJECTED.toString(), label: REJECTED_LABEL },
  { value: CANCELLED.toString(), label: CANCELLED_LABEL }
]

class Report extends React.Component<Props> {  

  timer: any = undefined;

  state = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'Código',
      'Cód. Contribuyente',
      'RIF',
      'Nombre / Razón Social',
      'Nro. Factura',
      'Fecha',
      'Estatus',
      'Acciones'
    ],
    form: INITIAL_STATE,
    visible: false,
    client: null,
    conversion_dolar: 0,
    form_search: '',
    visible_view: false,
    item:null,
    list_status: []
  }

  async componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Facturas Anuladas',
        onBack: null
      }
    });

    await this.setState({
      page: this.props.location.state?.page || this.state.page,
      form: this.props.location.state?.form || this.state.form
    });

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await ReportService.admin.voided_invoices({
      page: this.state.page,
      withoutLoading,
      ...this.state.form
    });
    this.setState({
      data: res.bills.data,
      last_page: res.bills.last_page,
      conversion_dolar: res.conversion_dolar?.amount || 0,
    });
  }


  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

    print = (e: any, item: any) => {
    e.preventDefault();
   
  }

  view = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      client: item
    });
  }


  onClose = () => {
    this.setState({
      visible: false
    });
  }

  onCloseView = () => {
    this.setState({
      visible_view: false
    });
  }
  
  render() {
    const { visible, visible_view } = this.state;
    const item: any = this.state.item;

    return (
        <div id="voided-invoices">
            <div className="row">

              <div className="col-md-4">
                <DatePicker
                  color="white"
                  label="Desde"
                  maxDate={ moment(this.state.form.until || moment()).toDate() }
                  value={this.state.form.since}
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'since', value }
                  },() => this.load(true))}
                />
              </div>

              <div className="col-md-4">
                <DatePicker
                  color="white"
                  label="Hasta"
                  maxDate={ moment().toDate() }
                  minDate={ this.state.form.since && moment(this.state.form.since).toDate() }
                  value={this.state.form.until}
                  onChange={ (value: string) => this.change({ 
                    target: { name: 'until', value }
                  },() => this.load(true))}
                />
              </div>
              <div className="col-md-4">
                <Select
                  label="Estatus"
                  color="white"
                  name="status"
                  placeholder="Estatus"
                  onChange={(e: any) => this.change(e,() => this.load(true))}
                  value={ this.state.form.status }
                  options={ STATUS } 
                />
              </div>

            </div>
            <div className="row area-movil">
              <div className="col-md-3">
                {
                  this.state.data.length > 0 && (
                    <div>
                      <a href={ Constants.BASE_API + 'admin/reports/voided-invoices/excel?' + Globals.getParams(this.state.form) }>
                        <Button className="btn-blue-create">
                          Exportar Excel
                        </Button>
                      </a>
                    </div>
                  )
                }
              </div>
              <div className="col-md-3">
                {
                  this.state.data.length > 0 && (
                    <div>
                      <a href={ Constants.BASE_API + 'admin/reports/voided-invoices/pdf?' + Globals.getParams(this.state.form) }>
                        <Button className="btn-blue-create">
                          Exportar PDF
                        </Button>
                      </a>
                    </div>
                  )
                }
              </div>
            <div className="col-md-5">
                <Input
                  color="white"
                  searchRight
                  value={ this.state.form.search }
                  name="search"
                  placeholder="Buscar (Contribuyente, Nro. Factura)"
                  onChange={ (e: any) => {
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                      this.load(true);
                    },1000);
                    this.change(e); 
                  } } />
              </div>
  
            </div>


              {
                this.state.form != INITIAL_STATE && (
                  <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
                )
              }

              <Modal
                className="modal-create-bank-account"
                visible={ visible_view }
                onClose={ this.onCloseView } 
                title="Pago Rechazado"
              >
                <div className="row">
                  <div className="col-12">
                    <Textarea
                        placeholder="Motivo de rechazo (Opcional)"
                        rows={ 4 }
                        color="white"
                        name="text"
                        disabled={ true }
                        value={ item?.reject_comments } />
                  </div> 
                </div>
              </Modal>  

              <Table data={ this.state.data.length } title="" header={ this.state.header }>
                { this.state.data.map((report: any,index: number) => {
                  return (
                    <tr key={ index }>
                      <td>{ report.property.urbaser_code }</td>
                      <td>{ report.user?.number_format }</td>
                      <td>{ report.user?.full_document }</td>
                      <td>{ report.user?.full_name }</td>
                      <td>{ report.number_format || '-' }</td>
                      <td>{ moment(report.rejected_at).format('DD/MM/YYYY') }</td>
                      <td>{ report.status_text }</td>
                      <td>
                        <Tooltip title="Ver Comentarios">
                          <a className="link-icon" href="#" onClick={ (e: any) => {
                              e.preventDefault();
                              this.setState({
                                visible_view: true,
                                item: report
                              });
                            } }>
                              <img src={ ViewIcon } />
                          </a>
                        </Tooltip>
                      </td>
                    </tr>
                  )
                }) }
              </Table>

             
              <div className="row">
                <Pagination 
                  pages={ this.state.last_page } 
                  active={ this.state.page }
                  onChange={ async (page: number) => {
                    await this.setState({
                      page: page
                    });
                    this.load();
                } } />
              </div>
        </div>        
    )
  }
}

export default connect()(Report);