import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const Button = React.forwardRef((props: any, ref) => {

	const renderButton = () => {
		return (
			<button 
				{ ...props } 
				title=""
				ref={ ref }
				className={ `btn ${ props.className || '' }
					${ props.outline ? 'btn-outline' : '' }
					${ props.clear ? 'btn-clear' : '' }
					${ props.color ? 'btn-' + props.color : 'btn-primary' }
					${ props.small ? 'btn-small' : '' }
					${ props.block ? 'btn-block' : '' }` }>
				{ props.children }
			</button>
		)		
	}

	if (props.title) {
		return (
			<OverlayTrigger
		      placement="top"
		      overlay={
		        <Tooltip id="tooltip-top">
		          { props.title }
		        </Tooltip>
		      }
		    >
				{ renderButton() }
			</OverlayTrigger>
		)
	}
	else {
		return renderButton();
	}		
});

export default Button;