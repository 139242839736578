import { Api, Constants } from 'utils';

class RouteService {

	static get = async (params?: any) => Api.createResponse('routes',params);

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/routes',params),
		read: async (params?: any) => Api.createResponse('admin/routes/read',params),
	}
}

export default RouteService;