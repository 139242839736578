import React from 'react';
import { Input, InputCurrency, Table, Button, Checkbox } from 'components';
import { Constants, Globals, RegisterMovement } from 'utils';
import { EconomicActivityService } from 'services';

interface Props {
	activity: any,
	types: any,
	onClose: (reload: boolean) => void,
	user: any
}

class EditTariff extends React.Component<Props> {

	state = {
		form: {
			name: '',
			size: '',
			tax_free: Constants.TAX_FREE.YES
		},
		prices: [],
		header: [
			'Factor Comercial',
			'Coeficiente Adicional',
			'Factor de Ajuste'
		]
	}

	componentDidMount() {
		const { activity } = this.props;

		this.setState({
			form: {
				name: activity.name,
				size: activity.size,
				tax_free: activity.tax_free
			},
			prices: activity.prices
		});
	}

	change = (e: any,callback = () => {}) => {
	    this.setState({
	      form: {
	        ...this.state.form,
	        [e.target.name]: e.target.value
	      }
	    },callback);
	}

	submit = async () => {
		await EconomicActivityService.admin.edit({
			economic_activity_id: this.props.activity.id,
			...this.state.form,
			prices: JSON.stringify(this.state.prices)
		});
		RegisterMovement.save({
	        value_id: this.props.activity.id,
	        user_id: this.props.user.id,
	        module_id: Constants.MODULES.TARIFF,
	        description: `Modificación de la actividad económica ${ this.props.activity.name }`
	    });
		this.props.onClose(true);
		Globals.showSuccess("Se ha modificado la actividad económica");
	}

	changePrice = (value: string,name: string,id: number) => {
		let prices: any = [...this.state.prices];
		const index: number = prices.findIndex((i: any) => i.id == id);
		prices[index][name] = value;
		this.setState({
			prices
		});
	}
	
	render() {
		const { types, activity } = this.props;

		return (
			<div id="admin-edit-tariff">
				<div className="row">
					<div className="col-md-4">
						<Input
                          color="white"
                          value={ this.state.form.name }
                          name="name" 
                          label="Nombre"
                          onChange={ this.change } />
					</div>
					<div className="col-md-4">
						<Input
                          color="white"
                          value={ this.state.form.size }
                          name="size" 
                          type="decimal"
                          label="Área Máxima"
                          onChange={ this.change } />
					</div>
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<Checkbox
							name="tax_free"
							inline={ true }
							label="Exento de IVA"
							checked={ this.state.form.tax_free == Constants.TAX_FREE.YES }
							onChange={ () => {
								this.setState({
									form: {
										...this.state.form,
										tax_free: this.state.form.tax_free == Constants.TAX_FREE.YES ? Constants.TAX_FREE.NO : Constants.TAX_FREE.YES
									}							
								});
							} } />
					</div>
				</div>

				{
					types.map((item: any,index: number) => {
						const prices: any = this.state.prices.filter((i: any) => i.service_type_id == item.id);

						return (
							<React.Fragment key={ index }>
								<h3>{ item.name }</h3>
								<Table title="" data={ prices.length } header={ this.state.header }>
									{
										prices.map((item: any,index: number) => (
											<tr key={ index }>
												<td>
													<InputCurrency
							                          color="white"
							                          value={ item.commercial_factor }
							                          name="commercial_factor" 
							                          placeholder="Factor Comercial"
							                          onChange={ (e: any) => this.changePrice(e.target.value,e.target.name,item.id) } />
												</td>
												<td>
													<InputCurrency
							                          color="white"
							                          value={ item.additional_coefficient }
							                          name="additional_coefficient" 
							                          placeholder="Coeficiente Adicional"
							                          onChange={ (e: any) => this.changePrice(e.target.value,e.target.name,item.id) } />
												</td>
												<td>
													<InputCurrency
							                          color="white"
							                          value={ item.adjustment_factor }
							                          name="adjustment_factor" 
							                          placeholder="Factor de Ajuste"
							                          onChange={ (e: any) => this.changePrice(e.target.value,e.target.name,item.id) } />
												</td>
											</tr>
										))
									}
								</Table>								
							</React.Fragment>
						)
					})
				}

				<div className="text-center">
					<Button type="button" onClick={ this.submit }>
						Guardar
					</Button>
				</div>
			</div>
		)
	}
}

export default EditTariff;