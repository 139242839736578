import React from 'react';
import { UserService } from 'services';

interface Props {
	moderator: any
}

class ViewModerator extends React.Component<Props> {

	state = {
		user: null
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await UserService.admin.getUser({
			id: this.props.moderator?.id
		});
		this.setState({
			user: res.user
		});
	}
	
	render() {
		const user: any = this.state.user;

		return (
			<div id="admin-view-client">
				{
					user && (
						<React.Fragment>
							<h3>Datos del Usuario</h3>
							<div className="container-data">
								<div className="item-data">
									<p><strong>Caja:</strong> { user.box }</p>
									<p><strong>Nombre:</strong> { user.name }</p>
									<p><strong>Apellido:</strong> { user.lastname }</p>
									<p><strong>CI/RIF:</strong> { user.full_document }</p>
									<p><strong>Teléfono:</strong> { user.full_phone }</p>
									<p><strong>Rol</strong> { user.roles.length > 0? user.roles[user.roles.length - 1].name : ''}</p>
								</div>
							</div>
						</React.Fragment>
					)
				}
			</div>
		)
	}
}

export default ViewModerator;