import React from 'react'
import { TooltipCurrency } from 'components'
import { Currency, Globals } from 'utils'
import { useCashContext } from './useCashContext'

export const CashDetailsItem = ({ title, value, className }: { title: string; value: number; className?: string }) => {
  const { property } = useCashContext()
  const petro_value = property?.petro_value ?? 1

  return (
    <li className={`mb-2 ${className ?? ''}`}>
      <span>{title}: </span>
      <TooltipCurrency title={value / petro_value}>
        {Globals.formatMiles(Currency.Convert(value, 1), true, Currency.BOLIVAR)}
        {/* {Globals.formatMiles(Currency.Convert(value, petro_value), true, Currency.BOLIVAR)} */}
      </TooltipCurrency>
    </li>
  )
}

const CashPaymentDetails = () => {
  const { paymentDetail } = useCashContext()

  return (
    <div className="p-3 pt-4 my-4 rounded-lg" style={{ backgroundColor: '#c7ccd1' }}>
      <ul className="list-inline text-right">
        <CashDetailsItem title="Subtotal" value={paymentDetail.subtotal} className="mt-2" />
        <CashDetailsItem title="Base Imponible" value={paymentDetail.taxBase} />
        <CashDetailsItem title="Multa" value={paymentDetail.penaltyFee} />
        <CashDetailsItem title="Exento" value={paymentDetail.exempt} />
        <CashDetailsItem title="Iva (16%)" value={paymentDetail.iva} />
        <CashDetailsItem title="Retención" value={paymentDetail.retention} />
        <CashDetailsItem title="Total Factura" value={paymentDetail.total} className="mt-4" />
        <CashDetailsItem title="Total a Pagar" value={paymentDetail.total_to_pay} />
        { !!paymentDetail.credit &&
          <>
            <CashDetailsItem title="Crédito" value={paymentDetail.credit} className="mt-4" />
            <p className="warning-credit-note">El crédito será utilizado para abonar a meses posteriores y la diferencia quedará en una nota de crédito</p>
          </>
        }
      </ul>
    </div>
  )
}

export default CashPaymentDetails
