import React from 'react'
import { RootState } from 'reducers'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Input, Select, DatePicker, Modal } from 'components'
import { ClientService } from 'services'
import { Socket, SocketEvents, Globals, Constants, RegisterMovement } from 'utils'
import { TrashIcon } from 'assets/icons'
import moment from 'moment'
import FormClientProperty from 'components/FormClientProperty'
import { Numeric } from 'utils'

interface Props extends RootState, RouteComponentProps<Params> {
  dispatch: any
}

interface Params {
  id: string | undefined
  propertyId: string | undefined
}

// const TABS = {
//   DATA: 1,
//   PROPERTIES: 2
// }

class EditClient extends React.Component<Props> {
  // state = {
  //   form: {
  //     name: '',
  //     lastname: '',
  //     username: '',
  //     email: '',
  //     phone: '',
  //     document_type_id: '',
  //     document: '',
  //     password: '',
  //     business_name: '',
  //     economic_activity: '',
  //     fiscal_address: '',
  //     retention: Constants.RETENTION.NO
  //   },
  //   codes: [],
  //   document_types: [],
  //   emails: [],
  //   phones: [],
  //   property_uses: [],
  //   property_types: [],
  //   economic_activities: [],
  //   tab: TABS.DATA,
  //   properties: [],
  //   visible: false,
  //   property: null
  // }

  // componentDidMount() {
  //   this.props.dispatch({
  //     type: 'SET_HEADER',
  //     payload: {
  //       title: 'Editar Contribuyente',
  //       onBack: () => {
  //         this.props.history.push({
  //           pathname: '/admin/users/clients',
  //           state: this.props.location.state
  //         });
  //       }
  //     }
  //   });

  //   this.load();
  // }

  // load = async (withoutLoading: boolean = false) => {
  //   const res: any = await ClientService.admin.getUser({
  //     id: this.props.match.params.id,
  //     propertyId: this.props.match.params.propertyId
  //   });
  //   this.setState({
  //     codes: res.codes,
  //     document_types: res.document_types,
  //     form: {
  //       ...this.state.form,
  //       name: res.user.name,
  //       lastname: res.user.lastname,
  //       username: res.user.username,
  //       email: res.user.email,
  //       phone: res.user.phone,
  //       document_type_id: res.user.document_type_id,
  //       document: res.user.document,
  //       business_name: res.user.business_name,
  //       economic_activity: res.user.economic_activity,
  //       fiscal_address: res.user.fiscal_address,
  //       retention: res.user.retention
  //     },
  //     emails: res.user.emails,
  //     phones: res.user.phones,
  //     economic_activities: res.economic_activities,
  //     property_types: res.property_types,
  //     property_uses: res.property_uses,
  //     properties: res.user.properties.map((item: any) => {
  //       item.date = moment(item.date);
  //       if (item.date.isValid()) {
  //         item.date = item.date.toDate();
  //       }
  //       else {
  //         item.date = '';
  //       }
  //       item.secondary = item.secondary_properties.map((item: any) => {
  //         item.date = moment(item.date);
  //         if (item.date.isValid()) {
  //           item.date = item.date.toDate();
  //         }
  //         else {
  //           item.date = '';
  //         }
  //         return {
  //           id: item.id,
  //           owner: item.owner || Constants.PROPERTIES.OWNER.YES,
  //           economic_activity_id: item.economic_activity_id || '',
  //           property_use_id: item.property_use_id || '',
  //           property_type_id: item.property_type_id || '',
  //           catastral_id: item.catastral_id || '',
  //           nic: item.nic || '',
  //           license: item.license || '',
  //           urbaser_code: item.urbaser_code || '',
  //           date: item.date || '',
  //           vba_number: item.vba_number || '',
  //           floor: item.floor || '',
  //           house_number: item.house_number || '',
  //           size: item.size || '',
  //           type_form: item.type_form || Constants.TYPE_FORM.COMERCIAL,
  //         }
  //       })
  //       return {
  //         id: item.id,
  //         owner: item.owner || Constants.PROPERTIES.OWNER.YES,
  //         economic_activity_id: item.economic_activity_id || '',
  //         property_use_id: item.property_use_id || '',
  //         property_type_id: item.property_type_id || '',
  //         catastral_id: item.catastral_id || '',
  //         nic: item.nic || '',
  //         license: item.license || '',
  //         urbaser_code: item.urbaser_code || '',
  //         date: item.date || '',
  //         vba_number: item.vba_number || '',
  //         street: item.street || '',
  //         floor: item.floor || '',
  //         zone: item.zone || '',
  //         house_number: item.house_number || '',
  //         size: item.size || '',
  //         reference: item.reference || '',
  //         secondary: item.secondary || [],
  //         quantity_houses: item.quantity_houses || '',
  //         type_form: item.type_form || Constants.TYPE_FORM.ESTATE,
  //       }
  //     })
  //   });
  // }

  // submit = async (e: any) => {
  //   e.preventDefault();

  //   /* if (this.state.properties.filter((i: any) => {
  //     const values = Object.keys(i).filter((key) => {
  //       let response = i[key] === '' && key != 'quantity_houses';
  //       if (i['property_type_id'] == Constants.PROPERTY_TYPES.RESIDENTIAL && Constants.OPTIONAL_FIELDS.indexOf(key) != -1) {
  //         response = false;
  //       }
  //       if (Constants.OPTIONAL_FIELDS_ALL.indexOf(key) != -1) {
  //         response = false;
  //       }
  //       return response;
  //     });
  //     return values.length > 0;
  //   }).length > 0) {
  //     Globals.showError("Debe ingresar todos los datos de las propiedades");
  //     return false;
  //   }

  //   if (this.state.properties.filter((item: any) => {
  //     return item.property_type_id == Constants.PROPERTY_TYPES.RESIDENTIAL && item.property_use_id == Constants.PROPERTY_USES.CONDOMINIUM && !item.quantity_houses;
  //   }).length > 0) {
  //     Globals.showError("Debe ingresar todos los datos de las propiedades");
  //     return false;
  //   }

  //   if (this.state.properties.filter((i: any) => {
  //     const values = i.secondary.filter((i: any) => {
  //       const values = Object.keys(i).filter((key) => {
  //         let response = i[key] === '';
  //         if (i['property_type_id'] == Constants.PROPERTY_TYPES.RESIDENTIAL && Constants.OPTIONAL_FIELDS.indexOf(key) != -1) {
  //           response = false;
  //         }
  //         if (Constants.OPTIONAL_FIELDS_ALL.indexOf(key) != -1) {
  //           response = false;
  //         }
  //         return response;
  //       });

  //       return values.length > 0;
  //     });

  //     return values.length > 0;
  //   }).length > 0) {
  //     Globals.showError("Tiene una actividad economica secundaria con datos por llenar");
  //     return false;
  //   }

  //   if (this.state.properties.filter((i: any) => {
  //     return i.size <= 0;
  //   }).length > 0) {
  //     Globals.showError("El campo metraje de las propiedades debe ser mayor a 0");
  //     return false;
  //   }

  //   if (this.state.properties.filter((i: any) => {
  //     const values = i.secondary.filter((i: any) => {
  //       return i.size <= 0;
  //     });

  //     return values.length > 0;
  //   }).length > 0) {
  //     Globals.showError("El campo metraje de las actividades economicas secundarias debe ser mayor a 0");
  //     return false;
  //   } */

  //   const properties: any = Globals.clone(this.state.properties);

  //   const res: any = await ClientService.admin.edit({
  //     ...this.state.form,
  //     id: this.props.match.params.id,
  //     emails: JSON.stringify(this.state.emails),
  //     phones: JSON.stringify(this.state.phones),
  //     properties: JSON.stringify(properties.map((item: any) => {
  //       item.date = item.date && moment(item.date,'DD-MM-YYYY').format('YYYY-MM-DD');
  //       return item;
  //     }))
  //   });
  //   Globals.showSuccess("Se han guardado los datos correctamente");
  //   /* Socket.emit(SocketEvents.USERS.UPDATE,{
  //     id: this.props.match.params.id
  //   }); */
  //   this.props.history.push({
  //     pathname: '/admin/users/clients',
  //     state: this.props.location.state
  //   });

  //   RegisterMovement.save({
  //       value_id: res.client?.id,
  //       user_id: this.props.user.id,
  //       module_id: Constants.MODULES.CLIENTS,
  //       description: `Edición del contribuyente ${ res.client?.full_name } ${ res.client?.full_document }`
  //   });
  // }

  // change = (e: any,callback = () => {}) => {
  //   this.setState({
  //     form: {
  //       ...this.state.form,
  //       [e.target.name]: e.target.value
  //     }
  //   },callback);
  // }

  // changeEmails = (e: any,index: number) => {
  //   let emails: any = [...this.state.emails];
  //   emails[index].email = e.target.value;
  //   this.setState({
  //     emails
  //   });
  // }

  // changePhones = (e: any,index: number) => {
  //   let phones: any = [...this.state.phones];
  //   phones[index].phone = e.target.value;
  //   this.setState({
  //     phones
  //   });
  // }

  // addPhone = () => {
  //   let phones: any = [...this.state.phones];
  //   phones.push({
  //     phone: ''
  //   });
  //   this.setState({
  //     phones
  //   });
  // }

  // addEmail = () => {
  //   let emails: any = [...this.state.emails];
  //   emails.push({
  //     email: ''
  //   });
  //   this.setState({
  //     emails
  //   });
  // }

  // deletePhone = (index: number) => {
  //   let phones: any = [...this.state.phones];
  //   phones.splice(index,1);
  //   this.setState({
  //     phones
  //   });
  // }

  // deleteEmail = (index: number) => {
  //   let emails: any = [...this.state.emails];
  //   emails.splice(index,1);
  //   this.setState({
  //     emails
  //   });
  // }

  // addProperty = () => {
  //   let properties: any = [...this.state.properties];
  //   properties.push({
  //     owner: Constants.PROPERTIES.OWNER.YES,
  //     economic_activity_id: '',
  //     property_use_id: '',
  //     property_type_id: '',
  //     catastral_id: '',
  //     nic: '',
  //     license: '',
  //     urbaser_code: '',
  //     date: '',
  //     vba_number: '',
  //     street: '',
  //     floor: '',
  //     zone: '',
  //     house_number: '',
  //     size: '',
  //     reference: '',
  //     secondary: [],
  //     quantity_houses: '',
  //     type_form: Constants.TYPE_FORM.ESTATE,
  //   });
  //   this.setState({
  //     properties
  //   });
  // }

  // deleteProperty = (index: number) => {
  //   Globals.confirm('¿Desea eliminar la propiedad?',() => {
  //     let properties: any = [...this.state.properties];
  //     properties.splice(index,1);
  //     this.setState({
  //       properties
  //     });
  //   });
  // }

  // setValueProperty = (value: any,target: string,index: number,callback: any = () => {}) => {
  //   let properties: any = [...this.state.properties];
  //   properties[index][target] = value;
  //   this.setState({
  //     properties
  //   },callback);
  // }

  // setTab = (tab: number) => {
  //   this.setState({
  //     tab
  //   });
  // }

  // onClose = () => {
  //   if (this.state.properties.filter((i: any) => {
  //     const values = i.secondary.filter((i: any) => {
  //       const values = Object.keys(i).filter((key) => {
  //         let response = i[key] === '';
  //         if (i['property_type_id'] == Constants.PROPERTY_TYPES.RESIDENTIAL && Constants.OPTIONAL_FIELDS.indexOf(key) != -1) {
  //           response = false;
  //         }
  //         if (Constants.OPTIONAL_FIELDS_ALL.indexOf(key) != -1) {
  //           response = false;
  //         }
  //         return response;
  //       });

  //       return values.length > 0;
  //     });

  //     return values.length > 0;
  //   }).length > 0) {
  //     Globals.showError("Tiene una actividad economica secundaria con datos por llenar");
  //     return false;
  //   }

  //   if (this.state.properties.filter((i: any) => {
  //     const values = i.secondary.filter((i: any) => {
  //       return i.size <= 0;
  //     });

  //     return values.length > 0;
  //   }).length > 0) {
  //     Globals.showError("El campo metraje de las actividades economicas secundarias debe ser mayor a 0");
  //     return false;
  //   }

  //   this.setState({
  //     visible: false
  //   });
  // }

  // deleteSecondaryProperty = (index: number,principal_index: number) => {
  //   Globals.confirm('¿Desea eliminar la propiedad?',() => {
  //     let properties: any = [...this.state.properties];
  //     properties[principal_index].secondary.splice(index,1);
  //     this.setState({
  //       properties
  //     });
  //   });
  // }

  // addSecondaryProperty = (index: number) => {
  //   let properties: any = [...this.state.properties];
  //   properties[index].secondary.push({
  //     owner: Constants.PROPERTIES.OWNER.YES,
  //     economic_activity_id: '',
  //     property_use_id: '',
  //     property_type_id: '',
  //     // catastral_id: '',
  //     // nic: '',
  //     // license: '',
  //     urbaser_code: '',
  //     date: '',
  //     // vba_number: '',
  //     floor: '',
  //     house_number: '',
  //     size: '',
  //     type_form: Constants.TYPE_FORM.COMERCIAL,
  //   });
  //   this.setState({
  //     properties
  //   });
  // }

  // setValueSecondaryProperty = (value: any,field: string,index: number,principal_index: number,callback: any = () => {}) => {
  //   let properties: any = [...this.state.properties];
  //   properties[principal_index].secondary[index][field] = value;
  //   this.setState({
  //     properties
  //   },callback);
  // }

  // validateForm = async () => {
  //   await ClientService.admin.validate({
  //     ...this.state.form,
  //     id: this.props.match.params.id,
  //   });
  //   this.setTab(TABS.PROPERTIES);
  // }

  load = async (self: any) => {
    const res: any = await ClientService.admin.getUser({
      id: self.props.match.params.id,
      propertyId: self.props.match.params.propertyId,
    })
    const property = res.user.properties
      .map((item: any) => {
        item.date = moment(item.date)
        if (item.date.isValid()) {
          item.date = item.date.toDate()
        } else {
          item.date = ''
        }
        item.secondary = item.secondary_properties?.map((item: any) => {
          item.date = moment(item.date)
          if (item.date.isValid()) {
            item.date = item.date.toDate()
          } else {
            item.date = ''
          }
          return {
            // id: item.id,
            property_id: item.id,
            owner: item.owner || Constants.PROPERTIES.OWNER.YES,
            economic_activity_id: item.economic_activity_id || '',
            property_use_id: item.property_use_id || '',
            property_type_id: item.property_type_id || '',
            catastral_id: item.catastral_id || '',
            nic: item.nic || '',
            license: item.license || '',
            urbaser_code: item.urbaser_code || '',
            date: item.date || '',
            vba_number: item.vba_number || '',
            floor: item.floor || '',
            house_number: item.house_number || '',
            size: item.size || '',
            type_form: item.type_form || Constants.TYPE_FORM.COMERCIAL,
          }
        })
        return {
          // id: item.id,
          property_id: item.id,
          owner: item.owner || Constants.PROPERTIES.OWNER.YES,
          economic_activity_id: item.economic_activity_id || '',
          property_use_id: item.property_use_id || '',
          property_type_id: item.property_type_id || '',
          catastral_id: item.catastral_id || '',
          nic: item.nic || '',
          license: item.license || '',
          urbaser_code: item.urbaser_code || '',
          date: item.date || '',
          vba_number: item.vba_number || '',
          street: item.street || '',
          floor: item.floor || '',
          zone: item.zone || '',
          house_number: item.house_number || '',
          size: item.size || '',
          reference: item.reference || '',
          secondary: item.secondary || [],
          quantity_houses: item.quantity_houses || '',
          type_form: item.type_form || Constants.TYPE_FORM.ESTATE,
        }
      })
      .at(0)

    self.setState({
      codes: res.codes,
      document_types: res.document_types,
      form: {
        ...self.state.form,
        name: res.user.name,
        lastname: res.user.lastname,
        username: res.user.username,
        email: res.user.email,
        phone: Numeric.getOnlyNumber(res.user.phone),
        document_type_id: res.user.document_type_id,
        document: res.user.document,
        business_name: res.user.business_name,
        economic_activity: res.user.economic_activity,
        fiscal_address: res.user.fiscal_address,
        retention: res.user.retention,
        note: res.user.note,
      },
      emails: res.user.emails,
      phones: res.user.phones?.map((n: string) => Numeric.getOnlyNumber(n)),
      economic_activities: res.economic_activities,
      property_types: res.property_types,
      property_uses: res.property_uses.filter((item: any) => item.name !== 'Otro'),
      // properties: res.user.properties.map((item: any) => {
      //   item.date = moment(item.date);
      //   if (item.date.isValid()) {
      //     item.date = item.date.toDate();
      //   }
      //   else {
      //     item.date = '';
      //   }
      //   item.secondary = item.secondary_properties.map((item: any) => {
      //     item.date = moment(item.date);
      //     if (item.date.isValid()) {
      //       item.date = item.date.toDate();
      //     }
      //     else {
      //       item.date = '';
      //     }
      //     return {
      //       id: item.id,
      //       owner: item.owner || Constants.PROPERTIES.OWNER.YES,
      //       economic_activity_id: item.economic_activity_id || '',
      //       property_use_id: item.property_use_id || '',
      //       property_type_id: item.property_type_id || '',
      //       catastral_id: item.catastral_id || '',
      //       nic: item.nic || '',
      //       license: item.license || '',
      //       urbaser_code: item.urbaser_code || '',
      //       date: item.date || '',
      //       vba_number: item.vba_number || '',
      //       floor: item.floor || '',
      //       house_number: item.house_number || '',
      //       size: item.size || '',
      //       type_form: item.type_form || Constants.TYPE_FORM.COMERCIAL,
      //     }
      //   })
      //   return {
      //     id: item.id,
      //     owner: item.owner || Constants.PROPERTIES.OWNER.YES,
      //     economic_activity_id: item.economic_activity_id || '',
      //     property_use_id: item.property_use_id || '',
      //     property_type_id: item.property_type_id || '',
      //     catastral_id: item.catastral_id || '',
      //     nic: item.nic || '',
      //     license: item.license || '',
      //     urbaser_code: item.urbaser_code || '',
      //     date: item.date || '',
      //     vba_number: item.vba_number || '',
      //     street: item.street || '',
      //     floor: item.floor || '',
      //     zone: item.zone || '',
      //     house_number: item.house_number || '',
      //     size: item.size || '',
      //     reference: item.reference || '',
      //     secondary: item.secondary || [],
      //     quantity_houses: item.quantity_houses || '',
      //     type_form: item.type_form || Constants.TYPE_FORM.ESTATE,
      //   }
      // }),
      property,
    })
  }

  submit = async (self: any) => {
    await ClientService.admin.validate({
      ...self.state.form,
      id: self.props.match.params.id,
    })

    // const properties: any = Globals.clone(self.state.properties);
    const property: any = Globals.clone(self.state.property)
    property.date = property.date && moment(property.date, 'DD-MM-YYYY').format('YYYY-MM-DD')

    const res: any = await ClientService.admin.edit({
      ...self.state.form,
      id: self.props.match.params.id,
      emails: JSON.stringify(self.state.emails),
      phones: JSON.stringify(self.state.phones),
      property: JSON.stringify(property),
      // properties: JSON.stringify(properties.map((item: any) => {
      //   item.date = item.date && moment(item.date,'DD-MM-YYYY').format('YYYY-MM-DD');
      //   return item;
      // }))
    })
    Globals.showSuccess('Se han guardado los datos correctamente')
    /* Socket.emit(SocketEvents.USERS.UPDATE,{
      id: self.props.match.params.id
    }); */
    self.props.history.push({
      pathname: '/admin/users/clients',
      state: self.props.location.state,
    })

    RegisterMovement.save({
      value_id: res.client?.id,
      user_id: self.props.user.id,
      module_id: Constants.MODULES.CLIENTS,
      description: `Edición del contribuyente ${res.client?.full_name} ${res.client?.full_document}`,
    })
  }

  render() {
    return (
      <div id="admin-create-user">
        
        <FormClientProperty
          {...this.props}
          isRegister={true}
          passwordNotRequired={true}
          isProfile={true}
          showNote={true}
          show={this.props.match.params.propertyId ? 'PROPERTY' : 'CLIENT'}
          headTitle="Editar Contribuyente"
          onBack={() => {
            this.props.history.push({
              pathname: '/admin/users/clients',
              state: this.props.location.state,
            })
          }}
          onLoad={this.load}
          onSubmit={this.submit}
          isAdmin={this.props.user.level_id != 3}
        />
      </div>
    )
    // const emails: any = this.state.emails;
    // const phones: any = this.state.phones;

    // const { properties, tab, visible } = this.state;
    // const property: any = this.state.property;
    // const principal_index: number = properties.findIndex((i: any) => i.id == property?.id);

    // return (
    //     <div id="admin-create-user">
    //       <Modal
    //             className="high-modal modal-secondary-properties"
    //             visible={ visible }
    //             onClose={ this.onClose }
    //             title="Actividades Económicas Secundarias"
    //       >
    //         <div className="text-right container-btn-add">
    //           <Button type="button" onClick={ () => this.addSecondaryProperty(principal_index) }>
    //             {/* Agregar Propiedad */}
    //             Agregar Comercio
    //           </Button>
    //         </div>

    //         {
    //           property?.secondary.map((item: any,index: number) => {
    //             return (
    //               <div className="row row-form row-form-property">
    //                 <div className="col-12">
    //                   <h3>
    //                     Ingrese los datos del inmueble
    //                     <img src={ TrashIcon } onClick={ () => this.deleteSecondaryProperty(index,principal_index) } />
    //                   </h3>
    //                 </div>
    //                 <div className="row col-12">
    //                   {/* <div className="col-12 row-top-radio">
    //                     <div className="container-radio bottom">
    //                       <div className="item-radio" onClick={ () => this.setValueSecondaryProperty(Constants.PROPERTIES.OWNER.YES,'owner',index,principal_index) }>
    //                         <div className={ `radio ${ item.owner == Constants.PROPERTIES.OWNER.YES ? 'active' : '' }` }>
    //                           <div className="circle"></div>
    //                         </div>
    //                         <p>Propietario</p>
    //                       </div>
    //                       <div className="item-radio" onClick={ () => this.setValueSecondaryProperty(Constants.PROPERTIES.OWNER.NO,'owner',index,principal_index) }>
    //                         <div className={ `radio ${ item.owner == Constants.PROPERTIES.OWNER.NO ? 'active' : '' }` }>
    //                           <div className="circle"></div>
    //                         </div>
    //                         <p>Inquilino</p>
    //                       </div>
    //                       <p>Seleccione si es propietario o inquilino</p>
    //                     </div>
    //                   </div> */}
    //                   <div className="col-md-4">
    //                       <Select
    //                         color="gray"
    //                         name="property_use_id"
    //                         onChange={ (e: any) => this.setValueSecondaryProperty(e.target.value,e.target.name,index,principal_index) }
    //                         value={ item.property_use_id }
    //                         label="Uso del Inmueble"
    //                         options={ this.state.property_uses.map((i: any) => {
    //                           return {
    //                             value: i.id,
    //                             label: i.name
    //                           }
    //                         }) } />
    //                   </div>
    //                   <div className="col-md-4">
    //                       <Select
    //                         color="gray"
    //                         name="property_type_id"
    //                         onChange={ (e: any) => this.setValueSecondaryProperty(e.target.value,e.target.name,index,principal_index,() => {
    //                           this.setValueSecondaryProperty('','economic_activity_id',index,principal_index);
    //                         }) }
    //                         value={ item.property_type_id }
    //                         label="Tipo de Inmueble"
    //                         options={ this.state.property_types.map((i: any) => {
    //                           return {
    //                             value: i.id,
    //                             label: i.name
    //                           }
    //                         }) } />
    //                   </div>
    //                   <div className="col-md-4">
    //                       {
    //                         item.property_type_id && (
    //                           <Select
    //                             color="gray"
    //                             name="economic_activity_id"
    //                             onChange={ (e: any) => this.setValueSecondaryProperty(e.target.value,e.target.name,index,principal_index) }
    //                             value={ item.economic_activity_id }
    //                             label="Actividad Económica Principal"
    //                             options={ Globals.filterEconomicActivities(this.state.economic_activities,item).map((i: any) => {
    //                               return {
    //                                 value: i.id,
    //                                 label: '['+i.id+'] ' + i.name
    //                               }
    //                             }) } />
    //                         )
    //                       }
    //                   </div>
    //                   {/* <div className="col-md-3">
    //                     <Input
    //                       color="white"
    //                       value={ item.nic }
    //                       name="nic"
    //                       label="NIC Corpoelec"
    //                       onChange={ (e: any) => this.setValueSecondaryProperty(e.target.value,e.target.name,index,principal_index) } />
    //                   </div> */}
    //                   {/* <div className="col-md-3">
    //                     <Input
    //                       color="white"
    //                       value={ item.catastral_id }
    //                       name="catastral_id"
    //                       label="Cédula Catastral"
    //                       onChange={ (e: any) => this.setValueSecondaryProperty(e.target.value,e.target.name,index,principal_index) } />
    //                   </div> */}
    //                   {/* <div className="col-md-3">
    //                     <Input
    //                       color="white"
    //                       value={ item.license }
    //                       name="license"
    //                       label="Lic. Actividad Económica"
    //                       onChange={ (e: any) => this.setValueSecondaryProperty(e.target.value,e.target.name,index,principal_index) } />
    //                   </div> */}
    //                   <div className="col-md-3">
    //                     <Input
    //                       color="white"
    //                       value={ item.urbaser_code }
    //                       name="urbaser_code"
    //                       label="Código"
    //                       onChange={ (e: any) => this.setValueSecondaryProperty(e.target.value,e.target.name,index,principal_index) } />
    //                   </div>
    //                   {/* <div className="col-12">
    //                     <h4>Visto Bueno ambiental</h4>
    //                   </div>
    //                   <div className="col-md-4">
    //                     <Input
    //                       color="white"
    //                       value={ item.vba_number }
    //                       name="vba_number"
    //                       label="Código VBA"
    //                       onChange={ (e: any) => this.setValueSecondaryProperty(e.target.value,e.target.name,index,principal_index) } />
    //                   </div>
    //                   <div className="col-md-4">
    //                     <DatePicker
    //                       color="gray"
    //                       label="Fecha de Emisión"
    //                       showYearDropdown={ true }
    //                       maxDate={ moment().toDate() }
    //                       onChange={ (value: string) => this.setValueSecondaryProperty(value,'date',index,principal_index) }
    //                       value={ item.date }
    //                     />
    //                   </div> */}
    //                   <div className="col-md-4">
    //                     <Input
    //                       color="white"
    //                       value={ item.house_number }
    //                       name="house_number"
    //                       label="N° Local, Casa, Apto"
    //                       onChange={ (e: any) => this.setValueSecondaryProperty(e.target.value,e.target.name,index,principal_index) } />
    //                   </div>
    //                   <div className="col-md-4">
    //                     <Input
    //                       color="white"
    //                       value={ item.floor }
    //                       name="floor"
    //                       label="Piso"
    //                       onChange={ (e: any) => this.setValueSecondaryProperty(e.target.value,e.target.name,index,principal_index) } />
    //                   </div>
    //                   <div className="col-md-4">
    //                     <Input
    //                       type="decimal"
    //                       color="white"
    //                       value={ item.size }
    //                       name="size"
    //                       label="Metraje (m2)"
    //                       onChange={ (e: any) => this.setValueSecondaryProperty(e.target.value,e.target.name,index,principal_index) } />
    //                   </div>
    //                 </div>
    //               </div>
    //             )
    //           })
    //         }
    //         <div className="text-right">
    //           <Button onClick={ this.onClose }>
    //             Guardar
    //           </Button>
    //         </div>
    //       </Modal>

    //       <form onSubmit={ this.submit }>
    //        {
    //           tab == TABS.DATA && (
    //              <div className="row row-form">
    //                <div className="col-lg-6">
    //                 <Input
    //                   color="white"
    //                   value={ this.state.form.name }
    //                   name="name"
    //                   label="Primer nombre e inicial del segundo"
    //                   onChange={ this.change } />
    //               </div>
    //               <div className="col-lg-6">
    //                 <Input
    //                   color="white"
    //                   value={ this.state.form.lastname }
    //                   name="lastname"
    //                   label="Primer apellido e inicial del segundo"
    //                   onChange={ this.change } />
    //               </div>
    //               <div className="col-lg-4">
    //                 <p className="label">C.I. / RIF</p>
    //                 <div className="i-flex">
    //                   <div className="doc-flex">
    //                     <Select
    //                       className="select-phone"
    //                       color="gray"
    //                       name="document_type_id"
    //                       onChange={ (e: any) => {
    //                         let retention = this.state.form.retention;
    //                         if (parseInt(this.state.form.document_type_id) != Constants.DOCUMENT_TYPES.J) {
    //                           retention = Constants.RETENTION.NO;
    //                         }
    //                         this.setState({
    //                           form: {
    //                             ...this.state.form,
    //                             retention,
    //                             document_type_id: e.target.value
    //                           }
    //                         })
    //                       } }
    //                       value={ this.state.form.document_type_id }
    //                       placeholder="Tipo"
    //                       options={ this.state.document_types.map((i: any) => {
    //                         return {
    //                           value: i.id,
    //                           label: i.name
    //                         }
    //                       }) } />
    //                   </div>
    //                   <div className="doc-flex">
    //                     <Input
    //                       color="white"
    //                       value={ this.state.form.document }
    //                       name="document"
    //                       type="number"
    //                       placeholder="C.I. / RIF"
    //                       onChange={ this.change } />
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="col-lg-4">
    //                    <Input
    //                     type="number"
    //                     color="white"
    //                     value={ this.state.form.phone }
    //                     name="phone"
    //                     label="Teléfono"
    //                     onChange={ this.change } />
    //               </div>
    //               <div className="col-lg-4">
    //                 <p className="label label-button">
    //                   Teléfonos Adicionales
    //                   <div className="btn-add" onClick={ this.addPhone }>
    //                     +
    //                   </div>
    //                 </p>
    //                 {
    //                   phones.map((item: any,index: number) => (
    //                      <div className="container-delete">
    //                       <Input
    //                         color="white"
    //                         value={ phones[index].phone }
    //                         name="phone"
    //                         type="number"
    //                         placeholder="Teléfono"
    //                         onChange={ (e: any) => this.changePhones(e,index) } />
    //                       <img src={ TrashIcon } onClick={ () => this.deletePhone(index) } />
    //                     </div>
    //                   ))
    //                 }
    //               </div>
    //               <div className="col-lg-4">
    //                 <Input
    //                   color="white"
    //                   value={ this.state.form.email }
    //                   name="email"
    //                   label="Correo Electrónico"
    //                   onChange={ this.change } />
    //               </div>
    //               <div className="col-lg-4">
    //                 <p className="label label-button">
    //                   Correos Adicionales
    //                   <div className="btn-add" onClick={ this.addEmail }>
    //                     +
    //                   </div>
    //                 </p>
    //                 {
    //                   emails.map((item: any,index: number) => (
    //                     <div className="container-delete">
    //                       <Input
    //                         color="white"
    //                         value={ emails[index].email }
    //                         name="email"
    //                         placeholder="Correo Electrónico"
    //                         onChange={ (e: any) => this.changeEmails(e,index) } />
    //                       <img src={ TrashIcon } onClick={ () => this.deleteEmail(index) } />
    //                     </div>
    //                   ))
    //                 }
    //               </div>
    //               <div className="col-lg-4">

    //               </div>
    //               <div className="col-lg-4">
    //                 <Input
    //                   color="white"
    //                   value={ this.state.form.fiscal_address }
    //                   name="fiscal_address"
    //                   label="Dirección Fiscal"
    //                   onChange={ this.change } />
    //               </div>
    //               <div className="col-lg-4">
    //                 <Input
    //                   color="white"
    //                   value={ this.state.form.business_name }
    //                   name="business_name"
    //                   label="Razón Social"
    //                   onChange={ this.change } />
    //               </div>
    //                <div className="col-lg-4">
    //                 {
    //                   parseInt(this.state.form.document_type_id) == Constants.DOCUMENT_TYPES.J && (
    //                     <div className="container-radio retention">
    //                       <p>Agente de Retención</p>
    //                       <div className="item-radio" onClick={ () => this.change({
    //                         target: {
    //                           name: 'retention',
    //                           value: Constants.RETENTION.YES
    //                         }
    //                       }) }>
    //                         <div className={ `radio ${ this.state.form.retention == Constants.RETENTION.YES ? 'active' : '' }` }>
    //                           <div className="circle"></div>
    //                         </div>
    //                         <p>Si</p>
    //                       </div>
    //                       <div className="item-radio" onClick={ () => this.change({
    //                         target: {
    //                           name: 'retention',
    //                           value: Constants.RETENTION.NO
    //                         }
    //                       }) }>
    //                         <div className={ `radio ${ this.state.form.retention == Constants.RETENTION.NO ? 'active' : '' }` }>
    //                           <div className="circle"></div>
    //                         </div>
    //                         <p>No</p>
    //                       </div>
    //                     </div>
    //                   )
    //                 }
    //               </div>
    //               <div className="col-lg-4">
    //                 <Input
    //                   color="white"
    //                   value={ this.state.form.username }
    //                   name="username"
    //                   label="Código"
    //                   onChange={ this.change }
    //                   disabled />
    //               </div>
    //               <div className="col-lg-4">
    //                 <Input
    //                   color="white"
    //                   type="password"
    //                   value={ this.state.form.password }
    //                   name="password"
    //                   label="Cambiar Contraseña (Opcional)"
    //                   onChange={ this.change } />
    //               </div>
    //               <div className="col-12 text-right">
    //                 <Button type="button" onClick={ this.validateForm }>
    //                   Siguiente
    //                 </Button>
    //               </div>
    //              </div>
    //            )
    //          }

    //          {
    //             tab == TABS.PROPERTIES && (
    //               <React.Fragment>
    //                 {
    //                   properties.map((item: any,index: number) => {
    //                    const { economic_activities } = item;

    //                    return (
    //                     <div className="row row-form row-form-property">
    //                       <div className="col-12">
    //                         <h3>
    //                           Ingrese los datos del inmueble
    //                           <img src={ TrashIcon } onClick={ () => this.deleteProperty(index) } />
    //                         </h3>
    //                       </div>
    //                       <div className="row col-12">
    //                         <div className="col-12 row-top-radio">
    //                           <div className="container-radio bottom">
    //                             <div className="item-radio" onClick={ () => this.setValueProperty(Constants.PROPERTIES.OWNER.YES,'owner',index) }>
    //                               <div className={ `radio ${ item.owner == Constants.PROPERTIES.OWNER.YES ? 'active' : '' }` }>
    //                                 <div className="circle"></div>
    //                               </div>
    //                               <p>Propietario</p>
    //                             </div>
    //                             <div className="item-radio" onClick={ () => this.setValueProperty(Constants.PROPERTIES.OWNER.NO,'owner',index) }>
    //                               <div className={ `radio ${ item.owner == Constants.PROPERTIES.OWNER.NO ? 'active' : '' }` }>
    //                                 <div className="circle"></div>
    //                               </div>
    //                               <p>Inquilino</p>
    //                             </div>
    //                             <p>Seleccione si es propietario o inquilino</p>
    //                           </div>
    //                         </div>
    //                         <div className="col-md-4">
    //                             <Select
    //                               color="gray"
    //                               name="property_use_id"
    //                               onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) }
    //                               value={ item.property_use_id }
    //                               label="Uso del Inmueble"
    //                               options={ this.state.property_uses.map((i: any) => {
    //                                 return {
    //                                   value: i.id,
    //                                   label: i.name
    //                                 }
    //                               }) } />
    //                         </div>
    //                         <div className="col-md-4">
    //                             <Select
    //                               color="gray"
    //                               name="property_type_id"
    //                               onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index,() => {
    //                                 this.setValueProperty('','economic_activity_id',index)
    //                               }) }
    //                               value={ item.property_type_id }
    //                               label="Tipo de Inmueble"
    //                               options={ this.state.property_types.map((i: any) => {
    //                                 return {
    //                                   value: i.id,
    //                                   label: i.name
    //                                 }
    //                               }) } />
    //                         </div>
    //                         <div className="col-md-3">
    //                           {
    //                             item.property_type_id && (
    //                               <Select
    //                                 color="gray"
    //                                 name="economic_activity_id"
    //                                 onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) }
    //                                 value={ item.economic_activity_id }
    //                                 label="Actividad Económica Principal"
    //                                 options={ Globals.filterEconomicActivities(this.state.economic_activities,item).map((i: any) => {
    //                                   return {
    //                                     value: i.id,
    //                                     label: '['+i.id+'] ' + i.name
    //                                   }
    //                                 }) } />
    //                             )
    //                           }
    //                         </div>
    //                         <div className="col-md-1">
    //                           {
    //                             item.property_type_id && (
    //                               <div className="btn-add btn-add-secondary" onClick={ () => this.setState({
    //                                 visible: true,
    //                                 property: item
    //                               }) }>
    //                                 +
    //                               </div>
    //                             )
    //                           }
    //                         </div>
    //                         {/* <div className="col-md-3">
    //                           <Input
    //                             color="white"
    //                             value={ item.nic }
    //                             name="nic"
    //                             label="NIC Corpoelec"
    //                             onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                         </div>
    //                         <div className="col-md-3">
    //                           <Input
    //                             color="white"
    //                             value={ item.catastral_id }
    //                             name="catastral_id"
    //                             label="Cédula Catastral"
    //                             onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                         </div>
    //                         <div className="col-md-3">
    //                           <Input
    //                             color="white"
    //                             value={ item.license }
    //                             name="license"
    //                             label="Lic. Actividad Económica"
    //                             onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                         </div> */}
    //                         {/* <div className="col-md-4">
    //                           <Input
    //                             color="white"
    //                             value={ item.urbaser_code }
    //                             name="urbaser_code"
    //                             label="Código cta"
    //                             disabled
    //                             onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                         </div> */}
    //                         {/* <div className="col-12">
    //                           <h4>Visto Bueno ambiental</h4>
    //                         </div> */}
    //                         {/* <div className="col-md-4">
    //                           <Input
    //                             color="white"
    //                             value={ item.vba_number }
    //                             name="vba_number"
    //                             label="Código VBA"
    //                             onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                         </div>
    //                         <div className="col-md-4">
    //                           <DatePicker
    //                             color="gray"
    //                             label="Fecha de Emisión"
    //                             showYearDropdown={ true }
    //                             maxDate={ moment().toDate() }
    //                             onChange={ (value: string) => this.setValueProperty(value,'date',index) }
    //                             value={ item.date }
    //                           />
    //                         </div> */}
    //                         <div className="col-md-4">
    //                           <Input
    //                             color="white"
    //                             value={ item.zone }
    //                             name="zone"
    //                             label="Zona, Sector, Urb"
    //                             onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                         </div>
    //                         <div className="col-md-4">
    //                           <Input
    //                             color="white"
    //                             value={ item.street }
    //                             name="street"
    //                             label="Av / Calle"
    //                             onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                         </div>
    //                         <div className="col-md-4">
    //                           <Input
    //                             color="white"
    //                             value={ item.house_number }
    //                             name="house_number"
    //                             label="N° Local, Casa, Apto"
    //                             onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                         </div>
    //                         <div className="col-md-4">
    //                           <Input
    //                             color="white"
    //                             value={ item.floor }
    //                             name="floor"
    //                             label="Piso"
    //                             onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                         </div>
    //                         <div className="col-md-4">
    //                           <Input
    //                             type="decimal"
    //                             color="white"
    //                             value={ item.size }
    //                             name="size"
    //                             label="Metraje (m2)"
    //                             onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                         </div>
    //                         <div className="col-md-4">
    //                           <Input
    //                             color="white"
    //                             value={ item.reference }
    //                             name="reference"
    //                             label="Punto de Referencia"
    //                             onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                         </div>
    //                         {
    //                           item.property_type_id == Constants.PROPERTY_TYPES.RESIDENTIAL && item.property_use_id == Constants.PROPERTY_USES.CONDOMINIUM && (
    //                             <div className="col-md-4">
    //                               <Input
    //                                 type="number"
    //                                 color="white"
    //                                 value={ item.quantity_houses }
    //                                 name="quantity_houses"
    //                                 label="N° de Casas o Apartamentos"
    //                                 onChange={ (e: any) => this.setValueProperty(e.target.value,e.target.name,index) } />
    //                             </div>
    //                           )
    //                         }
    //                         {/* <div className="col-12">
    //                           <div className="container-radio">
    //                             <p>¿Pertenece a condominio?</p>
    //                             <div className="item-radio" onClick={ () => this.setValueProperty(Constants.PROPERTIES.CONDOMINIUM.YES,'condominium',index) }>
    //                               <div className={ `radio ${ item.condominium == Constants.PROPERTIES.CONDOMINIUM.YES ? 'active' : '' }` }>
    //                                 <div className="circle"></div>
    //                               </div>
    //                               <p>Si</p>
    //                             </div>
    //                             <div className="item-radio" onClick={ () => this.setValueProperty(Constants.PROPERTIES.CONDOMINIUM.NO,'condominium',index) }>
    //                               <div className={ `radio ${ item.condominium == Constants.PROPERTIES.CONDOMINIUM.NO ? 'active' : '' }` }>
    //                                 <div className="circle"></div>
    //                               </div>
    //                               <p>No</p>
    //                             </div>
    //                           </div>
    //                         </div> */}
    //                       </div>
    //                     </div>
    //                   )})
    //                 }

    //                 <div className="container">
    //                   <div className="row">
    //                     <div className="col-md-12">
    //                       <div className="btn-combo">

    //                         <Button className="btn-blue-create" type="button" onClick={ () => this.setTab(TABS.DATA) }>
    //                           Volver
    //                         </Button>

    //                         <Button className="btn-urbaser-green" type="submit">
    //                           Guardar
    //                         </Button>

    //                         {/* <Button className="btn-urbaser-green" type="button" onClick={ this.addProperty }>
    //                           Agregar Propiedad
    //                         </Button> */}

    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>

    //               </React.Fragment>
    //             )
    //           }
    //       </form>
    //     </div>
    // )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user,
  }
})(EditClient)
