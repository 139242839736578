import { Api, Constants } from 'utils'

class ClientService {
  static admin = {
    get: async (params?: any) => Api.createResponse('admin/clients/get', params),
    create: async (params?: any) => Api.createResponse('admin/clients/create', params),
    edit: async (params?: any) => Api.createResponse('admin/clients/edit', params),
    status: async (params?: any) => Api.createResponse('admin/clients/status', params),
    delete: async (params?: any) => Api.createResponse('admin/clients/delete', params),
    getDocumentTypes: async (params?: any) => Api.createResponse('admin/clients/document-types', params),
    getUser: async (params?: any) => Api.createResponse('admin/clients/get-user', params),
    validate: async (params?: any) => Api.createResponse('admin/clients/validate', params),
    download: (id?: string) => Constants.BASE_API + 'admin/clients/download/' + id,
    downloadListExel: (params?: any) => Api.createResponse(Constants.BASE_API + 'admin/clients/excel?' + params, { method: 'get' }),
    getProperties: async (params?: any) => Api.createResponse('admin/clients/get/properties', params),
    disincorporate: async (id: string | number, params?: any) => Api.createResponse(`admin/clients/${id}/disincorporate`, params),
    importExcel: async (params?: any) => Api.createResponse(`admin/clients/import/excel`, params),
  }
}

export default ClientService
