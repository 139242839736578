import React from 'react';
import { AgreementService, CashService } from 'services';
import { SelectSearch, DatePicker, Select, Button, Input, Table, Checkbox, TooltipCurrency } from 'components';
import { Globals, Currency, RegisterMovement, Constants } from 'utils';
import moment from 'moment';
import { ExpandMore } from 'assets/icons'

type Payment = {
	id: string | number
	urbaser_code: string
	house_number: string
	description: string
	total: number
	isSelected: boolean
	isCollapse: boolean
	fines: any[]
	childs: Payment[]
	// [key: string]: any
}

type Agreement = {
	position: number;
	payments: Payment[];
	total: number;
	date?: string | null;
}

type State = {
	payments: Payment[];
	agreements: Agreement[];
	[key: string]: any;
}

interface Props {
	onClose: () => void,
	user: any
}

const TYPES = {
	MENSUAL: 1,
	QUINCENAL: 2
}

const INITIAL_STATE = {
	client_id: '',
	expiration_date: '',
	quotas: '',
	type: TYPES.MENSUAL,
	property_id: '',
	quota: ''
}

const headerModal = ['', 'Código', 'Local', 'Descripción', 'Total', '']

const paymentAdapter = (payment: any) => {
	const serviceName = payment.service_type?.name ?? 'Sin servicio'
	const date = payment.created_at ? moment(payment.created_at, 'YYYY-MM').format('MMMM YYYY') : 'Sin fecha'

	return {
		...payment,
		total: payment.total ?? 0,
		urbaser_code: payment.urbaser_code ?? '-',
		house_number: payment.house_number ?? '-',
		fines: payment.fines ?? [],
		childs: payment.childs?.map(paymentAdapter) ?? [],
		description: `${serviceName} (${date})`,
		isSelected: false,
		isCollapse: false,
	}
}

class CreateAgreementNew extends React.Component<Props> {

	state: State = {
		clients: [],
		form: INITIAL_STATE,
		client: null,
		property: null,
		properties: [],
		conversion_petro: 0,
		searchValue: '',
		payments: [],
		agreements: [],
	}

	load = async (value: string) => {
		const res: any = await AgreementService.admin.getClients({ search: value });
		this.setState({
			clients: res.clients,
			conversion_petro: res.conversion_petro?.amount || 0,
		});
	}

	loadProperties = async () => {
		const client: any = this.state.client;
		const res: any = await AgreementService.admin.getProperties({
			client_id: client?.id
		});
		this.setState({
			properties: res.properties
		});
	}

	change = (e: any,callback = () => {}) => {
	    this.setState({
	      form: {
	        ...this.state.form,
	        [e.target.name]: e.target.value
	      }
	    },callback);
	}

	finish = async () => {
		if (parseInt(this.state.form.quotas) <= 0) {
			Globals.showError("El campo cantidad de cuotas debe ser mayor a 0");
			return false;
		}

		const res: any = await AgreementService.admin.finish({
			...this.state.form
		});
		Globals.showSuccess("Se ha creado correctamente el convenio");
		this.props.onClose();

		RegisterMovement.save({
	        value_id: res.agreement?.id,
	        user_id: this.props.user.id,
	        module_id: Constants.MODULES.PAYMENT_AGREEMENTS,
	        description: `Emisión de convenio de pago ${ res.agreement?.number } del contribuyente ${ res.agreement?.user?.full_name } ${ res.agreement?.user?.full_document }`
	    });
	}

	calculateDate = (property: any) => {
		// @ts-ignore
      	const quota = property.debt_total / this.state.form.quotas;
      	const date = this.state.form.type == TYPES.QUINCENAL ? 
      				moment().add((parseInt(this.state.form.quotas) + 1) * 15,'days').add(1,'day').toDate()
      				: moment().add(parseInt(this.state.form.quotas) + 1,'months').add(1,'day').toDate();
      	this.change({
      		target: {
      			name: 'quota',
      			value: quota
      		}
      	},() => {
      		this.change({
          		target: {
          			name: 'expiration_date',
          			value: date
          		}
          	});
      	});
	}

	getPropertyIdPayments = async (id: string | number) => {
		try {
			const res: any = await CashService.admin.getPropertyIdFines(id)

			const resPayments: Payment[] = Object.values(res?.data ?? {})
				.filter((payment: any) => payment.urbaser_code)
				.map(paymentAdapter)

			console.log(resPayments)
			this.setState({
				payments: resPayments ?? [],
			})
		} catch (error) {
		  	console.log(error)
		}
	}

	handleSelected = (payment: Payment) => {
		const list = this.state.payments.map((p, i) => {
			if (p.id === payment.id) p.isSelected = !payment.isSelected
			return p
		})

		this.setState({
			payments: list,
		})
	}

	handleCollapse = (payment: Payment) => {
		const list = this.state.payments.map((p) => {
			if (p.id === payment.id) p.isCollapse = !payment.isCollapse

			return p
		})

		this.setState({
			payments: list,
		})
	}

	generateAgreement = () => {
		const selected = this.state.payments.filter((payment) => payment.isSelected)
		const notSelected = this.state.payments.filter((payment) => !payment.isSelected)

		if (selected.length === 0) return

		const total = selected.reduce((cur, payment) => {
			return cur + payment.total
		}, 0)

		const agreement = {
			position: this.state.agreements.length + 1,
			payments: selected,
			total,
			date: null,
		}

		this.setState({
			payments:  notSelected,
			agreements: [...this.state.agreements, agreement],
		})
	}

	render() {
		const client: any = this.state.client;
		const property: any = this.state.property;

		return (
			<div id="admin-modal-create-agreements">
				<h3>Contribuyente</h3>
				<SelectSearch
					label="Código Contribuyente - Razón Social"
					onEnter={(value: string) => {
						this.setState({
							searchValue: value
						}, () => this.load(value))
					}}
					onChange={ (client: any) => {
						this.setState({
							form: {
								...this.state.form,
								client_id: client.value,
								property_id: ''
							},
							property: null,
							client: {
								id: client.value,
								full_name: client.label
							},
							searchValue: client
						},() => this.loadProperties());
					} }
					placeholder="Contribuyente"
					value={this.state.searchValue}
					options={ this.state.clients.map((i: any) => {
						return {
							value: i.id,
							label: i.full_name + ' - ' + i.full_document + ' - ' + i.username
						}
					}) }
				/>
				<hr />
	            {
	            	client && (
	            		<React.Fragment>
	            			<h3>Cod. Propiedad</h3>
				            <div className="row">
				            	<div className="col-md-6">
				            		<SelectSearch
						                label="Código Propiedad - Actividad Comercial"
						                onChange={ (property: any) => {
						                  this.setState({
						                    form: {
						                      ...this.state.form,
						                      property_id: property.value,
						                    },
						                    property: {
						                      id: property.value,
						                      urbaser_code: property.label,
						                      debt_total: property.debt_total
						                    }
						                  });
										  console.log(property.value)
										  this.getPropertyIdPayments(property.value)
						                } }
						                placeholder="Propiedad"
						                value={ {
						                  value: property?.id || '',
						                  label: property?.urbaser_code || ''
						                } }
						                options={ this.state.properties.map((i: any) => {
						                  return {
						                  	debt_total: i.debt_total,
						                    value: i.id,
						                    label: i.urbaser_code + ' - ' + i.economic_activity?.name
						                  }
						                }) } />
				            	</div>
				            	<div className="col-md-6">
				            		{
				            			property && (
											<Input
							                  color="gray"
							                  value={ Globals.formatMiles(Currency.Convert(property.debt_total,this.state.conversion_petro),true,Currency.BOLIVAR) + ` (${ Globals.formatMiles(property.debt_total,true,Currency.PETRO) })` }
							                  name="debt_total"
							                  disabled={ true }
							                  label="Monto Total de la Deuda" />
				            			)
				            		}
				            	</div>
				            </div>
				            <hr />
				            {
				            	property && (
				            		<React.Fragment>
										<div className="d-flex align-items-center justify-content-between">
				            				<h3 className="mb-0">Datos del Convenio</h3>
											<Button onClick={() => this.generateAgreement()}>Generar convenio</Button>
										</div>
										<div className="row">
											<div className="col-12">
												<Table title="" data={this.state.payments} header={headerModal} hideTitle={true}>
													{this.state.payments.length === 0 ? (
														<tr>
															<td className="text-center" colSpan={headerModal.length}>
																No hay datos
															</td>
														</tr>
													) : (
														this.state.payments.map((payment) => (
														<>
															<tr key={payment.id}>
															<td onClick={() => this.handleSelected(payment)}>
																<Checkbox value={payment.id} checked={payment.isSelected} />
															</td>
															<td onClick={() => this.handleSelected(payment)}>{payment.urbaser_code}</td>
															<td onClick={() => this.handleSelected(payment)}>{payment.house_number}</td>
															<td className="capitalize" onClick={() => this.handleSelected(payment)}>
																{payment.description}
															</td>
															<td onClick={() => this.handleSelected(payment)}>
																<TooltipCurrency title={payment.total}>
																	{Globals.formatMiles(Currency.Convert(payment.total, this.state.conversion_petro), true, Currency.BOLIVAR)}
																</TooltipCurrency>
															</td>
															<td style={{ padding: '5px' }}>
																{payment.childs.length > 0 && (
																<img
																	src={ExpandMore}
																	onClick={() => this.handleCollapse(payment)}
																	style={{ width: '30px', transform: payment.isCollapse ? 'rotate(180deg)' : '' }}
																/>
																)}
															</td>
															</tr>
															{payment.isCollapse && payment.childs.map((child) => (
																<tr key={child.id}>
																<td style={{ background: '#e3e3e3' }}></td>
																<td style={{ background: '#e3e3e3' }}>{child.urbaser_code}</td>
																<td style={{ background: '#e3e3e3' }}>{child.house_number}</td>
																<td style={{ background: '#e3e3e3' }} className="capitalize">
																	{child.description}
																</td>
																<td style={{ background: '#e3e3e3' }}>
																	<TooltipCurrency title={child.total}>
																		{Globals.formatMiles(Currency.Convert(child.total, this.state.conversion_petro), true, Currency.BOLIVAR)}
																	</TooltipCurrency>
																</td>
																<td style={{ background: '#e3e3e3' }}></td>
																</tr>
															))}
														</>
														))
													)}
												</Table>
											</div>
										</div>

										<hr />

										{this.state.agreements.map((agreement, index) => (
											<>
											<div className="d-flex align-items-center mt-3 mb-1">
												<h3 className="mx-3 mb-0">Cuota {agreement.position}</h3>
												<div style={{ width: '260px' }}>
													<DatePicker
														placeholder="Fecha de la cuota"
														value={agreement.date}
														onChange={ (value: string) => {
															const agreements = this.state.agreements
															agreements[index].date = value
															this.setState({ agreements })
														}}
														className="mb-0"
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-12">
													<Table title="" data={agreement.payments} header={headerModal} hideTitle={true}>
														{agreement.payments.length === 0 ? (
															<tr>
																<td className="text-center" colSpan={headerModal.length}>
																	No hay datos
																</td>
															</tr>
														) : (
															agreement.payments.map((payment) => (
															<>
																<tr key={payment.id}>
																<td>
																	{/* <Checkbox value={payment.id} checked={payment.isSelected} /> */}
																</td>
																<td>{payment.urbaser_code}</td>
																<td>{payment.house_number}</td>
																<td className="capitalize">
																	{payment.description}
																</td>
																<td>
																	<TooltipCurrency title={payment.total}>
																		{Globals.formatMiles(Currency.Convert(payment.total, this.state.conversion_petro), true, Currency.BOLIVAR)}
																	</TooltipCurrency>
																</td>
																<td style={{ padding: '5px' }}>
																	{payment.childs.length > 0 && (
																	<img
																		src={ExpandMore}
																		onClick={() => this.handleCollapse(payment)}
																		style={{ width: '30px', transform: payment.isCollapse ? 'rotate(180deg)' : '' }}
																	/>
																	)}
																</td>
																</tr>
																{payment.isCollapse && payment.childs.map((child) => (
																	<tr key={child.id}>
																	<td style={{ background: '#e3e3e3' }}></td>
																	<td style={{ background: '#e3e3e3' }}>{child.urbaser_code}</td>
																	<td style={{ background: '#e3e3e3' }}>{child.house_number}</td>
																	<td style={{ background: '#e3e3e3' }} className="capitalize">
																		{child.description}
																	</td>
																	<td style={{ background: '#e3e3e3' }}>
																		<TooltipCurrency title={child.total}>
																			{Globals.formatMiles(Currency.Convert(child.total, this.state.conversion_petro), true, Currency.BOLIVAR)}
																		</TooltipCurrency>
																	</td>
																	<td style={{ background: '#e3e3e3' }}></td>
																	</tr>
																))}
															</>
															))
														)}
														<tr>
														<td colSpan={4}></td>
														<td>
															<TooltipCurrency title={agreement.total}>
																{Globals.formatMiles(Currency.Convert(agreement.total, this.state.conversion_petro), true, Currency.BOLIVAR)}
															</TooltipCurrency>
														</td>
														<td></td>
														</tr>
													</Table>
												</div>
											</div>
											</>
										))}

							            <div className="row mt-4">
							            	<div className="col-md-6 text-right">
							            		<Button className="btn-urbaser-red " onClick={ () => this.props.onClose() }>
								                    Cancelar
								                </Button>
							            	</div>
							            	<div className="col-md-6">
							            		<Button onClick={ this.finish }>
								                    Generar
								                </Button>
							            	</div>
							            </div>
				            		</React.Fragment>
				            	)
				            }
	            		</React.Fragment>
	            	)
	            }
			</div>
		)
	}	
}

export default CreateAgreementNew;