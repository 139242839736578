import React, { useState } from 'react'
import { Button, Table, Tooltip, TooltipCurrency } from 'components'

import { useCashContext } from './useCashContext'
import CashModalVoucher from './modals/CashModalVoucher'
import { TrashIcon } from 'assets/icons'
import { Currency, Globals } from 'utils'

const headerVouchers = ['Comprobante', 'Monto', 'Acción']

const CashListVouchers = () => {
  const { vouchers, setVouchers, property } = useCashContext()
  const [visible, setVisible] = useState(false)

  const total = vouchers.reduce((value, current) => {
    return value + parseFloat(current.amount_paid)
  }, 0)

  const removeVoucher = (e: any, index: number) => {
    e.preventDefault()

    const list = vouchers.filter((item, i) => index !== i)
    setVouchers(list)
  }

  return (
    <>
      <CashModalVoucher visible={visible} setVisible={setVisible} />

      <Table data={vouchers.length || 1} title="" header={headerVouchers}>
        {vouchers.length === 0 ? (
          <tr>
            <td className="text-center" colSpan={headerVouchers.length}>
              No hay datos
            </td>
          </tr>
        ) : (
          vouchers.map((voucher, index) => (
            <tr key={voucher.number}>
              <td>{voucher.number}</td>
              <td>
                {/* {voucher.amount_paid} */}
                <TooltipCurrency title={parseFloat(voucher.amount_paid) / (property?.petro_value ?? 1)}>
                  {Globals.formatMiles(Currency.Convert(parseFloat(voucher.amount_paid), 1), true, Currency.BOLIVAR)}
                </TooltipCurrency>
              </td>
              <td style={{ textAlign: 'center' }}>
                <Tooltip title="Eliminar">
                  <a className="link-icon" href="#" onClick={(e: any) => removeVoucher(e, index)}>
                    <img src={TrashIcon} />
                  </a>
                </Tooltip>
              </td>
            </tr>
          ))
        )}

        <tr>
          <td>Total</td>
          <td>
            <TooltipCurrency title={total / (property?.petro_value ?? 1)}>
              {Globals.formatMiles(Currency.Convert(total, 1), true, Currency.BOLIVAR)}
            </TooltipCurrency>
          </td>
          <td style={{ textAlign: 'center' }}>
            <Button
              className="btn btn-small btn-primary"
              color="primary"
              onClick={() => {
                if (!property) return
                setVisible(true)
              }}
            >
              Agregar
            </Button>
          </td>
        </tr>
      </Table>
    </>
  )
}

export default CashListVouchers
