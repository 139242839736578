import React from 'react';
import { Button, InputCurrency } from 'components';
import { EconomicActivityService } from 'services';
import { Globals, Constants, RegisterMovement, Currency, SocketEvents, Socket } from 'utils';

interface Props {
	onClose: (reload: boolean) => void,
	conversion: number,
	user: any
}

class EditPetro extends React.Component<Props> {

	state = {
		form: {
			conversion: 0
		}
	}

	componentDidMount() {
		this.setState({
			form: {
				conversion: this.props.conversion
			}
		});
	}

	change = (e: any,callback = () => {}) => {
	    this.setState({
	      form: {
	        ...this.state.form,
	        [e.target.name]: e.target.value
	      }
	    },callback);
	}

	submit = async (e: any) => {
		e.preventDefault();
		await EconomicActivityService.admin.editPetro({
			amount: this.state.form.conversion
		});
		RegisterMovement.save({
	        user_id: this.props.user.id,
	        module_id: Constants.MODULES.TARIFF,
	        description: `Edición del valor del petro a ${ Globals.formatMiles(this.state.form.conversion,true,Currency.BOLIVAR) }`
	    });
		this.props.onClose(true);
		Globals.showSuccess("Se ha modificado el valor del petro");
		/* Socket.emit(SocketEvents.PETRO.CHANGE); */
	}
	
	render() {
		return (
			<div id="admin-edit-petro">
				<form onSubmit={ this.submit }>
					<div className="row">
						<div className="col-md-12">
							<InputCurrency
	                          color="white"
	                          value={ this.state.form.conversion }
	                          name="conversion" 
	                          placeholder="Monto"
							  precision="4"
	                          onChange={ this.change } />
						</div>
						<div className="col-md-12">
							<Button type="submit">
								Guardar
							</Button>
						</div>	
					</div>
				</form>				
			</div>
		)
	}
}

export default EditPetro;