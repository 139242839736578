import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Button, Tooltip } from 'components';
import { PropertyService } from 'services';
import { TrashIcon, EditIcon } from 'assets/icons';
import { Globals } from 'utils';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

class Properties extends React.Component<Props> { 

  state = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'Código',
      'NIC',
      'Uso',
      'Tipo',
      'Actividad',
      'Acciones'
    ]
  }

  componentDidMount() {
  	this.props.dispatch({
  		type: 'SET_HEADER',
  		payload: {
  			title: 'Inmuebles',
  			onBack: null
  		}
  	});

    this.load();
  }

  load = async () => {
    const res: any = await PropertyService.get({
      page: this.state.page,
      user_id: this.props.user.id
    });
    this.setState({
      data: res.properties.data,
      last_page: res.properties.last_page
    });
  }

  create = () => {
    this.props.history.push({
      pathname: '/properties/create',
      state: this.state
    });
  }

  delete = (e: any, item: any) => {
    e.preventDefault();
    Globals.confirm('¿Desea eliminar la propiedad?',async () => {
      await PropertyService.delete({
        property_id: item.id
      });
      Globals.showSuccess("Se ha eliminado correctamente la propiedad");
      this.load();
    });
  }

  edit = (e: any, url: string) => {
    e.preventDefault();
    this.props.history.push({
      pathname: url,
      state: this.state
    });
  }
  
  render() {
    return (
        <div id="properties">
          <p>Nota: el propietario de un inmueble  es solidariamente responsable de la obligación de pago, aún cuando el inmueble se encuentra ocupado por otra persona, vacío, sin uso o desocupado</p>
          
          {/* <div className="text-right">
            <Button className="btn-urbaser-green" onClick={ this.create }>
              Añadir propiedad
            </Button>
          </div>   */}

          <Table data={ this.state.data.length } title="" header={ this.state.header }>
            { this.state.data.map((i: any,index: number) => {
              return (
                <tr key={ index }>
                  <td>{ i.urbaser_code }</td>
                  <td>{ i.nic }</td>
                  <td>{ i.use?.name || '-' }</td>
                  <td>{ i.type?.name || '-' }</td>
                  <td>{ i.economic_activity?.short || '-' }</td>
                  <td>
                    <Tooltip title="Editar">
                      <a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,`/properties/edit/${ i.id }`) }>
                        <img src={ EditIcon } />
                      </a>
                    </Tooltip>
                    {/* <Tooltip title="Eliminar">
                      <a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
                        <img src={ TrashIcon } />
                      </a>
                    </Tooltip> */}
                  </td>
                </tr>
              )
            }) }
          </Table>

          <Pagination 
            pages={ this.state.last_page } 
            active={ this.state.page }
            onChange={ async (page: number) => {
                console.log(page);
                await this.setState({
                  page: page
                });
                this.load();
            } } />
        </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(Properties);