import React from 'react';
import { Input, Button } from 'components';
import { AuthService } from 'services';
import { Globals } from 'utils';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { BgLogin, Logo, LogoLogin, NewLogo, NewLogoBlack } from 'assets/img';

interface Props extends RouteComponentProps<any> {
	dispatch: any
}

class Reset extends React.Component<Props> {

	state = {
		visible: false,
		form: {
			password: '',
			password_confirmation: ''
		}
	}

	async componentDidMount() {
		const res: any = await AuthService.checkCode({
			code: this.props.match.params.code
		});
		if (!res.result) {
			this.props.history.replace('/');
		}
		else {
			this.setState({
				visible: true
			});
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Recuperación de Contraseña',
				onBack: null
			}
		});
	}

	disabled = () => {
		const {
			password,
			password_confirmation
		} = this.state.form;
		return password == '' || password == null ||
		       password_confirmation == '' || password_confirmation == null;
	}

	submit = async (e: any) => {
		e.preventDefault();
		await AuthService.reset({
			code: this.props.match.params.code,
			password: this.state.form.password,
			password_confirmation: this.state.form.password_confirmation
		});
		this.props.history.replace('/login');
		Globals.showSuccess("Se ha cambiado su contraseña correctamente");
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="reset">
				{
					visible && (
						<React.Fragment>
							<div className="row container-form">
								<form className="col-md-6" onSubmit={ this.submit }>
									<div className="row">
										<div className="col-12">
											<div className="container-logo">
												<img src={ NewLogoBlack } />

											</div>
										</div>
										<div className="col-12">
											<div className="container-input">
												<div className="row">
													<div className="col-12">
														<p>Ingrese su nueva contraseña</p>
													</div>
													<div className="col-md-12">
														<Input
															type="password"
															name="password"
															placeholder="Nueva Contraseña"
															value={ this.state.form.password }
															onChange={ this.change }
														/>
													</div>
													<div className="col-md-12">
														<Input
															type="password"
															name="password_confirmation"
															placeholder="Repetir Contraseña"
															value={ this.state.form.password_confirmation }
															onChange={ this.change }
														/>
													</div>
													<div className="col-md-12 text-center">
														<Button type="submit" disabled={ this.disabled() }>
															Entrar
														</Button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
								<div className="col-md-6 container-bg" style={ {
									backgroundImage: `url(${ BgLogin })`
								} }></div>
							</div>
						</React.Fragment>
					)
				}				
			</div>
		)		
	}
}

export default connect()(Reset);