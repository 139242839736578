import React from 'react';
import { Button, InputCurrency } from 'components';
import { AccountStatusService } from 'services';
import { Globals, RegisterMovement, Constants } from 'utils';

interface Props {
	onClose: (reload: boolean) => void,
	registry: any,
	user: any
}

class EditFinesPaymentValue extends React.Component<Props> {

	state = {
		form: {
			id: 0,
			value: 0
		}
	}

	componentDidMount() {
		this.setState({
			form: {
				id: this.props.registry.id,
				value: this.props.registry.value_fines
			}
		});
	}

	change = (e: any,callback = () => {}) => {
	    this.setState({
	      form: {
	        ...this.state.form,
	        [e.target.name]: e.target.value
	      }
	    },callback);
	}

	submit = async (e: any) => {
		e.preventDefault();

		// if (this.state.form.value > 100) {
		// 	Globals.showError("Lo sentimos, el valor no puede ser mayor a 100");
		// 	return false;
		// }
		await AccountStatusService.admin.updateFinesPayment(
			this.state.form.id,
			this.state.form.value
		);
		// RegisterMovement.save({
	  //       user_id: this.props.user.id,
	  //       module_id: Constants.MODULES.TARIFF,
	  //       description: `Edición del Interes Bancario a ${ Globals.formatMiles(this.state.form.value,true,'') }%`
	  //   });

		this.props.onClose(true);
		Globals.showSuccess("Se ha modificado la multa del registro");
	}

	render() {
		return (
			<div id="admin-edit-bank-interest">
				<form onSubmit={ this.submit }>
					<div className="row">
						<div className="col-md-12">
							<InputCurrency
								color="white"
								value={ this.state.form.value }
								name="value"
								precision="4"
								placeholder="Multa"
								onChange={ this.change } />
						</div>
						<div className="col-md-12">
							<Button type="submit">
								Guardar
							</Button>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default EditFinesPaymentValue;