import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Input, Button, Tooltip, Modal } from 'components';
import { EconomicActivityService } from 'services';
import { Globals, Constants, Permissions, checkPermission, Print } from 'utils';
import { EditIcon, ViewIcon, PrintIcon } from 'assets/icons';
import EditTariff from './edit-tariff';
import ViewTariff from './view-tariff';
import EditIVA from './edit-iva';
import EditDolar from './edit-dolar';
import EditPetro from './edit-petro';
import EditRetention from './edit-retention';
import EditBankInterest from './edit-bankInterest';
import Discount from './discount/discount';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

const INITIAL_STATE = {
  search: ''
}

class Tariff extends React.Component<Props> {  

  timer: any = undefined;

  state = {
    data: [],
    page: 1,
    last_page: 1,
    form: INITIAL_STATE,
    header: [
      'Código',
      'Nombre',
      'Área Máxima',
      'Acciones'
    ],
    visible: false,
    visible_view: false,
    activity: null,
    types: [],
    visible_dolar: false,
    visible_petro: false,
    visible_iva: false,
    visible_retention: false,
    visible_bankInterest: false,
    visible_discount: false,
    conversion_dolar: 0,
    conversion_petro: 0,
    iva: 0,
    retention: 0,
    bankInterest: 0,
  }

  componentDidMount() {
  	this.props.dispatch({
  		type: 'SET_HEADER',
  		payload: {
  			title: 'Tarifas',
  			onBack: null
  		}
  	});

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await EconomicActivityService.admin.get({
      page: this.state.page,
      withoutLoading,
      ...this.state.form
    });
    this.setState({
      data: res.activities.data,
      last_page: res.activities.last_page,
      types: res.types,
      conversion_dolar: res.conversion_dolar,
      conversion_petro: res.conversion_petro,
      iva: res.iva,
      retention: res.retention,
      bankInterest: res.bankInterest
    });
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

  edit = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      activity: item
    });
  }

  view = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible_view: true,
      activity: item
    });
  }

  onClose = (reload: boolean = false) => {
    this.setState({
      visible: false
    },() => {
      if (reload) {
        this.load(true);
      }
    });
  }

  onCloseView = () => {
    this.setState({
      visible_view: false
    });
  }

  onCloseIVA = (reload: boolean = false) => {
    this.setState({
      visible_iva: false
    },() => {
      if (reload) {
        this.load(true);
      }
    });
  }

  onCloseRetention = (reload: boolean = false) => {
    this.setState({
      visible_retention: false
    },() => {
      if (reload) {
        this.load(true);
      }
    });
  }

  onCloseBankInterest = (reload: boolean = false) => {
    this.setState({
      visible_bankInterest: false
    },() => {
      if (reload) {
        this.load(true);
      }
    });
  }

  onCloseDiscount = (reload: boolean = false) => {
    this.setState({
      visible_discount: false
    },() => {
      if (reload) {
        this.load(true);
      }
    });
  }

  onCloseDolar = (reload: boolean = false) => {
    this.setState({
      visible_dolar: false
    },() => {
      if (reload) {
        this.load(true);
      }
    });
  }

  onClosePetro = (reload: boolean = false) => {
    this.setState({
      visible_petro: false
    },() => {
      if (reload) {
        this.load(true);
      }
    });
  }

  changeDolar = () => {
    this.setState({
      visible_dolar: true
    });
  }

  changePetro = () => {
    this.setState({
      visible_petro: true
    });
  }

  changeIVA = () => {
    this.setState({
      visible_iva: true
    });
  } 

  changeRetention = () => {
    this.setState({
      visible_retention: true
    });
  }

  changeBankInterest = () => {
    this.setState({
      visible_bankInterest: true
    });
  }

  changeDiscount = () => {
    this.setState({
      visible_discount: true
    });
  }

  print = (e: any, item: any) => {
    e.preventDefault();
    Print(EconomicActivityService.admin.download(item.crypt),true);
  }
  
  render() {
    const { visible, visible_view, visible_dolar, visible_petro, visible_iva, visible_retention, visible_bankInterest, visible_discount } = this.state;

    return (
        <div id="admin-tariff">

          <Modal
              className="modal-edit-dolar"
              visible={ visible_dolar }
              onClose={ this.onCloseDolar } 
              title="Conversión Dolar - Bs"
          >
            <EditDolar
              user={ this.props.user }
              conversion={ this.state.conversion_dolar }
              onClose={ this.onCloseDolar }
            />
          </Modal>

          <Modal
              className="modal-edit-petro"
              visible={ visible_petro }
              onClose={ this.onClosePetro } 
              title="Conversión UCD - Bs"
          >
            <EditPetro
              user={ this.props.user }
              conversion={ this.state.conversion_petro }
              onClose={ this.onClosePetro }
            />
          </Modal>

          <Modal
              className="modal-edit-iva"
              visible={ visible_iva }
              onClose={ this.onCloseIVA } 
              title="Porcentaje del IVA"
          >
            <EditIVA
              user={ this.props.user }
              value={ this.state.iva }
              onClose={ this.onCloseIVA }
            />
          </Modal>

          <Modal
              className="modal-edit-iva"
              visible={ visible_retention }
              onClose={ this.onCloseRetention } 
              title="Porcentaje de Retención"
          >
            <EditRetention
              user={ this.props.user }
              value={ this.state.retention }
              onClose={ this.onCloseRetention }
            />
          </Modal>

          <Modal
              className="modal-edit-iva"
              visible={ visible_bankInterest }
              onClose={ this.onCloseBankInterest } 
              title="Interés Bancario"
          >
            <EditBankInterest
              user={ this.props.user }
              value={ this.state.bankInterest }
              onClose={ this.onCloseBankInterest }
            />
          </Modal>

          <Modal
              className="modal-view-discount"
              visible={ visible_discount }
              onClose={ this.onCloseDiscount } 
              title="Descuentos"
          >
            <Discount
              user={ this.props.user }
              onClose={ this.onCloseDiscount }
            />
          </Modal>

          <Modal
              className="high-modal modal-edit-tariff"
              visible={ visible }
              onClose={ this.onClose } 
              title="Editar Actividad Económica"
          >
            <EditTariff
              user={ this.props.user }
              onClose={ this.onClose } 
              activity={ this.state.activity }
              types={ this.state.types }
            />
          </Modal>

          <Modal
              className="high-modal modal-view-tariff"
              visible={ visible_view }
              onClose={ this.onCloseView } 
              title="Ver Actividad Económica"
          >
            <ViewTariff
              activity={ this.state.activity }
              types={ this.state.types }
            />
          </Modal>

          <div className="row row-filter">
            <div className="col-md-6">
                <Input
                  color="white"
                  searchRight
                  value={ this.state.form.search }
                  name="search"
                  placeholder="Buscar por código o nombre"
                  onChange={ (e: any) => {
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                      this.load(true);
                    },1000);
                    this.change(e); 
                  } } />
            </div>
            <div className="col-md-6 text-right">
              {
                checkPermission(Permissions.TARIFF.EDIT_DOLAR) && (
                  <div className="container-button-conversion">
                    <Button onClick={ this.changeDolar }>
                      Conversión Dolar - Bs
                    </Button>
                  </div>
                )
              }
              {
                checkPermission(Permissions.TARIFF.EDIT_PETRO) && (
                  <div className="container-button-conversion">
                    <Button onClick={ this.changePetro }>
                      Conversión UCD - Bs
                    </Button>
                  </div>
                )
              }
              {
                checkPermission(Permissions.TARIFF.EDIT_IVA) && (
                  <div className="container-button-conversion">
                    <Button onClick={ this.changeIVA }>
                      Porcentaje del IVA
                    </Button>
                  </div>
                )
              }

              {
                checkPermission(Permissions.TARIFF.EDIT_RETENTION) && (
                  <div className="container-button-conversion">
                    <Button onClick={ this.changeRetention }>
                      Porcentaje de Retención
                    </Button>
                  </div>
                )
              }

              {
                checkPermission(Permissions.TARIFF.EDIT_INTEREST) && (
                  <div className="container-button-conversion">
                    <Button onClick={ this.changeBankInterest }>
                      Interés Bancario
                    </Button>
                  </div>
                )
              } 

              {/* {
                checkPermission(Permissions.DISCOUNT.VIEW) && (
                  <div className="container-button-conversion">
                    <Button onClick={ this.changeDiscount }>
                      Descuento
                    </Button>
                  </div>
                )
              } */}
            </div>
          </div>

          {
            this.state.data.length > 0 && (
              <div className="container-export">
                <a href={ Constants.BASE_API + 'admin/economic-activities/excel?' + Globals.getParams(this.state.form) }>
                  <Button className="btn-blue-create">
                    Exportar Excel
                  </Button>
                </a>
              </div>
            )
          }

          {
            this.state.form != INITIAL_STATE && (
              <p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
            )
          }  

          <Table data={ this.state.data.length } title="" header={ this.state.header }>
            { this.state.data.map((i: any,index: number) => {
              return (
                <tr key={ index }>
                  <td>{ i.code || '-' }</td>
                  <td>{ i.name }</td>
                  <td>{ i.size } m2</td>
                  <td>
                    <Tooltip title="Ver">
                      <a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
                        <img src={ ViewIcon } />
                      </a>
                    </Tooltip>
                    {
                        checkPermission(Permissions.TARIFF.EDIT) && (
                          <Tooltip title="Editar">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
                              <img src={ EditIcon } />
                            </a>
                          </Tooltip>
                        )
                      }
                      {/* <Tooltip title="Imprimir">
                        <a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
                          <img src={ PrintIcon } />
                        </a>
                      </Tooltip> */}
                  </td>
                </tr>
              )
            }) }
          </Table>

          <Pagination 
            pages={ this.state.last_page } 
            active={ this.state.page }
            onChange={ async (page: number) => {
              await this.setState({
                page: page
              });
              this.load();
            } } />
        </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(Tariff);