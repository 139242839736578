import React from 'react';
import { Search } from 'assets/icons';
import CurrencyInput from 'react-currency-input';

interface Props {
	label?: string,
	name: string,
	type?: string 
	color?: string,
	placeholder?: string,
	onChange: any,
	value?: string | number
}

const maxValue = 9999999999999.9999;

const Input	= React.forwardRef((props: any, ref) => (
	<div className="form-group form-group-input" style={props.containerStyle}>
		{ props.label && <label htmlFor={ props.name }>{ props.label }</label> }
		<CurrencyInput 
			ref={ ref }
			{ ...props }
			onChange={ (event: any, maskedvalue: string, value: number) => {
				if (parseFloat(maskedvalue) < maxValue) {
					props.onChange({
						target: {
							value: maskedvalue,
							name: props.name
						}
					});	
				}
				else {
					props.onChange({
						target: {
							value: maxValue,
							name: props.name
						}
					});	
				}
			} }
			type={ props.type == 'number' ? 'text' : props.type }
			className={ `form-control ${ props.color ? 'input-'+props.color : 'input-white' }` }
			name={ props.name } />
		{
			props.searchRight && (
				<img className="image-search-input" src={ Search } />
			)
		}
	</div>
))

export default Input;

export {
	maxValue
}