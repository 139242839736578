import React from 'react';
import { Table, Pagination, Button, InputCurrency, Input, DatePicker, Tooltip, Modal } from 'components';
import { Discounts, EconomicActivityService } from 'services';
import { Globals, RegisterMovement, Constants } from 'utils';
import moment from 'moment';
import { setTimeout } from 'timers';
import { PropsView, INITIAL_STATE, DiscountForm } from './interfaces';

class ViewDiscount extends React.Component<PropsView> {

	state = {
    data: [],
    page: 1,
    last_page: 1,
    id: this.props.value,
    form: INITIAL_STATE,
    header: [
      'Código',
      'Nombre',
      'Área Máxima'
    ],
	}

	componentDidMount() {
		this.setState({
			id: this.props.value
		});

    this.loadDiscount(true);
    // this.load(true);
	}

  loadDiscount = async (withoutLoading: boolean = false) => {
    const res: any = await Discounts.admin.view(this.state.id, {
      withoutLoading,
    });

    const form = {
      amount: res.discount.amount,
      start_date: moment(res.discount.start_date).format('YYYY-MM-DD'),
      expire_date: moment(res.discount.expire_date).format('YYYY-MM-DD'),
      items: res.discount.economic_activities.map((item: any) => item.id),
    }

    this.setState({
      form,
      data: res.discount.economic_activities,
      last_page: 1,
    });
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await EconomicActivityService.admin.get({
      page: this.state.page,
      withoutLoading,
    });

    this.setState({
      data: res.activities.data,
      last_page: res.activities.last_page,
      types: res.types,
      conversion_dolar: res.conversion_dolar,
      conversion_petro: res.conversion_petro,
      iva: res.iva,
      retention: res.retention,
      bankInterest: res.bankInterest
    });
  }

	change = (e: any,callback = () => {}) => {
	    this.setState({
	      form: {
	        ...this.state.form,
	        [e.target.name]: e.target.value
	      }
	    },callback);
	}

  toggleItem = (item: number): void => {
    let _items: number[] = [...this.state.form.items]

    if (_items.includes(item)) {
      _items = _items.filter((id) => id !== item)
    } else {
      _items.push(item)
    }

    this.setState({
      form: {
        ...this.state.form,
        items: [..._items],
      }
    })
  }

	submit = async (e: any) => {
		e.preventDefault();
		if (this.state.form.amount > 100) {
			Globals.showError("Lo sentimos, el Porcentaje no puede ser mayor a 100");
			return false;
		}
		if (!this.state.form.start_date || !this.state.form.expire_date) {
			Globals.showError("Lo sentimos, ambas fechas son requeridas");
			return false;
		}

    try {
      const form = { ...this.state.form };

      form.start_date = moment(form.start_date).format('YYYY-MM-DD hh:mm:ss');
      form.expire_date = moment(form.expire_date).format('YYYY-MM-DD hh:mm:ss');

      await Discounts.admin.edit(this.state.id, form);
      // RegisterMovement.save({
      //       user_id: this.props.user.id,
      //       module_id: Constants.MODULES.TARIFF,
      //       description: `Edición del Interes Bancario a ${ Globals.formatMiles(this.state.form.value,true,'') }%`
      //   });
      // this.props.onClose(true);

		  Globals.showSuccess("Se ha creado el descuento con exito");
    } catch (error) {
      Globals.showError("Lo sentimos, ocurrio un error al crear el descuento");
    }
	}

	render() {
		return (
			<div id="admin-edit-discount">
				<form>
					<div className="row">
						<div className="col-md-12">
							<InputCurrency
                color="white"
                value={ this.state.form.amount }
                name="amount"
                label="Porcentaje"
                precision="0"
                disabled />
						</div>
            <div className="col-md-6">
							<Input
                color="white"
                value={ this.state.form.start_date }
                name="start_date"
                label="Desde"
                disabled />
            </div>
            <div className="col-md-6">
              <Input
                color="white"
                value={ this.state.form.expire_date }
                name="expire_date"
                label="Hasta"
                disabled />
            </div>
						<div className="col-md-12">
              <Table data={ this.state.data.length } title="" header={ this.state.header }>
                { this.state.data.map((i: any,index: number) => {
                  return (
                    <tr key={ index }>
                      <td>{ i.id }</td>
                      <td>{ i.name }</td>
                      <td>{ i.size } m2</td>
                    </tr>
                  )
                }) }
              </Table>

              <Pagination
                pages={ this.state.last_page }
                active={ this.state.page }
                onChange={ async (page: number) => {
                  await this.setState({
                    page: page
                  });
                  this.load();
                } } />
						</div>
						<div className="col-md-12 text-right mt-2">
              <Button className="bg-secondary" onClick={ () => this.props.onClose(false) }>
								Cancelar
							</Button>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default ViewDiscount;