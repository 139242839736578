import React from 'react';
import { Button, InputCurrency } from 'components';
import { EconomicActivityService } from 'services';
import { Globals, Constants, RegisterMovement } from 'utils';

interface Props {
	onClose: (reload: boolean) => void,
	value: number,
	user: any
}

class EditRetention extends React.Component<Props> {

	state = {
		form: {
			value: 0
		}
	}

	componentDidMount() {
		this.setState({
			form: {
				value: this.props.value
			}
		});
	}

	change = (e: any,callback = () => {}) => {
	    this.setState({
	      form: {
	        ...this.state.form,
	        [e.target.name]: e.target.value
	      }
	    },callback);
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.state.form.value > 100) {
			Globals.showError("Lo sentimos, el valor no puede ser mayor a 100");
			return false;
		}
		await EconomicActivityService.admin.editRetention({
			amount: this.state.form.value
		});
		RegisterMovement.save({
	        user_id: this.props.user.id,
	        module_id: Constants.MODULES.TARIFF,
	        description: `Edición de la retención a ${ Globals.formatMiles(this.state.form.value,true,'') }%`
	    });
		this.props.onClose(true);
		Globals.showSuccess("Se ha modificado el valor del IVA");
	}
	
	render() {
		return (
			<div id="admin-edit-iva">
				<form onSubmit={ this.submit }>
					<div className="row">
						<div className="col-md-12">
							<InputCurrency
	                          color="white"
	                          value={ this.state.form.value }
	                          name="value" 
	                          placeholder="Porcentaje"
	                          onChange={ this.change } />
						</div>
						<div className="col-md-12">
							<Button type="submit">
								Guardar
							</Button>
						</div>	
					</div>
				</form>				
			</div>
		)
	}
}

export default EditRetention;