import React from 'react';
import { AccountStatusService } from 'services';
import { Table, Pagination, TooltipCurrency } from 'components';
import { Globals, Currency, Constants } from 'utils';
import moment from 'moment';

interface Props {
	property: any
}

class ViewProperty extends React.Component<Props> {

	state = {
		property: null,
    payments:[],
		header: [
			'Fecha',
			'Tipo de Servicio',
			'Subtotal',
			'IVA',
			'Multa',
      'Intereses',
			'Total'
		],
		page:1,
		last_page:1,
		conversion_dolar: {
			amount: 0
		},
		conversion_petro: {
			amount: 0
		},
		total_fines: 0,
    total_debt_fines: 0,
		total_fines_clean: 0,
		total_iva: 0,
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await AccountStatusService.admin.getPayments({
			page: this.state.page,
			id: this.props.property?.id
		});
		this.setState({
			property: this.props.property,
      payments: res.payments.data,
			last_page: res.payments.last_page,
			conversion_dolar: res.conversion_dolar,
			conversion_petro: res.conversion_petro,
			total_fines: res.total_fines,
			total_fines_clean: res.total_fines_clean,
      total_debt_fines: res.total_debt_fines + res.total_iva,
      total_iva: res.total_iva,
		});
	}

	render() {
		const property: any = this.state.property;
    const payments: any = this.state.payments;
    const RESIDENTIAL_TYPE: any = Constants.PROPERTY_TYPES.RESIDENTIAL;

		return (
			<div id="admin-view-client">
				{
					property && (
						<React.Fragment>
							<h3>Datos de la propiedad</h3>
                <div className="container-data">
									<div className="item-data">
										<p>Código: { property.user.username }</p>
										<p>Tipo: { property.type?.name ?? '' }</p>
										<p>Tamaño: { property.size } m2</p>
										<p>Actividad comercial: { property.economic_activity.short }</p>
									</div>
									<div className="item-data">
										{/* <p>Código Contribuyente: { property.user.number_format }</p> */}
										<p>Propietario: { property.user.full_name }</p>
										<p>Email: { property.user.email }</p>
										<p>Teléfono: { property.user.full_phone }</p>
										<p>
                    Subtotal:{" "}
                    <strong>
                      <TooltipCurrency title={property.subtotal_property}>
                        {Globals.formatMiles(
                          Currency.Convert(
                            property.subtotal_property,
                            this.state.conversion_petro.amount
                          ),
                          true,
                          Currency.BOLIVAR
                        )}
                      </TooltipCurrency>
                    </strong>
                  </p>
                  <p>
                    IVA:{" "}
                    <strong>
                      <TooltipCurrency title={this.state.total_iva}>
                        {Globals.formatMiles(
                          Currency.Convert(
                            this.state.total_iva,
                            this.state.conversion_petro.amount
                          ),
                          true,
                          Currency.BOLIVAR
                        )}
                      </TooltipCurrency>
                    </strong>
                  </p>
                  <p>
                    Multas:{" "}
                    <strong>
                      <TooltipCurrency title={this.state.total_fines}>
                        {Globals.formatMiles(
                          Currency.Convert(
                            this.state.total_fines,
                            this.state.conversion_petro.amount
                          ),
                          true,
                          Currency.BOLIVAR
                        )}
                      </TooltipCurrency>
                    </strong>
                  </p>
                  <p>
                    Intereses:{" "}
                    <strong>
                      <TooltipCurrency title={this.state.total_fines_clean}>
                        {Globals.formatMiles(
                          Currency.Convert(
                            this.state.total_fines_clean,
                            this.state.conversion_petro.amount
                          ),
                          true,
                          Currency.BOLIVAR
                        )}
                      </TooltipCurrency>
                    </strong>
                  </p>
                  <p>
                    Total Deuda:{" "}
                    <strong>
                      <TooltipCurrency title={this.state.total_debt_fines}>
                        {Globals.formatMiles(
                          Currency.Convert(
                            this.state.total_debt_fines,
                            this.state.conversion_petro.amount
                          ),
                          true,
                          Currency.BOLIVAR
                        )}
                      </TooltipCurrency>
                    </strong>
                  </p>
									</div>
								</div>
						</React.Fragment>
					)
				}

				{
					payments.length > 0 && (
						<React.Fragment>
							<hr />
							<h3>Pagos pendientes</h3>

							<Table title="" data={ payments } header={ this.state.header }>
								{
									payments.map((payment: any,index: number) => {
										return (
											<tr>
												<td>{ moment(payment.created_at).format('DD/MM/YYYY') }</td>
												<td>{ payment.service_type.name }</td>
												<td className="text-right">
													<TooltipCurrency title={ payment.total }>{ Globals.formatMiles(Currency.Convert(payment.total, this.state.conversion_petro.amount),true,Currency.BOLIVAR) }</TooltipCurrency>
												</td>
												<td className="text-right">
                          <TooltipCurrency title={ property.type.id != RESIDENTIAL_TYPE ? payment.iva : '-' }>
                            { property.type.id != RESIDENTIAL_TYPE ? Globals.formatMiles(Currency.Convert(payment.iva, this.state.conversion_petro.amount),true,Currency.BOLIVAR) : '-'}
                          </TooltipCurrency>
                        </td>
												<td className="text-right">
													<TooltipCurrency title={payment.fines_payment}>
														{Globals.formatMiles(
															Currency.Convert(payment.fines_payment, this.state.conversion_petro.amount
															),
															true,
															Currency.BOLIVAR
														)}
													</TooltipCurrency>
												</td>
												<td className="text-right">
													<TooltipCurrency title={payment.fines_clean}>
														{Globals.formatMiles(
															Currency.Convert(payment.fines_clean, this.state.conversion_petro.amount
															),
															true,
															Currency.BOLIVAR
														)}
													</TooltipCurrency>
												</td>
                        <td className="text-right">
                          <TooltipCurrency title={ property.type.id != RESIDENTIAL_TYPE ? payment.total_with_iva : payment.total }>
                            { Globals.formatMiles(Currency.Convert(property.type.id != RESIDENTIAL_TYPE ? payment.total_with_iva : payment.total, this.state.conversion_petro.amount),true,Currency.BOLIVAR) }
                          </TooltipCurrency>
                        </td>
											</tr>
										)
									})
								}
							</Table>
							<Pagination 
								pages={ this.state.last_page } 
								active={ this.state.page }
								onChange={ async (page: number) => {
									await this.setState({
										page: page
									});
									this.load();
							} } />
						</React.Fragment>
					)
				}
			</div>
		)
	}
}

export default ViewProperty;