import { Api } from 'utils';

class AuthService {
	
	static login = async (params?: any) => Api.createResponse('auth/login',params);
	static check = async (params?: any) => Api.createResponse('auth/check',params);
	static checkCode = async (params?: any) => Api.createResponse('auth/check-code',params);
	static recover = async (params = {}) => Api.createResponse('auth/recover',params);
	static reset = async (params = {}) => Api.createResponse('auth/reset',params);
	static register = async (params?: any) => Api.createResponse('auth/register',params);
	static phoneCodes = async (params?: any) => Api.createResponse('auth/phone-codes',params);
	static checkBadge = async (params?: any) => Api.createResponse('auth/check-badge',params);
	static getDocumentTypes = async (params?: any) => Api.createResponse('auth/document-types',params);
}

export default AuthService;