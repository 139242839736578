import { Api, Constants } from 'utils';

class AgreementService {

	static get = async (params?: any) => Api.createResponse('agreements',params);
	static download = async (id?: string) => Api.createResponse('agreements/print/' + id);

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/agreements',params),
		finish: async (params?: any) => Api.createResponse('admin/agreements/finish',params),
		getClients: async (params?: any) => Api.createResponse('admin/agreements/clients',params),
		getProperties: async (params?: any) => Api.createResponse('admin/agreements/properties',params),
		download: async (id?: string) => Api.createResponse('admin/agreements/print/' + id),
		uploadFile: async (params?: any) => Api.createResponse('admin/agreements/upload',params),
	}
}

export default AgreementService;