import React, { useEffect, useState } from 'react'
import { RootState } from 'reducers'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { SelectSearch, Button, Input, Checkbox, Tooltip } from 'components'
import { Constants } from 'utils'

import {
  CashContextProvider,
  CashDetailProperty,
  CashPaymentDetails,
  CashInvoices,
  CashListVouchers,
  CashModalFines,
  CashModalPayments,
  CashModalServices,
  CashModalConfirmPetroValue,
  CashRootProps,
  useCashContext,
} from './components'

const CashPage = () => {
  const { id, urb } = useParams() as { id: string; urb: string }

  const {
    user,
    searchValue,
    setSearchValue,
    searchPropertiesWithClient,
    handleSelectSearch,
    clients,
    property,
    creditApply,
    setCreditApply,
    isCondominium,
    resetAll,
    generatePreinvoice,
    finish,
    loadingFinish,
    userIsClient,
    visibleConfirmPetroValue,
    setVisibleConfirmPetroValue,
  } = useCashContext()

  const [visibleModalPayments, setVisibleModalPayments] = useState(false)
  const [visibleModalFines, setVisibleModalFines] = useState(false)
  const [visibleModalServices, setVisibleModalServices] = useState(false)

  useEffect(() => {
    if (userIsClient) {
      searchPropertiesWithClient(user.id)
    } else {
      setVisibleConfirmPetroValue(true)

      if (id) {
        setSearchValue({ value: id, label: urb })
        searchPropertiesWithClient(urb)
      }
    }
  }, [])

  return (
    <div id="admin-cash">
      {!userIsClient &&
        <CashModalConfirmPetroValue visible={visibleConfirmPetroValue} setVisible={setVisibleConfirmPetroValue} />
      }
      <CashModalPayments visible={visibleModalPayments} setVisible={setVisibleModalPayments} />
      <CashModalFines visible={visibleModalFines} setVisible={setVisibleModalFines} />
      <CashModalServices visible={visibleModalServices} setVisible={setVisibleModalServices} />

      <div className="container-white mb-5">
        <SelectSearch
          label={userIsClient ? 'Propiedad' : 'Cliente'}
          onEnter={searchPropertiesWithClient}
          onChange={handleSelectSearch}
          placeholder="Seleccionar Cliente"
          value={searchValue}
          options={clients}
        />
      </div>

      <div className="d-flex">
        <div className="flex-grow-1 pr-3">
          <div>
            <Button
              className="btn-green-create btn-filter mr-3"
              onClick={(e: any) => {
                if (!property) return
                setVisibleModalPayments(true)
              }}
            >
              Agregar Deuda
            </Button>
            {!userIsClient && (
              <>
                <Button
                  className="btn-green-create btn-filter mr-3"
                  onClick={(e: any) => {
                    if (!property) return
                    setVisibleModalFines(true)
                  }}
                >
                  Agregar Multa
                </Button>
                <Button
                  className="btn-green-create btn-filter mr-3"
                  onClick={(e: any) => {
                    if (!property) return
                    setVisibleModalServices(true)
                  }}
                >
                  Agregar servicio
                </Button>
              </>
            )}
          </div>

          <div className="mt-3">
            <CashInvoices />
          </div>
        </div>

        <div className="flex-grow-0" style={{ width: '500px' }}>
          {/* Datos */}
          <CashDetailProperty />

          {/* Tabla */}
          <div className="mx-n1">
            <CashListVouchers />
          </div>

          {/* Form */}
          <div className="row mt-4">
            <Tooltip title="Al aplicar el crédito, se restará del monto total">
              <div className="col-6 offset-6 position-relative">
                <Input type="number" value={property?.total_credit_notes_bs ?? 0} name="number" label="Crédito" disabled readonly />
                <div className="position-absolute" style={{ top: 34, right: 20 }}>
                  <Checkbox
                    name="apply"
                    label="Aplicar"
                    inline={true}
                    checked={creditApply}
                    disabled={!!property?.retention}
                    onChange={() => setCreditApply(!creditApply)}
                  />
                </div>
              </div>
            </Tooltip>
          </div>

          {/* Detalles */}
          <CashPaymentDetails />

          {/* Acciones */}
          <div className="text-right">
            <Button color="outline-danger" className="mr-3" disabled={loadingFinish} onClick={resetAll}>
              Limpiar
            </Button>
            <Button className="" disabled={loadingFinish} onClick={finish}>
              {loadingFinish ? 'Procesando...' : 'Procesar'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
/** END Cash Components */

const CashRoot = ({ user, match }: CashRootProps) => {
  return (
    <CashContextProvider user={user}>
      <CashPage />
    </CashContextProvider>
  )
}

export default connect((state: RootState) => {
  return {
    user: state.user,
  }
})(CashRoot)
