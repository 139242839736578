import React from 'react';
import { Input, Textarea, Select, Button } from 'components';
import { ProfileService } from 'services';
import moment from 'moment';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { Globals, Constants, Numeric } from 'utils';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Profile extends React.Component<Props> {

	state = {
		form: {
			name: '',
			lastname: '',
			username: '',
			document: '',
			document_type_id: '',
	        email: '',
	        phone: '',
		},
		document_types: [],
		codes: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Mis Datos',
				onBack: null
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await ProfileService.get({
			user_id: this.props.user.id
		});
		this.setState({
			form: {
				name: res.user.name,
				lastname: res.user.lastname,
				username: res.user.username,
				document: res.user.document,
				document_type_id: res.user.document_type_id,
		        email: res.user.email,
		        phone: Numeric.getOnlyNumber(res.user.phone)
			},
			document_types: res.document_types,
			codes: res.codes
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		const res: any = await ProfileService.update({
			user_id: this.props.user.id,
			...this.state.form
		});
		Globals.showSuccess("Se ha actualizado el perfil correctamente");
		this.props.dispatch({
			type: 'SET_USER',
			payload: res.user
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="profile">
				<h3>Datos Generales</h3>
				<form className="row" onSubmit={ this.submit }>	
					<div className="row col-12 container-form">
						<div className="col-lg-6">
			              <Input
			                color="white"
			                value={ this.state.form.name }
			                name="name" 
			                label="Nombre"
			                onChange={ this.change } />
			            </div>
			            <div className="col-lg-6">
			              <Input
			                color="white"
			                value={ this.state.form.lastname }
			                name="lastname" 
			                label="Apellido"
			                onChange={ this.change } />
			            </div>
			            <div className="col-lg-6">
			              <Input
			                color="white"
			                value={ this.state.form.username }
			                name="username" 
			                label="Cuenta"
							readonly="1"
			                onChange={ this.change } />
			            </div>
			            <div className="col-lg-6">
			              <p className="label">C.I. / RIF</p>
			              <div className="i-flex">
			                <div className="doc-flex">
			                  <Select
			                    className="select-phone"
			                    color="gray"
			                    name="document_type_id"
			                    onChange={ this.change }
			                    value={ this.state.form.document_type_id }
			                    placeholder="Tipo"
			                    options={ this.state.document_types.map((i: any) => {
			                      return {
			                        value: i.id,
			                        label: i.name
			                      }
			                    }) } />
			                </div>
			                <div className="doc-flex">
			                  <Input
			                    color="white"
			                    value={ this.state.form.document }
			                    name="document" 
			                    type="number"
			                    placeholder="C.I. / RIF"
			                    onChange={ this.change } />
			                </div>
			              </div>
			            </div>
			            <div className="col-lg-6">
			              <Input
			                color="white"
			                value={ this.state.form.email }
			                name="email" 
			                label="Correo Electrónico"
			                onChange={ this.change } />
			            </div>
			            <div className="col-lg-6">
		                   <Input
		                    type="number"
		                    color="white"
		                    value={ this.state.form.phone }
		                    name="phone" 
		                    label="Teléfono"
		                    onChange={ this.change } />
			            </div>
					</div>					
					<div className="col-12 text-center">
						<Button type="submit">
							Guardar
						</Button>													
					</div>
				</form>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Profile);