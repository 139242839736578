import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Globals, Constants, Permissions } from 'utils';
import {
	Menu as MenuIcons
} from 'assets/icons';
import Header from './header';
import $ from 'jquery';
import { Logo, NewLogo } from 'assets/img';
import { AuthService } from 'services';

interface MenuItem {
	name: string;
	path: string;
	submenu?: MenuItem[];
	icon?: string;
	permission?: string;
	onClick?: () => void;
	iconActive?: string;
	code: string;
}

const CODES = {
	MESSAGES: 'messages'
}

class Menu extends React.Component<any> {

	logout = () => {
		Globals.confirm('¿Desea cerrar sesión?',async () => {
			await this.props.dispatch({
				type: 'SET_USER',
				payload: null
			});
			this.props.history.replace('/login');
			Globals.quitLoading();
		});
	}

	componentDidMount() {
		// this.checkBadge();
	}

	checkBadge = async () => {
      if (this.props.user) {
        const res: any = await AuthService.checkBadge({
          user_id: this.props.user.id,
          withoutLoading: true
        });
        this.props.dispatch({
          type: 'SET_BADGE',
          payload: res
        });
      }
    }

	state: any = {
		leftOpen: true,
		openResponsive: false,
		items: [
			{ name: 'Registro', path: '/admin/users', icon: MenuIcons.MenuHome, iconActive: MenuIcons.MenuHome, permission: [
				Permissions.CLIENTS.VIEW,
				Permissions.MODERATORS.VIEW
			], submenu: [
					{ name: 'Contribuyentes', path: '/admin/users/clients', permission: Permissions.CLIENTS.VIEW },
					{ name: 'Administradores', path: '/admin/users/moderators', permission: Permissions.MODERATORS.VIEW }
				]
			},	
			{ name: 'Tarifas', path: '/admin/tariff', icon: MenuIcons.MenuTarifas, iconActive: MenuIcons.MenuTarifas, permission: Permissions.TARIFF.VIEW },
			{ name: 'Record de Cuenta', path: '/admin/account-status', icon: MenuIcons.MenuRecord, iconActive: MenuIcons.MenuRecord, permission: Permissions.ACCOUNT_STATUS.VIEW_RECORD },
			// { name: 'Caja Viejo', path: '/admin/cash', icon: MenuIcons.MenuCaja, iconActive: MenuIcons.MenuCaja, permission: Permissions.CASH.VIEW },
			{ name: 'Caja', path: '/admin/cash-new', icon: MenuIcons.MenuCaja, iconActive: MenuIcons.MenuCaja, permission: Permissions.CASH.VIEW },
			{ name: 'Historial de comprobantes', path: '/admin/voucher-history', icon: MenuIcons.MenuComprobante, iconActive: MenuIcons.MenuComprobante, permission: Permissions.VOUCHER_HISTORY.VIEW },
			{ name: 'Certificado de Pago', path: '/admin/urban-cleaning-solvencies',icon: MenuIcons.MenuSolvencia, iconActive: MenuIcons.MenuSolvencia, permission: Permissions.URBAN_CLEANLINESS.VIEW },
			{ name: 'Pagos', path: '/admin/payments', icon: MenuIcons.MenuSolvencia, iconActive: MenuIcons.MenuSolvencia, permission: Permissions.PAYMENTS.VIEW },
			// { name: 'Aseo urbano', path: '/admin/urban-cleanliness', icon: MenuIcons.MenuSolvencia, iconActive: MenuIcons.MenuSolvencia, permission: Permissions.URBAN_CLEANLINESS.VIEW }, //Estaba comentado
			{ name: 'Convenios de pago', path: '/admin/payment-agreements', icon: MenuIcons.MenuConvenios, iconActive: MenuIcons.MenuConvenios, permission: Permissions.PAYMENT_AGREEMENTS.VIEW },
			{ name: 'Reportes', path: '/admin/reports', icon: MenuIcons.MenuReportes, iconActive: MenuIcons.MenuReportes, permission: Permissions.REPORTS.VIEW, 
			    submenu: [
					{ name: '1 Libro de Ventas', path: '/admin/reports/sales-book', permission: Permissions.REPORTS.VIEW },
					{ name: '2 Reporte de Cobro Vencido', path: '/admin/reports/overdue-collection-report', permission: Permissions.REPORTS.VIEW },
					// { name: '3 Reporte de Ingresos', path: '/admin/reports/income-report', permission: Permissions.REPORTS.VIEW },//Estaba comentado
					// { name: '4 Reporte de Convenios', path: '/admin/reports/report-agreements', permission: Permissions.REPORTS.VIEW },//Estaba comentado
					// { name: '5 Reporte de Retenciones', path: '/admin/reports/retention-report', permission: Permissions.REPORTS.VIEW },//Estaba comentado
					{ name: '3 Conciliación Bancaria', path: '/admin/reports/bank-reconciliation', permission: Permissions.REPORTS.VIEW },
					{ name: '4 Facturas Anuladas', path: '/admin/reports/voided-invoices', permission: Permissions.REPORTS.VIEW },
					{ name: '5 Reporte de Auditoria', path: '/admin/reports/audit-report', permission: Permissions.REPORTS.VIEW },
				]
			 },
			{ name: 'Cuentas bancarias', path: '/admin/bank-accounts', icon: MenuIcons.MenuCuentas, iconActive: MenuIcons.MenuCuentas, permission: Permissions.BANK_ACCOUNTS.VIEW },
			{ name: 'Rutas y horarios', path: '/admin/routes-schedules', icon: MenuIcons.MenuRutas, iconActive: MenuIcons.MenuRutas, permission: Permissions.ROUTES_SCHEDULES.VIEW },		
			{ name: 'Denuncias y sugerencias', code: CODES.MESSAGES, path: '/admin/complaints-suggestions', icon: MenuIcons.MenuDenuncias, iconActive: MenuIcons.MenuDenuncias, permission: Permissions.COMPLAINTS_SUGGESTIONS.VIEW },		
			{ name: 'Salir', icon: MenuIcons.MenuSalir, iconActive: MenuIcons.MenuSalir, onClick: this.logout },
		],
		items_client: [
			{ name: 'Mis Datos', path: '/client/profile', icon: MenuIcons.MenuUser, iconActive: MenuIcons.MenuUser },
			{ name: 'Inmuebles', path: '/properties', icon: MenuIcons.MenuHome, iconActive: MenuIcons.MenuHome },
			{ name: 'Record de Cuenta', path: '/account-status', icon: MenuIcons.MenuRecord, iconActive: MenuIcons.MenuRecord },
			{ name: 'Realizar pago', path: '/cash', icon: MenuIcons.MenuCaja, iconActive: MenuIcons.MenuCaja },
			// { name: 'Historial de Facturación', path: '/bill-history' },//Estaba comentado
			{ name: 'Historial de comprobantes', path: '/voucher-history', icon: MenuIcons.MenuComprobante, iconActive: MenuIcons.MenuComprobante },
			{ name: 'Certificado de Pago', path: '/urban-cleanliness', icon: MenuIcons.MenuSolvencia, iconActive: MenuIcons.MenuSolvencia },
			{ name: 'Convenio de Pagos', path: '/payment-agreements', icon: MenuIcons.MenuConvenios, iconActive: MenuIcons.MenuConvenios },
			{ name: 'Pagos', path: '/payments', icon: MenuIcons.MenuPagos, iconActive: MenuIcons.MenuPagos },
			{ name: 'Rutas y horarios', path: '/routes-schedules', icon: MenuIcons.MenuRutas, iconActive: MenuIcons.MenuRutas },		
			{ name: 'Denuncias y sugerencias', code: CODES.MESSAGES, path: '/complaints-suggestions', icon: MenuIcons.MenuDenuncias, iconActive: MenuIcons.MenuDenuncias },
			{ name: 'Salir', icon: MenuIcons.MenuSalir, iconActive: MenuIcons.MenuSalir, onClick: this.logout },
		]
	}

	toggleSidebar = () => {
	    let key: any = `leftOpen`;
	    // @ts-ignore
	    this.setState({ [key]: !this.state[key] });
	}

	checkActive = (value: string, current: string) => {
		return current.includes(value);
	}

	changeMenu = (item: MenuItem) => {
		if (item.submenu && !this.state.leftOpen) {
			this.toggleSidebar();
		}
	}

	checkPermission = (item: any) => {
		const permissions = this.props.user?.permissions.map((i: any) => i.code);
		if (Array.isArray(item.permission)) {
			let _continue = false;
			item.permission.forEach((i: any) => {
				if (permissions.indexOf(i) != -1) {
					_continue = true;
				}
			});
			return _continue;
		}
		else {
			return permissions.indexOf(item.permission) != -1;
		}		
	}

	getBadge = (code: string) => {
		let badge = null;
		switch(code) {
			case CODES.MESSAGES:
				badge = this.props.badge.MESSAGES || 0;
			break;
		}
		return badge;
	}

	closeNavbar = () => {
		// @ts-ignore
		$('#collapsibleNavbar').collapse('hide');
	}

	toggleResponsive = () => {
		this.setState({
	  		openResponsive: !this.state.openResponsive
	  	});
	}

	closeResponsive = () => {
		this.setState({
	  		openResponsive: !this.state.openResponsive
	  	});
	}

	render() {
		let leftOpen = !this.props.isAuth && this.state.leftOpen ? "open" : "closed";

		const isClient = this.props.user?.level_id == Constants.LEVELS.CLIENT || (this.props.user?.level_id == Constants.LEVELS.MODERATOR && this.props.user?.user_id);
		const isModerator = this.props.user?.level_id == Constants.LEVELS.MODERATOR && !this.props.user?.user_id;
		const isContractorAdmin = this.props.user?.level_id == Constants.LEVELS.CLIENT || this.props.user?.level_id == Constants.LEVELS.ADMIN;

		let items = [];
		if (this.props.isAuth) {
			items = [];
		}
		else if (!this.props.user) {
			items = [];
		}
		else if (isClient) {
			items = this.state.items_client;
		}
		else {
			items = this.state.items;
		}

		return (
			<React.Fragment>
				{
					items.length > 0 && (
						<nav className="navbar navbar-expand-md bg-dark navbar-dark fixed-top justify-content-start">
						  <button className="navbar-toggler" type="button" onClick={ this.toggleResponsive }>
						    <span className="navbar-toggler-icon"></span>
						  </button>
					   </nav>
					)
				}

				<div id="left" className={ leftOpen }>
					
					{
						!this.props.isAuth && (
							<div className={ `toggle-menu` } onClick={ this.toggleSidebar }>
								<i className={ `fa fa-${ leftOpen != 'closed' ? 'arrow-circle-left' : 'arrow-circle-right' }` }></i>
					        </div>
						)
					}					

					<div className={`sidebar ${ leftOpen + ' ' + (this.state.openResponsive ? 'open-responsive' : '') }`}>
						<div className={ `shadow-close ${ this.state.openResponsive ? 'open' : '' }` } onClick={ this.closeResponsive }></div>
						<div className="container-menu">
							<div className="menu">
								<div className={ `p-2 container-logo ${ leftOpen }` }>
									<img src={ NewLogo } />
								</div>

								<div className="bg-orange"></div>

								<div className="container-scroll">
									<ul className={ leftOpen }>
										{ items.map((i: MenuItem,index: number) => {
											const badge: any = this.getBadge(i.code);

											return (
												<React.Fragment key={ index }>
													{
														// TODO: Agregar i.path && al inicio por que en ocaciones viene undefined y da error en el componente
														(!i.permission || isContractorAdmin || this.checkPermission(i)) && (
															<li className={ 'nav-item ' + (this.checkActive(i.path,this.props.history.location.pathname) ? 'active' : '') }>
																<Link to={ i.path } onClick={ (e: any) => {
																	if (i.onClick) {
																		e.preventDefault();
																		i.onClick();
																	}
																	else {
																		this.changeMenu(i);
																		this.closeResponsive();
																	}																	
																} }>
																	{i.icon && (
																		<img
																			src={this.checkActive(i.path, this.props.history.location.pathname) ? i.iconActive : i.icon}
																			className={this.checkActive(i.path, this.props.history.location.pathname) ? 'active-icon' : ''}
																		/>
                        											)}													
																	<span className={ leftOpen }>{ i.name }</span>
																	{ badge != null && badge > 0 && <div className="badge">{ badge }</div> }
																</Link>
																{
																	leftOpen != 'closed' && this.checkActive(i.path,this.props.history.location.pathname) && i.submenu && i.submenu.map((item: MenuItem,index: number) => {
																		if (!item.permission || !isModerator || this.checkPermission(item)) {
																			return (
																				<li className={ 'nav-item item-submenu ' + (this.props.history.location.pathname == item.path ? 'active' : '') }>
																					<Link to={ item.path } className="submenu">
																						{ item.name }
																					</Link>
																				</li>
																			)
																		}
																	})
																}
															</li>
														)
													}												
												</React.Fragment>
											)
										}) }
									</ul>
								</div>
							</div>
						</div>
						<div className={ `container-router ${ leftOpen }` }>
							<Header className={ leftOpen + ' ' + (items.length > 0 && 'header-responsive') } />
							<div className={ `container-padding-router ${ items.length > 0 && 'container-responsive' }` }>{ this.props.children }</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user,
		badge: state.badge
	}
})(Menu);