import { combineReducers } from 'redux';
import user from './user';
import header from './header';
import badge from './badge';
import chat from './chat';

export const reducer = combineReducers({
    user,
    header,
    badge,
    chat
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;